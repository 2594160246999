import React, { useState, useRef, useEffect, useCallback } from "react";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { AppConstants } from "../../../constants/AppConstants";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { DataService } from '../../../services/DataService';
import { AuthorizedDataService } from '../../../services/AuthorizedDataService';
import classes from './StudentUpdateProfilePage.module.scss';
import { StudentHeader } from '../../../layout/StudentPageLayout/StudentHeader';
import { Button, Form, Card, Modal } from "react-bootstrap";
import { FaCheckCircle, FaEdit, FaTimes } from 'react-icons/fa';
import { CiUser, CiMail, CiCalendar, CiMobile1 } from "react-icons/ci";
import OTPInput, { ResendOTP } from "otp-input-react";
import { BiLoaderAlt, BiChevronDown } from 'react-icons/bi';
import Auth from '../../../utils/Auth';
import { SuccessMessage } from '../../../constants/SuccessMessage';
import Tabs from '../../../component/studentProfilePage/StudentProfilePageTabs';
import { ProfileSkillCategoryTab } from '../../../component/studentProfilePage/ProfileSkillCategoryTab';
import { RiInformationFill } from "react-icons/ri";
import formClasses from "../../../common/FormStyling.module.css";
import { MdOutlineDelete } from "react-icons/md";
import { ErrorSuccessAlertMessage } from "../../../component/errorSuccessMessage/ErrorSuccessAlertMessage";
import { Logout } from "../../../common/Logout";

export const StudentUpdateProfilePage = () => {
    const [profilePictureFile, setProfilePictureFile] = useState({});
    const initialValues = { userImagePath: AppConstants.emptyString, countryCode: AppConstants.emptyString, userName: AppConstants.emptyString, firstName: AppConstants.emptyString, lastName: AppConstants.emptyString, userEmail: AppConstants.emptyString, country: AppConstants.emptyString, mobileNumber: AppConstants.emptyString, state: AppConstants.emptyString, city: AppConstants.emptyString, dob: AppConstants.emptyString, gender: AppConstants.emptyString, selectedSkills: [] };
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultProfilePicture = `${mediaContent}${AppConstants.profileImageS3Path}`;
    const profileOverlayPicture = `${mediaContent}${AppConstants.profileOverlayImageS3Path}`;
    const backdropElementDesktop = `${mediaContent}${AppConstants.studentProfileBackgroundDesktop}`;
    const backdropElementMobile = `${mediaContent}${AppConstants.studentProfileBackgroundMobile}`;
    const [errorSuccessAlertVarient, setErrorSuccessAlertVarient] = useState(AppConstants.emptyString);
    const [errorSuccessAlertMessage, setErrorSuccessAlertMessage] = useState(AppConstants.emptyString);
    const [imageError, setImageError] = useState(AppConstants.emptyString);
    const [interestedSkill, setInterestedSkill] = useState(AppConstants.emptyString);
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSessionExpired, setIsSessionExpired] = useState(AppConstants.falseText);

    // const [phoneVerified, setPhoneVerified] = useState(AppConstants.falseText);
    const [phoneVerified, setPhoneVerified] = useState(AppConstants.trueText);
    const [profileCompletion, setProfileCompletion] = useState(AppConstants.falseText);
    const [userAge, setUserAge] = useState(AppConstants.emptyString);
    const [showSpinner, setShowSpinner] = useState(AppConstants.falseText);
    const [countriesList, setCountriesList] = useState([]);
    const [statesList, setStatesList] = useState([]);
    const [citiesList, setCitiesList] = useState([]);
    const [selectedState, setSelectedState] = useState(AppConstants.emptyString);
    const [selectedCountry, setSelectedCountry] = useState(AppConstants.emptyString);
    const [profilePic, setProfilePic] = useState();
    const [showProfilePreview, setShowProfilePreview] = useState(AppConstants.falseText);
    const hiddenFileInput = useRef(null);
    const [qrenciaCategoryWiseSkillsDetails, setQrenciaCategoryWiseSkillsDetails] = useState([]);
    const [notInterestedSkills, setNotInterestedSkills] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [initialRender, setInitialRender] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(AppConstants.emptyString);
    const [mobileOtp, setMobileOtp] = useState(AppConstants.emptyString);
    const [checkedCategories, setCheckedCategories] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [initialMobileNumber, setInitialMobileNumber] = useState(AppConstants.emptyString)
    const [mobileVerificationScreen, setMobileVerificationScreen] = useState(AppConstants.falseText);
    const [callingCode, setCallingCode] = useState([]);
    const userDetails = Auth.getLoggedInUserDetails();

    useEffect(() => {
        if (window.innerWidth > 991) {
            document.documentElement.style.setProperty('--background-image', `url(${backdropElementDesktop})`);
        } else {
            document.documentElement.style.setProperty('--background-image', `url(${backdropElementMobile})`);
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setImageError(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);

        return () => clearTimeout(timer);
    }, [imageError]);

    // commented code for Mobile verification

    // useEffect(() => {
    //     if (initialMobileNumber === `${callingCode}${formValues.mobileNumber}`) {
    //         setPhoneVerified(AppConstants.trueText)
    //     } else {
    //         setPhoneVerified(AppConstants.falseText);
    //     }

    // }, [formValues.mobileNumber, initialMobileNumber, callingCode])

    const renderButton = buttonProps => {
        return (
            <button className={classes.resendOtpButton} {...buttonProps}>
                {buttonProps.remainingTime !== 0
                    ? `Resend code (in ${buttonProps.remainingTime} sec)`
                    : "Resend"}
            </button>
        )
    };

    const renderTime = () => React.Fragment;

    useEffect(() => {
        getFilteredList()
    }, [selectedCategory, initialRender])

    function getFilteredList() {
        if (selectedCategory === AppConstants.emptyString || selectedCategory === undefined) {
            setSelectedCategory(categoryList[0]);
        }
        let filteredSkillsArr = [];
        if (selectedCategory === AppConstants.emptyString) {
            filteredSkillsArr = notInterestedSkills?.filter(item => item.category === categoryList[0]);
        }
        else {
            filteredSkillsArr = notInterestedSkills?.filter(item => item.category === selectedCategory);
        }
        setFilteredList(filteredSkillsArr);
    }

    useEffect(() => {
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                var data = new Map();
                for (let obj of response) {
                    data.set(obj.categoryId, obj.category);
                }
                let categories = [...data.values()];
                setCategoryList(categories);
                setSelectedCategory(categories[0]);
                setQrenciaCategoryWiseSkillsDetails(response);
                setNotInterestedSkills(response);
            } else {
                setQrenciaCategoryWiseSkillsDetails([]);
                setNotInterestedSkills([]);
            }
            setInitialRender(true);
        }

        async function fetchCountries() {
            const url = AppConstants.fetchCountriesAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setCountriesList(response);
        };

        const fetchStates = async () => {
            var config = { headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates } };
            const response = await fetch(AppConstants.getStatesApi, config);
            const responseData = await response.json();
            const sortedData = responseData.sort((a, b) => a.name.localeCompare(b.name));
            setStatesList(sortedData);
        };

        fetchCountries();
        fetchStates();
        fetchSkills();

    }, []);


    const updateLocalStorageDetails = (refreshData) => {
        const userDetails = Auth.getLoggedInUserDetails();

        localStorage.setItem(AppConstants.sessionKeyUserDetails, JSON.stringify({
            ...userDetails,
            ...refreshData
        }));
    };

    const fetchUserInfo = useCallback(async () => {
        const url = AppConstants.fetchLoggedInStudentInfoAPI + Auth.getLoggedInUserDetails().userId;
        const response = await AuthorizedDataService.getRequestWithResponseCode(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        let loggedUserInfo;

        if (response.status === AppConstants.httpResponseCodes.responseCode403) {
            setIsSessionExpired(AppConstants.trueText);
            loggedUserInfo = {};
        } else if (response.status === AppConstants.httpResponseCodes.responseCode400 || response.status === AppConstants.httpResponseCodes.responseCode500) {
            loggedUserInfo = {}
        } else {
            loggedUserInfo = response;
            setFormValues({
                profileImagePreSignedUrl: loggedUserInfo.userImagePath,
                userName: loggedUserInfo.userName,
                firstName: loggedUserInfo.firstName,
                lastName: loggedUserInfo.lastName,
                userEmail: loggedUserInfo.userEmail,
                mobileNumber: loggedUserInfo.mobileNumber,
                gender: loggedUserInfo.gender || AppConstants.emptyString,
                userImagePath: loggedUserInfo.userImagePath || AppConstants.emptyString,
                dob: loggedUserInfo.dob,
                country: response?.countryCode,
                countryCode: response?.countryId,
                city: loggedUserInfo.city || AppConstants.emptyString,
                state: loggedUserInfo.state || AppConstants.emptyString,
                selectedSkills: loggedUserInfo.selectedSkills,
            });
            if (response.userEmail) {
                updateLocalStorageDetails({
                    country: loggedUserInfo.country,
                    firstName: loggedUserInfo.firstName,
                    lastName: loggedUserInfo.lastName,
                    nameInitials: loggedUserInfo.userInitials,
                    profileImagePreSignedUrl: loggedUserInfo.userImagePath,
                    preferredTimezone: loggedUserInfo.timeZone,
                    name: `${loggedUserInfo.firstName} ${loggedUserInfo.lastName}`,
                    mobileNumber: `${loggedUserInfo.countryMobileNumberCode}${loggedUserInfo.mobileNumber}`
                })
            }
        }
        return loggedUserInfo;
    }, []);


    useEffect(() => {
        const populateDetails = async () => {
            const loggedUserInfo = await fetchUserInfo();
            let selectedState = statesList?.find(state => state.name === loggedUserInfo.state);
            setSelectedCountry(loggedUserInfo.countryCode);
            setCallingCode(loggedUserInfo.countryMobileNumberCode);
            setInitialMobileNumber(`${loggedUserInfo.countryMobileNumberCode}${loggedUserInfo.mobileNumber}`);

            if (selectedState?.name) {
                setSelectedState(selectedState.name);
            }

            await Promise.all(
                loggedUserInfo.selectedSkills?.map(async skill => {
                    let selectedSkill = qrenciaCategoryWiseSkillsDetails.find(item => item.skillId === skill.skillId);
                    if (selectedSkill) {
                        await addToCheckedCategories(selectedSkill, 'initialRender');
                    }
                })
            );
            // commented code for Mobile verification
            // if (loggedUserInfo.mobileNumberVerificationFlag === AppConstants.yText) {
            //     setPhoneVerified(AppConstants.trueText);
            // }
            if (loggedUserInfo.profileCompletionFlag === AppConstants.yText) {
                setProfileCompletion(AppConstants.trueText);
            }
        }
        populateDetails();
    }, [fetchUserInfo, statesList])

    const openMobileVerificationPopup = () => {
        requestMobileVerification()
        setMobileVerificationScreen(true);
    };

    const closeMobileVerificationPopup = () => {
        setMobileVerificationScreen(false);
    };

    useEffect(() => {
        async function handleVerifyMobileOtp() {
            if (mobileOtp?.length === 6) {
                const url = AppConstants.signupPageVerifyMobileAPI;
                const mobileWithCountryCode = `${callingCode}${formValues.mobileNumber}`;
                const data = { "mobileNumber": mobileWithCountryCode?.replace("+", ""), "otp": mobileOtp };
                const response = await DataService.post(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response?.status === AppConstants.httpResponseCodes.responseCode200) {
                    setMobileOtp(AppConstants.emptyString);
                    setMobileVerificationScreen(AppConstants.falseText);
                    // setPhoneVerified(AppConstants.trueText);
                    setInitialMobileNumber(`${callingCode}${formValues.mobileNumber}`);
                    setFormErrors(formErrors.errors = { mobileOtpError: AppConstants.nullText });
                }
                if (response?.status === AppConstants.httpResponseCodes.responseCode409) {
                    setMobileVerificationScreen(AppConstants.trueText);
                    setFormErrors(formErrors.errors = { mobileOtpError: ErrorMessages.signupEmailOtpVerifyError });
                    // setPhoneVerified(AppConstants.falseText);
                }
                if (response?.status === AppConstants.httpResponseCodes.responseCode500) {
                    setMobileVerificationScreen(AppConstants.trueText);
                    // setPhoneVerified(AppConstants.falseText);
                    setFormErrors(formErrors.errors = { mobileOtpError: ErrorMessages.signupEmailOtpVerifyError });
                }
            }
        }
        handleVerifyMobileOtp();
    }, [mobileOtp]);

    const fetchCities = useCallback(async () => {
        if (selectedState !== AppConstants.emptyString && statesList.length > 0) {

            let stateISO = statesList.filter(state => state.name === selectedState)[0]?.iso2;
            let config = { headers: { 'X-CSCAPI-KEY': AppConstants.APIKeyToFetchStates } };
            const response = await fetch(`${AppConstants.getCountriesApi}${AppConstants.forwardSlash}${selectedCountry}/states${AppConstants.forwardSlash}${stateISO}${AppConstants.getCityUri}`, config);
            const responseData = await response.json();
            if (responseData.length !== 0 && (responseData instanceof Array)) {
                setCitiesList(responseData);
            } else {
                setCitiesList([]);
            }
            return null;
        }
    }, [selectedState, selectedCountry, statesList]);


    //preview selected profile pic
    const handleProfileChange = (e) => {
        setImageError(AppConstants.emptyString);
        if (e.target.files[0] !== undefined) {
            const imageType = e.target.files[0].type;
            const imageSize = e.target.files[0].size / (1024 * 1024);
            if (imageType === AppConstants.jpegImageType || imageType === AppConstants.pngImageType) {
                if (imageSize < 5) {
                    setImageError(AppConstants.emptyString);
                    setProfilePictureFile(e.target.files[0]);
                    setShowProfilePreview(AppConstants.trueText);
                    setProfilePic(URL.createObjectURL(e.target.files[0]));
                    e.preventDefault();
                } else {
                    setShowProfilePreview(AppConstants.falseText);
                    setImageError(ErrorMessages.userProfileMoreThan5mbError);
                }
            } else {
                setShowProfilePreview(AppConstants.falseText);
                setImageError(ErrorMessages.userProfileImageTypeError);
            }
        } else {
            setShowProfilePreview(AppConstants.falseText);
            setProfilePictureFile({});
        }
    }

    //fetchCities
    useEffect(() => {
        fetchCities()
    }, [selectedState, fetchCities]);

    function handleCountryChange(e) {
        const { value } = e.target;
        let updatedValues = { ...formValues, city: AppConstants.emptyString, state: AppConstants.emptyString };
        if (!e.target.value) {
            updatedValues = { ...updatedValues, "country": AppConstants.emptyString, "countryCode": AppConstants.emptyString };
            setCitiesList([]);
            setSelectedState(AppConstants.emptyString);
            setCallingCode(AppConstants.emptyString);
        } else {
            const country = countriesList.filter(country => country.countryCode === value)[0];
            setCallingCode(country.countryCallingCode);
            updatedValues = { ...updatedValues, "country": value, "countryCode": country?.id };
        }
        setSelectedCountry(e.target.value);
        setFormValues({ ...updatedValues });
    }

    //method called on selecting state
    function handleStateChange(e) {
        const { name, value } = e.target;
        let updatedValues = { ...formValues };
        if (!e.target.value) {
            setCitiesList([]);
        }
        setSelectedState(e.target.value);
        setFormValues({ ...updatedValues, [name]: value, "city": AppConstants.emptyString });
    }

    //handle form inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleInput = (e) => {
        const value = e.target.value;
        if (callingCode && value === callingCode.slice(0, -1)) {
            e.preventDefault();
            return;
        }
        const numericValue = value.replace(callingCode, '').replace(/[^0-9]/g, '');
        setFormValues({ ...formValues, "mobileNumber": numericValue });
    };

    //calculate age on selecting date of birth
    function handelDateOfBirth(e) {
        var today = new Date();
        var birthDate = new Date(e.target.value);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        setUserAge(age.toString());
    }

    //function call on selecting category
    function categorySelectionHandler(option) {
        setSelectedCategory(option);
    }

    function isFileObjectValid(fileObject) {
        if (fileObject === undefined) {
            return false
        }
        return (
            fileObject && // Check if the object exists
            fileObject.name && // Check if it has a name property
            fileObject.name !== "" && // Check if the name is not empty
            fileObject.size > 0 // Check if it has a size greater than zero
        );
    }

    //validate form inputs
    const isFormvalid = () => {
        let errorFlag = AppConstants.falseText;

        const nameRegex = /^[a-zA-Z ]*$/;
        const indianMobileRegex = /^\d{10}$/;
        if (checkedCategories?.length === 0) {
            setErrorSuccessAlertMessage(ErrorMessages.noInterestSelectedMessage);
            errorFlag = AppConstants.trueText;
        } else if (!formValues.firstName.trim()) {
            setErrorSuccessAlertMessage(ErrorMessages.invalidUserFirstName);
            errorFlag = AppConstants.trueText;
        } else if (!formValues.lastName.trim()) {
            setErrorSuccessAlertMessage(ErrorMessages.invalidUserLastName);
            errorFlag = AppConstants.trueText;
        } else if (formValues.firstName.trim().length < 3) {
            setErrorSuccessAlertMessage(ErrorMessages.expertObrfirstNameMinLength);
            errorFlag = AppConstants.trueText;
        } else if (formValues.lastName.trim().length < 3) {
            setErrorSuccessAlertMessage(ErrorMessages.expertObrLasttNameMinLength);
            errorFlag = AppConstants.trueText;
        } else if (!nameRegex.test(formValues.firstName)) {
            setErrorSuccessAlertMessage(ErrorMessages.invalidUserFirstNameWithRegex);
            errorFlag = AppConstants.trueText;
        } else if (!nameRegex.test(formValues.lastName)) {
            setErrorSuccessAlertMessage(ErrorMessages.invalidUserLastNameWithRegex);
            errorFlag = AppConstants.trueText;
        } else if (!formValues.mobileNumber) {
            setErrorSuccessAlertMessage(ErrorMessages.completeProfileMobleError);
            errorFlag = AppConstants.trueText;
        } else if (!phoneVerified) {
            setErrorSuccessAlertMessage(ErrorMessages.completeProfileVerifyMobileValidation);
            errorFlag = AppConstants.trueText;
        } else if (formValues.countryCode === parseInt(AppConstants.countryIdIndia)) {
            if (!indianMobileRegex.test(formValues.mobileNumber)) {
                setErrorSuccessAlertMessage(ErrorMessages.invalidMobileIndian);
                errorFlag = AppConstants.trueText;
            }
        } else if (formValues.countryCode !== parseInt(AppConstants.countryIdIndia)) {
            if (formValues.mobileNumber.length < AppConstants.nonIndianMinMobileNumber || formValues.mobileNumber.length > AppConstants.nonIndianMaxMobile) {
                setErrorSuccessAlertMessage(ErrorMessages.invalidMobileNonIndian);
                errorFlag = AppConstants.trueText;
            }
        } else if (formValues.country === AppConstants.emptyString) {
            setErrorSuccessAlertMessage(ErrorMessages.completeProfileCountryError);
            errorFlag = AppConstants.trueText;
        } else if (formValues.countryCode === parseInt(AppConstants.countryIdIndia) && formValues.state === AppConstants.emptyString && formValues.city !== AppConstants.emptyString) {
            setErrorSuccessAlertMessage(ErrorMessages.completeProfileInvalidState);
            errorFlag = AppConstants.trueText;
        } else if (formValues.countryCode === parseInt(AppConstants.countryIdIndia) && formValues.state !== AppConstants.emptyString && formValues.city === AppConstants.emptyString) {
            setErrorSuccessAlertMessage(ErrorMessages.completeProfileInvalidCity);
            errorFlag = AppConstants.trueText;
        } else if (initialMobileNumber !== formValues.mobileNumber && !phoneVerified) {
            setErrorSuccessAlertMessage(ErrorMessages.MobileNumberNotVerified);
            errorFlag = AppConstants.trueText;
        }

        return !errorFlag;
    }

    const getPayloadData = (imagePath) => {
        if (!isFileObjectValid(profilePictureFile) && formValues.profileImagePreSignedUrl === AppConstants.nullText) {
            imagePath = AppConstants.nullText;
        } else if (formValues.profileImagePreSignedUrl && !isFileObjectValid(profilePictureFile)) {
            imagePath = extractPath(formValues.profileImagePreSignedUrl);
        } else if (isFileObjectValid(profilePictureFile)) {
            imagePath = imagePath || null;
        }
        return {
            ...formValues,
            userQrenciaId: Auth.getLoggedInUserDetails().userId,
            userImagePath: imagePath,
            mobileNumber: removeCallingCode(formValues.mobileNumber),
            gender: formValues.gender || AppConstants.nullText,
            state: formValues.state || AppConstants.nullText,
            city: formValues.city || AppConstants.nullText,
            selectedSkills: checkedCategories,
            capitalCity: countriesList.filter(country => country.countryCode === formValues.country)[0]?.capitalCity
        };
    }

    //submit user details
    async function submitUserDetails(e) {
        try {
            e.preventDefault();
            if (isFormvalid()) {
                setShowSpinner(AppConstants.trueText);
                let userImagePath = AppConstants.nullText;

                //upload profile picture to S3
                if (isFileObjectValid(profilePictureFile)) {
                    let fileName = profilePictureFile.name.replace(/\s+/g, AppConstants.emptyString);
                    const path = `${AppConstants.s3ProfilePictureFolder}${AppConstants.forwardSlash}${Auth.getLoggedInUserDetails().userId}${AppConstants.hyphen}${fileName}`
                    const result = await FileOperationsUtil.uploadFileThroughPreSignedURL(profilePictureFile, path, AppConstants.s3filePermissionPublic, AppConstants.s3FileContentDispositionInline);

                    if (result.objectKey !== undefined) {
                        userImagePath = result.objectKey;
                    } else {
                        userImagePath = AppConstants.nullText;
                    }
                }

                const data = getPayloadData(userImagePath);
                const url = AppConstants.postCompleteProfileDetailsAPI;
                const response = await AuthorizedDataService.post(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (!response.ok) {
                    setShowSpinner(AppConstants.falseText);
                    setErrorSuccessAlertMessage(ErrorMessages.completeProfileInternalServerError);
                    setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
                    hideErrorMessage();
                } else if (response.ok && response.status === 200) {
                    setShowSpinner(AppConstants.falseText);
                    setErrorSuccessAlertMessage(SuccessMessage.completeProfileSubmitSuccess);
                    setErrorSuccessAlertVarient(AppConstants.alertVarient[0]);
                    hideErrorMessage();
                    // setPhoneVerified(true)
                    setProfileCompletion(AppConstants.trueText);
                    fetchUserInfo();
                }
                return null;
            } else {
                setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
                hideErrorMessage();
            }
        } catch (error) {
            setShowSpinner(AppConstants.falseText);
            setErrorSuccessAlertMessage(ErrorMessages.completeProfileInternalServerError);
            setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
            hideErrorMessage();
        }
    };

    function removeCallingCode(phoneNumber) {
        return phoneNumber?.replace(callingCode, '');
    }

    function extractPath(url) {
        const urlObject = new URL(url); // Create a URL object for easier parsing
        return urlObject.pathname.substring(1); // Remove the initial '/' from pathname
    }

    async function requestMobileVerification() {
        const url = AppConstants.signupPageGenerateMobileOtpAPI;
        const mobileWithCountryCode = `${callingCode}${formValues.mobileNumber}`;
        const data = { "mobileNumber": mobileWithCountryCode?.replace("+", "") };
        const response = await DataService.post(
            url,
            data,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response?.status === AppConstants.httpResponseCodes.responseCode201) {
            setMobileVerificationScreen(AppConstants.trueText);

            setFormErrors(formErrors.errors = { mobileNumber: AppConstants.emptyString });
            setMobileOtp(AppConstants.emptyString);
        }
        if (response?.status === AppConstants.httpResponseCodes.responseCode200) {
            // setPhoneVerified(AppConstants.trueText)
            setFormErrors(formErrors.errors = { mobileNumber: AppConstants.emptyString });
        }
        if (response?.status === AppConstants.httpResponseCodes.responseCode500) {

            setFormErrors(formErrors.errors = { mobileNumber: ErrorMessages.signupGenerateMobileOtpError });
        }
    }

    //hide error success messages
    function hideErrorMessage() {
        let timer = setTimeout(() => setErrorSuccessAlertMessage(AppConstants.emptyString), AppConstants.messageDisappearTime);
        return () => {
            clearTimeout(timer)
        }
    }

    //handle change in selected skills array
    async function addToCheckedCategories(skill, str) {
        const skillObject = {
            'skillName': skill.skill,
            "skillId": skill.skillId,
            "skillUniqueId": skill.skillUniqueId,
            "competitionUniqueId": null,
            "expertUniqueId": null,
            "skillCategoryName": skill.category,
            "skillCategorySlug": skill.categorySlug,
            "skillEmojiUnicode": skill.skillEmojiUnicode
        };
        let alreadyExists = checkedCategories?.filter((item) => item.skillId === skill.skillId)
        if (alreadyExists.length !== 0 && str) {
            return
        }
        if (alreadyExists.length !== 0) {
            setCheckedCategories(checkedCategories.filter(item => item.skillId !== skill.skillId));
        } else {
            setInterestedSkill(skill.skillId);
            let updatedSkills = checkedCategories
            updatedSkills.push(skillObject);
            setCheckedCategories([...updatedSkills]);
            return
        }
    };

    const checkSendCodeButtonVisibility = () => {
        const indianMobileRegex = /^\d{10}$/;
        if (formValues.countryCode === AppConstants.emptyString) {
            return false;
        } else if (formValues.countryCode === parseInt(AppConstants.countryIdIndia) && !indianMobileRegex.test(formValues.mobileNumber)) {
            return false;
        } else if (formValues.countryCode !== parseInt(AppConstants.countryIdIndia) && (formValues.mobileNumber?.length < AppConstants.nonIndianMinMobileNumber || formValues.mobileNumber?.length > AppConstants.nonIndianMaxMobile)) {
            return false
        }
        return true;
    };

    const removeProfilePicture = () => {
        setProfilePic(AppConstants.nullText);
        setProfilePictureFile({});
        setFormValues({ ...formValues, profileImagePreSignedUrl: AppConstants.nullText, userImagePath: AppConstants.nullText });
    }

    const isSubmitButtonDisabled = () => {
        if (!phoneVerified) return true;
        if (!formValues.country) return true;

        return false;
    };

    const navigateToHomePage = () => {
        const link = process.env.REACT_APP_FRONT_END_URL.replace(/\/$/, "");
        window.open(`${link}${AppConstants.studentHomePath}`, AppConstants.newPageTargetSelf);
    }

    return (
        <>

            <div className={classes.completeProfileCnt}>
                <StudentHeader className={classes.headerCustom} page={'profileUpdate'} hasShadow={true} />
                {isSessionExpired ?
                    <div className={classes.sessionExpiredContainer}>
                        <p className={classes.sessionExpiredText}>Your Session has been expired. Please <span onClick={Logout.logout}>login</span> to continue.</p>
                    </div> :
                    <div className='row'>
                        <form className={classes.formContainer}>
                            <div className={classes.container}>
                                <div className={classes.leftSection}>
                                    <div className={classes.profilecontainer}>
                                        <div className={classes.profilePictureContainer}>
                                            <div className={classes.profilePhoto}>
                                                {showProfilePreview && (
                                                    <img className={classes.profilePhoto} src={profilePic ? profilePic : defaultProfilePicture} alt="Profile" />
                                                )}
                                                {!showProfilePreview && <img className={classes.profilePhoto} alt="Profile" src={formValues.userImagePath ? formValues.userImagePath : defaultProfilePicture}></img>}
                                                <input className={classes.profilePicInputBox} type="file" value={formValues.profilePic} name="profilePic" onChange={handleProfileChange}
                                                    id="photo" accept="image/*" ref={hiddenFileInput} title="" />

                                                <img className={classes.profileOverlayInput} src={profileOverlayPicture} alt="overlay" />
                                            </div>
                                            {(profilePictureFile.size > 0 || formValues.userImagePath) && <div onClick={removeProfilePicture} className={classes.deleteProfilePicture}><MdOutlineDelete /></div>}
                                        </div>
                                        {imageError && <p className={`${classes.errorMessage} errorText`}>{imageError}</p>}
                                        <div className={classes.profileText}>
                                            <h2 className={classes.profileText}>{userDetails.name}</h2>
                                            <p>Let's enchance your profile!</p>
                                        </div>

                                    </div>
                                </div>
                                <div className={classes.rightSection}>
                                    <Tabs>
                                        <div label="Interests" className={classes.tabHeading}>
                                            <p className={classes.tabContentHeading}>Choose your interested skills</p>
                                            {errorSuccessAlertMessage &&
                                                <div className={` ${classes.errorSuccessContainer}`}>
                                                    {<div className={classes.errorSuccessMessage}>
                                                        <ErrorSuccessAlertMessage message={errorSuccessAlertMessage} varient={errorSuccessAlertVarient} />
                                                    </div>}
                                                </div>
                                            }
                                            <Card className={`${classes.cardContainer} ${classes.interestCard}`}>
                                                <ProfileSkillCategoryTab categoryList={categoryList} onSelection={categorySelectionHandler} />
                                                <div className={classes.skillContainerBox}>
                                                    <div className={classes.skillContainer}>
                                                        {filteredList?.map((item, idx) => (
                                                            <div
                                                                className={`${classes.interestitem} ${checkedCategories.some(skill => skill.skillId === item.skillId) ? classes.selected : ''}`}
                                                                onClick={() => addToCheckedCategories(item)}
                                                                key={idx}
                                                            >
                                                                <span>{item.skillEmojiUnicode ? String.fromCodePoint(parseInt(item.skillEmojiUnicode?.replace('U+', ''), 16)) : String.fromCodePoint('0x1f603')}</span> {item.skill}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </Card>
                                            {checkedCategories.length > 0 && <p className={classes.tabContentHeading}>My selected skills</p>}

                                            <div className={classes.selectedSkillSection}>
                                                {checkedCategories.map((item, index) => (
                                                    <div className={`${classes.interestitem} ${classes.selected} ${classes.selectedSkillPill}`} key={index} >
                                                        <span>{item.skillEmojiUnicode ? String.fromCodePoint(parseInt(item.skillEmojiUnicode?.replace('U+', ''), 16)) : String.fromCodePoint('0x1f603')}</span> {item.skillName} <FaTimes className={classes.closeIcon} onClick={() => addToCheckedCategories(item)} />
                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                        <div label="Personal details" className={classes.tabHeading}>
                                            <div className="personalDetailsDiv">
                                                <p className={classes.tabContentHeading}>Your details</p>
                                                {errorSuccessAlertMessage &&
                                                    <div className={` ${classes.errorSuccessContainer}`}>
                                                        {<div className={classes.errorSuccessMessage}>
                                                            <ErrorSuccessAlertMessage message={errorSuccessAlertMessage} varient={errorSuccessAlertVarient} />
                                                        </div>}
                                                    </div>
                                                }
                                                <form>
                                                    <div className={`${classes.inputLabelsRow} row`}>
                                                        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2'>
                                                            <div className={classes.formGroup}>
                                                                <div className={classes.inputWrapper}>
                                                                    <Form.Control placeholder="First name" name="firstName" value={formValues.firstName} onChange={handleChange} type='text' />
                                                                    <CiUser className={classes.inputIcon} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2'>
                                                            <div className={classes.formGroup}>
                                                                <div className={classes.inputWrapper}>
                                                                    <Form.Control placeholder="Last name" name="lastName" value={formValues.lastName} onChange={handleChange} type='text' />
                                                                    <CiUser className={classes.inputIcon} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-6 mb-2'>
                                                            <Form.Group className={`${classes.formGroup} ${formClasses.dateTimeInput}`}>
                                                                <div className={classes.inputWrapper}>
                                                                    <Form.Control className={classes.formInput} id="dobInput" value={formValues.dob} onChange={e => { handleChange(e); handelDateOfBirth(e) }} name="dob" type='date' required disabled />
                                                                    <CiCalendar className={classes.inputIcon} />
                                                                </div>
                                                            </Form.Group>
                                                        </div>
                                                        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-6 mb-2'>
                                                            <div className={classes.formGroup}>
                                                                <div className={classes.inputWrapper}>
                                                                    <Form.Select className={formValues.gender ? classes.formInput : classes.formInputPlaceholder} value={formValues.gender} onChange={handleChange} name="gender">
                                                                        <option value={AppConstants.emptyString}>Please select gender</option>
                                                                        {AppConstants.gender.map((gender) => (
                                                                            <option value={gender} key={gender}>{gender}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                    <BiChevronDown className={classes.inputIcon} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2'>
                                                            <div className={classes.formGroup}>
                                                                <Form.Label className={classes.formLabel}>
                                                                    {/* Email */}
                                                                    <span data-tooltip-place="top" data-tooltip-id="verifiedMobile" className={classes.verifySpan}><FaCheckCircle className={classes.iButtonVerified} />Verified</span>
                                                                </Form.Label>
                                                                <div className={classes.inputWrapper}>
                                                                    <Form.Control id="disabledEmailInput" name="userEmail" value={formValues.userEmail} onChange={handleChange} type='text' disabled />
                                                                    <CiMail className={classes.inputIcon} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2'>
                                                            <div className={classes.formGroup}>
                                                                <Form.Label className={classes.formLabel}>
                                                                    {/* Mobile Number */}
                                                                    {/* commented code for Mobile verification */}
                                                                    {/* {!phoneVerified && checkSendCodeButtonVisibility() && <span data-tooltip-place="top" data-tooltip-id="verifyMobile" onClick={openMobileVerificationPopup}>Send Code</span>}
                                                                {phoneVerified && <span data-tooltip-place="top" data-tooltip-id="verifiedMobile" className={classes.verifySpan}><FaCheckCircle className={classes.iButtonVerified} />Verified</span>} */}
                                                                    <span data-tooltip-place="top" data-tooltip-id="verifiedMobile" className={classes.emptySpan}></span>
                                                                </Form.Label>
                                                                <div className={classes.inputWrapper}>
                                                                    <Form.Control
                                                                        name="mobileNumber"
                                                                        type='text'
                                                                        placeholder="Phone number"
                                                                        // onKeyDown={handleKeyDown}
                                                                        value={`${callingCode}${formValues.mobileNumber}`}
                                                                        // onChange={handleMobileChange}
                                                                        onInput={handleInput}
                                                                        className={classes.formInput}
                                                                    />
                                                                    <CiMobile1 className={classes.inputIcon} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2'>
                                                            <div className={classes.formGroup}>
                                                                <div className={classes.inputWrapper}>
                                                                    <Form.Select className={formValues.country ? classes.formInput : classes.formInputPlaceholder} onChange={handleCountryChange} name="country" required value={formValues.country}>
                                                                        <option value={AppConstants.emptyString}>Please select country</option>
                                                                        {countriesList.map((country) => (
                                                                            <option value={country.countryCode} key={country.id}>{country.country}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                    <BiChevronDown className={classes.inputIcon} />
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <Modal
                                                            show={mobileVerificationScreen}
                                                            onRequestClose={closeMobileVerificationPopup}
                                                            contentLabel="Mobile Verification Popup"
                                                            className={classes.otpPopup}
                                                            overlayClassName={classes.overlay}
                                                        >
                                                            <div className={classes.verifyMobileModal}>
                                                                <div>
                                                                    <p className={classes.dialogHeading}>
                                                                        <span>
                                                                            <RiInformationFill className={classes.infoIcon} />
                                                                        </span>
                                                                        Verify mobile number</p>
                                                                </div>
                                                                <div className={classes.otpInputContainer}>
                                                                    <Form.Label className={`${classes.formLabel} ${classes.inputLabel} ${classes.dialogInputLabel}`}>{AppConstants.enterMobileText}</Form.Label>
                                                                    <div className={classes.verifyMobileContainer}>
                                                                        <div className={classes.mobileContainer}>
                                                                            <p className={classes.mobileDisabledText}>{callingCode + "-" + formValues.mobileNumber}</p>
                                                                            <div onClick={() => setMobileVerificationScreen(false)} className={classes.editEmailText}><FaEdit /> Edit</div>
                                                                        </div>
                                                                        <div className={classes.otpInputContainer}>
                                                                            <OTPInput
                                                                                value={mobileOtp}
                                                                                onChange={setMobileOtp}
                                                                                autoFocus
                                                                                OTPLength={6}
                                                                                otpType="number"
                                                                                disabled={false}
                                                                                className={classes.otpInputBox}
                                                                            />
                                                                            <ResendOTP className="m-1" onResendClick={() => requestMobileVerification()} renderButton={renderButton} renderTime={renderTime} />
                                                                        </div>
                                                                        {formErrors.mobileOtpError ? <p className={classes.errorMessage}>{formErrors.mobileOtpError}</p> : <p className={classes.otpInfoText}>Please enter the 6-digit code sent on your mobile number</p>}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Modal>
                                                        {selectedCountry === AppConstants.countryAbbreviationIndia && <div className='col-lg-6 col-md-12 col-sm-6 col-xs-12 mb-2'>
                                                            <div className={classes.formGroup}>
                                                                <div className={classes.inputWrapper}>
                                                                    <Form.Select className={formValues.state ? classes.formInput : classes.formInputPlaceholder} onChange={handleStateChange} name="state" required value={formValues.state}>
                                                                        <option value={AppConstants.emptyString}>Please select state</option>
                                                                        {statesList?.map((state) => (
                                                                            <option value={state.name} key={state.id}>{state.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                    <BiChevronDown className={classes.inputIcon} />
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        {selectedCountry === AppConstants.countryAbbreviationIndia && <div className='col-lg-6 col-md-12 col-sm-6 col-xs-12 mb-2'>
                                                            <div className={classes.formGroup}>
                                                                <div className={classes.inputWrapper}>
                                                                    <Form.Select className={formValues.city ? classes.formInput : classes.formInputPlaceholder} value={formValues.city} onChange={handleChange} name="city" required>
                                                                        <option value={AppConstants.emptyString}>Please select city</option>
                                                                        {citiesList && citiesList?.map((city) => (
                                                                            <option value={city.name} key={city.id}>{city.name}</option>
                                                                        ))}
                                                                    </Form.Select>
                                                                    <BiChevronDown className={classes.inputIcon} />
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        {selectedCountry && selectedCountry !== AppConstants.countryAbbreviationIndia && <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2'>
                                                            <div className={classes.formGroup}>
                                                                <div className={classes.inputWrapper}>
                                                                    <Form.Control placeholder="State" name="state" required value={formValues.state} onChange={handleChange} type='text' />
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        {selectedCountry && selectedCountry !== AppConstants.countryAbbreviationIndia && <div className='col-lg-6 col-md-12 col-sm-12 col-xs-12 mb-2'>
                                                            <div className={classes.formGroup}>
                                                                <div className={classes.inputWrapper}>
                                                                    <Form.Control placeholder="City" name="city" required value={formValues.city} onChange={handleChange} type='text' />
                                                                </div>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                    {/* {errorSuccessAlertMessage &&
                                                    <div className={classes.errorSuccessContainer}>
                                                        <ErrorSuccessAlertMessage message={errorSuccessAlertMessage} varient={errorSuccessAlertVarient} />
                                                    </div>
                                                } */}
                                                </form>
                                            </div>
                                        </div>
                                    </Tabs>
                                </div>
                            </div>
                            <div className={classes.submitButtonCnt}>
                                <Button type="button" onClick={navigateToHomePage} className={classes.submitButton}>Back to Learning</Button>
                                <Button type="submit" onClick={submitUserDetails} disabled={isSubmitButtonDisabled()} value="Submit" className={classes.submitButton}>Update {showSpinner && <BiLoaderAlt className={classes.spinner} />}</Button>
                            </div>
                        </form>
                    </div>}
            </div>
        </>
    )
}