import React from "react";
import { StudentActivitySnapshot } from "../../component/studentPageComponents/StudentActivitySnapshot";
import { StudentLearningActivitiy } from "../../component/studentPageComponents/StudentLearningActivitiy";
export const StudentMyLearningPage = () => {
    return (
        <div className="studentContentSection">
            <StudentActivitySnapshot />
            <StudentLearningActivitiy />
        </div>
    )
}