import { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
import validator from "validator";
import { MdDelete } from 'react-icons/md'
import { RiInformationFill } from 'react-icons/ri';
import { Tooltip } from "react-tooltip";
import { AppConstants } from "../../../constants/AppConstants";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import classes from "../../createCourseComponent/addCourseDetails/AddCourseDetails.module.css";
import formClass from "../../../common/FormStyling.module.css"
import moment from "moment";
import ReactQuill, { Quill } from 'react-quill';
import parse from 'html-react-parser';
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { DateTime } from "luxon";
import { FileUploadInput } from "../../fileUploadInput/FileUploadInput";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { BiLoaderAlt } from "react-icons/bi";
import { AiOutlineDownload } from "react-icons/ai";

export const AddWorkshopDetails = (props) => {
    const { skills, saveCurrentStep, stage, changeBasicFormDetailsData, changeBasicDetailsFormError, selectedWorkshopDetails } = props;
    const formLabels = AppConstants.workshopDetailsFormLabels;
    const supportedImageFormats = AppConstants.supportedImageFormats;
    const modeOfDelivery = AppConstants.modeOfDelivery;
    const stages = AppConstants.createWorkshopActions;
    const [description, setDescription] = useState(AppConstants.emptyEditorString);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [registrationDateAdded, setRegistrationDateAdded] = useState(false);
    const [registrationTimeAdded, setRegistrationTimeAdded] = useState(false);
    const [descriptionDocumentSpinner, setDescriptionDocumentSpinner] = useState(AppConstants.falseText);
    const [thumbnailSpinner, setThumbnailSpinner] = useState(AppConstants.falseText);
    const [promotionImageWebSpinner, setPromotionImageWebSpinner] = useState(AppConstants.falseText);
    const [promotionImageTabletSpinner, setPromotionImageTabletSpinner] = useState(AppConstants.falseText);
    const [promotionImageMobileSpinner, setPromotionImageMobileSpinner] = useState(AppConstants.falseText);
    const [videoSpinner, setVideoSpinner] = useState(AppConstants.falseText);

    let initialValues = {
        title: selectedWorkshopDetails.workshopTitle || AppConstants.emptyString,
        descriptionDocument: AppConstants.emptyString,
        skill: selectedWorkshopDetails.skillUniqueId ? selectedWorkshopDetails.skillUniqueId : skills?.length === 1 ? skills[0]?.skillId : AppConstants.emptyString,
        startDate: AppConstants.emptyString,
        endDate: AppConstants.emptyString,
        durationInHours: selectedWorkshopDetails.workshopDuration || AppConstants.emptyString,
        modeOfDelivery: selectedWorkshopDetails.workshopModeOfDelivery || AppConstants.emptyString,
        startTime: AppConstants.emptyString,
        endTime: AppConstants.emptyString,
        registrationClosingDate: AppConstants.emptyString,
        registrationClosingTime: AppConstants.emptyString,
        address: selectedWorkshopDetails.address || AppConstants.emptyString,
        geoLocation: selectedWorkshopDetails.geoLocation || AppConstants.emptyString,
        workshopLink: AppConstants.emptyString,
        cost: selectedWorkshopDetails.workshopCost ? selectedWorkshopDetails.workshopCost : AppConstants.emptyString,
        discount: AppConstants.emptyString,
        expertName: selectedWorkshopDetails.spocName || AppConstants.emptyString,
        expertEmail: selectedWorkshopDetails.spocEmail || AppConstants.emptyString,
        expertMobile: selectedWorkshopDetails.spocMobileNumber || AppConstants.emptyString,
        thumbnailImage: AppConstants.emptyString,
        promotionImageForWeb: AppConstants.emptyString,
        promotionImageForTablet: AppConstants.emptyString,
        promotionImageForMobile: AppConstants.emptyString,
        promotionVideo: AppConstants.emptyString,
    };

    const Link = Quill.import('formats/link');
    Link.sanitize = function (url) {
        try {
            if (!url.startsWith(`${AppConstants.internetProtocols.http}://`) && !url.startsWith(`${AppConstants.internetProtocols.https}://`)) {
                return `${AppConstants.internetProtocols.https}://`;
            }
            return url;
        } catch (e) {
            return url;
        }
    };

    const validationSchema = Yup.object().shape({
        title: Yup.string().trim().max(100, ErrorMessages.invalidWorkshopTitle).matches(AppConstants.quotesRegex, { message: ErrorMessages.doubleQuotesTitleError }).required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.title.name}`),
        descriptionDocument: Yup.mixed().notRequired().test("file_size_description", ErrorMessages.workshopDescriptionSizeLimit, (value) => { return value ? (value.size <= 50 * 1000 * 1000) : true }).test('file_Type', ErrorMessages.workshopDescriptionPdfFormat, (value) => { return value ? (["application/pdf"].includes(value.type)) : true }),
        skill: Yup.string().required(ErrorMessages.selectSkill).oneOf(skills?.map(s => s.skillId)),
        startDate: Yup.date().required(ErrorMessages.workshopStartDateRequired).min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `Start date ${ErrorMessages.pastDateError}`),
        endDate: Yup.date().required(ErrorMessages.workshopEndDateRequired).min(Yup.ref(formLabels.startDate.name), `${ErrorMessages.workshopEndDateError}`),
        startTime: Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}start time`).test(
            "start_time_test", ErrorMessages.workshopStartTimeError, function (value) {
                const { startDate } = this.parent;
                const d = new Date(startDate);
                let hourMins = value.split(":");
                let currentDateTime = DateTime.now().setZone(timeZone);
                const selectedDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                return selectedDateTime.toMillis() > currentDateTime.toMillis()
            }
        ),
        endTime: Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}end time`).test(
            "end_time_test", ErrorMessages.workshopEndTimeError,
            function (value) {
                const { startTime, startDate, endDate } = this.parent;
                // if start date and end date are same then check end time must be greater than start time
                if (moment(endDate).isSame(moment(startDate))) {
                    return moment(value, 'HH:mm').isAfter(moment(startTime, 'HH:mm'));
                } else {
                    return true;
                }
            }
        ).test("end_time_before_current_test", "Invalid workshop end time. Please select a time that is later than the current time", function (value) {
            const { endDate } = this.parent;
            const d = new Date(endDate);
            let hourMins = value.split(":");
            let currentDateTime = DateTime.now().setZone(timeZone);
            const selectedEndDate = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
            return selectedEndDate.toMillis() > currentDateTime.toMillis()
        }),
        registrationClosingDate: registrationTimeAdded ? Yup.date().required(`${ErrorMessages.pleaseAddText}${AppConstants.space}${formLabels.registrationClosingDate.label}`).min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.registrationClosingDate.label} ${ErrorMessages.pastDateError}`).max(Yup.ref(formLabels.endDate.name), `${ErrorMessages.workshopRegistrationDateBeforeEndDate}`)
            : registrationDateAdded ? Yup.date().min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.registrationClosingDate.label} ${ErrorMessages.pastDateError}`).max(Yup.ref(formLabels.endDate.name), `${ErrorMessages.workshopRegistrationDateBeforeEndDate}`) : Yup.date().notRequired(),
        registrationClosingTime: registrationDateAdded ? Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.registrationClosingTime.label}`).test(
            "registration_time_test", ErrorMessages.registrationTimeBeforeEndTimeError,
            function (value) {
                const { endTime, registrationClosingDate, endDate } = this.parent;
                if (moment(registrationClosingDate).isSame(moment(endDate)))
                    return moment(endTime, 'HH:mm').isAfter(moment(value, 'HH:mm'));
                else return true;
            }).test("registration_time_before_current_test", ErrorMessages.registrationBeforeCurrentTimeError, function (value) {
                const { registrationClosingDate } = this.parent;
                const d = new Date(registrationClosingDate);
                let hourMins = value.split(":");
                let currentDateTime = DateTime.now().setZone(timeZone);
                const selectedDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                return selectedDateTime.toMillis() > currentDateTime.toMillis();
            }) : Yup.string().notRequired(),
        durationInHours: Yup.number().required(ErrorMessages.workshopDurationRequired).min(0.1, ErrorMessages.workshopDurationError).test(
            "step_of_0_5",
            ErrorMessages.workshopDurationIn30intervalError,
            function (value) {
                return Math.abs(value % 0.5) === 0
            }

        ),
        cost: Yup.number().notRequired().min(0, ErrorMessages.minimumCostError),
        modeOfDelivery: Yup.string().required(`${ErrorMessages.pleaseSelectText}${AppConstants.space}${AppConstants.modeOfDeliveryText}`).oneOf(AppConstants.modeOfDelivery),
        geoLocation: Yup.string().when(formLabels.modeOfDelivery.name, {
            is: (val) => val === AppConstants.workshopTypeOffline,
            then: () => Yup.string().test("url_validation", `${ErrorMessages.enterValidUrl} for geo location`,
                function (value = "") { return validator.isURL(value) })
        }),
        address: Yup.string().when(formLabels.modeOfDelivery.name, {
            is: (val) => val === AppConstants.workshopTypeOffline,
            then: () => Yup.string().trim().required(ErrorMessages.enterAddressError)
        }),
        workshopLink: Yup.string().when(formLabels.modeOfDelivery.name, {
            is: (val) => val === AppConstants.workshopTypeOnline,
            then: () => Yup.string().test("url_validation", `${ErrorMessages.enterValidUrl} for workshop link`,
                function (value = "") { return validator.isURL(value) })
        }),
        expertName: Yup.string().notRequired().matches(/^[a-zA-Z .]*$/, ErrorMessages.invalidUserNameWithRegex),
        expertMobile: Yup.string().notRequired().matches(/^[1-9]{1}\d{5,}$/, "Please enter a valid mobile number"),
        expertEmail: Yup.string().notRequired().matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, ErrorMessages.invalidEmailFormat),
        thumbnailImage: Yup.mixed().notRequired().test('fileTypeTablet', `Thumbnail image ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizetablet', `Thumbnail image ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true }),
        promotionImageForWeb: Yup.mixed().notRequired().test('fileTypeTablet', `Promotion image for web ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizetablet', `Promotion image for web ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true }),
        promotionImageForTablet: Yup.mixed().notRequired().test('fileTypeTablet', `Promotion image for tablet ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizetablet', `Promotion image for tablet ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true }),
        promotionImageForMobile: Yup.mixed().notRequired().test('fileTypeMobile', `Promotion image for mobile ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizemobile', `Promotion image for mobile ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true }),
        promotionVideo: Yup.mixed().notRequired().test("fileTypeVideo", ErrorMessages.promotionVideoTypeError, (value) => { return value ? (["video/mp4", "video/mkv", "video/x-m4v", "video/quicktime", "video/ai", "video/ogg", "video/webm", "video/x-msvideo"].includes(value.type)) : true }).test('fileSizeVideo', ErrorMessages.promotionVideoFileSizeError, (value) => { return value ? (value.size <= 200 * 1000 * 1000) : true })
    });

    const { values, touched, errors, setFieldValue, handleBlur, handleChange, validateForm } = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        handleSubmit: () => {
        }
    });

    useEffect(() => {
        const updatedValues = { ...values, description: description };
        if (JSON.stringify(values) !== JSON.stringify(updatedValues)) {
            changeBasicFormDetailsData(updatedValues);
        }
    }, [values, description]);

    useEffect(() => {
        if (!values.startDate && selectedWorkshopDetails.id && values.title) {
            validateForm();
        }
    }, [values.startDate, selectedWorkshopDetails.id, values.title]);


    useEffect(() => {
        const getUploadedFiles = async () => {
            if (selectedWorkshopDetails.descriptionDocumentAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedWorkshopDetails.descriptionDocumentAbsolutePath, formLabels.descriptionDocument.name);
                setFieldValue(formLabels.descriptionDocument.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedWorkshopDetails.thumbnailImageAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedWorkshopDetails.thumbnailImageAbsolutePath, formLabels.thumbnailImage.name);
                setFieldValue(formLabels.thumbnailImage.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedWorkshopDetails.promotionImageMobileAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedWorkshopDetails.promotionImageMobileAbsolutePath, formLabels.promotionImageForMobile.name);
                setFieldValue(formLabels.promotionImageForMobile.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedWorkshopDetails.promotionImageTabletAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedWorkshopDetails.promotionImageTabletAbsolutePath, formLabels.promotionImageForTablet.name);
                setFieldValue(formLabels.promotionImageForTablet.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedWorkshopDetails.promotionImageWebAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedWorkshopDetails.promotionImageWebAbsolutePath, formLabels.promotionImageForWeb.name);
                setFieldValue(formLabels.promotionImageForWeb.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedWorkshopDetails.promotionVideoAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedWorkshopDetails.promotionVideoAbsolutePath, formLabels.promotionVideo.name);
                setFieldValue(formLabels.promotionVideo.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
        }

        if (selectedWorkshopDetails.id) {
            getUploadedFiles();
            setDescription(selectedWorkshopDetails.workshopDescription);
        } else {
            setDescription(AppConstants.emptyEditorString);
        }
    }, [selectedWorkshopDetails]);

    const getFileExtension = (url) => {
        return url.split(".").pop();
    };

    const downloadFile = async (fileUrl, name) => {
        let ext = getFileExtension(fileUrl)
        let resp = await FileOperationsUtil.downloadFileWithLoader(fileUrl, `${selectedWorkshopDetails.workshopTitle}-${name}${AppConstants.dot}${ext}`);
        if (resp) {
            if (name === formLabels.descriptionDocument.name) {
                setDescriptionDocumentSpinner(AppConstants.falseText);
            } else if (name === formLabels.thumbnailImage.name) {
                setThumbnailSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForWeb.name) {
                setPromotionImageWebSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForTablet.name) {
                setPromotionImageTabletSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForMobile.name) {
                setPromotionImageMobileSpinner(AppConstants.falseText);
            }
        } else {
            if (name === formLabels.descriptionDocument.name) {
                setDescriptionDocumentSpinner(AppConstants.falseText);
            } else if (name === formLabels.thumbnailImage.name) {
                setThumbnailSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForWeb.name) {
                setPromotionImageWebSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForTablet.name) {
                setPromotionImageTabletSpinner(AppConstants.falseText);
            } else if (name === formLabels.promotionImageForMobile.name) {
                setPromotionImageMobileSpinner(AppConstants.falseText);
            }
        }
    };

    useEffect(() => {
        const changeParentState = () => {
            if (description?.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
                if (errors == {} && values.title === AppConstants.emptyString) {
                    changeBasicDetailsFormError(errors);
                } else if (values.title === AppConstants.emptyString || values.title?.includes(`"`)) {
                    changeBasicDetailsFormError(errors);
                } else {
                    changeBasicDetailsFormError({ description: "Please enter a description", ...errors });
                }
            } else {
                changeBasicDetailsFormError(errors);
            }
        }
        changeParentState();
    }, [errors, description]);

    const changeModeOfDelivery = (event) => {
        setFieldValue(formLabels.modeOfDelivery.name, event.target.value);
        if (event.target.value) {
            if (event.target.value === AppConstants.workshopTypeOffline) {
                setFieldValue(formLabels.workshopLink.name, AppConstants.emptyString);
            } else if (event.target.value === AppConstants.workshopTypeOnline) {
                setFieldValue(formLabels.address.name, AppConstants.emptyString);
                setFieldValue(formLabels.geoLocation.name, AppConstants.emptyString);
            }
        } else {
            setFieldValue(formLabels.address.name, AppConstants.emptyString);
            setFieldValue(formLabels.workshopLink.name, AppConstants.emptyString);
            setFieldValue(formLabels.geoLocation.name, AppConstants.emptyString);
        }
    };
    const downloadVideoFile = async (fileUrl, name) => {
        setVideoSpinner(AppConstants.trueText);;
        let resp = await FileOperationsUtil.downloadFileWithLoader(fileUrl, `${selectedWorkshopDetails.workshopTitle}-${name}`);
        if (resp) {
            setVideoSpinner(AppConstants.falseText);
        } else {
            setVideoSpinner(AppConstants.falseText);
        }
    };

    const checkValidCost = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }

    const checkValidMobile = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", ".", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }

    const removeFile = (id) => {
        const input = document.getElementById(id);
        input.value = AppConstants.emptyString;
        input.type = AppConstants.inputFieldTypes.file;
        setFieldValue(id, AppConstants.emptyString);
    };

    const moveToNextSection = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    };

    const changeRegistrationClosingDate = (e) => {
        setFieldValue(formLabels.registrationClosingDate.name, e.target.value)
        if (e.target.value) {
            setRegistrationDateAdded(true);
        } else {
            setRegistrationDateAdded(false);
        }
    };

    const changeRegistrationClosingTime = (e) => {
        setFieldValue(formLabels.registrationClosingTime.name, e.target.value);
        if (e.target.value) {
            setRegistrationTimeAdded(true)
        } else {
            setRegistrationTimeAdded(false)
        }
    };

    return (
        <div className={classes.formContainer}>
            <Form noValidate>
                <Row className="mb-2" >
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.title.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            required placeholder="Workshop Title"
                            type="text" maxLength={100}
                            name={formLabels.title.name}
                            value={values.title} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.title && errors.title}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.title}</Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Row className={`mb-12 mt-2 ${classes.textEditorContainer}`}>
                    <Form.Group className={classes.textEditorContainer} controlId="basicDetailsGuidelinesText">
                        <Form.Label className={`${formClass.formLabel} ${classes.textEditorLabel}`}>{formLabels.description.label}<span className="redAstrisk">*</span><RiInformationFill data-tooltip-id="workshopDescriptionInfo" className={classes.infoButton} ></RiInformationFill></Form.Label>
                        <ReactQuill
                            formats={AppConstants.richTextEditorFormats} modules={AppConstants.richTextEditorModules}
                            onChange={setDescription}
                            value={description}
                            className={classes.textEditor} theme="snow" placeholder="Description of your workshop"
                        />
                        <Tooltip className={classes.tooltipMessage} id="workshopDescriptionInfo"><span className={classes.tooltipMessage}>{AppConstants.workshopDescriptionInfo}</span></Tooltip>
                    </Form.Group>
                </Row>

                <Row className="mb-2 mt-3">
                    <div className={classes.uploadFileRow}>
                        <div>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                                <FileUploadInput id={formLabels.descriptionDocument.name} name={formLabels.descriptionDocument.name} accept=".pdf" label={formLabels.descriptionDocument.label} handleFileChange={e => setFieldValue(formLabels.descriptionDocument.name, e.target.files[0])} infoMessage={AppConstants.workshopBrochureInfo} inputFile={values.descriptionDocument} />
                                {errors.descriptionDocument && <div className="errorText">{errors.descriptionDocument}</div>}
                            </Form.Group>
                        </div>
                        {selectedWorkshopDetails.descriptionDocumentAbsolutePath && <button type="button" onClick={() => { downloadFile(selectedWorkshopDetails.descriptionDocumentAbsolutePath, formLabels.descriptionDocument.name); setDescriptionDocumentSpinner(AppConstants.trueText); }} data-tooltip-id="downloaddescriptionDoc" className={errors.descriptionDocument ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{descriptionDocumentSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        <button type="button" onClick={() => removeFile(formLabels.descriptionDocument.name)} data-tooltip-id="removedescriptionDoc" className={classes.removeBtn}><MdDelete /></button>
                        <Tooltip className={classes.tooltipMessage} id="removedescriptionDoc"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                    </div>
                </Row>
                <Row>
                    {skills?.length > 1 && <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.skill.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Select className={formClass.selectInput}
                            name={formLabels.skill.name}
                            value={values.skill} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.skill && errors.skill}>
                            <option value={AppConstants.emptyString} defaultValue>Select Skill</option>
                            {skills?.map(option => (
                                <option key={option.skillId} value={option.skillId}>
                                    {option.skillName}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.skill}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>
                <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                    <Form.Group className={values.startDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.startDate.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.startDate.name} min={new Date().toISOString().split('T')[0]}
                            type="date"
                            value={values.startDate} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.startDate && errors.startDate}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.startDate}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.startTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsStarttime">
                        <Form.Label className={formClass.formLabel}>{formLabels.startTime.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.startTime.name}
                            type="time"
                            value={values.startTime} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.startTime && errors.startTime}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.startTime}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.endDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.endDate.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.endDate.name} min={new Date().toISOString().split('T')[0]}
                            type="date"
                            value={values.endDate} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.endDate && errors.endDate}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.endDate}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.endTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsEndTime">
                        <Form.Label className={formClass.formLabel}>{formLabels.endTime.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.endTime.name}
                            type="time"
                            value={values.endTime} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.endTime && errors.endTime}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.endTime}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2 mt-2">
                    <Form.Group controlId="basicDetailsTitle" className={`${classes.registrationDetails} mb-2 mt-2`}>
                        <Form.Label className={`${formClass.formLabel} ${classes.registrationDetailsLabel}`}>Registration window <RiInformationFill className={classes.infoButton} data-tooltip-id="registrationEndInfoIcon" /></Form.Label>
                        <Tooltip className={classes.tooltipMessage} id="registrationEndInfoIcon"><span className={classes.tooltipMessage}>{AppConstants.workshopRegistrationDeadlineInfo}</span></Tooltip>
                    </Form.Group>
                </Row>

                <Row xs={1} sm={2} md={2} lg={2} xl={2} className="mb-2">
                    <Form.Group className={values.registrationClosingDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsRegistrationDate">
                        <Form.Label className={formClass.formLabel}>{formLabels.registrationClosingDate.label}{(registrationTimeAdded || registrationDateAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.registrationClosingDate.name}
                            type="date"
                            min={new Date().toISOString().split('T')[0]}
                            isInvalid={touched.registrationClosingDate && errors.registrationClosingDate}
                            value={values.registrationClosingDate} onBlur={handleBlur}
                            onChange={(e) => changeRegistrationClosingDate(e)}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.registrationClosingDate}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.registrationClosingTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="registrationClosingTime">
                        <Form.Label className={formClass.formLabel}>{formLabels.registrationClosingTime.label}{(registrationTimeAdded || registrationDateAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.registrationClosingTime.name}
                            type="time"
                            value={values.registrationClosingTime} onBlur={handleBlur}
                            onChange={(e) => changeRegistrationClosingTime(e)}
                            isInvalid={touched.registrationClosingTime && errors.registrationClosingTime}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.registrationClosingTime}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.durationInHours.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="number"
                            min={0}
                            placeholder="Duration in hours"
                            name={formLabels.durationInHours.name}
                            value={values.durationInHours} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidCost}
                            isInvalid={touched.durationInHours && errors.durationInHours}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.durationInHours}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>Cost (INR)</Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="number"
                            placeholder="Workshop cost"
                            name={formLabels.cost.name}
                            value={values.cost} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidCost}
                            isInvalid={touched.cost && errors.cost}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.cost}</Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Row className="mb-2" xs={1} sm={2} md={1} lg={2} xl={2}>
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.modeOfDelivery.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Select className={formClass.selectInput}
                            name={formLabels.modeOfDelivery.name}
                            value={values.modeOfDelivery} onBlur={handleBlur}
                            onChange={(event) => changeModeOfDelivery(event)}
                            isInvalid={touched.modeOfDelivery && errors.modeOfDelivery}>
                            <option value={AppConstants.emptyString} defaultValue>Select Type</option>
                            {modeOfDelivery?.map((type, i) => (
                                <option key={i} value={type}>
                                    {type}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.modeOfDelivery}</Form.Control.Feedback>
                    </Form.Group>
                    {values.modeOfDelivery === modeOfDelivery[0] && <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.workshopLink.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="url" placeholder="Workshop link"
                            name={formLabels.workshopLink.name}
                            value={values.workshopLink} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.workshopLink && errors.workshopLink} />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.workshopLink}</Form.Control.Feedback>
                    </Form.Group>}
                    {values.modeOfDelivery === modeOfDelivery[1] && <Form.Group className="mb-2" as={Col} controlId="basicDetailVenueLocation">
                        <Form.Label className={formClass.formLabel}>{formLabels.geoLocation.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="url"
                            placeholder="Workshop venue location URL"
                            name={formLabels.geoLocation.name}
                            value={values.geoLocation} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.geoLocation && errors.geoLocation} />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.geoLocation}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>
                <Row className="mb-2">
                    {values.modeOfDelivery === modeOfDelivery[1] && <Form.Group className="mb-2" as={Col} controlId="basicDetailVenue">
                        <Form.Label className={formClass.formLabel}>{formLabels.address.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formTextAreaInput}
                            as="textarea"
                            placeholder="Workshop address"
                            name={formLabels.address.name}
                            value={values.address} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.address && errors.address} />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.address}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>
                <Row>
                    <Form.Label className={classes.contactLabel}>SPOC Details</Form.Label>
                </Row>
                <Row className="mb-2" xs={1} sm={2} md={1} lg={2} xl={2}>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.expertName.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            placeholder="SPOC name"
                            type="text" maxLength={30}
                            name={formLabels.expertName.name}
                            value={values.expertName} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.expertName && errors.expertName}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.expertName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.expertMobile.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            placeholder="SPOC mobile number"
                            type="number"
                            name={formLabels.expertMobile.name}
                            value={values.expertMobile} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidMobile}
                            isInvalid={touched.expertMobile && errors.expertMobile}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.expertMobile}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2">
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.expertEmail.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            placeholder="SPOC email id"
                            type="text" maxLength={50}
                            name={formLabels.expertEmail.name}
                            value={values.expertEmail} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.expertEmail && errors.expertEmail}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.expertEmail}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <div>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                                <FileUploadInput id={formLabels.thumbnailImage.name} name={formLabels.thumbnailImage.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.thumbnailImage.label} handleFileChange={e => setFieldValue(formLabels.thumbnailImage.name, e.target.files[0])} inputFile={values.thumbnailImage} infoMessage={AppConstants.expertCreateWorkshopThumbnailMessage} />
                                {errors.thumbnailImage && <div className="errorText">{errors.thumbnailImage}</div>}
                            </Form.Group>
                        </div>
                        {selectedWorkshopDetails.thumbnailImageAbsolutePath && <button type="button" onClick={() => { downloadFile(selectedWorkshopDetails.thumbnailImageAbsolutePath, formLabels.thumbnailImage.name); setThumbnailSpinner(AppConstants.trueText); }} data-tooltip-id="downloadThumbnail" className={errors.thumbnailImage ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{thumbnailSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        <button type="button" onClick={() => removeFile(formLabels.thumbnailImage.name)} data-tooltip-id="removeThumbnail" className={classes.removeBtn}><MdDelete /></button>
                        <Tooltip className={classes.tooltipMessage} id="removeThumbnail"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                            <FileUploadInput id={formLabels.promotionImageForWeb.name} name={formLabels.promotionImageForWeb.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForWeb.label} handleFileChange={e => setFieldValue(formLabels.promotionImageForWeb.name, e.target.files[0])} inputFile={values.promotionImageForWeb} infoMessage={AppConstants.expertCreateWorkshopWebBannerMessage} />
                            {errors.promotionImageForWeb && <div className="errorText">{errors.promotionImageForWeb}</div>}
                        </Form.Group>
                        {selectedWorkshopDetails.promotionImageWebAbsolutePath && <button type="button" onClick={() => { downloadFile(selectedWorkshopDetails.promotionImageWebAbsolutePath, formLabels.promotionImageForWeb.name); setPromotionImageWebSpinner(AppConstants.trueText); }} data-tooltip-id="downloadWebImage" className={errors.promotionImageForWeb ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageWebSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        <button type="button" onClick={() => removeFile(formLabels.promotionImageForWeb.name)} data-tooltip-id="removeWebImage" className={classes.removeBtn}><MdDelete /></button>
                        <Tooltip className={classes.tooltipMessage} id="removeWebImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                            <FileUploadInput id={formLabels.promotionImageForTablet.name} name={formLabels.promotionImageForTablet.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForTablet.label} handleFileChange={e => setFieldValue(formLabels.promotionImageForTablet.name, e.target.files[0])} inputFile={values.promotionImageForTablet} infoMessage={AppConstants.expertCreateWorkshopTabletBannerMessage} />
                            {errors.promotionImageForTablet && <div className="errorText">{errors.promotionImageForTablet}</div>}
                            <Tooltip className={classes.tooltipMessage} id="courseTabletBannerInfo"><span className={classes.tooltipMessage}>{parse(AppConstants.expertCreateCompetitionsTabletBannerMessage)}</span></Tooltip>
                        </Form.Group>
                        {selectedWorkshopDetails.promotionImageTabletAbsolutePath && <button type="button" onClick={() => { downloadFile(selectedWorkshopDetails.promotionImageTabletAbsolutePath, formLabels.promotionImageForTablet.name); setPromotionImageTabletSpinner(AppConstants.trueText); }} data-tooltip-id="downloadTabletImage" className={errors.promotionImageForTablet ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageTabletSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        <button type="button" onClick={() => removeFile(formLabels.promotionImageForTablet.name)} data-tooltip-id="removeTabletImage" className={classes.removeBtn}><MdDelete /></button>
                        <Tooltip className={classes.tooltipMessage} id="removeTabletImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                            <FileUploadInput id={formLabels.promotionImageForMobile.name} name={formLabels.promotionImageForMobile.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForMobile.label} handleFileChange={e => setFieldValue(formLabels.promotionImageForMobile.name, e.target.files[0])} inputFile={values.promotionImageForMobile} infoMessage={AppConstants.expertCreateWorkshopMobileBannerMessage} />
                            {errors.promotionImageForMobile && <div className="errorText">{errors.promotionImageForMobile}</div>}
                        </Form.Group>
                        {selectedWorkshopDetails.promotionImageMobileAbsolutePath && <button type="button" onClick={() => { downloadFile(selectedWorkshopDetails.promotionImageMobileAbsolutePath, formLabels.promotionImageForMobile.name); setPromotionImageMobileSpinner(AppConstants.trueText);; }} data-tooltip-id="downloadMoblieImage" className={errors.promotionImageForMobile ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageMobileSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        <button type="button" onClick={() => removeFile(formLabels.promotionImageForMobile.name)} data-tooltip-id="removeMobileImage" className={classes.removeBtn}><MdDelete /></button>
                        <Tooltip className={classes.tooltipMessage} id="removeMobileImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                            <FileUploadInput id={formLabels.promotionVideo.name} name={formLabels.promotionVideo.name} accept="video/mp4,video/mkv,video/x-m4v,video/*" label={formLabels.promotionVideo.label} handleFileChange={e => setFieldValue(formLabels.promotionVideo.name, e.target.files[0])} inputFile={values.promotionVideo} infoMessage={AppConstants.workshopPromotionVideoInfo} />
                            {errors.promotionVideo && <div className="errorText">{errors.promotionVideo}</div>}
                        </Form.Group>
                        {selectedWorkshopDetails.promotionVideoAbsolutePath && <button type="button" onClick={() => downloadVideoFile(selectedWorkshopDetails.promotionVideoAbsolutePath, `${formLabels.promotionVideo.name}.${getFileExtension(selectedWorkshopDetails.promotionVideoRelativePath)}`)} data-tooltip-id="downloadVideo" className={errors.promotionVideo ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{videoSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        <button type="button" onClick={() => removeFile(formLabels.promotionVideo.name)} data-tooltip-id="removePromotionVideo" className={classes.removeBtn}><MdDelete /></button>
                        <Tooltip className={classes.tooltipMessage} id="removePromotionVideo"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                    </div>
                </Row>
            </Form>
            <div className={classes.nextBtn}>
                <Button onClick={() => moveToNextSection()}>{AppConstants.nextText}</Button>
            </div>
        </div>
    )
}