
import { useState, useEffect, useCallback } from "react";
import { TbShare3 } from 'react-icons/tb';
import { BiLoaderAlt } from "react-icons/bi";
import { BsLinkedin, BsFacebook, BsFillEnvelopeAtFill, BsFillCheckCircleFill } from 'react-icons/bs';
import { AiOutlineCopy } from 'react-icons/ai';
import Accordion from 'react-bootstrap/Accordion';
import parse from 'html-react-parser';
import Table from 'react-bootstrap/Table';
import { Button } from "react-bootstrap";
import Moment from 'react-moment';
import 'moment-timezone';
import moment from 'moment';
import { FaArrowLeft } from "react-icons/fa";
import { AppConstants } from "../../../constants/AppConstants";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { ErrorSuccessAlertMessage } from "../../errorSuccessMessage/ErrorSuccessAlertMessage";
import { StudentQueryCard } from "../../cards/studentQueryCards/StudentQueryCard";
import classes from "./ViewCourseDetailsAccordion.module.css"
import "../../expertManageCompetitionComponent/viewCompetitionDetailsAccordion.css";
import { RiTwitterXLine } from "react-icons/ri";
import { GrDocumentZip } from "react-icons/gr";
import { SuccessMessage } from "../../../constants/SuccessMessage";
import { MathUtil } from "../../../utils/MathUtil";
import { PaymentsUtil } from "../../../utils/PaymentsUtil"

export const ViewCourseDetailAccordion = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [selectedCourse, setSelectedCourse] = useState({});
    const [respondedQueries, setRespondedQueries] = useState([]);
    const [openQueries, setOpenQueries] = useState([]);
    const [certificateTemplateSpinner, setCertificateTemplateSpinner] = useState(false);
    const [showLinkCopied, setShowLinkCopied] = useState(false);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(false);
    const [linkToShare, setLinkToShare] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [showDetailsError, setShowDetailsError] = useState(false);
    const [basicDetailsError, setBasicDetailsError] = useState(AppConstants.emptyString);
    const [fetchQueryFlag, setFetchQueryFlag] = useState(0);
    const [certificateSpinner, setCertificateSpinner] = useState([]);
    const [certificateDownloadMessage, setCertificateDownloadMessage] = useState(false);
    const [subscriptionReminderEmailMessage, setSubscriptionReminderEmailMessage] = useState(AppConstants.emptyString);
    const [subscriptionAlertVarient, setSubscriptionAlertVarient] = useState(AppConstants.emptyString);
    const [downloadZipSpinner, setDownloadZipSpinner] = useState(AppConstants.falseText);
    const [downloadZipError, setDownloadZipError] = useState(AppConstants.emptyString);
    const [participantsDetailsError, setParticipantsDetailsError] = useState(AppConstants.falseText);
    const [participantsDetailsErrorMessage, setParticipantsDetailsErrorMessage] = useState(AppConstants.emptyString);
    const [downloadParticipantsSpinner, setDownloadParticipantsSpinner] = useState(AppConstants.falseText);
    const [toggleEnrolmentFlagSpinner, setToggleEnrolmentFlagSpinner] = useState(AppConstants.falseText);
    const [toggleEnrolmentFlagError, setToggleEnrolmentFlagError] = useState(AppConstants.emptyString);
    const [subscriptionReminderSpinner, setSubscriptionReminderSpinner] = useState([])

    const fetchCourseDetails = useCallback(async () => {
        const url = `${AppConstants.getSpecificCourseDetailsAPI}${props.selectedCourse}`;

        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            setSelectedCourse(response);
            setShowLoader(false);
            setShowDetailsError(false);
            setCertificateSpinner(new Array(response.participantsDetails?.length).fill(false));
            setSubscriptionReminderSpinner(new Array(response.participantsDetails?.length).fill(false));
        } else {
            setSelectedCourse([]);
            setShowLoader(false);
            setShowDetailsError(true);
        }
    }, [props.selectedCourse]);

    useEffect(() => {
        if (props.selectedCourse) {
            fetchCourseDetails();
        }

    }, [fetchCourseDetails, props.selectedCourse]);

    useEffect(() => {
        const fetchStudentQueries = async () => {
            const url = `${AppConstants.getStudentQueriesAPI}${selectedCourse.id}&learningActivityType=${AppConstants.learningActivitiesTypes.course}`;

            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setRespondedQueries(response.filter(query => query.respondedOn !== null));
                setOpenQueries(response.filter(query => query.respondedOn === null));
            } else {
                setRespondedQueries([]);
                setOpenQueries([]);
            }
        };
        if (selectedCourse.id) {
            fetchStudentQueries();
        }
    }, [selectedCourse, fetchQueryFlag]);

    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.coursesText}${AppConstants.forwardSlash}${shareData.skillCategorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.courseSlug}`);
    }
    //function to copy to clipboard
    function copyToClipBoard(event) {
        event.preventDefault();
        navigator.clipboard.writeText(linkToShare);
        setShowLinkCopied(AppConstants.trueText);
        var timer = setTimeout(() => {
            setShowLinkCopied(AppConstants.falseText);
        }, 3000);
    }
    //function Send on Mail
    function sendOnMail() {
        window.open(`${AppConstants.mailToURI}` + `${linkToShare}`, AppConstants.openInNewTab);
    }
    //Function to Share on facebook
    function shareOnFacebook() {
        window.open(`${AppConstants.facebookShareURI}` + `${linkToShare}`, AppConstants.openInNewTab);
    }
    //Function to share on Linkedin
    function shareOnLinkedIn() {
        window.open(`${AppConstants.linkedInShareURI}` + `${encodeURIComponent(linkToShare)}`, AppConstants.openInNewTab);
    }
    //function to share on twitter
    function shareOnTwitter() {
        window.open(`${AppConstants.twitterShareURI}` + `${linkToShare}`, AppConstants.openInNewTab);
    }

    const downloadCertificateTemplate = async (course) => {
        setCertificateTemplateSpinner(true);
        let url = `${AppConstants.downloadCourseCertificateAPI}${course.uniqueId}`;
        const name = `${course.courseTitle}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`;
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setCertificateTemplateSpinner(AppConstants.falseText);
            } else {
                setCertificateTemplateSpinner(AppConstants.falseText);
                setBasicDetailsError(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setBasicDetailsError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setCertificateTemplateSpinner(AppConstants.falseText);
            setBasicDetailsError(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setBasicDetailsError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    const getWeekTimings = (value) => {
        let options = AppConstants.weekDaysOptions;
        let arr = value.split(AppConstants.comma);
        if (arr.length === 7) {
            return AppConstants.allDaysOfWeekText;
        } else {
            let dayString = AppConstants.emptyString;
            options.forEach(opt => {
                if (value.includes(opt.name)) dayString += `${opt.key}, `
            })
            return `(On ${dayString.replace(/,\s*$/, AppConstants.emptyString)})`
        }
    };

    const updateFetchDataFlag = () => {
        setFetchQueryFlag(fetchQueryFlag + 1);
    };

    //convert duration in hours and min
    function displayTimeInHoursAndMinutes(decimalNumber) {
        const hours = Math.floor(decimalNumber);
        const minutes = Math.round((decimalNumber - hours) * 60);
        if (minutes === 0) {
            return `${hours}h`;
        } else {
            return `${hours}h ${minutes}min`;
        }
    };

    const downloadStudentCertificate = async (participantQrenciaId, index) => {
        try {
            setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? true : state));
            const url = `${AppConstants.downloadCourseCertificateForStudentAPI}${selectedCourse.uniqueId}${AppConstants.studentQrenciaIdParam}${participantQrenciaId}`;
            const name = `${selectedCourse.courseTitle}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`;
            const fileName = name.replace(/\s+/g, AppConstants.hyphen);
            const response = await FileOperationsUtil.downloadFile(
                url,
                fileName,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined) {
                if (response.ok && response.status === 200) {
                    setCertificateDownloadMessage(AppConstants.emptyString);
                    setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
                } else {
                    setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
                    setCertificateDownloadMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
                    let timer = setTimeout(() => {
                        setCertificateDownloadMessage(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                }
            } else {
                setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? false : state));
                setCertificateDownloadMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setCertificateDownloadMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } catch (error) {
            setCertificateSpinner(certificateSpinner => certificateSpinner.map((state, idx) => idx === index ? true : state));
            setCertificateDownloadMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setCertificateDownloadMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    const sendSubscriptionReminder = async (learnerEmail, index) => {
        try {
            setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? true : state));
            const url = `${AppConstants.sendReminderEmailToRenewFlexiCourseSubscriptionAPI}${selectedCourse.uniqueId}&learnerEmail=${encodeURIComponent(learnerEmail)}`;
            const response = await AuthorizedDataService.post(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response !== undefined) {
                if (response.ok && response.status === 200) {
                    setSubscriptionAlertVarient(AppConstants.alertVarient[0]);
                    setSubscriptionReminderEmailMessage(`${SuccessMessage.courseSubscriptionReminderSentSuccessfully} ${learnerEmail}`);
                    setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? false : state));
                    let timer = setTimeout(() => {
                        setSubscriptionReminderEmailMessage(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                } else {
                    setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? false : state));
                    setSubscriptionReminderEmailMessage(ErrorMessages.subscriptionReminderEmailError);
                    setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
                    let timer = setTimeout(() => {
                        setSubscriptionReminderEmailMessage(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                }
            } else {
                setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? false : state));
                setSubscriptionReminderEmailMessage(ErrorMessages.subscriptionReminderEmailError);
                setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
                let timer = setTimeout(() => {
                    setSubscriptionReminderEmailMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } catch (error) {
            setSubscriptionReminderSpinner(subscriptionReminderSpinner => subscriptionReminderSpinner.map((state, idx) => idx === index ? true : state));
            setSubscriptionReminderEmailMessage(ErrorMessages.subscriptionReminderEmailError);
            setSubscriptionAlertVarient(AppConstants.alertVarient[1]);
            let timer = setTimeout(() => {
                setSubscriptionReminderEmailMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    const downloadCertificateZip = async (course) => {
        setDownloadZipSpinner(true);
        let url = `${AppConstants.generateCertificateZipForCourseAPI}${course.uniqueId}`;
        const name = `${course.courseTitle}${AppConstants.hyphen}${AppConstants.certificatesZipFileName}`;
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setDownloadZipSpinner(AppConstants.falseText);
            } else {
                setDownloadZipSpinner(AppConstants.falseText);
                setDownloadZipError(`${ErrorMessages.fileDownloadError}ZIP file.${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setDownloadZipError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setDownloadZipSpinner(AppConstants.falseText);
            setDownloadZipError(`${ErrorMessages.fileDownloadError}ZIP file.${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setDownloadZipError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    //download registered candidates
    async function downloadCourseEnrolledStudent(courseData) {
        setDownloadParticipantsSpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadCourseParticipants}${courseData.uniqueId}`;
        const name = `${courseData.courseTitle}${AppConstants.hyphen}${AppConstants.registeredParticipantsFileText}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString,
            { preferredTimezone: DateTimeUtil.getPreferredTimeZoneForUser() }
        );
        if (response !== undefined) {
            if (response.ok) {
                setDownloadParticipantsSpinner(AppConstants.falseText);
            } else {
                setDownloadParticipantsSpinner(AppConstants.falseText);
                setParticipantsDetailsError(AppConstants.trueText);
                setParticipantsDetailsErrorMessage(`${ErrorMessages.fileDownloadError}${AppConstants.participantsListText}${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setParticipantsDetailsError(AppConstants.falseText);
                    setParticipantsDetailsErrorMessage(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setDownloadParticipantsSpinner(AppConstants.falseText);
            setParticipantsDetailsError(AppConstants.trueText);
            setParticipantsDetailsErrorMessage(`${ErrorMessages.fileDownloadError}${AppConstants.participantsListText}${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setParticipantsDetailsError(AppConstants.falseText);
                setParticipantsDetailsErrorMessage(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    const toggleEnrolmentFlag = async () => {
        try {
            let url = `${AppConstants.updateEnrolmentsAllowedStatusForCourseAPI}${selectedCourse.uniqueId}&enrolmentDisableFlag=${selectedCourse.disableEnrolmentFlag === AppConstants.yText ? AppConstants.nText : AppConstants.yText}`;
            setToggleEnrolmentFlagSpinner(AppConstants.trueText);
            let response = await AuthorizedDataService.putRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode200) {
                fetchCourseDetails();
                setToggleEnrolmentFlagSpinner(AppConstants.falseText);
                setToggleEnrolmentFlagError(AppConstants.emptyString);
            } else {
                setToggleEnrolmentFlagError(selectedCourse.disableEnrolmentFlag === AppConstants.yText ? ErrorMessages.enableEnrolmentsFailedError : ErrorMessages.disableEnrolmentsFailedError)
                setToggleEnrolmentFlagSpinner(AppConstants.falseText);
                let timer = setTimeout(() => {
                    setToggleEnrolmentFlagError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } catch (error) {
            setToggleEnrolmentFlagError(selectedCourse.disableEnrolmentFlag === AppConstants.yText ? ErrorMessages.enableEnrolmentsFailedError : ErrorMessages.disableEnrolmentsFailedError)
            setToggleEnrolmentFlagSpinner(AppConstants.falseText);
            let timer = setTimeout(() => {
                setToggleEnrolmentFlagError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    return (
        <div className="expertCompDetailsAccordion">
            {showLoader ? <div className={classes.pageLoader}><BiLoaderAlt className="spinner" /></div> : showDetailsError ? <div>
                <div><FaArrowLeft onClick={() => props.onClickHide()} className={classes.goBackButton} />
                </div>
                <ErrorSuccessAlertMessage message={ErrorMessages.fetchCourseDetailsError} varient={AppConstants.alertVarient[1]} /></div>
                : <div>
                    <div><FaArrowLeft onClick={() => props.onClickHide()} className={classes.goBackButton} /></div>
                    <Accordion defaultActiveKey={['0']} alwaysOpen>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Course Details</Accordion.Header>
                            <Accordion.Body>
                                <div className="row expertAccordionContentSection">
                                    <Table striped bordered>
                                        <tbody>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Title</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.courseTitle !== AppConstants.nullText ? selectedCourse.courseTitle : AppConstants.notAvailableText}
                                                    <TbShare3 className={classes.shareIcon} onClick={() => { shareOnSocialMedia(selectedCourse) }} />
                                                    {showSocialMediaPannel && <div className={classes.shareIconPannelContainerParent}>
                                                        <div className={classes.shareIconPannelContainer}>
                                                            <BsLinkedin className={classes.shareOnLinkedIn} onClick={shareOnLinkedIn} />
                                                            <BsFacebook className={classes.shareOnFacebook} onClick={shareOnFacebook} />
                                                            <RiTwitterXLine className={classes.shareOnTwitter} onClick={shareOnTwitter} />
                                                            <BsFillEnvelopeAtFill className={classes.shareOnMailButton} onClick={sendOnMail} />
                                                            {!showLinkCopied && <AiOutlineCopy className={classes.copyToClipboard} onClick={copyToClipBoard} />}
                                                            {showLinkCopied && <BsFillCheckCircleFill className={classes.showLinkCopiedIcon} />}
                                                        </div>
                                                    </div>}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Course Id</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.courseId !== AppConstants.nullText ? selectedCourse.courseId : AppConstants.notAvailableText}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Course Type</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.courseType}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Description</td>
                                                <td className={classes.tableDescriptionColumn}><div className={`${classes.quillDescription} quillListIndent ql-editor`}>{selectedCourse.courseDescription !== AppConstants.nullText ? parse(selectedCourse.courseDescription) : AppConstants.notAvailableText}</div></td>
                                            </tr>
                                            {selectedCourse.courseDescriptionDocumentRelativePath !== AppConstants.nullText && <tr>
                                                <td className={classes.tableLabelColumn}>Course Brochure</td>
                                                {selectedCourse.courseDescriptionDocumentRelativePath !== AppConstants.nullText && <td className={classes.tableDescriptionColumn}><Button target={AppConstants.openInNewTab} download="problemStatement" className={classes.downloadBtn} href={selectedCourse.courseDescriptionDocumentAbsolutePath}>Download</Button></td>}
                                                {selectedCourse.courseDescriptionDocumentRelativePath === AppConstants.nullText && <td className={classes.tableDescriptionColumn}>{AppConstants.notAvailableText}</td>}
                                            </tr>}
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Mode of delivery</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.modeOfDelivery !== AppConstants.nullText ? selectedCourse.modeOfDelivery : AppConstants.notAvailableText}</td>
                                            </tr>
                                            {selectedCourse.modeOfDelivery.toUpperCase() === AppConstants.modeOfDelivery[0].toUpperCase() && selectedCourse.sessionLink !== null && <tr>
                                                <td className={classes.tableLabelColumn}>Session Link</td>
                                                <td className={classes.tableDescriptionColumn}><a target={AppConstants.openInNewTab} href={selectedCourse.sessionLink}>Click Here</a></td>
                                            </tr>}
                                            {selectedCourse.modeOfDelivery.toUpperCase() === AppConstants.modeOfDelivery[1].toUpperCase() && selectedCourse.address !== null && <tr>
                                                <td className={classes.tableLabelColumn}>Address</td>
                                                <td className={classes.tableDescriptionColumn}><a href={selectedCourse.geoLocation} target={AppConstants.openInNewTab}>{selectedCourse.address}</a></td>
                                            </tr>}
                                            {selectedCourse.courseType !== AppConstants.flexibleCourseType && <tr>
                                                <td className={classes.tableLabelColumn}>Start Date</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.startTime.split(",")[1]} {timeZone === AppConstants.utcTimezone && `(${selectedCourse.preferredTimezone})`}</td>
                                            </tr>}
                                            {selectedCourse.courseType !== AppConstants.flexibleCourseType && <tr>
                                                <td className={classes.tableLabelColumn}>End Date</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.endTime.split(",")[1]} {timeZone === AppConstants.utcTimezone && `(${selectedCourse.preferredTimezone})`}</td>
                                            </tr>}
                                            {selectedCourse.courseUtcRegistrationClosesOn && selectedCourse.courseType !== AppConstants.flexibleCourseType && <tr>
                                                <td className={classes.tableLabelColumn}>Registration Deadline</td>
                                                <td className={classes.tableDescriptionColumn}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{selectedCourse.courseUtcRegistrationClosesOn.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{selectedCourse.courseUtcRegistrationClosesOn.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${selectedCourse.preferredTimezone})`}</td>
                                            </tr>}
                                            {(selectedCourse.windowStartTime !== null && selectedCourse.windowEndTime !== null && selectedCourse.weekDays !== null) && <tr>
                                                <td className={classes.tableLabelColumn}>Course Timings</td>
                                                <td className={classes.tableDescriptionColumn}>{moment(DateTimeUtil.getLocalTimeValue(selectedCourse.windowStartTime)).tz(timeZone).format("h:mm A")} - {moment(DateTimeUtil.getLocalTimeValue(selectedCourse.windowEndTime)).tz(timeZone).format("h:mm A")} {timeZone === AppConstants.utcTimezone && `(${selectedCourse.preferredTimezone})`} {getWeekTimings(selectedCourse.weekDays)}</td>
                                            </tr>}
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Enrolled Students</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.participantsDetails !== AppConstants.nullText ? MathUtil.formatNumberToLocaleString(selectedCourse.participantsDetails.length) : AppConstants.notAvailableText}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Cost</td>
                                                {selectedCourse.discount ? <td className={`${classes.tableDescriptionColumn}`}>{selectedCourse.cost !== AppConstants.nullText ? <p className={classes.costContainer}>{PaymentsUtil.calculateCost({ cost: selectedCourse.cost, discount: selectedCourse.discount })}&nbsp;&nbsp;<p className={classes.costRow}>{MathUtil.formatNumberToLocaleString(selectedCourse.cost)}</p>{` (${MathUtil.formatNumberToLocaleString(selectedCourse.discount)} OFF)`}</p> : AppConstants.notAvailableText}</td>
                                                    : <td className={classes.tableDescriptionColumn}>{selectedCourse.cost !== AppConstants.nullText ? PaymentsUtil.calculateCost({ cost: selectedCourse.cost, discount: selectedCourse.discount }) : AppConstants.notAvailableText}</td>}
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Offline Payment Enabled</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.payOfflineFlag === AppConstants.yText ? "Yes" : "No"}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Number of Classes</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.numberOfClasses !== AppConstants.nullText ? selectedCourse.numberOfClasses : AppConstants.notAvailableText}</td>
                                            </tr>
                                            {selectedCourse.validity > 0 && <tr>
                                                <td className={classes.tableLabelColumn}>Class Validity</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.validity}  {selectedCourse.validity > 1 ? "Days" : "Day"}</td>
                                            </tr>}
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Total Learning Hours</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.duration !== AppConstants.nullText ? displayTimeInHoursAndMinutes(selectedCourse.duration) : AppConstants.notAvailableText}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Enrolment Disabled</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.disableEnrolmentFlag === AppConstants.yText ? "Yes" : "No"}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>{selectedCourse.disableEnrolmentFlag === AppConstants.yText ? "Enable" : "Disable"} Enrolments</td>
                                                <td className={classes.tableDescriptionColumn}><Button className={classes.downloadBtn} disabled={toggleEnrolmentFlagSpinner} onClick={toggleEnrolmentFlag}>{selectedCourse.disableEnrolmentFlag === AppConstants.yText ? "Enable" : "Disable"} {toggleEnrolmentFlagSpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button></td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Tracking Type</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.trackingType}</td>
                                            </tr>
                                            {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.durationBasedTrackingText && <tr>
                                                <td className={classes.tableLabelColumn}>Package Duration</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.packageDuration} {selectedCourse.packageDuration > 1 ? "Days" : "Day"}</td>
                                            </tr>}
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Attendance Enabled</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.isAttendanceEnabledFlag === AppConstants.yText ? "Yes" : "No"}</td>
                                            </tr>
                                            {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.classBasedTrackingText && selectedCourse.isAttendanceEnabledFlag === AppConstants.yText && <tr>
                                                <td className={classes.tableLabelColumn}>Reduce a class if marked as absent</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.isAbsentCountedAsAClassFlag === AppConstants.yText ? "Yes" : "No"}</td>
                                            </tr>}
                                            <tr>
                                                <td className={classes.tableLabelColumn}>Created On</td>
                                                <td className={classes.tableDescriptionColumn}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{selectedCourse.createdOn.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${selectedCourse.preferredTimezone})`}</td>
                                            </tr>
                                            {selectedCourse.templateId !== null && selectedCourse.templateId !== 0 && selectedCourse.courseType !== AppConstants.flexibleCourseType && <tr>
                                                <td className={classes.tableLabelColumn}>Certificate Template</td>
                                                <td className={classes.tableDescriptionColumn}><Button className={classes.downloadBtn} onClick={() => { downloadCertificateTemplate(selectedCourse) }}>Download {certificateTemplateSpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button></td>
                                            </tr>}
                                        </tbody>
                                    </Table>

                                    <p className={classes.spocHeadingText}>SPOC Details</p>
                                    <Table striped bordered>
                                        <tbody>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>SPOC Name</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.spocName !== AppConstants.nullText ? selectedCourse.spocName : AppConstants.notAvailableText}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>SPOC Email</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.spocEmail !== AppConstants.nullText ? selectedCourse.spocEmail : AppConstants.notAvailableText}</td>
                                            </tr>
                                            <tr>
                                                <td className={classes.tableLabelColumn}>SPOC Mobile</td>
                                                <td className={classes.tableDescriptionColumn}>{selectedCourse.spocMobileNumber !== AppConstants.nullText ? selectedCourse.spocMobileNumber : AppConstants.notAvailableText}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    {toggleEnrolmentFlagError && <div className={classes.errorSuccessContainer}> <ErrorSuccessAlertMessage message={toggleEnrolmentFlagError} varient={AppConstants.alertVarient[1]} /></div>}
                                    {basicDetailsError.length > 0 && <div className={classes.errorSuccessContainer}><ErrorSuccessAlertMessage message={basicDetailsError} varient={AppConstants.alertVarient[1]} /></div>}
                                </div>

                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Participants</Accordion.Header>
                            <Accordion.Body>
                                <div className="expertAccordionContentSection">
                                    {selectedCourse.participantsDetails?.length > 0 &&
                                        <div className={classes.participantDetailsTable}>
                                            <Table striped bordered className={classes.paymentTable}>
                                                <thead>
                                                    <tr>
                                                        <th className={classes.tableHeaderOne}>Qrencia ID</th>
                                                        <th className={classes.tableHeaderTwo}>User Name</th>
                                                        <th className={classes.tableHeaderTwo}>Email</th>
                                                        <th className={classes.tableHeaderTwo}>Phone</th>
                                                        <th className={classes.tableHeaderEnrolledDate}>Enrolled On</th>
                                                        {PaymentsUtil.calculateCost({ cost: selectedCourse.cost, discount: selectedCourse.discount }) !== AppConstants.freeText && <th className={classes.tableHeaderEnrolledDate}>Last Payment Method</th>}
                                                        {selectedCourse.courseType === AppConstants.flexibleCourseType && <th className={classes.tableHeaderEnrolledDate}>Subscription Start Date</th>}
                                                        {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.classBasedTrackingText && selectedCourse.validity > 0 && <th className={classes.tableHeaderEnrolledDate}>Validity</th>}
                                                        {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.classBasedTrackingText && <th className={classes.tableHeaderTwo}>Remaining Classes</th>}
                                                        {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.durationBasedTrackingText && <th className={classes.tableHeaderEnrolledDate}>Subscription End Date</th>}
                                                        {selectedCourse.courseType === AppConstants.flexibleCourseType && <th className={classes.tableHeaderTwo}>Status</th>}
                                                        {selectedCourse.courseType === AppConstants.structuredCourseType && <th className={classes.tableHeaderThree}>Certificate</th>}
                                                        {selectedCourse.courseType === AppConstants.flexibleCourseType && <th className={classes.tableHeaderThree}>Reminder</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectedCourse.participantsDetails?.map((participants, index) => (
                                                        <tr key={participants.userQrenciaId}>
                                                            <td className={classes.tableDescriptionColumnOne}>{participants.userQrenciaId}</td>
                                                            <td className={classes.tableDescriptionColumnTwo}>{participants.studentName}</td>
                                                            <td className={classes.tableDescriptionColumnTwo}>{participants.studentEmail}</td>
                                                            <td className={classes.tableDescriptionColumnTwo}>{participants.contryMobileNumberCode}{participants.mobileNumber}</td>
                                                            <td className={classes.tableDescriptionEnrolledDate}><Moment format="hh:mm A" tz={timeZone}>{participants.formattedEnrolledOn?.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{participants.formattedEnrolledOn?.replace(' ', 'T') + 'Z'}</Moment></td>
                                                            {PaymentsUtil.calculateCost({ cost: selectedCourse.cost, discount: selectedCourse.discount }) !== AppConstants.freeText && <td className={classes.tableDescriptionColumnTwo}>{participants.paymentMode}</td>}
                                                            {selectedCourse.courseType === AppConstants.flexibleCourseType && <td className={classes.tableDescriptionEnrolledDate}><Moment format="hh:mm A" tz={timeZone}>{participants.subscriptionStartDate ? participants.subscriptionStartDate?.replace(' ', 'T') + 'Z' : participants.formattedEnrolledOn?.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{participants.subscriptionStartDate ? participants.subscriptionStartDate?.replace(' ', 'T') + 'Z' : participants.formattedEnrolledOn?.replace(' ', 'T') + 'Z'}</Moment></td>}
                                                            {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.classBasedTrackingText && selectedCourse.validity > 0 && <td className={classes.tableDescriptionEnrolledDate}><Moment format="hh:mm A" tz={timeZone}>{participants.subscriptionEndDate?.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{participants.subscriptionEndDate?.replace(' ', 'T') + 'Z'}</Moment></td>}
                                                            {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.classBasedTrackingText && <td className={classes.tableDescriptionColumnTwo}>{participants.remainingClasses}</td>}
                                                            {selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.durationBasedTrackingText && <td className={classes.tableDescriptionEnrolledDate}><Moment format="hh:mm A" tz={timeZone}>{participants.subscriptionEndDate?.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{participants.subscriptionEndDate?.replace(' ', 'T') + 'Z'}</Moment></td>}
                                                            {selectedCourse.courseType === AppConstants.flexibleCourseType && <td className={`${classes.tableDescriptionColumnTwo} ${participants.subscriptionStatus === AppConstants.subscriptionStatus.expired ? classes.expiredText : classes.activeText}`}>{participants.subscriptionStatus}</td>}
                                                            {selectedCourse.courseType === AppConstants.structuredCourseType && <td className={classes.tableDescriptionColumnThree}>{(props.selectedTab === AppConstants.courseStatus.past && selectedCourse.templateId !== 0 && selectedCourse.templateId !== null) ? <Button className={classes.downloadBtn} disabled={certificateSpinner[index]} onClick={() => downloadStudentCertificate(participants.userQrenciaId, index)}>Download {certificateSpinner[index] && <BiLoaderAlt className="spinner" />}</Button> : AppConstants.notAvailableText}</td>}
                                                            {selectedCourse.courseType === AppConstants.flexibleCourseType && <td className={classes.tableDescriptionColumnThree}><Button className={classes.reminderEmail} disabled={subscriptionReminderSpinner[index] || participants.subscriptionStatus !== "Expired" || selectedCourse.disableEnrolmentFlag === AppConstants.yText} onClick={() => sendSubscriptionReminder(participants.studentEmail, index)}>Send {subscriptionReminderSpinner[index] && <BiLoaderAlt className="spinner" />}</Button></td>}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>}
                                    {(selectedCourse.participantsDetails !== AppConstants.nullText && selectedCourse.participantsDetails.length !== 0) &&
                                        <div className={`expertCompleteCompButtonCnt ${classes.downloadParticipantSheetSection}`}>
                                            <Button className={classes.downloadZip} onClick={() => { downloadCourseEnrolledStudent(selectedCourse) }}>Participants List{downloadParticipantsSpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button>
                                            {(props.selectedTab === AppConstants.courseStatus.past && selectedCourse.templateId !== 0 && selectedCourse.templateId !== null && selectedCourse.courseType === AppConstants.structuredCourseType) &&
                                                <Button disabled={downloadZipSpinner} className={classes.downloadZip} onClick={() => downloadCertificateZip(selectedCourse)}>Certificates&nbsp;&nbsp;{downloadZipSpinner ? <BiLoaderAlt className="expertSpinnerIcon" /> : <GrDocumentZip />}</Button>}
                                            {selectedCourse.isAttendanceEnabledFlag === AppConstants.yText && selectedCourse.participantsDetails?.length > 0 && props.selectedTab !== AppConstants.courseStatus.upcoming && <Button className={classes.downloadZip} onClick={() => window.open(`${AppConstants.markAttendancePath}${AppConstants.forwardSlash}${selectedCourse.uniqueId}`, AppConstants.openInNewTab)}>Mark Attendance</Button>}
                                            {selectedCourse.participantsDetails?.length > 0 && props.selectedTab !== AppConstants.courseStatus.upcoming && <Button className={classes.downloadZip} onClick={() => window.open(`${AppConstants.shareCourseFeedbackPath}${AppConstants.forwardSlash}${selectedCourse.uniqueId}`, AppConstants.openInNewTab)}>Give Feedback</Button>}
                                            {selectedCourse.isAttendanceEnabledFlag === AppConstants.nText && selectedCourse.courseType === AppConstants.flexibleCourseType && selectedCourse.trackingType === AppConstants.flexiAttendanceTrackingOptions.classsBasedTracking.value && selectedCourse.participantsDetails?.length > 0 && props.selectedTab !== AppConstants.courseStatus.upcoming && <Button className={classes.downloadZip} onClick={() => window.open(`${AppConstants.markAttendancePath}${AppConstants.forwardSlash}${selectedCourse.uniqueId}`, AppConstants.openInNewTab)}>Track Classes</Button>}
                                        </div>}
                                    {certificateDownloadMessage && <ErrorSuccessAlertMessage message={certificateDownloadMessage} varient={AppConstants.alertVarient[1]} />}
                                    {downloadZipError && <ErrorSuccessAlertMessage message={downloadZipError} varient={AppConstants.alertVarient[1]} />}
                                    {subscriptionReminderEmailMessage && <ErrorSuccessAlertMessage message={subscriptionReminderEmailMessage} varient={subscriptionAlertVarient} />}
                                    {participantsDetailsErrorMessage && <ErrorSuccessAlertMessage message={participantsDetailsErrorMessage} varient={AppConstants.alertVarient[1]} />}
                                    {selectedCourse.participantsDetails?.length === 0 && <div><p className="expertNoParticipantsErroMessage">{ErrorMessages.noCourseParticipantsError}</p></div>}
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>Queries</Accordion.Header>
                            <Accordion.Body>
                                {(openQueries.length > 0 || respondedQueries.length > 0) && <div>
                                    {
                                        openQueries.map((query, i) => {
                                            return <StudentQueryCard key={query.queryUniqueId} query={{ ...query, title: selectedCourse.courseTitle, learningActivityType: AppConstants.learningActivitiesTypes.course }} updateFetchDataFlag={updateFetchDataFlag} />
                                        })
                                    }
                                    {
                                        respondedQueries.map((query, i) => {
                                            return <StudentQueryCard key={query.queryUniqueId} query={{ ...query, title: selectedCourse.courseTitle, learningActivityType: AppConstants.learningActivitiesTypes.course }} updateFetchDataFlag={updateFetchDataFlag} />
                                        })
                                    }
                                </div>}
                                {(openQueries.length === 0 && respondedQueries.length === 0) && <div className="expertAccordionContentSection"><p className="expertNoParticipantsErroMessage">{ErrorMessages.noQuestionAskedFallbackMessage}</p></div>}
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>FAQ</Accordion.Header>
                            <Accordion.Body>
                                {selectedCourse.courseFaqs?.length > 0 &&
                                    selectedCourse.courseFaqs?.length > 0 && selectedCourse.courseFaqs?.map((faq, i) => <div className={classes.addedFaqs} key={i}>
                                        <div className={classes.displayedFaqs}>
                                            <div className={classes.questions}>Q{i + 1} : {faq.question}</div>
                                            <div className={`${classes.answers} quillListIndent ql-editor`}>{parse(faq.answer)}</div>
                                        </div>
                                    </div>)
                                }
                                {selectedCourse.courseFaqs?.length === 0 && <div className="expertAccordionContentSection"><p className="expertNoParticipantsErroMessage">{ErrorMessages.expertCourseNoFaqAddedFallBackMessage}</p></div>}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>}
        </div>
    )
}
