import React, { useState, useEffect } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { LandingPageTopNavigationForListingPages } from '../../layout/ExtendedPagesLayout/LandingPageTopNavigationForListingPages';
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
import { AppConstants } from "../../constants/AppConstants";
import { SkillCategoryTabs } from "../../component/lists/SkillsCategoryTabs";
import { CompetitionsListingPageCards } from "../../component/cards/competitionListingCards/CompetitionListingCards";
import classes from "../../component/expertListingPage/ExpertListingPage.module.css";
import { Button } from "react-bootstrap";
import { DataService } from '../../services/DataService';
import { CompetitionsListingPageBanner } from "../../component/cards/competitionListingCards/CompetitionListingPageBanner";
import sharePanelClasses from "../../component/individualCoursePage/IndividualCoursePageLayout.module.css";
import { ShareLinkToSocialMediaUtil } from "../../utils/ShareLinkToSocialMediaUtil";

export const CompetitionsPage = () => {
    const location = useLocation();
    const currentPath = location.pathname.replace(/^\/+/, '').toLowerCase();
    const [learningActivities, setLearningActivities] = useState([]);
    const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
    const navigate = useNavigate();
    const [landingPageData, skillsData, competitionData, bannerSectionData] = useLoaderData();
    const [filterText, setFilterText] = useState(AppConstants.emptyString);
    const [competitionOfCategory, setCompetitionOfCategory] = useState(competitionData);
    const [filteredList, setFilteredList] = useState(competitionData);
    const [selectedCategory, setSelectedCategory] = useState(AppConstants.AllText);
    const linkToShare = `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.competitionsText}`;


    useEffect(() => {
        async function getLaData() {
            const url = `${AppConstants.getPageContentAPI}/${AppConstants.laDropdownValues}`
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setLearningActivities(response);
            if (response == null || response?.length === 0) {
                navigate(errorPageUrl);
            }
            else {
                const competitionsEnabled = response.find(item => item.subTitle.replace(/^\/+/, '').toLowerCase() === currentPath.toLowerCase());
                if (competitionsEnabled === undefined) {
                    navigate(errorPageUrl);
                }
            }
        }
        getLaData();
    }, []);

    // function to filter the experts based on selected category
    function categorySelectionHandler(option) {
        setSelectedCategory(option);

        if (option) {
            if (option === AppConstants.AllText) {

                setCompetitionOfCategory(competitionData);
                setFilteredList(competitionData)
            } else {
                const competitionArray = competitionData.filter(competition =>
                    competition.categoryName === option)

                setFilteredList(competitionArray);
                setCompetitionOfCategory(competitionArray);
            }
        } else {

            setFilteredList(competitionData)
            setCompetitionOfCategory(competitionData);
        }
    }

    const handleResetFields = () => {
        setFilterText(AppConstants.emptyString);
        if (selectedCategory.toLowerCase() !== AppConstants.AllText.toLowerCase()) {
            setFilteredList(competitionData.filter(expert =>
                expert.skillDetails?.some(skill => skill.skillCategoryName?.toLowerCase() === selectedCategory?.toLowerCase())
            ));
        }
        if (selectedCategory.toLowerCase() === AppConstants.AllText.toLowerCase()) {
            setFilteredList(competitionData);
        }
    }

    const handleSearchInputChange = (e) => {
        const searchText = e.target.value.toLowerCase();
        setFilterText(e.target.value);

        let filteredArray = competitionOfCategory;

        if (searchText !== AppConstants.emptyString) {
            filteredArray = competitionOfCategory?.filter((item) => {
                const isExpertMatch = item.expertName?.toLowerCase()?.includes(searchText);
                const isAgencyMatch = item.agencyName?.toLowerCase()?.includes(searchText);
                const isSkillMatch = item.skillName?.toLowerCase()?.includes(searchText);
                const isTitleMatch = item.title?.toLowerCase()?.includes(searchText);
                const isFreeMatch = searchText === AppConstants.freeText && (item.cost === 0 || item.cost === null);
                const isTypeMatch = (item.type?.toLowerCase().includes(searchText))

                return isExpertMatch || isSkillMatch || isTitleMatch || isFreeMatch || isTypeMatch || isAgencyMatch;
            });
        }

        setFilteredList(filteredArray);
    }

    return (
        <div>
            <LandingPageTopNavigationForListingPages landingPageData={landingPageData} learningActivities={learningActivities} />
            <div className={classes.shareIconPannelContainer}>
                <ShareLinkToSocialMediaUtil className={sharePanelClasses} linkToShare={linkToShare} />
            </div>
            <CompetitionsListingPageBanner bannerImage={bannerSectionData} />
            <div className={classes.stickyHeader}>
                <SkillCategoryTabs categoryList={skillsData} onSelection={categorySelectionHandler} />
                <div className={classes.containerSearchSkill}>
                    <div className={classes.containerSearchItem}><input
                        type={AppConstants.fieldTypeText}
                        value={filterText}
                        onChange={handleSearchInputChange}
                        placeholder={AppConstants.competitionSearchPlaceholder}
                    />
                    </div>
                    <Button onClick={handleResetFields} className={classes.resetButton}>Reset</Button>
                </div>
            </div>
            {filteredList?.length !== 0 ?
                <CompetitionsListingPageCards competitionsOfCategory={filteredList} skillsData={skillsData} />
                : competitionData?.length === 0 ?
                    (<div className={classes.noDataText}><h5 className={classes.headingBlue}>{AppConstants.newCompetitionsHeading}</h5><p>{AppConstants.newCoursesText} <a className={classes.exploreLink} href="/courses">Explore Courses</a></p><p className={classes.noDataSubText}>{AppConstants.newCompetitionsSubText}</p></div>) : <div className={classes.noDataError}><p className={classes.errorText}>No Competitions Found</p></div>}
            <LandingPageFooter landingPageData={landingPageData} />
        </div>

    );
}