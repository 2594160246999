import { useState, useEffect } from "react";
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Form } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import { ErrorMessages } from '../../constants/ErrorMessages';
import { SuccessMessage } from '../../constants/SuccessMessage';
import { DataService } from '../../services/DataService';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { BiLoaderAlt } from 'react-icons/bi';
import { RiErrorWarningLine } from 'react-icons/ri';
import classes from './ResetPasswordPageForm.module.css';
import formClass from "../../common/FormStyling.module.css";

export const ResetPasswordPageForm = () => {
    const initialPasswordTypes = {
        password: "password",
        confirmPassword: "password"
    };
    const [showSpinner, setShowSpinner] = useState(false);
    const [showSuccessScreen, setShowSuccessScreen] = useState(AppConstants.falseText);

    const [passwordType, setPasswordType] = useState(initialPasswordTypes);
    const [email, setEmail] = useState(AppConstants.emptyString);
    const [password, setPassword] = useState(AppConstants.emptyString);
    const [confirmPassword, setConfirmPassword] = useState(AppConstants.emptyString);
    const [emailVerified, setEmailVerified] = useState(false);
    const [tokenVerified, setTokenVerified] = useState(false);

    const [emailError, setEmailError] = useState(AppConstants.emptyString);
    const [passwordError, setPasswordError] = useState(AppConstants.emptyString);
    const [confirmPasswordError, setConfirmPasswordError] = useState(AppConstants.emptyString);
    const [displayError, setDisplayError] = useState(AppConstants.emptyString);
    const [searchParams, setSearchParams] = useSearchParams();
    const [token, setToken] = useState(searchParams.get("token"));
    const [userQrenciaId, setUserQrenciaId] = useState(searchParams.get("userQrenciaId"));
    const [userParamEmail, setUserParamEmail] = useState(searchParams.get("u"));
    const [showLoginSpinner, setShowLoginSpinner] = useState(false);
    const [successScreenMessage, setSuccessScreenMessage] = useState(AppConstants.emptyString);
    const [loginMessage, setLoginMessage] = useState(AppConstants.emptyString);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}/${AppConstants.qrenciaTaglineLogoFile}`;
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            setDisplayError(AppConstants.emptyString);
        }, AppConstants.messageDisappearTime);

        return () => clearTimeout(timer);
    }, [displayError]);

    const togglePassword = (key) => {
        if (key === "password") {
            passwordType.password === AppConstants.fieldTypePassword ? setPasswordType({ ...passwordType, password: AppConstants.fieldTypeText }) : setPasswordType({ ...passwordType, password: AppConstants.fieldTypePassword });
        } else if (key === "confirmPassword") {
            passwordType.confirmPassword === AppConstants.fieldTypePassword ? setPasswordType({ ...passwordType, confirmPassword: AppConstants.fieldTypeText }) : setPasswordType({ ...passwordType, confirmPassword: AppConstants.fieldTypePassword });
        }
    };

    useEffect(() => {
        const checkResetPasswordLinkValidity = async () => {
            try {
                const url = `${AppConstants.checkTheResetPasswordLinkValidityAPI}${userQrenciaId}&passwordResetToken=${token}`;
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response === 1) {
                    setTokenVerified(true);
                } else {
                    navigate("/" + AppConstants.resetPasswordUrl);
                    setTokenVerified(false);
                    setEmailVerified(false);
                    setShowSuccessScreen(false);
                    setUserQrenciaId(AppConstants.emptyString);
                    setToken(AppConstants.emptyString);
                    setDisplayError(ErrorMessages.invalidResetPasswordLink);
                }
            } catch (error) {
                setTokenVerified(false);
            }
        }

        if (userQrenciaId && token) {
            checkResetPasswordLinkValidity();
        }

    }, []);

    const sendResetPasswordLink = async (e) => {
        e.preventDefault();
        try {
            setShowSpinner(true);
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
            if (emailRegex.test(email)) {
                setEmailError(AppConstants.emptyString);
                const url = `${AppConstants.sendResetPasswordLinkAPI}${email}`;
                const response = await DataService.post(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response) {
                    setShowSpinner(false);
                    if (response.ok && response.status === 200) {
                        setEmailVerified(true);
                        setShowSuccessScreen(true);
                        setLoginMessage(AppConstants.emptyString)
                        setSuccessScreenMessage(`${SuccessMessage.resetPasswordMailSendSuccessfully}${AppConstants.space}${email}`);
                    } else if (response.status === 500) {
                        setEmailVerified(false);
                        setShowSuccessScreen(false);
                        setUserQrenciaId(AppConstants.emptyString);
                        setToken(AppConstants.emptyString);
                        setDisplayError(ErrorMessages.emailVerificationError);
                    }
                } else {
                    setShowSpinner(false);
                }
            } else {
                setEmailError(ErrorMessages.invalidEmailFormat);
                setShowSpinner(false);
            }
        } catch (error) {
            setEmailVerified(false);
            setShowSpinner(false);
        }
    };

    const loginUser = async () => {
        try {
            setShowLoginSpinner(true);
            let userName = atob(userParamEmail);
            const authData = {
                userName: userName,
                userPassword: password
            }

            const resp = await DataService.post(
                AppConstants.authenticationUri,
                authData,
                AppConstants.emptyString
            );

            const body = await resp.json();

            if (body !== AppConstants.nullText) {
                const token = body.accessToken;
                if (token !== null && token !== undefined) {
                    localStorage.setItem(AppConstants.sessionKeyAccesToken, token);
                    localStorage.setItem(AppConstants.sessionKeyUserDetails, JSON.stringify(body));
                    if (body.authorities[0].authority === AppConstants.userRoleExpert) {
                        window.location.href = "../" + AppConstants.expertHomePath;
                        window.location.reload();
                    }
                    else if (body.authorities[0].authority === AppConstants.userRoleAdmin) {
                        window.location.href = "../" + AppConstants.adminNumberTrackingPath;
                        window.location.reload();
                    }
                    else if (body.authorities[0].authority === AppConstants.userRoleStudent) {
                        window.location.href = "../" + AppConstants.studentHomePath;
                        window.location.reload();
                    }
                    else if (body.authorities[0].authority === AppConstants.userRoleAgency) {
                        window.location.href = "../" + AppConstants.agencyHomePath;
                        window.location.reload();
                    } else if (body.authorities[0].authority === AppConstants.userRoleSales) {
                        window.location.href = "../" + AppConstants.salesLeadsPath;
                        window.location.reload();
                    } else if (body.authorities[0].authority === AppConstants.userRoleMarketing) {
                        window.location.href = "../" + AppConstants.marketingBulkSignupPath;
                        window.location.reload();
                    } else if (body.authorities[0].authority === AppConstants.userRolePartnerSuccess) {
                        window.location.href = "../" + AppConstants.partnerSuccessRecommendCreatorsPath;
                        window.location.reload();
                    }
                }
            }
        } catch (error) {
            setShowLoginSpinner(false);
            setLoginMessage(SuccessMessage.passwordResetSuccess);
        }
    }

    const resetPasswordRequest = async (e) => {
        try {
            e.preventDefault();
            let valid = true;
            if (!password.trim() || password.trim().length < 8) {
                valid = false;
                setPasswordError(ErrorMessages.passwordLengthShortError);
            } else if (confirmPassword !== password) {
                valid = false;
                setPasswordError(AppConstants.emptyString);
                setConfirmPasswordError(ErrorMessages.passwordDoNotMatchError);
            }

            if (valid) {
                setShowSpinner(true);
                setConfirmPasswordError(AppConstants.emptyString);
                const url = AppConstants.updateUserPasswordAPI;
                let data = {
                    "userQrenciaId": userQrenciaId,
                    "passwordToken": token,
                    "newPassword": password
                }

                const response = await DataService.post(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response) {
                    setShowSpinner(false);
                    if (response.status === 200) {
                        setShowSuccessScreen(true);
                        setUserQrenciaId(AppConstants.emptyString);
                        setToken(AppConstants.emptyString);
                        setSuccessScreenMessage(SuccessMessage.passwordResetSuccess);
                        loginUser();
                    } else {
                        setDisplayError(ErrorMessages.passwordResetFailedError);
                    }
                } else {
                    setShowSpinner(false);
                    setDisplayError(ErrorMessages.passwordResetFailedError);
                }
            }

        } catch (error) {
            setShowSpinner(false);
            setDisplayError(ErrorMessages.passwordResetFailedError);
        }
    }

    return (
        <>
            {!showSuccessScreen &&
                <div className={classes.resetFormSection}>
                    <div className={classes.qrenciaLogoSection}>
                        <NavLink to="/">
                            <img
                                className={classes.qrenciaLogo}
                                src={qrenciaLogoUrl}
                                alt="Qrencia Logo"
                            />
                        </NavLink>
                    </div>
                    <h1 className={classes.formHeading}>{AppConstants.resetPasswordFormHeading}</h1>
                    <form className={classes.formSection}>
                        <div className={classes.searchFormElementsSection}>
                            <div>
                                {displayError && <p className="errorMessage"><RiErrorWarningLine />{displayError}</p>}
                            </div>
                            {!(token && userQrenciaId) && <div>
                                <div className={classes.inputContainer}>
                                    <Form.Label className={`${classes.inputLabel} ${formClass.formLabel} `}>{AppConstants.enterEmailLabelText}</Form.Label>
                                    <Form.Control name="email" value={email} onChange={e => setEmail(e.target.value)} className={`${formClass.formInput} ${classes.formInputBox}`} type="email" required />
                                </div>
                                <p className={classes.errorMessage}>{emailError}</p>
                                <div>
                                    {showSpinner && <Button className={classes.resetButton}><BiLoaderAlt className={classes.spinner} /></Button>}
                                    {!showSpinner && <Button type="submit" value="Submit" onClick={(e) => sendResetPasswordLink(e)} className={classes.resetButton}>Send Link</Button>}
                                </div>
                            </div>}
                            {token && userQrenciaId && tokenVerified && <div>
                                <div className={classes.inputContainer}>
                                    <Form.Label className={`${formClass.formLabel} ${classes.inputLabel}`}>{AppConstants.passwordText}</Form.Label>
                                    <Form.Control name="password" value={password} onChange={e => setPassword(e.target.value)} className={`${formClass.formInput} ${classes.formInputBox}`} minLength={8} maxLength={16} type={passwordType.password} required />
                                    <div className={classes.showHidePasswordIcon}>{passwordType.password === AppConstants.fieldTypePassword ? <FaEye onClick={() => togglePassword("password")} /> : <FaEyeSlash onClick={() => togglePassword("password")} />}</div>
                                </div>
                                <p className={`${classes.errorMessage} ${classes.passwordError}`}>{passwordError}</p>
                                <div className={classes.inputContainer}>
                                    <Form.Label className={`${formClass.formLabel} ${classes.inputLabel}`}>{AppConstants.confirmPasswordText}</Form.Label>
                                    <Form.Control name="confirm-password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} className={`${formClass.formInput} ${classes.formInputBox}`} minLength={8} maxLength={16} type={passwordType.confirmPassword} required />
                                    <div className={classes.showHidePasswordIcon}>{passwordType.confirmPassword === AppConstants.fieldTypePassword ? <FaEye onClick={() => togglePassword("confirmPassword")} /> : <FaEyeSlash onClick={() => togglePassword("confirmPassword")} />}</div>
                                </div>
                                <p className={`${classes.errorMessage} ${classes.passwordError}`}>{confirmPasswordError}</p>
                                <div>
                                    {showSpinner && <Button className={classes.resetButton}><BiLoaderAlt className={classes.spinner} /></Button>}
                                    {!showSpinner && <Button type="submit" value="Submit" onClick={(e) => resetPasswordRequest(e)} className={classes.resetButton}>Reset Password</Button>}
                                </div>
                            </div>}
                        </div>
                    </form>
                </div>
            }
            {showSuccessScreen &&
                <div className={classes.successScreenContainer}>
                    {<p className={classes.signupSuccessTitle}>{successScreenMessage}</p>}
                    {loginMessage.length > 0 && <p className={`${classes.tncText} ${classes.signupSuccessSubtitle}`}>Return to <NavLink className={classes.tncTextHyper} to={AppConstants.loginPageUrl}>login</NavLink></p>}
                    {loginMessage.length === 0 && showLoginSpinner && <p className={classes.loginSpinner}>Please wait while we log you in to your account <BiLoaderAlt className="spinner" /></p>}
                </div>
            }
        </>
    );

}