import React from "react";
import { useLoaderData } from "react-router-dom";
import { LandingPageTopNavigation } from '../../layout/LandingPageLayout/LandingPageTopNavigation';
import { ExpertMicrositeLayout } from "../../component/expertMicrosite/ExpertMicrositeLayout";
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';

export const AgencyMicrositePage = () => {
    const landingPageData = useLoaderData();
    return (
        <>
            <LandingPageTopNavigation landingPageData={landingPageData} />
            <ExpertMicrositeLayout />
            <LandingPageFooter landingPageData={landingPageData} />
        </>
    );

}
