import React,{useEffect} from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { LandingPageTopNavigation } from '../../layout/LandingPageLayout/LandingPageTopNavigation';
import { IndividualCompetition } from "../../component/individualCompetition/IndividualCompetitionPageLayout";
import { LandingPageFooter } from '../../layout/LandingPageLayout/LandingPageFooter';
export const IndividualCompetitionPage = () => {
    
    const { categorySlug, skillSlug } = useParams();
    const [landingPageData, skillsData, bannerSectionData] = useLoaderData();

    return (
        <>
            <LandingPageTopNavigation landingPageData={landingPageData} />
            <IndividualCompetition />
            <LandingPageFooter landingPageData={landingPageData} />
        </>
    );

}

