import { useLoaderData } from "react-router-dom";
import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { ExternalNudgePageForm } from "../../component/formControls/ExternalNudgePageForm";
import { AppConstants } from "../../constants/AppConstants";
import classes from "../student/NudgesPage.module.css";
import { NudgesPageBanner } from "../../component/nudgesPage/NudgesPageBanner";
import { LandingPageTopNavigation } from "../../layout/LandingPageLayout/LandingPageTopNavigation";

export const NudgesPage = () => {
    const [landingPageData, nudgePageData] = useLoaderData();

    return (
        <>
            <LandingPageTopNavigation landingPageData={landingPageData} />
            <NudgesPageBanner />
            <div className={classes.nudgeFormContainer}>
                <ExternalNudgePageForm subHeading={nudgePageData?.slides[0].active ? nudgePageData?.slides[0]?.subTitle : AppConstants.nullText} heading={nudgePageData?.slides[0].active ? nudgePageData?.slides[0]?.title : AppConstants.nullText} category={AppConstants.learningActivitiesTypes.nudge} />
            </div>
            <LandingPageFooter landingPageData={landingPageData} />
        </>
    )
}