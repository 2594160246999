import { Nav, Navbar, Badge, Accordion } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import { RiUserFollowLine } from "react-icons/ri"
import { useEffect, useState } from 'react';
import { MdOutlinePublishedWithChanges } from "react-icons/md"
import { FaRegCalendarCheck, FaRegEdit, FaRegBell, FaChalkboardTeacher, FaRegLightbulb, FaRegStar, FaRegUserCircle, FaRegClipboard, FaAward } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { AppConstants } from "../../constants/AppConstants";
import Auth from '../../utils/Auth';
import '../../common/TemplateStyle.css';
import { MdPendingActions } from "react-icons/md";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import classes from "../StudentPageLayout/StudentLeftNav.module.css";
import { LiaCommentsSolid } from "react-icons/lia";

export const ExpertLeftNav = (props) => {
    const [expanded, setExpanded] = useState(false);
    const [ambassadorStatus, setAmbassadorStatus] = useState(AppConstants.falseText);

    const icons = AppConstants.qrenciaApplicationIcons;

    const getIconPath = (icon) => {
        return `${process.env.REACT_APP_MEDIA_CONTENT}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.iconImagesS3Path}${AppConstants.forwardSlash}${icon}`
    };

    useEffect(() => {
        const fetchAmbassadorChallengeStatus = async () => {
            const url = AppConstants.hasCreatorAcceptedAmbassadorChallengeAPI;
            const response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyString);

            if (response) {
                setAmbassadorStatus(AppConstants.trueText);
            } else {
                setAmbassadorStatus(AppConstants.falseText)
            }
        }
        fetchAmbassadorChallengeStatus();
    }, []);

    return (
        <>
            <Navbar expanded={expanded} expand="lg" className="sideNavigation">
                <Navbar.Toggle className="sidebarToggleButton" onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse className="sideNavigationContent">
                    <Nav className="sideNavigationListSection">
                        <div className="profileSection">
                            {Auth.getLoggedInUserDetails().profileImagePreSignedUrl != null && <div className="profileImageDiv">
                                <img src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl} className="profileImage" alt="User Profile" />
                                {ambassadorStatus && <FaAward className="ambassadorIcon" />}
                            </div>}
                            {Auth.getLoggedInUserDetails().profileImagePreSignedUrl == null && <div>
                                <div className="userInitials">{Auth.getLoggedInUserDetails().nameInitials}
                                    {ambassadorStatus && <FaAward className="ambassadorIcon" />}
                                </div>
                            </div>}
                            <div className="userNameDiv">
                                <p className="userName">{Auth.getLoggedInName()}</p>
                                <p className="qrenciaIdText">QID: {Auth.getLoggedInUserDetails().userId}</p>
                            </div>
                        </div>
                        <div className="referButtonSection">
                            {/* <div className="referButtonInnerSection">
                                <Button className="referButton">REFER A STUDENT</Button>
                            </div> */}
                            <div className="notificationSidebarSection">
                                <FaRegBell className="notificationSidebarImage" />
                                <Badge bg="danger" className="notificationSidebarBadge">0</Badge>
                            </div>
                        </div>
                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.expertHomePath}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><FaRegUserCircle className="linkIcons" />
                                <p className="navigationLinks">Home</p>
                            </NavLink>
                        </Nav.Item>
                        {/* <div className="sideNavigationItemMobileView">
                            <Nav.Item className="sideNavigationItem disabledSideNavigationComponent" onClick={() => setExpanded(false)}>
                                <NavLink
                                    to={AppConstants.myTeachingExpertTemplatePath}
                                    className={({ isActive }) =>
                                        isActive ? "active" : undefined
                                    }
                                ><HiOutlineBookOpen className="linkIcons" />
                                    <p className="navigationLinks">My Teaching</p>
                                </NavLink>
                            </Nav.Item>
                        </div> */}
                        {/* <div className="sideNavigationItemMobileView">
                            <Nav.Item className="sideNavigationItem disabledSideNavigationComponent" onClick={() => setExpanded(false)}>
                                <NavLink
                                    to={AppConstants.myStudentsExpertTemplatePath}
                                    className={({ isActive }) =>
                                        isActive ? "active" : undefined
                                    }
                                ><RiUserFollowLine className="linkIcons" />
                                    <p className="navigationLinks">My Students</p>
                                </NavLink>
                            </Nav.Item>
                        </div> */}
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><img src={getIconPath(icons.competitionIcon)} alt="icon" className={`linkIcons ${classes.logoImages}`} />Competitions</Accordion.Header>
                                <Accordion.Body>
                                    <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.expertCreateCompetitionsPath}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        >  <FaRegClipboard className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Create</p>
                                        </NavLink>
                                    </Nav.Item><Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.expertManageCompetitionsPath}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        ><FaRegCalendarCheck className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Manage</p>
                                        </NavLink>
                                    </Nav.Item>
                                    {/* <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.expertJudgeCompetitionsPath}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        ><FaRegEdit className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Judge</p>
                                        </NavLink>
                                    </Nav.Item> */}
                                    <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.expertBulkMessagingPath}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        ><FiMail className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Mail</p>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.expertBulkEnrolRoute}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        ><FaRegEdit className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Bulk Enrol</p>
                                        </NavLink>
                                    </Nav.Item>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Accordion>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><img src={getIconPath(icons.courseIcon)} alt="icon" className={`linkIcons ${classes.logoImages}`} />Courses</Accordion.Header>
                                <Accordion.Body>
                                    <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.expertCreateCoursesPath}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        ><FaRegClipboard className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Create</p>
                                        </NavLink>
                                    </Nav.Item><Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.expertManageCoursesPath}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        ><FaRegCalendarCheck className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Manage</p>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.expertpublishUnpublishCoursePath}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }><MdOutlinePublishedWithChanges className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Publish</p>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.markAttendancePath}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        ><RiUserFollowLine className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Attendance</p>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.shareCourseFeedbackPath}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        ><LiaCommentsSolid className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Feedback</p>
                                        </NavLink>
                                    </Nav.Item>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.expertNudgesPath}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            >
                                {({ isActive }) => (
                                    <>
                                        <img src={getIconPath(isActive ? icons.nudgeIconWhite : icons.nudgeIcon)} alt="icon" className={`linkIcons ${classes.logoImages}`} />
                                        <p className="navigationLinks">Nudges</p>
                                    </>
                                )}
                            </NavLink>
                        </Nav.Item>
                        <Accordion>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header disabled><img src={getIconPath(icons.workshopIcon)} alt="icon" className={`linkIcons ${classes.logoImages}`} />Workshops</Accordion.Header>
                                <Accordion.Body>
                                    <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.expertCreateWorkshopPath}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        ><FaRegClipboard className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Create</p>
                                        </NavLink>
                                    </Nav.Item><Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.expertManageWorkshopPath}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        ><FaRegCalendarCheck className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Manage</p>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.expertpublishUnpublishWorkshopPath}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }><MdOutlinePublishedWithChanges className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Publish</p>
                                        </NavLink>
                                    </Nav.Item>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.approvePendingEnrolmentsRoute}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><MdPendingActions className="linkIcons" />
                                <p className="navigationLinks">Offline Payments</p>
                            </NavLink>
                        </Nav.Item>
                        {props && props.associationStatus && props?.associationStatus?.associationFlag === AppConstants.yText && <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.expertMySchoolPath}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><FaChalkboardTeacher className="linkIcons" />
                                <p className="navigationLinks">My School</p>
                            </NavLink>
                        </Nav.Item>}
                        {Auth.getLoggedInUserDetails().b2bAccessDetails?.isB2BEnabled === AppConstants.trueText && <Accordion>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header><FaRegLightbulb className="linkIcons linkIconsDropdown" />World Skills</Accordion.Header>
                                <Accordion.Body>
                                    <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <Link
                                            to={Auth.getLoggedInUserDetails().b2bAccessDetails?.b2bUrl}
                                            target="_blank"
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        ><FaRegStar className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">CMS</p>
                                        </Link>
                                    </Nav.Item>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}