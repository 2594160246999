import React, { useState, useEffect } from 'react';
import { ExpertMicrositeCompetitionsCards } from '../cards/expertMicrositeCards/ExpertMicrositeCompetitionsCards';

export const MicrositeCompetitionsSection = (props) => {
    const [competitionsDetailsObj, setCompetitionsDetailsObj] = useState([]);

    //enroll to competitions
    const onClickEnroll = async (competition) => { }

    useEffect(() => {
        setCompetitionsDetailsObj(props.competitionsData);
    }, [props.competitionsData]);

    return (
        <>
            <div>
                {competitionsDetailsObj.length !== 0 &&
                    <ExpertMicrositeCompetitionsCards
                        userRoleForMicrosite={props.userRoleForMicrosite}
                        category="Competition"
                        competitionsData={competitionsDetailsObj}
                        onClickEnroll={onClickEnroll}
                    />
                }
            </div>
        </>
    );
}