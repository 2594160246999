import { NavLink } from 'react-router-dom';
import { Badge } from "react-bootstrap";
import { FaChevronDown, FaRegBell, FaRegClipboard } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { RiUserFollowLine, RiProfileLine } from "react-icons/ri"
import { AppConstants } from "../../constants/AppConstants";
import Dropdown from 'react-bootstrap/Dropdown';
import './ExpertHeader.css';
import classes from './ExpertHeader.module.css';
import { Logout } from '../../common/Logout';
import Auth from '../../utils/Auth';
import { useEffect, useState } from 'react';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { FaAward } from 'react-icons/fa6';
import { MdPendingActions } from 'react-icons/md';
import { LiaCommentsSolid } from "react-icons/lia";

export const ExpertHeader = () => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const userBrandingPageLink = `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.expert}${AppConstants.forwardSlash}${Auth.getLoggedInUserDetails().userSlug}`;
    const qrenciaLogoUrl = `${mediaContent}${AppConstants.logoImageS3Path}${AppConstants.forwardSlash}${AppConstants.qrenciaLogoFilePath}`;
    const [ambassadorStatus, setAmbassadorStatus] = useState(AppConstants.falseText);


    const icons = AppConstants.qrenciaApplicationIcons;

    const getIconPath = (icon) => {
        return `${process.env.REACT_APP_MEDIA_CONTENT}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.iconImagesS3Path}${AppConstants.forwardSlash}${icon}`
    };

    useEffect(() => {
        const fetchAmbassadorChallengeStatus = async () => {
            const url = AppConstants.hasCreatorAcceptedAmbassadorChallengeAPI;
            const response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyString);

            if (response) {
                setAmbassadorStatus(AppConstants.trueText);
            } else {
                setAmbassadorStatus(AppConstants.falseText)
            }
        }
        fetchAmbassadorChallengeStatus();
    }, []);

    return (
        <>
            <header className={classes.header}>
                <div className={classes.logoSection}>
                    <NavLink to={AppConstants.forwardSlash}>
                        <img src={qrenciaLogoUrl} className={classes.logo} alt="Qrencia Logo" />
                    </NavLink>
                </div>
                <div className={classes.listSection}>
                    <nav>
                        <div className={classes.list}>
                            {/* <li>
                                <NavLink
                                    to={AppConstants.myTeachingExpertTemplatePath}
                                    // className={({ isActive }) =>
                                    //     isActive ? classes.active : undefined
                                    // }
                                    className='disabledComponent'
                                ><HiOutlineBookOpen className={classes.linkIcons} /> My Teaching
                                </NavLink>
                            </li> */}
                            {/* <li>
                                <NavLink
                                    to={AppConstants.myStudentsExpertTemplatePath}
                                    // className={({ isActive }) =>
                                    //     isActive ? classes.active : undefined
                                    // }
                                    className='disabledComponent'
                                ><RiUserFollowLine className={classes.linkIcons} /> My Students
                                </NavLink>
                            </li> */}
                            <div className={classes.sectionNavItem}>
                                <NavLink
                                    to={AppConstants.expertCreateCoursesPath}
                                    className={({ isActive }) =>
                                        isActive ? "active" : undefined
                                    }
                                ><FaRegClipboard className={classes.linkIcons} />Create Courses
                                </NavLink>
                            </div>
                            <div className={classes.sectionNavItem}>
                                <NavLink
                                    to={AppConstants.shareCourseFeedbackPath}
                                    className={({ isActive }) =>
                                        isActive ? classes.active : undefined
                                    }
                                ><LiaCommentsSolid className={classes.linkIcons} />Give Feedback
                                </NavLink>
                            </div>
                            <div className={classes.sectionNavItem}>
                                <NavLink
                                    to={AppConstants.markAttendancePath}
                                    className={({ isActive }) =>
                                        isActive ? classes.active : undefined
                                    }
                                ><RiUserFollowLine className={classes.linkIcons} />Attendance
                                </NavLink>
                            </div>
                            <div className={classes.sectionNavItem}>
                                <NavLink
                                    to={AppConstants.approvePendingEnrolmentsRoute}
                                    className={({ isActive }) =>
                                        isActive ? classes.active : undefined
                                    }
                                ><MdPendingActions className={classes.linkIcons} /> Offline Payments
                                </NavLink>
                            </div>
                            <div className={classes.sectionNavItem}>
                                <NavLink
                                    to={AppConstants.expertNudgesPath}
                                    className={({ isActive }) =>
                                        isActive ? "active" : undefined
                                    }
                                >
                                    {() => (
                                        <>
                                            <img src={getIconPath(icons.nudgeIcon)} alt="icon" className={`${classes.linkIcons} ${classes.logoImages}`} />
                                            <p className="navigationLinks">Respond to Nudges</p>
                                        </>
                                    )}
                                </NavLink>
                            </div>

                        </div>
                    </nav>
                </div>
                <div className={classes.payoutSection}>
                    {/* <Button className={classes.payoutButton}>Payout = $0.00</Button> */}
                </div>
                <div className={classes.buttonSection}>
                    {/* <Button className={classes.referButton}>REFER A STUDENT</Button> */}
                </div>
                <div className={classes.notificationSection}>
                    <FaRegBell className={classes.notificationImage} />
                    <Badge bg="danger" className={classes.notificationBadge}>0</Badge>
                </div>
                <div className={classes.profileSection}>
                    {Auth.getLoggedInUserDetails().profileImagePreSignedUrl != null && <div className={classes.profileIconContainer}>
                        <img src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl} className={classes.profileImage} alt="Profile" />
                        {ambassadorStatus && <FaAward className={`${classes.profileVerifiedIcon}`} />}
                    </div>}
                    {Auth.getLoggedInUserDetails().profileImagePreSignedUrl == null && <div className={classes.profileIconContainer}>
                        <div className={classes.userInitials}>{Auth.getLoggedInUserDetails().nameInitials}</div>
                        {ambassadorStatus && <FaAward className={`${classes.profileVerifiedIcon}`} />}
                    </div>}
                    <Dropdown>
                        <Dropdown.Toggle className={classes.profilePopoverButton}>
                            <p className={classes.profileName}>{Auth.getLoggedInName().length < 16 ? Auth.getLoggedInName() : Auth.getLoggedInName().substr(0, 16) + ".."}</p> <FaChevronDown />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => window.open(userBrandingPageLink, AppConstants.openInNewTab)} className={classes.profileDropdownItem}><RiProfileLine className={classes.profileDropdownOptionIcon} /><p className={classes.profileDropdownOption}>Branding Page</p></Dropdown.Item>
                            {/* <Dropdown.Item className={classes.profileDropdownItem}><RiUserSettingsLine className={classes.profileDropdownOptionIcon} /><p className={classes.profileDropdownOption}>Update Profile</p></Dropdown.Item> */}
                            <Dropdown.Item className={classes.profileDropdownItem} onClick={Logout.logout}><BiLogOut className={classes.profileDropdownOptionIcon} /><p className={classes.profileDropdownOption}>Logout</p></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </header>
        </>
    );
}