import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { BiCalendarCheck, BiWallet, BiCalendarX } from "react-icons/bi";
import { TbShare3 } from "react-icons/tb";
import { AppConstants } from "../../../constants/AppConstants";
import classes from '../CompetitionsCards.module.css';
import 'moment-timezone';
import Moment from 'react-moment';
import { CgPin } from "react-icons/cg";
import '../CourseCards.css';
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";
import { FaChalkboardTeacher } from "react-icons/fa";
import { AiOutlineClockCircle } from "react-icons/ai";
import { DataService } from "../../../services/DataService";
import { MathUtil } from "../../../utils/MathUtil";

export const ExpertMicrositeCoursesCards = (props) => {
    const [courseCards, setCourseCards] = useState([]);
    const [cardCount, setCardCount] = useState(0);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [editIndex, setEditIndex] = useState(null);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [linkToShare, setLinkToShare] = useState(AppConstants.emptyString);
    const learningActivitiesCardsCount = AppConstants.cardCounts;
    const [minimumReviewCount, setMinimumReviewCount] = useState(AppConstants.emptyString);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const defaultCourseThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultThumbnailImage}`;

    //set cardCount to 4 on tablet screen and 2 on mobile screen
    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.learningActivityDesktopCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityDesktopCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.learningActivityTabCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityTabCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.learningActivityMobileCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityMobileCount);
        }
    };

    //call handleResize() on screen width change
    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    useEffect(() => {
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setMinimumReviewCount(parseInt(response.find(item => item.keyName === AppConstants.minimumCourseReviewsCardDisplay).keyValue));
            }
        };

        fetchConfigurableKeys();
    }, [courseCards]);

    useEffect(() => {
        setCourseCards(props.courseData);
    }, [props.courseData]);

    // Share on social media
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.coursesText}${AppConstants.forwardSlash}${shareData.skillCategorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.courseSlug}`);
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.openInNewTab);
    };

    return (
        <>
            <Row xs={1} sm={2} md={2} lg={3} xl={3} className="cardSection">
                {courseCards !== undefined && courseCards.slice(0, cardCount).map((course, index) => (
                    <Col key={course.uniqueId}>
                        <Card className="cardDiv" key={index} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.coursesText}${AppConstants.forwardSlash}${course.skillCategorySlug}${AppConstants.forwardSlash}${course.skillSlug}${AppConstants.forwardSlash}${course.courseSlug}`)}>
                            <Card.Img variant="top" className="cardImage" src={course.courseThumbnailImageAbsolutePath ? course.courseThumbnailImageAbsolutePath : defaultCourseThumbnail} />
                            <TbShare3 className={classes.shareIconUpcomingCards} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(course) }} />
                            <Card.Body className="cardBody">
                                <div className="subTitleContainer">
                                    <div className={`cardSubTitle courseDiffColor`}>{course.modeOfDelivery.toLowerCase() === AppConstants.offlineText.toLocaleLowerCase() ? <span className="modeSubtitle" data-tooltip-place="top" data-tooltip-id={course.uniqueId}><a onClick={stopPropagation} href={course.geoLocation} target={AppConstants.openInNewTab} className="workshopLink onlineIconCourse"><CgPin className="geoLocationIcon onlineIconCourse" /> {course.modeOfDelivery.toUpperCase()}</a></span> : <span className="onlineIconCourse modeSubtitle">{course.modeOfDelivery.toUpperCase()}</span>}</div>
                                    <Tooltip className="tooltipMessage" id={course.uniqueId}><span className="tooltipMessage">{course.address}</span></Tooltip>
                                </div>
                                <Card.Text className="cardTitle titleFixedHeight">
                                    <Link className="cardLink" data-tooltip-id={`${course.uniqueId}-title`}>{course.courseTitle}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${course.uniqueId}-title`}><span>{course.courseTitle}</span></Tooltip>
                                </Card.Text>
                                {editIndex === index && showSocialMediaPannel && <div onClick={stopPropagation} className={classes.shareIconPannelContainerUpcomingCards}>
                                    <ShareLinkToSocialMediaUtil linkToShare={linkToShare} className={classes} useIcons={AppConstants.trueText} />
                                </div>}
                                {props.userRoleForMicrosite === AppConstants.userRoleAgency && <Card.Text onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${course.userRole === AppConstants.userRoleAgency ? AppConstants.agency : AppConstants.expert}${AppConstants.forwardSlash}${course.userSlug}`) }} className="cardTimeAndDate">By : {course.userRole === AppConstants.userRoleAgency ? course.agencyName : course.expertName}</Card.Text>}
                                {course.courseType === AppConstants.structuredCourseType && <div className="cardTimeAndDate">
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">Starts&nbsp;{DateTimeUtil.getDateTimeForCourses(course.startTime)} {course.preferredTimezone === AppConstants.utcTimezone && `(${course.preferredTimezone})`}</span>
                                    </div>
                                </div>}

                                {course.courseType === AppConstants.flexibleCourseType && <div className="cardTimeAndDate">
                                    <div><AiOutlineClockCircle className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">{course.startTime} - {course.endTime} {course.preferredTimezone === AppConstants.utcTimezone && `(${course.preferredTimezone})`} {course.reviewCount >= minimumReviewCount && <><span className="durationSeparator">|</span> {`${MathUtil.formatNumberToLocaleString(course.reviewCount)} ${course.reviewCount > 1 ? "Reviews" : "Review"}`}</>}</span>
                                    </div>
                                </div>}
                                {course.courseType === AppConstants.structuredCourseType && <div className="cardTimeAndDate">
                                    <div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">{course.numberOfClasses} {course.numberOfClasses > 1 ? "Classes" : "Class"} <span className="durationSeparator">|</span> {course.duration} {course.duration > 1 ? "Hours" : "Hour"} </span>
                                    </div>
                                </div>}
                                {course.courseType === AppConstants.flexibleCourseType && <div className="cardTimeAndDate">
                                    {course.trackingType === AppConstants.classBasedTrackingText && (course.isLoggedInStudentEnrolled === AppConstants.falseTextString || course.isLoggedInStudentEnrolled === AppConstants.nullText || course.subscriptionStatus === AppConstants.subscriptionStatus.expired) && <> <div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">{course.numberOfClasses} {course.numberOfClasses > 1 ? "Classes" : "Class"} <span className="durationSeparator">|</span> {course.duration} {course.duration > 1 ? "Hours" : "Hour"} </span>
                                    </div></>}
                                    {course.trackingType === AppConstants.durationBasedTrackingText && (course.isLoggedInStudentEnrolled === AppConstants.falseTextString || course.isLoggedInStudentEnrolled === AppConstants.nullText || course.subscriptionStatus === AppConstants.subscriptionStatus.expired) && <><div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">{course.packageDuration} {course.packageDuration > 1 ? "Days" : "Day"}</span>
                                    </div></>}
                                    {course.trackingType === AppConstants.classBasedTrackingText && course.subscriptionStatus === AppConstants.subscriptionStatus.active && <>
                                        <div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                            <span className="competitionDateTimeFormat">{course.remainingClasses} {course.remainingClasses > 1 ? "classes" : "class"} remaining</span>
                                        </div> </>}
                                </div>}
                                {course.courseType === AppConstants.flexibleCourseType && course.trackingType === AppConstants.durationBasedTrackingText && course.subscriptionStatus === AppConstants.subscriptionStatus.active && <div className="cardTimeAndDate">
                                    <div><BiCalendarX className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">Ends&nbsp;<Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{course.subscriptionEndDate?.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{course.subscriptionEndDate?.replace(' ', 'T') + 'Z'}</Moment> {course.preferredTimezone === AppConstants.utcTimezone && `(${course.preferredTimezone})`}</span>
                                    </div>
                                </div>}
                                <div className="buttonSection">
                                    <div className="learningActivityCostInfo"><BiWallet /> {PaymentsUtil.calculateCost({ cost: course.cost, discount: course.discount })}</div>
                                    {((course.isOfflinePaymentRequested === AppConstants.nText || !course.isOfflinePaymentRequested) && ((course.courseType === AppConstants.structuredCourseType && (course.isLoggedInStudentEnrolled === AppConstants.falseTextString || course.isLoggedInStudentEnrolled === AppConstants.nullText)) || (course.courseType === AppConstants.flexibleCourseType && (course.subscriptionStatus === AppConstants.nullText || course.subscriptionStatus === AppConstants.subscriptionStatus.expired)))) && <Button type="button" onClick={(e) => { stopPropagation(e); props.confirmBeforeEnrolment({ id: course.courseId, title: course.courseTitle, uniqueId: course.uniqueId, startTime: `${course.utcStartDate} ${course.windowStartTime}`, payOfflineFlag: course.payOfflineFlag, creatorName: course.userRole === AppConstants.userRoleAgency ? course.agencyName : course.expertName, creatorRole: course.userRole }) }} className="cardButton">{course.courseEnrolledOnDate === AppConstants.nullText ? "Enrol Now" : AppConstants.renewPlanText}</Button>}
                                    {((course.isOfflinePaymentRequested === AppConstants.yText) || (course.isLoggedInStudentEnrolled === AppConstants.trueTextString && course.courseType === AppConstants.structuredCourseType) || (course.subscriptionStatus === AppConstants.subscriptionStatus.active) || (course.isOfflinePaymentRequested === AppConstants.yText)) && <Button onClick={stopPropagation} type="button" className="cardButton" disabled>{course.isOfflinePaymentRequested === AppConstants.yText ? "Requested" : "Enrolled"}</Button>}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className={classes.loadMore}>
                <Col className="p-2">
                    {cardCount < courseCards.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + viewMoreCardsCount)}>Load more</button>}
                </Col>
            </Row>
        </>
    );
}