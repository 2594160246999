import React, { useEffect, useState, useContext, useRef } from "react";
import { useLoaderData, useSearchParams, useNavigate } from "react-router-dom";
import { Breadcrumbs } from '../../../utils/BreadcrumbsUtil';
import classes from './IndividualSkills.module.css';
import { AppConstants } from '../../../constants/AppConstants';
import { DataService } from '../../../services/DataService';
import { IndividualSkillsPageHeader } from "../../../layout/ExtendedPagesLayout/IndividualSkillsPageHeader";
import { IndividualSkillsIntroSection } from "../skills/IndividualSkillsIntroSection";
import { IndividualSkillsRelevanceSection } from "../skills/IndividualSkillsRelevanceSection";
import { IndividualSkillsActivitiesSection } from "./IndividualSkillsActivitiesSection";
import { IndividualSkillsPageList } from "../../lists/IndividualSkillsPageList";
import { useParams } from "react-router-dom";
import sharePanelClasses from "../../individualCoursePage/IndividualCoursePageLayout.module.css"
import { SelectionContext } from '../../../context/SelectionContext';
import { IndividualSkillPageNavigation } from '../../../layout/ExtendedPagesLayout/IndividualSkillPageNavigation';
import { Element } from 'react-scroll';
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";

export const IndividualSkill = (props) => {
    const { categorySlug, skillSlug } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [activitiesData, setActivitiesData] = useState({});
    const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
    const navigate = useNavigate();
    const { selectedActivity, setSelectedActivity } = useContext(SelectionContext);
    const [landingPageData, skills, bannerSectionData, footerSectionData, keyHighlightsHeadingData, activitySectionStaticContent, activitiesList] = useLoaderData();
    const [skillData, setSkillData] = useState(...props.data.filter((item) => item.categorySlug.toLowerCase() === categorySlug.toLowerCase() && item.skillSlug.toLowerCase() === skillSlug.toLowerCase()));
    if (skillData == undefined) {
        navigate(errorPageUrl);
    }

    const linkToShare = `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.skills}${AppConstants.forwardSlash}${categorySlug}${AppConstants.forwardSlash}${skillSlug}`;
    const [navigationSelectedActivity, setNavigationSelectedActivity] = useState(AppConstants.emptyString);
    const sectionRef = useRef(AppConstants.nullText);
    const [expertExistsForSkill, setExpertExistsForSkill] = useState(AppConstants.emptyString);

    useEffect(() => {
        fetchSkills();
        async function fetchSkills() {
            const url = AppConstants.getSkillsDataAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            setSkillData(...response.filter((item) => item.categorySlug.toLowerCase() === categorySlug.toLowerCase() && item.skillSlug.toLowerCase() === skillSlug.toLowerCase()));
            if (skillData == undefined) {
                navigate(errorPageUrl);
            }
        }

        checkIfActiveExpertExistsForSkill();
        async function checkIfActiveExpertExistsForSkill() {
            const url = `${AppConstants.checkIfActiveExpertExistsForSkillAPI}${skillData.skillId}`;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response != undefined) {
                setExpertExistsForSkill(response);
            }
            else {
                setExpertExistsForSkill(AppConstants.falseText);
            }
        }

        function getAllActivitiesData() {
            let allActivities = props.allActivitiesForSkill;
            AppConstants.activitiesType.forEach((type, index) => {
                if (!activitiesList.some(activity => activity.activityType === type)) {
                    if (index === 0) {
                        allActivities["creatorDetails"] = [];
                    } else if (index === 1) {
                        allActivities["courseDetails"] = [];
                    } else if (index === 2) {
                        allActivities["competitionDetails"] = [];
                    } else if (index === 3) {
                        allActivities["workshopDetails"] = [];
                    }
                }
            });

            setActivitiesData(allActivities);
        }

        getAllActivitiesData();
    }, []);

    const getNavigationSelectedActivity = (activityType) => {
        setNavigationSelectedActivity(activityType);
    };

    useEffect(() => {
        async function scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'instant'
            });
        };

        async function scrollToSection() {
            if (sectionRef?.current !== null) {
                setTimeout(() => {
                    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
                }, 200);
            }
        }

        if (selectedActivity != "") {
            if (expertExistsForSkill === AppConstants.trueText) {
                scrollToSection();
            } else {
                if (selectedActivity === AppConstants.activitiesType[0]) {
                    scrollToTop();
                }
                if (selectedActivity === AppConstants.activitiesType[4]) {
                    scrollToTop();
                }
                if (selectedActivity === AppConstants.activitiesType[1] && props.allActivitiesForSkill.courseDetails?.length > 0) {
                    scrollToSection();
                } else {
                    scrollToTop();
                }
                if (selectedActivity === AppConstants.activitiesType[2] && props.allActivitiesForSkill.competitionDetails?.length > 0) {
                    scrollToSection();
                } else {
                    scrollToTop();
                }
                if (selectedActivity === AppConstants.activitiesType[3] && props.allActivitiesForSkill.workshopDetails?.length > 0) {
                    scrollToSection();
                } else {
                    scrollToTop();
                }
            }
        }
        else {
            scrollToTop();
        }
    }, [selectedActivity, expertExistsForSkill]);

    return (
        <>
            <IndividualSkillPageNavigation expertExistsForSkill={expertExistsForSkill} landingPageData={landingPageData} getNavigationSelectedActivity={getNavigationSelectedActivity} activitiesList={activitiesList} allActivitiesForSkill={activitiesData} />
            {skillData != undefined && <IndividualSkillsPageHeader skillName={skillData.skill} bannerSectionData={bannerSectionData} />}
            {skillData !== undefined && <div className={classes.shareIconPannelContainer}>
                <ShareLinkToSocialMediaUtil className={sharePanelClasses} linkToShare={linkToShare} />
            </div>}
            {skillData !== undefined && <Breadcrumbs class={classes.breadcrumbs} />}
            {skillData !== undefined && <IndividualSkillsIntroSection skill={skillData.skill} skillName={skillData.skill} skillIntroduction={skillData.skillIntroduction} skillHistory={skillData.skillHistory} skillFunFactImageUrl={skillData.skillFunFactImageUrl} skillFunFact={skillData.skillFunFact} skillFunFactVideoPath={skillData.skillFunFactVideoPath} />}
            {activitiesList?.length > 0 && <Element id="activitySection"> <div ref={sectionRef}>{skillData !== undefined && !(!expertExistsForSkill && (activitiesData.courseDetails === undefined || activitiesData.courseDetails?.length == 0) && (activitiesData.competitionDetails === undefined || activitiesData.competitionDetails?.length == 0) && (activitiesData.workshopDetails === undefined || activitiesData.workshopDetails?.length == 0)) && <IndividualSkillsActivitiesSection expertExistsForSkill={expertExistsForSkill} skill={skillData.skill} skillId={skillData.skillId} activitiesList={activitiesList} activitySectionStaticContent={activitySectionStaticContent} navigationSelectedActivity={navigationSelectedActivity} allActivitiesForSkill={activitiesData} />}</div></Element >}
            {skillData !== undefined && <IndividualSkillsRelevanceSection skill={skillData.skill} skillName={skillData.skill} skillRelevanceText={skillData.skillRelevanceText} skillRelevanceImagePath={skillData.skillRelevanceImagePath} skillFunFactImageUrl={skillData.skillFunFactImageUrl} skillFunFact={skillData.skillFunFact} />}
            {skillData !== undefined && <IndividualSkillsPageList skill={skillData.skill} skillInterest={skillData.skillInterest} skillRequired={skillData.skillRequired} skillUse={skillData.skillUse} skillHighlightsHeading={keyHighlightsHeadingData.bannerImage} />}
        </>
    )
}