import { useState, useEffect } from 'react';
import { Collapse } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import { HiOutlinePlusSm, HiOutlineMinusSm } from "react-icons/hi";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { StudentPendingEnrolmentWidgetCards } from '../cards/StudentPendingEnrolmentWidgetCards';
import './widget.css';

export const StudentOfflinePaymentRequestsWidget = () => {
    const [open, setOpen] = useState(true);
    const [learningActivityDetails, setLearningActivityDetails] = useState([]);

    useEffect(() => {
        async function fetchOfflinePaymentRequest() {
            const url = AppConstants.getLoggedInStudentOfflinePaymentRequestDetailsAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response && response?.length > 0) {
                setLearningActivityDetails(response);
            } else {
                setLearningActivityDetails([]);
            }
        }
        fetchOfflinePaymentRequest();
    }, []);

    return (
        <>
            <div className="quickLinkSection">
                <div className="widgetSection">
                    <div className="headingSection">
                        <h1 className="widgetHeading">Pending Enrolments</h1>
                    </div>
                    <div className="iconSection">
                        {!open && <HiOutlinePlusSm onClick={() => setOpen(!open)} className="expandMinimizeIcon" />}
                        {open && <HiOutlineMinusSm onClick={() => setOpen(!open)} className="expandMinimizeIcon" />}
                    </div>
                </div>
                <Collapse in={open}>
                    <div id="studentTodaysActivitiesWidget">
                        <div className="collapseDiv">
                            {learningActivityDetails && learningActivityDetails?.length !== 0 && <StudentPendingEnrolmentWidgetCards cardDetails={learningActivityDetails} />}
                            {learningActivityDetails?.length === 0 && <div className="noActivityAvailableSection">No pending enrolment requests at the moment.</div>}
                        </div>
                    </div>
                </Collapse>
            </div>
        </>
    )
}