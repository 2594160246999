import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import styles from "./StudentPerformanceReport.module.css";
import Fullpage, { FullPageSections, FullpageSection, FullpageNavigation } from "@ap.cx/react-fullpage";
import { StudentRankSection } from "./StudentRankSection";
import { StudentTopScoreSection } from './StudentTopScoreSection';
import { StudentWeakAspectsSection } from './StudentWeakAspectsSection';
import { StudentCompetitionSnapshotSection } from './StudentCompetitionSnapshotSection';
import { StudentRaiseNudgeSection } from './StudentRaiseNudgeSection';
import { AppConstants } from '../../constants/AppConstants';
import { StudentLearningActivity } from './StudentLearningActivity';
import { AdvertismentSectionOne } from './AdvertismentSectionOne';
import { DataService } from '../../services/DataService';
import { DateTimeUtil } from '../../utils/DateTimeUtil';
import Auth from '../../utils/Auth';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';

export const StudentPerformanceReport = () => {
    const { competitionUniqueId, studentUniqueId } = useParams();
    const [performancePageData, setPerformancePageData] = useState({});
    const [performancePageLoader, setPerformancePageLoader] = useState(AppConstants.trueText);
    const navigate = useNavigate();
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const qrenciaGlobeImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.performanceAnalysisPageS3Path}/${AppConstants.qrenciaGlobeImage}`;
    const bgAbstractBnW = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.performanceAnalysisPageS3Path}/${AppConstants.bgAbstractBnwImage}`;
    const handWithMagnifyingGlassImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.performanceAnalysisPageS3Path}/${AppConstants.handWithMagnifyingGlassImage}`;
    const handWithRocketImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.performanceAnalysisPageS3Path}/${AppConstants.handWithRocketImage}`;
    const zeroConstant = 0;
    const preferredTimezone = DateTimeUtil.getPreferredTimeZoneForUser();


    useEffect(() => {
        //fetch microsite details
        async function getPerformancePageData() {
            setPerformancePageLoader(AppConstants.trueText);
            const requestHeaders = {
                preferredTimezone
            }
            let response;
            const url = `${AppConstants.studentPerformancePageAPI}/${competitionUniqueId}/${studentUniqueId}`;

            if (Auth.isLogin()) {
                response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString,
                );
            } else {
                response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString,
                    requestHeaders
                );
            }

            if (response !== undefined) {
                setPerformancePageData(response);
                setPerformancePageLoader(AppConstants.falseText);
            } else {
                setPerformancePageData([]);
                setPerformancePageLoader(AppConstants.falseText);
                navigate(AppConstants.asterisk);
            }
        };
        getPerformancePageData();
    }, [])

    return (
        <>
            {!performancePageLoader && <Fullpage>
                <FullpageNavigation
                    itemStyle={{
                        backgroundColor: "#224b7f",
                        border: "2px solid white"
                    }}
                    style={{
                        paddingRight: "6px"
                    }} />
                <FullPageSections>
                    {performancePageData.competitionWithStudentDetails &&
                        <FullpageSection>
                            <StudentRankSection competitionDetails={performancePageData.competitionWithStudentDetails} />
                        </FullpageSection>}
                    {performancePageData.strongCriteria && performancePageData.strongCriteria?.length !== zeroConstant && <FullpageSection>
                        <StudentTopScoreSection topScoringCreteria={performancePageData.strongCriteria} />
                    </FullpageSection>}
                    {performancePageData.weakAspects && performancePageData.weakAspects?.length !== zeroConstant &&
                        <FullpageSection>
                            <div className={styles.studentWeakAspectCover}>
                                <div className={styles.studentWeakAspectContainer}>
                                    <img className={styles.bgImageLeft} src={bgAbstractBnW} alt="Abstract Background Shapes" />
                                    <img className={styles.bgImageRight} src={bgAbstractBnW} alt="Abstract Background Shapes" />
                                    <div className={styles.contentHeadingContainer}>
                                        <h1 className={styles.contentHeading}><strong>{AppConstants.performanceReportWeakAspectsSectionText.coverHeading}</strong></h1>
                                        <p className={styles.contentSubHeading}>{AppConstants.performanceReportWeakAspectsSectionText.coverSubHeading}</p>
                                    </div>
                                    <img className={styles.magnifyingHandImage} src={handWithMagnifyingGlassImage} alt="Hand With Magnifying Glass Illustration" />
                                </div>
                            </div>
                        </FullpageSection>}
                    {performancePageData.weakAspects && performancePageData.weakAspects?.length !== zeroConstant &&
                        <FullpageSection>
                            <StudentWeakAspectsSection weakAspects={performancePageData.weakAspects} competitionDetails={performancePageData.competitionWithStudentDetails} />
                        </FullpageSection>}
                    {performancePageData.competitionSnapShot &&
                        <FullpageSection>
                            <StudentCompetitionSnapshotSection competitionSnapshot={performancePageData.competitionSnapShot} />
                        </FullpageSection>}
                    {performancePageData.weakCriteria && performancePageData.weakCriteria?.length !== zeroConstant &&
                        <FullpageSection>
                            <div className={styles.studentRaiseNudgeCover}>
                                <div className={styles.studentWeakAspectContainer}>
                                    <img className={styles.bgImageLeft} src={bgAbstractBnW} alt="Abstract Background Shapes" />
                                    <img className={styles.bgImageRight} src={bgAbstractBnW} alt="Abstract Background Shapes" />
                                    <div className={styles.contentHeadingContainer}>
                                        <h1 className={styles.contentHeading}><strong>{AppConstants.performanceReportRaiseNudgeSectionText.wantToAceText}</strong></h1>
                                        <p className={styles.contentSubHeading}>{AppConstants.performanceReportRaiseNudgeSectionText.weHaveSomeIdeaText}</p>
                                    </div>
                                    <img className={styles.magnifyingHandImage} src={handWithRocketImage} alt="Hand With Rocket Illustration" />
                                </div>
                            </div>
                        </FullpageSection>}
                    {performancePageData.weakCriteria && performancePageData.weakCriteria?.length !== zeroConstant &&
                        <FullpageSection>
                            <StudentRaiseNudgeSection studentWeakCreteria={performancePageData.weakCriteria} category={AppConstants.learningActivitiesTypes.nudge} />
                        </FullpageSection>}
                    {performancePageData.learningActivities && performancePageData.learningActivities?.length !== zeroConstant &&
                        <FullpageSection>
                            <StudentLearningActivity learningActivityDetails={performancePageData.learningActivities} competitionDetails={performancePageData.competitionWithStudentDetails} />
                        </FullpageSection>}
                    {/* {performancePageData.rewardPoints &&
                        <FullpageSection>
                            <StudentRewardSection studentRewardsDetails={performancePageData.rewardPoints} competitionDetails={performancePageData.competitionWithStudentDetails} />
                        </FullpageSection>} */}
                    {performancePageData.advertisement && performancePageData.advertisement?.length !== zeroConstant &&
                        performancePageData.advertisement.map((adData, index) => (
                            <FullpageSection>
                                <AdvertismentSectionOne key={index} adDetails={adData} />
                            </FullpageSection>))}
                </FullPageSections>
            </Fullpage>}
            {performancePageLoader &&
                <div className={styles.preLoaderContainer}>
                    <div className={styles.preLoaderDiv}>
                        <div className={styles.loader}></div>
                        <img className={styles.qrenciaGlobe} src={qrenciaGlobeImage} alt="Qrencia globe" />
                        <p className={styles.loaderText}>{AppConstants.performancePageLoaderText}</p>
                    </div>
                </div>}
        </>
    );
}

