import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { HiOutlineUserCircle } from 'react-icons/hi';
import { AiOutlineCalendar, AiOutlineDownload, AiOutlineEnvironment, AiOutlineMail, AiOutlineMobile, AiOutlineUser } from 'react-icons/ai';
import { BiLoaderAlt, BiTimeFive } from 'react-icons/bi';
import { MdOutlineSend } from 'react-icons/md';
import { FaRegMoneyBillAlt, FaPaperPlane } from 'react-icons/fa';
import { BsBuilding } from 'react-icons/bs';
import { IoMdClose, IoMdStopwatch } from 'react-icons/io';
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { AppConstants } from '../../constants/AppConstants';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { DataService } from '../../services/DataService';
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import Auth from "../../utils/Auth";
import classes from "../individualCoursePage/IndividualCoursePageLayout.module.css"
import { CourseReviewCards } from "../cards/courseReviewCards/CourseReviewCards";
import { FaqSectionCards } from "../cards/faqSectionCards/FaqSectionCards";
import { EnrolmentModal } from "../modal/EnrolmentModal";
import parse from 'html-react-parser';
import { PaymentsUtil } from "../../utils/PaymentsUtil";
import { LoginModal } from "../modal/LoginModal";
import { DateTime } from "luxon";
import { IndividualWorkshopPageBanner } from "./individualWorkshopPageBanner/IndividualWorkshopPageBanner";
import Moment from "react-moment";
import { ShareLinkToSocialMediaUtil } from "../../utils/ShareLinkToSocialMediaUtil";
import { PostStartEnrolmentModal } from "../modal/PostStartEnrolmentModal";
import { AskQuestionModal } from "../modal/AskQuestionModal";
import { PayOfflineConfirmationModal } from "../modal/PayOfflineConfirmationModal";
import { useEnrolment } from "../../hooks/useEnrolment";
import { useInAppBrowserStatus } from "../../hooks/useInAppBroswerStatus";
import useWarnOnLeave from "../../hooks/useWarnOnLeave";

export const IndividualWorkshopPageLayout = () => {
    const navigate = useNavigate();
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [isStudentEnroled, setIsStudentEnroled] = useState(AppConstants.falseText);
    const [userDetails, setUserDetails] = useState({});
    const { categorySlug, skillSlug, workshopSlug } = useParams();
    const [workshopDetails, setWorkshopDetails] = useState({});
    const [workshopPageLoadingSpinner, setWorkshopPageLoadingSpinner] = useState(AppConstants.trueText);
    const [isVideoPlaying, setIsVideoPlaying] = useState(AppConstants.falseText);
    const [showPlayIcon, setShowPlayIcon] = useState(AppConstants.trueText);
    const [reviewComments, setReviewComments] = useState([]);
    const [reviewSpinner, setReviewSpinner] = useState(AppConstants.falseText);
    const [comment, setComment] = useState(AppConstants.emptyString);
    const [showEnrolmentModal, setShowEnrolmentModal] = useState(AppConstants.falseText);
    const [fetchReviewCommentsFlag, setFetchReviewCommentsFlag] = useState(0);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const [reviewCommentError, setReviewCommentError] = useState(null);
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);
    const [searchParams, setSearchParams] = useSearchParams();
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));
    const [payloadData, setPayloadData] = useState({});
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [showQuestionModal, setShowQuestionModal] = useState(AppConstants.falseText);
    const [showLoginModal, setShowLoginModal] = useState(AppConstants.falseText);
    const [profanityDetails, setProfanityDetails] = useState({});
    const [signupTimerFlag, setSignupTimerFlag] = useState(AppConstants.falseText);
    const [signupTimerDuration, setSignupTimerDuration] = useState(AppConstants.emptyString);
    const [isEnrolButtonClickedOnce, setIsEnrolledButtonClickedOnce] = useState(AppConstants.falseText);
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const [isCodRequested, setIsCodRequested] = useState(AppConstants.falseText);
    const isInAppBrowser = useInAppBrowserStatus();
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    useWarnOnLeave(shouldWarn);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.workshopDefaultThumbnailImage}`;
    const linkToShare = `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.workshopsText}${AppConstants.forwardSlash}${categorySlug}${AppConstants.forwardSlash}${skillSlug}${AppConstants.forwardSlash}${workshopSlug}`;
    const videoPlayIconUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.videoPlayIconImagePath}`;
    const [canStudentPostReview, setCanStudentPostReview] = useState(AppConstants.falseText);

    useEffect(() => {
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setSignupTimerDuration(parseInt(response.find(item => item.keyName === AppConstants.signupEnrolmentModalDurationKey).keyValue));
            }
        };
        fetchConfigurableKeys();

        const fetchWorkshopDetails = async () => {
            setWorkshopPageLoadingSpinner(AppConstants.trueText);
            const url = `${AppConstants.getIndividualWorkshopPageDetailsAPI}${workshopSlug}`;
            const requestHeaders = {
                preferredTimezone: timeZone
            }
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString,
                requestHeaders
            );

            if (response !== undefined) {
                setWorkshopDetails(response);
                setWorkshopPageLoadingSpinner(AppConstants.falseText);
            } else {
                setWorkshopDetails([]);
                setWorkshopPageLoadingSpinner(AppConstants.falseText);
                navigate(AppConstants.asterisk);
            }
        };

        if (Auth.isLogin()) {
            setUserDetails(Auth.getLoggedInUserDetails());
        } else {
            setUserDetails({});
        }

        const fetchProfanityApiDetails = async () => {
            const url = AppConstants.getProfanityApiDetailsAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setProfanityDetails(response);
            }
        }
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            fetchProfanityApiDetails();
        }
        fetchWorkshopDetails();
    }, []);

    useEffect(() => {
        const checkStudentEnrollmentStatus = async () => {
            const url = `${AppConstants.checkStudentWorkshopEnrolmentStatusAPI}${workshopDetails.uniqueId}`;
            const response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyString);

            if (response === 1) {
                setIsStudentEnroled(AppConstants.trueText);
            } else if (response === 2) {
                setIsCodRequested(AppConstants.trueText);
            } else {
                setIsStudentEnroled(AppConstants.falseText);
            }
        }
        if (workshopDetails?.uniqueId) {
            checkStudentEnrollmentStatus();
        }
    }, [workshopDetails])

    useEffect(() => {
        const fetchWorkshopReviewComments = async () => {
            const url = `${AppConstants.getWorkshopReviewCommentsAPI}${workshopDetails.uniqueId}`;
            const requestHeaders = {
                preferredTimezone: timeZone
            }
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString,
                requestHeaders
            );

            if (response !== undefined) {
                setReviewComments(response);
            } else {
                setReviewComments([]);
            }
        }

        const checkStudentReviewEligiblity = async () => {
            const learningActivityType = AppConstants.tabWorkshopValueText;
            const url = `${AppConstants.checkStudentEligibilityToPostCourseReview}${workshopDetails.uniqueId}&learningActivityType=${learningActivityType}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response === 0) {
                setCanStudentPostReview(AppConstants.trueText);
            } else if (response === -25) {
                setCanStudentPostReview(AppConstants.trueText);
            } else {
                setCanStudentPostReview(AppConstants.falseText);
            }
        }

        if (workshopDetails?.uniqueId) {
            fetchWorkshopReviewComments();
            if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
                checkStudentReviewEligiblity();
            }
        }

    }, [fetchReviewCommentsFlag, workshopDetails]);

    useEffect(() => {
        const openSignupModalAfterTimer = (isEnrolButtonClickedOnce) => {
            if (!isEnrolButtonClickedOnce) {
                setShowEnrolmentModal(AppConstants.trueText);
                setSignupTimerFlag(AppConstants.trueText);
            }
        }
        if (!Auth.isLogin() && signupTimerDuration && workshopDetails?.uniqueId && DateTime.fromISO(workshopDetails.workshopUtcRegistrationClosesOn?.replace(' ', 'T') + 'Z').setZone(timeZone) > DateTime.local().setZone(timeZone)) {
            let timer = setTimeout(openSignupModalAfterTimer, signupTimerDuration * 1000, isEnrolButtonClickedOnce);
            return () => { clearTimeout(timer) };
        }

    }, [workshopDetails, signupTimerDuration, isEnrolButtonClickedOnce]);

    const playVideo = () => {
        setIsVideoPlaying(AppConstants.trueText);
        setShowPlayIcon(AppConstants.falseText);
    };

    const handleClose = () => {
        setIsVideoPlaying(AppConstants.falseText);
        setShowPlayIcon(AppConstants.trueText);
    };

    const checkReviewValidity = async () => {
        try {
            if (comment?.trim() === AppConstants.emptyString) {
                setReviewSpinner(false);
                setReviewCommentError(ErrorMessages.coursePageInvalidReviewComment);
                setTimeout(() => {
                    setReviewCommentError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return AppConstants.falseText;
            };

            setReviewSpinner(AppConstants.trueText);
            const url = `${profanityDetails.endpoint}?text=${comment.trim()}`;
            const options = {
                method: 'GET',
                headers: {
                    'X-RapidAPI-Key': profanityDetails.key,
                    'X-RapidAPI-Host': profanityDetails.host
                }
            };
            const response = await fetch(url, options);
            const data = await response.json();

            if (data.has_profanity) {
                setReviewCommentError(ErrorMessages.reviewProfanityError);
                setTimeout(() => {
                    setReviewCommentError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return AppConstants.falseText;
            } else {
                return AppConstants.trueText;
            }
        } catch (error) {
            return AppConstants.falseText;
        }
    }

    const postReviewComments = async () => {
        const isValid = await checkReviewValidity();
        if (isValid) {
            setReviewCommentError(null);
            try {
                setReviewSpinner(AppConstants.trueText);
                const url = AppConstants.postWorkshopReviewCommentAPI;
                let data = {
                    reviewComment: comment,
                    workshopUniqueId: workshopDetails.uniqueId
                }
                const response = await AuthorizedDataService.postRequest(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response !== undefined) {
                    setComment(AppConstants.emptyString);
                    setReviewSpinner(AppConstants.falseText);
                    setFetchReviewCommentsFlag(fetchReviewCommentsFlag + 1);

                } else {
                    setReviewSpinner(AppConstants.falseText);
                }
            } catch (error) {
                setReviewSpinner(AppConstants.falseText);
            }
        } else {
            setReviewSpinner(AppConstants.falseText);
        }
    };


    useEffect(() => {
        const verifyPayment = async () => {
            try {
                if (paymentParam && workshopDetails.uniqueId) {
                    const data = JSON.parse(window.atob(paymentParam));
                    setPaymentStatus(AppConstants.paymentStatus.pending);
                    if (data.learningActivityType === AppConstants.learningActivitiesTypes.workshop) {
                        data.learningActivityName = workshopDetails.workshopTitle;
                        setPayloadData(data);
                        const timer = setTimeout(() => {
                            PaymentsUtil.verifyPayment(data).then((response) => {
                                if (response.verificationStatus === AppConstants.paymentStatus.verified) {
                                    setPaymentStatus(AppConstants.paymentStatus.verified);
                                    setTransactionId(response.transactionId);
                                    window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                                    handleEnrolment(workshopDetails.workshopId, workshopDetails.workshopTitle, workshopDetails.uniqueId, AppConstants.paymentStatus.verified);
                                } else {
                                    setModalTypeError(AppConstants.trueText);
                                    setModalMessage(response.message);
                                    setTransactionId(response.transactionId);
                                    window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                                    setPaymentStatus(AppConstants.emptyString);
                                    setShowEnrolmentModal(AppConstants.trueText);
                                }
                            }).catch(error => {
                                setModalTypeError(AppConstants.trueText);
                                setModalMessage(ErrorMessages.verifyPaymentFailedError);
                                setPaymentStatus(AppConstants.emptyString);
                                window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                                setShowEnrolmentModal(AppConstants.trueText);
                            });
                        }, AppConstants.paymentVerificationTimeout);

                        return () => clearTimeout(timer);
                    }
                }
            } catch (error) {
                setPaymentStatus(AppConstants.emptyString);
            }
        };
        verifyPayment();
    }, [paymentParam, workshopDetails]);

    const confirmBeforeEnrolment = () => {
        setIsEnrolledButtonClickedOnce(AppConstants.trueText);
        setConfirmationPopupActivityDetails({ id: workshopDetails.workshopId, title: workshopDetails.workshopTitle, uniqueId: workshopDetails.uniqueId, payOfflineFlag: workshopDetails.payOfflineFlag, creatorName: workshopDetails.userRole === AppConstants.userRoleExpert ? workshopDetails.organizerName : workshopDetails.agencyName, creatorRole: workshopDetails.userRole, type: AppConstants.learningActivitiesTypes.workshop })
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            const startDateTime = DateTime.fromISO(workshopDetails.utcStartDate?.replace(' ', 'T') + 'Z').setZone(timeZone);
            const currentDateTime = DateTime.local().setZone(timeZone);
            if (currentDateTime > startDateTime) {
                setShowConfirmationModal(AppConstants.trueText);
            } else {
                if (workshopDetails.payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    handleEnrolment(workshopDetails.workshopId, workshopDetails.workshopTitle, workshopDetails.uniqueId);
                }
            }
        } else {
            handleEnrolment(workshopDetails.workshopId, workshopDetails.workshopTitle, workshopDetails.uniqueId);
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                handleEnrolment(workshopDetails.workshopId, workshopDetails.workshopTitle, workshopDetails.uniqueId);
            }
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            handleEnrolment(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const handleAskQuestion = () => {
        if (Auth.isLogin()) {
            if (Auth.getLoggedInUserDetails().userRole !== AppConstants.userRoleStudent) {
                setModalTypeError(true);
                setModalMessage(ErrorMessages.onlyStudentRaiseCourseQueryError)
                setShowEnrolmentModal(true);
            } else {
                setShowQuestionModal(true);
            }
        } else {
            setShowLoginModal(AppConstants.trueText);
        }
    };

    const closeQuestionModal = () => {
        setShowQuestionModal(false);
    };

    const hideLoginModal = () => {
        if (Auth.isLogin()) {
            window.location.reload();
        }
        setShowLoginModal(AppConstants.falseText);
    }

    //enroll to workshop
    const handleEnrolment = async (id, title, uniqueId, status) => {
        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setModalTypeError(AppConstants.trueText);
            setModalMessage(ErrorMessages.onlyStudentEnrolWorkshopError);
            setPaymentStatus(AppConstants.emptyString);
            setShowEnrolmentModal(AppConstants.trueText);
            return;
        }

        const cost = PaymentsUtil.calculateCost({ cost: workshopDetails.workshopCost, discount: 0 });
        let response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: title, learningActivityId: id, learningActivityType: AppConstants.learningActivitiesTypes.workshop }, status);
        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setModalMessage(response.message);
        setShowEnrolmentModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };


    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowEnrolmentModal(AppConstants.falseText);
        const rzp1 = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setTransactionId(response.transactionId);
                setShowEnrolmentModal(AppConstants.trueText);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setShouldWarn(AppConstants.trueText);
                setTimeout(async () => {
                    handleEnrolment(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityName, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }
        });

        rzp1.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzp1.open();
    };
    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        handleEnrolment(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    };

    const handleEnrolmentModalClose = () => {
        setShowEnrolmentModal(AppConstants.falseText);
        if (signupTimerFlag) {
            setSignupTimerFlag(AppConstants.falseText);
        } else {
            if (paymentParam) {
                window.location.replace(window.location.href.split(AppConstants.questionMark)[0]);
            } else if (transactionId) {
                window.location.reload();
            } else {
                if (userLoggedInAndEnroled) {
                    window.location.reload();
                } else {
                    if (modalTypeError) {
                        setModalTypeError(AppConstants.falseText);
                        setModalMessage(AppConstants.emptyString)
                        setShowEnrolmentModal(AppConstants.falseText);
                    } else {
                        window.location.reload();
                    }
                }
            }
        }
    };

    async function downloadPreRead() {
        const fileUrl = workshopDetails.descriptionDocumentAbsolutePath;
        FileOperationsUtil.downloadDocument(fileUrl, workshopDetails.workshopTitle, AppConstants.guidelinesFile);
    };


    return (
        <div>
            {!workshopPageLoadingSpinner && workshopDetails?.id && <IndividualWorkshopPageBanner workshopDetails={workshopDetails} />}
            {!workshopPageLoadingSpinner && workshopDetails?.id && <div className={classes.shareIconPannelContainer}>
                <ShareLinkToSocialMediaUtil className={classes} linkToShare={linkToShare} />
            </div>}
            {!workshopPageLoadingSpinner && workshopDetails?.id && <div className={classes.courseLayout}>
                <div className={classes.thumbnailContainer}>
                    <div className={classes.leftCourseDetails}>
                        <img className={classes.courseThumbnailImage} src={workshopDetails.thumbnailImageAbsolutePath ? workshopDetails.thumbnailImageAbsolutePath : defaultThumbnail} alt="Thumbnail"></img>
                        <div className={classes.leftThumbnailDetails}>
                            <div className={classes.titleContainer}>
                                <p className={classes.courseTitle}>{workshopDetails.workshopTitle}</p>
                            </div>
                            <div className={classes.workshopInfo}>
                                {<div className={classes.costInfo}>
                                    <BiTimeFive />&nbsp;
                                    <div className={classes.costText}>{workshopDetails.workshopDuration} {workshopDetails.workshopDuration !== 0.5 && workshopDetails.workshopDuration !== 1 ? "Hours" : "Hour"}</div>
                                </div>}
                                {workshopDetails.workshopCost !== AppConstants.nullText && workshopDetails.workshopCost !== 0 && <div className={classes.costInfo}>
                                    <FaRegMoneyBillAlt />&nbsp;
                                    {<div className={classes.costText}>{PaymentsUtil.calculateCost({ cost: workshopDetails.workshopCost, discount: 0 })}</div>}
                                </div>}
                                {workshopDetails.workshopCost === 0 && <div className={classes.costInfo}>
                                    <FaRegMoneyBillAlt />&nbsp;
                                    {<div className={classes.costText}>{AppConstants.freeText}</div>}
                                </div>}
                            </div>
                            <div className={classes.workshopDate}><AiOutlineCalendar />
                                <div className={classes.costText}> <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{workshopDetails.utcStartDate.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{workshopDetails.utcStartDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> - <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{workshopDetails.utcEndDate.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{workshopDetails.utcEndDate.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${workshopDetails.perferredTimeZone})`}</div>
                            </div>
                            {workshopDetails.workshopUtcRegistrationClosesOn && <div className={classes.workshopDate}><IoMdStopwatch />
                                <div className={classes.costText}>Registration Deadline:&nbsp;<Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{workshopDetails.workshopUtcRegistrationClosesOn?.replace(' ', 'T') + 'Z'}</Moment>&nbsp;|&nbsp;<Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{workshopDetails.workshopUtcRegistrationClosesOn?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${workshopDetails.preferredTimezone})`}</div></div>}
                            {workshopDetails.userRole === AppConstants.userRoleExpert && <p onClick={() => { window.open(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.expert}${AppConstants.forwardSlash}${workshopDetails.userSlug}`) }} className={`${classes.workshopDate} ${classes.workshopExpertName} ${classes.workshopSkillIcon}`}><AiOutlineUser />Expert: {workshopDetails.organizerName} <p className={classes.skillName}><FaPaperPlane className={classes.skillIcon} /> {workshopDetails.skillName}</p></p>}
                            {workshopDetails.userRole === AppConstants.userRoleAgency && <p onClick={() => { window.open(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.agency}${AppConstants.forwardSlash}${workshopDetails.userSlug}`) }} className={`${classes.workshopDate} ${classes.workshopExpertName}  ${classes.workshopSkillIcon}`}><BsBuilding />Agency: {workshopDetails.agencyName} <p className={classes.skillName}><FaPaperPlane className={classes.skillIcon} /> {workshopDetails.skillName}</p></p>}
                            {workshopDetails.descriptionDocumentAbsolutePath !== AppConstants.nullText && <div className={classes.displayInline}><AiOutlineDownload /> {isInAppBrowser ? <a href={workshopDetails.descriptionDocumentAbsolutePath} rel="noreferrer" target="_blank" className={` ${classes.donwloadPreRead} ${classes.workshopExpertName}`}>Download Workshop Brochure</a> : <p onClick={downloadPreRead} className={` ${classes.donwloadPreRead} ${classes.workshopExpertName}`}>Download Workshop Brochure</p>}</div>}
                            {workshopDetails.workshopModeOfDelivery === AppConstants.workshopTypeOffline && workshopDetails.address !== AppConstants.nullText && <div className={classes.offlineAddress}>
                                <div className={classes.offlineAddressIcon}><AiOutlineEnvironment /></div><p className={classes.onlineText}><a href={workshopDetails.geoLocation} target={AppConstants.openInNewTab}>{`Offline (${workshopDetails?.address})`}</a></p></div>}
                            {workshopDetails.workshopModeOfDelivery === AppConstants.workshopTypeOnline && <div className={classes.displayInline}><AiOutlineEnvironment /> <p className={classes.onlineText}><a className={classes.onlineLink}>{workshopDetails?.workshopModeOfDelivery}</a></p></div>}
                        </div>
                    </div>
                    <div className={classes.rightSection}>
                        {(DateTime.fromISO(workshopDetails.utcEndDate?.replace(' ', 'T') + 'Z').setZone(timeZone)) > DateTime.local().setZone(timeZone) && <button onClick={handleAskQuestion} className={classes.askQuestionButton}>Ask a question</button>}
                        {(DateTime.fromISO(workshopDetails.utcEndDate?.replace(' ', 'T') + 'Z').setZone(timeZone)) > DateTime.local().setZone(timeZone) && <Button onClick={handleAskQuestion} className={classes.askButtonBelow}>Ask a question</Button>}
                        {(!isStudentEnroled && !isCodRequested) && (DateTime.fromISO(workshopDetails.workshopUtcRegistrationClosesOn?.replace(' ', 'T') + 'Z').setZone(timeZone) > DateTime.local().setZone(timeZone)) ? <Button onClick={() => confirmBeforeEnrolment({ id: workshopDetails.workshopId, title: workshopDetails.workshopTitle, uniqueId: workshopDetails.uniqueId, payOfflineFlag: workshopDetails.payOfflineFlag, creatorName: workshopDetails.userRole === AppConstants.userRoleExpert ? workshopDetails.organizerName : workshopDetails.agencyName, creatorRole: workshopDetails.userRole })} className={classes.enrolButton}>Enrol Now</Button> : (workshopDetails.workshopModeOfDelivery === AppConstants.workshopTypeOnline && workshopDetails.workshopStatus === AppConstants.workshopStatus.ongoing && isStudentEnroled && !isCodRequested) ? <Button className={`${classes.enrolButton} ${classes.sessionLinkButton}`} target={AppConstants.openInNewTab} href={workshopDetails.workshopLink}>Workshop Link</Button> : null}
                        {isCodRequested && <Button disabled={true} className={classes.enrolButton}>Requested</Button>}
                    </div>
                </div>
                {(workshopDetails?.spocName || workshopDetails?.spocEmail || workshopDetails?.spocMobileNumber) &&
                    <div className={classes.section}>
                        <div className={`${classes.descriptionHeading} mb-2`}>SPOC Details</div>
                        <div className={classes.spocDetailsContainer}>
                            {workshopDetails?.spocName && <div className={classes.displayInline}><HiOutlineUserCircle stroke-width={1.5} size={18} /> <p className={classes.workshopExpertName}>{workshopDetails?.spocName}</p></div>}
                            {workshopDetails?.spocEmail && <div className={classes.displayInline}><AiOutlineMail /> <p className={classes.workshopExpertName}>{workshopDetails?.spocEmail}</p></div>}
                            {workshopDetails?.spocMobileNumber && <div className={classes.displayInline}><AiOutlineMobile /> <p className={classes.workshopExpertName}>{workshopDetails?.spocMobileNumber}</p></div>}
                        </div>
                    </div>}
                <div className={classes.section}>
                    <div className={classes.descriptionHeading}>Description</div>
                    <div className={`${classes.descriptionBody} quillListIndent ql-editor`}> {parse(workshopDetails.workshopDescription)}</div>
                </div>
                {(workshopDetails.promotionVideoAbsolutePath || reviewComments.length !== 0 || (canStudentPostReview && Auth.isLogin() && userDetails.userRole === AppConstants.userRoleStudent && isStudentEnroled && workshopDetails.workshopStatus !== AppConstants.workshopStatus.upcoming)) &&
                    <div className={classes.videoReviewSection}>
                        {workshopDetails.promotionVideoAbsolutePath !== AppConstants.nullText && <div className={classes.promotionVideoSection}>
                            {isVideoPlaying ? (
                                <div>
                                    <video controls autoPlay className={classes.videoContent}>
                                        <source src={workshopDetails.promotionVideoAbsolutePath} type="video/mp4" />
                                    </video>
                                    <IoMdClose className={classes.closeButton} onClick={handleClose} />
                                </div>
                            ) : <img src={workshopDetails.thumbnailImageAbsolutePath || defaultThumbnail} alt="Play button" className={classes.videoContentThumnail} />}
                            {showPlayIcon && <img src={videoPlayIconUrl} onClick={playVideo} alt="Video Play Button" className={classes.videoIcon} />}
                            <div>

                            </div>
                        </div>}
                        <div className={classes.reviewSection}>
                            {(reviewComments.length !== 0 || (canStudentPostReview && Auth.isLogin() && userDetails.userRole === AppConstants.userRoleStudent && isStudentEnroled && workshopDetails.workshopStatus !== AppConstants.workshopStatus.upcoming)) && <div className={classes.reviewHeading}>Workshop Reviews</div>}
                            {reviewComments.length !== 0 && <div className={classes.reviewCards}>
                                {
                                    reviewComments?.map((review, index) => {
                                        return <div>
                                            <CourseReviewCards review={review} />
                                            {index + 1 < reviewComments.length && <hr />}
                                        </div>
                                    })
                                }
                                {
                                    reviewComments.length === 0 && <div className={classes.noReviewText}><p className="noDataAvailableError">Currently there are no reviews for this workshop</p></div>
                                }
                            </div>}
                            {Auth.isLogin() && userDetails.userRole === AppConstants.userRoleStudent && isStudentEnroled && workshopDetails.workshopStatus !== AppConstants.workshopStatus.upcoming && canStudentPostReview && <div className={classes.reviewInput}>
                                <textarea maxLength={500} className={classes.inputTextBox} value={comment} onChange={(e) => setComment(e.target.value)} placeholder="Enter your comment" />
                                {reviewSpinner ? <BiLoaderAlt className="spinner" /> : <MdOutlineSend onClick={postReviewComments} />}
                            </div>}
                            {Auth.isLogin() && userDetails.userRole === "Student" && isStudentEnroled && reviewCommentError && <p className={classes.reviewErrorMessage}>{reviewCommentError}</p>}
                        </div>
                    </div>}
                {workshopDetails.workShopFaqs.length > 0 && (workshopDetails.workshopStatus !== AppConstants.workshopStatus.past) && <div className={classes.section}>
                    <div className={classes.faqHeading}>Frequently Asked Questions</div>
                    {
                        workshopDetails.workShopFaqs?.map(workshopFaq => <FaqSectionCards faqDetails={workshopFaq} />)
                    }
                </div>}
                {workshopDetails.promotionAdsImageOneAbsolutePath !== AppConstants.nullText && <div className={classes.competitionAdsSection}>
                    <div>
                        <img src={workshopDetails.promotionAdsImageOneAbsolutePath} className={classes.competitionAdImage} alt="Advertisment" />
                    </div>
                </div>}
            </div>}
            {
                workshopPageLoadingSpinner && <div className={classes.errorContainer}>
                    <p className={classes.loaderIcon}><BiLoaderAlt className="spinner" /></p>
                </div>
            }
            {showQuestionModal && <AskQuestionModal learningActivityDetails={{ id: workshopDetails.id, title: workshopDetails.workshopTitle, learningActivityType: AppConstants.learningActivitiesTypes.workshop, organizerEmail: workshopDetails.organizerEmail, organizerName: workshopDetails.organizerName, agencyName: workshopDetails.agencyName, userRole: workshopDetails.userRole }} closeQuestionModal={closeQuestionModal} />}
            {showLoginModal && <LoginModal onShow={() => setShowLoginModal(AppConstants.trueText)} onClose={hideLoginModal} />}
            {(showEnrolmentModal || payloadData.learningActivityType === AppConstants.learningActivitiesTypes.workshop) && <EnrolmentModal signupTimerFlag={signupTimerFlag} onShow={() => setShowEnrolmentModal(AppConstants.trueText)} learningActivityId={workshopDetails.workshopId} enrolInWorkshop={handleEnrolment} learningActivityTitle={workshopDetails.workshopTitle} learningActivityUniqueId={workshopDetails.uniqueId} message={modalMessage} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} modalTypeError={modalTypeError} learningActivityType={AppConstants.learningActivitiesTypes.workshop} onClose={handleEnrolmentModalClose} paymentStatus={paymentStatus} transactionId={transactionId} confirmBeforeEnrolment={confirmBeforeEnrolment} creatorName={workshopDetails.userRole === AppConstants.userRoleExpert ? workshopDetails.organizerName : workshopDetails.agencyName} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </div>
    )
}