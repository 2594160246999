import classes from '../individualCompetition/IndividualCompetitionBanner.module.css';
import { AppConstants } from '../../constants/AppConstants';

export const NudgesPageBanner = () => {
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultWebBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/nudge-web-banner.png`;
    const defaultTabletBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/nudge-tablet-banner.png`;
    const defaultMobileBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/nudge-mobile-banner.png`;


    return (
        <>
            <div className={classes.competitionMobileImgContainer}>
                <img src={defaultMobileBanner} alt="Nudge Banner" className={classes.competitionBannerImage} />
            </div>
            <div className={classes.competitionTabletImgContainer}>
                <img src={defaultTabletBanner} alt="Nudge Banner" className={classes.competitionBannerImage} />
            </div>
            <div className={classes.competitionBannerImgContainer}>
                <img src={defaultWebBanner} alt="Nudge Banner" className={classes.competitionWebBannerImage} />
            </div>
        </>
    );
}