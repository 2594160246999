import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { Row, Col, Card, Button } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import { BiCalendarCheck, BiLoaderAlt, BiWallet } from "react-icons/bi";
import { TbShare3 } from 'react-icons/tb';
import { AiOutlineExclamationCircle, AiOutlineCheckCircle, AiOutlineQuestionCircle } from 'react-icons/ai';
import { AppConstants } from "../../../constants/AppConstants";
import classes from './PublishedUnpublishedCourseCards.module.css';
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { SuccessMessage } from "../../../constants/SuccessMessage";
import 'moment-timezone';
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import '../CourseCards.css';

import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import { DateTime } from "luxon";
import { CgPin } from "react-icons/cg";
import { FaChalkboardTeacher } from "react-icons/fa";
import { MathUtil } from "../../../utils/MathUtil";

export const PublishedUnpublishedCourseCards = (props) => {

    const [courseCards, setCourseCards] = useState([]);
    const [cardCount, setCardCount] = useState(0);
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [editIndex, setEditIndex] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [modalSpinner, setModalSpinner] = useState(false);
    const [modalAlertText, setModalAlertText] = useState("");
    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalError, setModalError] = useState(false);
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [linkToShare, setLinkToShare] = useState("");
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [publishAlert, setPublishAlert] = useState(AppConstants.emptyString);
    const learningActivitiesCardsCount = AppConstants.cardCounts;
    const [participantsImageList, setParticipantImageList] = useState([]);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultCourseThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultThumbnailImage}`;

    useEffect(() => {
        const populateParticipantImageList = () => {
            let imageArray = [];
            for (let i = 0; i < props.courseData.length; i++) {
                let partcipantsData = props.courseData[i].participantsDetails;
                let imageList = [];
                partcipantsData.map(data => {
                    if (data.studentImage) imageList.push(data.studentImage)
                });
                imageArray.push(imageList);
            }
            setParticipantImageList(imageArray);
        }
        populateParticipantImageList();
    }, [props.courseData]);

    useEffect(() => {
        setCourseCards(props.courseData);
    }, [props.courseData]);


    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(learningActivitiesCardsCount.learningActivityDesktopCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityDesktopCount);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(learningActivitiesCardsCount.learningActivityTabCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityTabCount);
        }
        if (viewportWidth <= 575) {
            setCardCount(learningActivitiesCardsCount.learningActivityMobileCount);
            setViewMoreCardsCount(learningActivitiesCardsCount.learningActivityMobileCount);
        }
    };

    //call handleResize() on screen width change
    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    // Share on social media
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.coursesText}${AppConstants.forwardSlash}${shareData.skillCategorySlug}${AppConstants.forwardSlash}${shareData.skillSlug}${AppConstants.forwardSlash}${shareData.courseSlug}`);
    }

    //show/hide modal
    const handleModalClose = () => {
        setShowModal(false);
        setModalSpinner(false);
        setModalSuccess(false);
        setModalError(false);
        setPublishAlert(AppConstants.emptyString);
    }
    const handleModalShow = () => {
        setShowModal(true);
        setModalSpinner(false);
        setModalSuccess(false);
        setModalError(false);
    }

    function setModalDataFunc(data) {
        setModalData(data);
        if (isStartDatePassed(data) && props.courseType === AppConstants.unpublishedCourseText) {
            setModalError(true);
            setModalAlertText("Start date of the course has passed. Please change the start date and try again.");
        } else {
            setModalSpinner(false);
            setModalSuccess(false);
            setModalError(false);
            if (props.courseType === AppConstants.unpublishedCourseText) {
                setModalAlertText(`${AppConstants.publishConfirmation}${AppConstants.space}${AppConstants.hyphen}${AppConstants.space}${data.courseTitle}${" ?"}`);
            } else {
                setModalAlertText(`${AppConstants.unpublishConfirmation}${AppConstants.space}${AppConstants.hyphen}${AppConstants.space}${data.courseTitle}${" ?"}`);
            }
        }
    }

    const isStartDatePassed = (course) => {
        if (course.courseType === AppConstants.flexibleCourseType) {
            // returning false in case of flexible course as start date/end date is not applicable for flexi courses
            return AppConstants.falseText;
        }
        const specifiedTime = DateTime.fromFormat(course.startTime, 'hh:mm a, dd LLL yyyy');
        const currentDateTimeInSpecificZone = DateTime.now().setZone(timeZone)
        if (specifiedTime > currentDateTimeInSpecificZone) {
            return false;
        } else if (specifiedTime < currentDateTimeInSpecificZone) {
            return true;
        } else {
            return false;
        }
    }

    //update selected course status
    async function updateCourseStatus(courseData) {
        setModalSpinner(true);
        let status = props.courseType === AppConstants.publishedCourseText ? AppConstants.coursePublishedStatus.unpublished : AppConstants.coursePublishedStatus.published;
        const url = `${AppConstants.updateCourseStatusAPI}${courseData.uniqueId}&status=${encodeURIComponent(status)}&locale=${encodeURIComponent(timeZone)}`;
        const response = await AuthorizedDataService.put(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined && response.ok) {
            if (props.courseType === AppConstants.unpublishedCourseText) {
                setModalAlertText(`${courseData.courseTitle}${AppConstants.space}${SuccessMessage.publishedSuccessfullyText}`);
            } else {
                setModalAlertText(`${courseData.courseTitle}${AppConstants.space}${SuccessMessage.unpublishedSuccessfullyText}`);
            }
            setModalSpinner(false);
            setModalSuccess(true);
            setModalError(false);
            props.refreshCoursesData();
        } else {
            if (props.courseType === AppConstants.unpublishedCourseText) {
                setModalAlertText(ErrorMessages.failedToPublishError);
            } else {
                setModalAlertText(ErrorMessages.failedToUnpublishError);
            }
            setModalSpinner(false);
            setModalSuccess(false);
            setModalError(true);
        }
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    const navigateToGeolocation = (mode, location) => {
        if (mode?.toUpperCase() === "OFFLINE") {
            window.open(location, AppConstants.openInNewTab);
        }
    };

    const getWeekTimings = (value) => {
        let options = AppConstants.weekDaysOptions;
        let arr = value.split(AppConstants.comma);
        if (arr.length === 7) {
            return AppConstants.allDaysOfWeekText;
        } else {
            let dayString = AppConstants.emptyString;
            options.forEach(opt => {
                if (value.includes(opt.name)) dayString += `${opt.key}, `
            })
            return `On ${dayString.replace(/,\s*$/, AppConstants.emptyString)}`
        }
    };

    return (
        <>
            <Row xs={1} sm={2} md={2} lg={3} xl={3} className="cardSection">
                {courseCards !== undefined && courseCards.slice(0, cardCount).map((course, index) => (
                    <Col key={course.uniqueId}>
                        <Card className="cardDiv" key={index} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.coursesText}${AppConstants.forwardSlash}${course.skillCategorySlug}${AppConstants.forwardSlash}${course.skillSlug}${AppConstants.forwardSlash}${course.courseSlug}`)}>
                            <Card.Img variant="top" className="cardImage" src={course.courseThumbnailImageAbsolutePath ? course.courseThumbnailImageAbsolutePath : defaultCourseThumbnail} />
                            <div className={classes.uniqueIdText}>{course.courseId}</div>
                            <TbShare3 className={classes.shareIcon} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(course) }} />
                            <Card.Body className="cardBody">
                                <div className="subTitleContainer">
                                    <div data-tooltip-id={`${course.uniqueId}-offline`} onClick={(e) => { stopPropagation(e); navigateToGeolocation(course.modeOfDelivery.toUpperCase(), course.geoLocation) }} className={`cardSubTitle courseDiffColor`}>{course.modeOfDelivery.toUpperCase()}</div>
                                    <div className="cardsCostInfoText"><BiWallet /> {PaymentsUtil.calculateCost({ cost: course.cost, discount: course.discount })}</div>
                                </div>
                                {course.modeOfDelivery.toUpperCase() === "OFFLINE" && <Tooltip className="tooltipMessage" id={`${course.uniqueId}-offline`}><span className="tooltipMessage">{course.address}</span></Tooltip>}
                                <Card.Text className="cardTitle titleFixedHeight">
                                    <Link className="cardLink" data-tooltip-id={`${course.uniqueId}-title`}>{course.courseTitle}</Link>
                                    <Tooltip className="courseTitleTooltip" id={`${course.uniqueId}-title`}><span>{course.courseTitle}</span></Tooltip>
                                </Card.Text>
                                {editIndex === index && showSocialMediaPannel && <div onClick={stopPropagation} className={classes.shareIconPannelContainer}>
                                    <ShareLinkToSocialMediaUtil className={classes} linkToShare={linkToShare} useIcons={AppConstants.trueText} />
                                </div>}
                                {course.courseType === AppConstants.structuredCourseType && <div className="cardTimeAndDate">
                                    <div><BiCalendarCheck className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText"><span className="competitionDateTimeFormat">Starts {DateTimeUtil.getDateTimeForCourses(course.startTime)} {timeZone === AppConstants.utcTimezone && `(${course.preferredTimezone})`}</span>
                                    </div>
                                </div>}

                                {course.courseType === AppConstants.flexibleCourseType && <div className="cardTimeAndDate">
                                    <div><AiOutlineCheckCircle className="calendarIcon" />&nbsp;</div>
                                    <div className="dateTimeText"><span data-tooltip-id={`${course.uniqueId}-weekdays`} className="competitionDateTimeFormat">{course.startTime} - {course.endTime} {timeZone === AppConstants.utcTimezone && `(${course.preferredTimezone})`}</span>
                                        {course.weekDays && <Tooltip className="courseTitleTooltip" id={`${course.uniqueId}-weekdays`}><span>{getWeekTimings(course.weekDays)}</span></Tooltip>}
                                    </div>
                                </div>}

                                {course.trackingType !== AppConstants.durationBasedTrackingText && <div className="cardTimeAndDate">
                                    <div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">&nbsp;{course.numberOfClasses} {course.numberOfClasses > 1 ? "Classes" : "Class"} <span className="durationSeparator">|</span> {course.duration} {course.duration > 1 ? "Hours" : "Hour"} </span>
                                    </div>
                                </div>}
                                {course.trackingType === AppConstants.durationBasedTrackingText && <div className="cardTimeAndDate">
                                    <div><FaChalkboardTeacher className="durationIcon calendarIcon" />&nbsp;</div><div className="dateTimeText">
                                        <span className="competitionDateTimeFormat">&nbsp;{course.packageDuration} {course.packageDuration > 1 ? "Days" : "Day"} </span>
                                    </div>
                                </div>}
                                <div className="buttonSection">
                                    <div>
                                        <span onClick={stopPropagation} data-tooltip-id={`pastCourseEditInfo-${course.uniqueId}`}><Button type="button" disabled={course.eventStatus === AppConstants.courseStatus.past} onClick={(e) => { stopPropagation(e); props.onClickEdit(course) }} className="cardButton me-2">Edit</Button></span>
                                        {course.eventStatus === AppConstants.courseStatus.past && < Tooltip className="courseEditTooltip" id={`pastCourseEditInfo-${course.uniqueId}`}><span>{ErrorMessages.cannotEditPastCourseInfo}</span></Tooltip>}

                                        <span onClick={stopPropagation} data-tooltip-id={`pastCoursePublishInfo-${course.uniqueId}`}>{props.courseType === AppConstants.unpublishedCourseText && <Button onClick={(e) => { stopPropagation(e); handleModalShow(); setModalDataFunc(course) }} type="button" disabled={course.eventStatus === AppConstants.courseStatus.past || isStartDatePassed(course)} className="cardButton">Publish</Button>}</span>
                                        {course.eventStatus === AppConstants.courseStatus.past && <Tooltip className="courseEditTooltip" id={`pastCoursePublishInfo-${course.uniqueId}`}><span>{ErrorMessages.cannotPublishPastCourseInfo}</span></Tooltip>}
                                        {(course.eventStatus === AppConstants.courseStatus.ongoing && course.courseType === AppConstants.structuredCourseType) && <Tooltip className="courseEditTooltip" id={`pastCoursePublishInfo-${course.uniqueId}`}><span>{ErrorMessages.cannotPublishOngoingCourseInfo}</span></Tooltip>}
                                        <span onClick={stopPropagation} data-tooltip-id={`enrolledCourseUnpublishInfo-${course.uniqueId}`}> {props.courseType === AppConstants.publishedCourseText && <Button disabled={course.studentsEnrolled !== 0 || course.pendingCodRequestsFlag === AppConstants.yText} onClick={(e) => { stopPropagation(e); handleModalShow(); setModalDataFunc(course) }} type="button" className="cardButton">Unpublish</Button>}</span>
                                        {(course.studentsEnrolled !== 0 || course.pendingCodRequestsFlag === AppConstants.yText) && <Tooltip className="courseEditTooltip" id={`enrolledCourseUnpublishInfo-${course.uniqueId}`}><span>{course.studentsEnrolled !== 0 ? ErrorMessages.cannotUnPublishCourseWithEnrolmentsInfo : ErrorMessages.pendingCourseEnrolmentRequestUnpublishError}</span></Tooltip>}
                                    </div>
                                </div>
                                <div className="cardUserProfileImageSection">
                                    <Tooltip className="tooltipMessage" id={course.uniqueId}><span className="tooltipMessage">{course.address}</span></Tooltip>
                                </div>
                                <div className="cardUserProfileImageSectionExpert mt-1">
                                    <div className="cardUserProfileImageContainerExpert">
                                        {(course?.length !== 0 && course.participantsDetails !== null && course?.participantsDetails?.length !== 0 && participantsImageList?.length !== 0 && participantsImageList[index]?.length !== 0) && <div className="cardProfileImageSection">
                                            {course.length !== 0 && course.participantsDetails !== null
                                                && participantsImageList[index]?.slice(0, AppConstants.studentProfileImageLimit).map((imgageSrc, i) => (
                                                    <img src={imgageSrc} key={courseCards[index]?.participantsDetails?.studentUniqueId} className="cardProfileImage" alt="student profile" />
                                                ))}
                                        </div>}
                                        <div className="cardEnrolledCountSection"><div className="cardText">{MathUtil.formatNumberToLocaleString(course.studentsEnrolled)} Enrolled</div></div>
                                    </div>
                                    <div onClick={stopPropagation}>
                                        {(course.modeOfDelivery.toUpperCase() === AppConstants.physicalText || course.modeOfDelivery.toUpperCase() === AppConstants.offlineText) && <span className="onlineIcon" data-tooltip-place="top" data-tooltip-id={course.uniqueId}><a href={course.geoLocation} target={AppConstants.openInNewTab} className="cardLink"><CgPin className="geoLocationIcon" /></a></span>}
                                    </div>
                                    <Tooltip className="tooltipMessage" id={course.uniqueId}><span className="tooltipMessage">{course.address}</span></Tooltip>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row >
            <Modal show={showModal} onHide={handleModalClose} backdrop="static" keyboard={false} centered>
                {showModal === true && <Modal.Body className={classes.modalBody}>
                    {!(modalSuccess || modalError) && <div className={classes.modalAlertIcon}><AiOutlineQuestionCircle /></div>}
                    {modalSuccess && <div className={classes.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                    {modalError && <div className={classes.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={classes.modalAlertText}>{modalAlertText}</div>
                </Modal.Body>}
                {showModal === true && <Modal.Footer className={classes.modalFooter}>
                    {!(modalSuccess || modalError) && publishAlert.length === 0 && <Button className={classes.modalNoButton} onClick={handleModalClose}>No</Button>}
                    {!(modalSuccess || modalError) && publishAlert.length === 0 && <Button className={classes.modalYesButton} onClick={() => updateCourseStatus(modalData)}>Yes {modalSpinner && <BiLoaderAlt className="spinner" />}</Button>}
                    {(modalSuccess || modalError) && <Button className={classes.modalCloseButton} onClick={handleModalClose}>Close</Button>}
                </Modal.Footer>}
            </Modal>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className={classes.loadMore}>
                <Col className={classes.loadMoreDiv}>
                    {cardCount < courseCards.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + viewMoreCardsCount)}>Load more</button>}
                </Col>
            </Row>
        </>
    );
}