import React, { useState, useEffect, useMemo, useRef } from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { FaArrowLeft } from "react-icons/fa";
import { Button } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import '../../component/expertManageCompetitionComponent/dataGrid.css';
import Moment from 'react-moment';
import 'moment-timezone';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { BiLoaderAlt, BiRightArrowAlt, BiLinkExternal } from "react-icons/bi";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { ErrorSuccessAlertMessage } from "../errorSuccessMessage/ErrorSuccessAlertMessage";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { ViewResultJudgeDetails } from "./viewResultJudgeDetails";
import { FiDownloadCloud } from 'react-icons/fi';
import { Tooltip } from 'react-tooltip';
import classes from "./expertViewCompetitionResult.module.css"
import { GrDocumentZip } from "react-icons/gr";

export const ExpertViewCompetitionsResult = (props) => {
    const [viewDetailsSection, setViewDetailsSection] = useState(AppConstants.falseText);
    const [studentMarksDetails, setStudentMarksDetails] = useState([]);
    const [certificateSpinner, setCertificateSpinner] = useState(AppConstants.falseText);
    const [viewDetailsSectionArray, setViewDetailsSectionArray] = useState([]);
    const [timeZone, setTimeZone] = useState(DateTimeUtil.getPreferredTimeZoneForUser());
    const [downloadResultSpinner, setDownloadResultSpinner] = useState(AppConstants.falseText);
    const [downloadResultError, setDownloadResultError] = useState(AppConstants.falseText);
    const [downloadCertificateError, setDownloadCertificateError] = useState(AppConstants.emptyString);
    const [downloadSummarrySpinner, setDownloadSummarrySpinner] = useState(AppConstants.falseText);
    const [downloadSummaryError, setDownloadSummaryError] = useState(AppConstants.falseText);
    const gridRef = useRef(AppConstants.nullText);
    const [resultGridWidth, setResultGridWidth] = useState();
    const [columnDefsResultGrid, setColumnDefsResultGrid] = useState([]);
    const [declareResultsError, setDeclareResultsError] = useState(AppConstants.emptyString);
    const [declareResultsSpinner, setDeclareResultSpinner] = useState(AppConstants.emptyString);
    const [declareResultVarient, setDeclareResultVarient] = useState(AppConstants.emptyString);
    const [downloadZipSpinner, setDownloadZipSpinner] = useState(AppConstants.falseText);
    const [downloadZipError, setDownloadZipError] = useState(AppConstants.emptyString);
    //Fetch Result data
    useEffect(() => {
        const getGridWidth = () => {
            if (gridRef.current) {
                const gridWidth = gridRef.current.offsetWidth;
                setResultGridWidth(gridWidth);
            }
            defineResultTable();
        }
        getGridWidth();

    }, [])

    const getWidth = (percentageWidth) => {
        return Math.floor((percentageWidth * resultGridWidth) / 100);
    };

    const defineResultTable = () => {
        setColumnDefsResultGrid([
            {
                headerName: "S. No",
                valueGetter: "node.rowIndex + 1",
                maxWidth: 70
            },
            { field: 'studentName', tooltipField: 'studentName', headerName: 'Name', width: getWidth(20) },
            {
                field: 'totalMarks/maximumMarksCalculated', headerName: 'Marks', width: getWidth(10),
                tooltipValueGetter: function getValue(params) {
                    if (props.competitionDetails.checkCompetitionRubrics === 1) {
                        return `${params.data.totalMarks} / ${params.data.maximumMarksCalculated}`;
                    }
                    else {
                        return 'N/A';
                    }

                },
                valueGetter: function getValue(params) {
                    if (props.competitionDetails.checkCompetitionRubrics === 1) {
                        return `${params.data.totalMarks} / ${params.data.maximumMarksCalculated}`;
                    }
                    else {
                        return 'N/A';
                    }
                }
            },
            {
                field: 'judgedBy', headerName: 'Judged by', width: getWidth(29.8),
                cellRendererFramework: ViewResultJudgeDetails,
                cellRendererParams: (params) => ({
                    judges: params == AppConstants.nullText ? AppConstants.nullText : params.value
                })
            },
            {
                field: 'participantRank',
                tooltipValueGetter: function getValue(params) {
                    if (props.competitionCompletionStatus === AppConstants.completitionStatus.complete && props.competitionDetails.checkCompetitionRubrics === 1) {
                        return `${params.data.participantRank}`;
                    }
                    else {
                        return 'N/A';
                    }

                },
                headerName: 'Rank', width: getWidth(10),
                valueGetter: function getValue(params) {
                    return props.competitionCompletionStatus === AppConstants.completitionStatus.complete && props.competitionDetails.checkCompetitionRubrics === 1 ? (params.data?.participantRank === 0 ? AppConstants.notAvailableText : params.data?.participantRank) : AppConstants.notAvailableText;
                }
            },
            {
                field: 'Submission Status', headerName: 'Submission', width: getWidth(20),
                tooltipValueGetter: function getValue(params) {
                    if (params.data.submissionFilePath === AppConstants.nullText) {
                        return "N/A";
                    }
                    else {
                        return "Submitted";
                    }

                },

                valueGetter: function getValue(params) {
                    return params.data.submissionFilePath === AppConstants.nullText ? AppConstants.notAvailableText : "Submitted"
                }
            },
            { field: 'Result', width: getWidth(10), cellRendererFramework: (params) => <div><BiRightArrowAlt className="gridIcon" onClick={() => { handleView(params.data) }} /></div> },
        ]);
    }

    const defaultColDef = useMemo(() => ({
        width: 100,
        resizable: AppConstants.trueText,
        filter: AppConstants.trueText,
        floatingFilter: AppConstants.trueText,
        suppressMenu: AppConstants.trueText,
        suppressHorizontalScroll: AppConstants.falseText,
        floatingFilterComponentParams: { suppressFilterButton: AppConstants.trueText }
    }), [])

    //handle click on view button
    async function handleView(params) {
        setViewDetailsSection(AppConstants.trueText);
        //fetch student marks
        const url = `${AppConstants.getSelectedStudentMarksWithParam}${props.competitionDetails.id}${AppConstants.getSelectedStudentMarksSecondParam}${params.studentId}`;
        const response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            setStudentMarksDetails(response);
            setViewDetailsSectionArray(params);
        } else {
            setStudentMarksDetails([]);
        }
    }

    const [columnDefsStudentMarksGrid] = useState([
        { field: 'criteriaName', tooltipField: 'criteriaName', headerName: 'Criteria', width: getWidth(20) },
        { field: 'aspectDescription', tooltipField: 'aspectDescription', headerName: 'Aspect', width: getWidth(40), cellStyle: { textAlign: 'left' } },
        { field: 'judgeName', tooltipField: 'judgeName', headerName: 'Judge', width: getWidth(30) },
        { field: 'marksalloted', tooltipField: 'marksalloted', headerName: 'Marks', width: getWidth(10) },
        // { field: 'criteriaName', tooltipField: 'criteriaName', headerName: 'Criteria', width: 30 },
        // { field: 'aspectDescription', tooltipField: 'aspectDescription', headerName: 'Aspect', width: 50 },
        // { field: 'judgeName', tooltipField: 'judgeName', headerName: 'Judge', width: getWidth(30) },
        // { field: 'marksalloted', tooltipField: 'marksalloted', headerName: 'Marks', width: 20 },
    ]);

    //handle click on back button
    const backToGrid = () => {
        setViewDetailsSection(AppConstants.falseText);
    }

    const getJudgeNames = () => {
        const nameSet = new Set();
        studentMarksDetails.forEach(judge => {
            nameSet.add(judge.judgeName);
        });
        let judgeNames = Array.from(nameSet).join(", ");
        return judgeNames || AppConstants.notAvailableText;
    }

    //download competition result
    async function downloadSummary() {
        setDownloadSummarrySpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadResultSummarySheetAPI}${props.competitionDetails.uniqueId}`;
        const name = `${props.competitionDetails.title}${AppConstants.hyphen}${AppConstants.competitionResultSummaryFileName}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setDownloadSummarrySpinner(AppConstants.falseText);
            } else {
                setDownloadSummarrySpinner(AppConstants.falseText);
                setDownloadSummaryError(AppConstants.trueText);
                let timer = setTimeout(() => {
                    setDownloadSummaryError(AppConstants.falseText);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setDownloadSummarrySpinner(AppConstants.falseText);
            setDownloadSummaryError(AppConstants.trueText);
            let timer = setTimeout(() => {
                setDownloadSummaryError(AppConstants.falseText);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    //download competition result
    async function downloadResult(params) {
        setDownloadResultSpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadStudentReportSheetAPI}${props.competitionDetails.uniqueId}${AppConstants.downloadCompetitionResultSecondAPI}${viewDetailsSectionArray.studentQrenciaId}`;
        const name = `${props.competitionDetails.title}${AppConstants.hyphen}${params.studentName}${AppConstants.hyphen}${AppConstants.studentReportSheetDownloadFileName}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setDownloadResultSpinner(AppConstants.falseText);
            } else {
                setDownloadResultSpinner(AppConstants.falseText);
                setDownloadResultError(AppConstants.trueText);
                let timer = setTimeout(() => {
                    setDownloadResultError(AppConstants.falseText);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setDownloadResultSpinner(AppConstants.falseText);
            setDownloadResultError(AppConstants.trueText);
            let timer = setTimeout(() => {
                setDownloadResultError(AppConstants.falseText);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    const downloadCertificate = async (params) => {
        setCertificateSpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadCertificateAPI}${props.competitionDetails.uniqueId}${AppConstants.studentQrenciaIdParam}${params.studentQrenciaId}`;
        const name = `${params.competitionTitle}${AppConstants.hyphen}${params.studentName}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`;
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setCertificateSpinner(AppConstants.falseText);
            } else {
                setCertificateSpinner(AppConstants.falseText);
                setDownloadCertificateError(AppConstants.trueText);
                let timer = setTimeout(() => {
                    setDownloadCertificateError(AppConstants.falseText);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setCertificateSpinner(AppConstants.falseText);
            setDownloadCertificateError(AppConstants.trueText);
            let timer = setTimeout(() => {
                setDownloadCertificateError(AppConstants.falseText);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }

    const downloadCertificateZip = async () => {
        setDownloadZipSpinner(true);
        let data = {
            uniqueId: props.competitionDetails.uniqueId,
            participantDetails: props.resultDetails.map(result => ({
                participantName: result.studentName,
                certificateType: result.certificateType,
                participantRank: result.participantRank,
                userQrenciaId: result.studentQrenciaId,
                studentCompetitionMappingUniqueId: result.studentCompetitionMappingUniqueId
            }))
        }
        let url = `${AppConstants.generateCertificateZipForCompetitionAPI}`;
        const name = `${props.competitionDetails.title}${AppConstants.hyphen}${AppConstants.certificatesZipFileName}`;
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFileUsingPostRequest(
            url,
            fileName,
            data,
            AppConstants.emptyString,
            AppConstants.emptyString
        );
        if (response !== undefined) {
            if (response.ok) {
                setDownloadZipSpinner(AppConstants.falseText);
            } else {
                setDownloadZipSpinner(AppConstants.falseText);
                setDownloadZipError(`${ErrorMessages.fileDownloadError}ZIP file.${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setDownloadZipError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setDownloadZipSpinner(AppConstants.falseText);
            setDownloadZipError(`${ErrorMessages.fileDownloadError}ZIP file.${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setDownloadZipError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    }


    function downloadSubmission(params) {
        let file = params.submissionFilePath;
        var submissionsWindowName = AppConstants.submissionWindowName + props.competitionDetails.competitionId + AppConstants.submissionWindowNamePartTwo + params.studentQrenciaId;
        let startIndex = file.lastIndexOf(".");
        let endIndex = file.indexOf("?");
        let fileExtension = file.substring(startIndex, endIndex);
        if (fileExtension === '.ogg' || fileExtension === '.avi' || fileExtension === '.mp4' || fileExtension === '.mov' || fileExtension === '.webm') {
            var submissionsWindow = window.open("", AppConstants.openInNewTab, "name=submissions,height=600,width=600");
            // Write the video tag to the new window.
            submissionsWindow.document.write('<video controls="controls" width="600" height="600" name="submission"><source src="' + file + '"></video>');

            // Play the video.
            submissionsWindow.document.querySelector('video').play();
        }
        else {
            var submissionsWindow = window.open(file, AppConstants.openInNewTab, "name=submissions,height=600,width=600");
            submissionsWindow.name = submissionsWindowName;
        }
        submissionsWindow.name = submissionsWindowName;
        submissionsWindow.document.title = submissionsWindowName;
    };

    async function declareCompetitionResults(compData) {
        try {
            setDeclareResultSpinner(AppConstants.trueText);
            let data = {
                uniqueId: compData.uniqueId,
                title: compData.title,
                disablePerformanceResultFlag: compData.disablePerformanceResultFlag,
                templateId: compData.templateId
            };

            const url = AppConstants.declareResultsAPI;
            const response = await AuthorizedDataService.postRequest(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response.ok && response.status === AppConstants.httpResponseCodes.responseCode200) {
                setDeclareResultSpinner(AppConstants.falseText);
                let data = await response.json();

                if (data === 1) {
                    setDeclareResultVarient(AppConstants.alertVarient[0]);
                    setDeclareResultsError("Success! Competition result has been declared.");
                    props.fetchCompetitionDetails(props.competitionDetails);
                    let timer = setTimeout(() => {
                        setDeclareResultsError(AppConstants.falseText);
                        setDeclareResultVarient(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                } else if (data === -111) {
                    setDeclareResultVarient(AppConstants.alertVarient[1]);
                    setDeclareResultsError("You have already declared the results for this competition");
                    let timer = setTimeout(() => {
                        setDeclareResultsError(AppConstants.falseText);
                        setDeclareResultVarient(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                } else if (data === -113) {
                    setDeclareResultVarient(AppConstants.alertVarient[1]);
                    setDeclareResultsError("No Sutdents are enrolled in this competitition");
                    let timer = setTimeout(() => {
                        setDeclareResultsError(AppConstants.falseText);
                        setDeclareResultVarient(AppConstants.emptyString);
                    }, AppConstants.messageDisappearTime);
                    return () => { clearTimeout(timer) };
                }

            } else {
                setDeclareResultSpinner(AppConstants.falseText);
                setDeclareResultVarient(AppConstants.alertVarient[1]);
                setDeclareResultsError("Something went wrong! Please try again or contact support for assistance.");
                let timer = setTimeout(() => {
                    setDeclareResultsError(AppConstants.falseText);
                    setDeclareResultVarient(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } catch (error) {
            setDeclareResultSpinner(AppConstants.falseText);
            setDeclareResultVarient(AppConstants.alertVarient[1]);
            setDeclareResultsError("Something went wrong! Please try again or contact support for assistance.");
            let timer = setTimeout(() => {
                setDeclareResultsError(AppConstants.falseText);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    return (
        <>
            {!viewDetailsSection &&
                <div style={{ width: '100%' }} className="ag-theme-alpine">
                    <div ref={gridRef} style={{ width: '100%', height: '100%' }}>
                        <div className={classes.agGridMobile}>
                            <AgGridReact
                                headerHeight={AppConstants.gridConstants.headerHeight}
                                rowData={props.resultDetails}
                                defaultColDef={defaultColDef}
                                columnDefs={columnDefsResultGrid}
                                enableBrowserTooltips={AppConstants.trueText}
                                domLayout='autoHeight'>
                            </AgGridReact>
                        </div>
                        <div className={classes.agGridDesktop}>
                            <AgGridReact
                                headerHeight={AppConstants.gridConstants.headerHeight}
                                rowData={props.resultDetails}
                                defaultColDef={{ ...defaultColDef, flex: 1 }}
                                columnDefs={columnDefsResultGrid}
                                enableBrowserTooltips={AppConstants.trueText}
                                domLayout='autoHeight'>
                            </AgGridReact>
                        </div>

                        {props.competitionTab !== AppConstants.competitionTabHeading[0] && <div className="reportButtonSection">
                            {props.competitionDetails.checkCompetitionRubrics === 1 && <>
                                <Button className="expertViewCompetitionDownloadResultBtn" onClick={() => { downloadSummary() }}>Report {downloadSummarrySpinner && <BiLoaderAlt className="expertSpinnerIcon" />}</Button>
                                {props.competitionDetails.resultDate && props.competitionDetails.checkCompetitionRubrics === 1 && <div data-tooltip-id="declareResultsInfo"> <Button className="expertViewCompetitionDownloadResultBtn"
                                    disabled={(props.competitionDetails.completionStatus === AppConstants.completitionStatus.incomplete || new Date(DateTimeUtil.utcToLocal(props.competitionDetails.endDateTimeUTC)) > new Date()) || (props.competitionDetails.resultDeclaredFlag !== null && props.competitionDetails.resultDeclaredFlag === AppConstants.yText)} onClick={() => declareCompetitionResults(props.competitionDetails)}>Declare Results {declareResultsSpinner && <BiLoaderAlt className="spinner" />}</Button>
                                </div>}
                            </>}
                            {props.competitionDetails.participantsDetails?.length > 0 && props.competitionDetails.templateId !== 0 && props.competitionDetails.templateId !== AppConstants.nullText && props.competitionCompletionStatus !== AppConstants.completitionStatus.incomplete && <Button disabled={downloadZipSpinner} className="expertViewCompetitionDownloadResultBtn" onClick={() => { downloadCertificateZip(viewDetailsSectionArray) }}>Certificates&nbsp;&nbsp;{downloadZipSpinner ? <BiLoaderAlt className="expertSpinnerIcon" /> : <GrDocumentZip />}</Button>}
                        </div>}
                        {(props.competitionDetails.completionStatus === AppConstants.completitionStatus.incomplete || new Date(DateTimeUtil.utcToLocal(props.competitionDetails.endDateTimeUTC)) > new Date()) && <Tooltip className={classes.tooltipMessage} id="declareResultsInfo"><span className={classes.tooltipMessage}>Declare Results option will be available once the competition is marked as complete.</span></Tooltip>}
                        {(props.competitionDetails.resultDeclaredFlag !== null && props.competitionDetails.resultDeclaredFlag === AppConstants.yText) && <Tooltip className={classes.tooltipMessage} id="declareResultsInfo"><span className={classes.tooltipMessage}>You have already declared the results for this competition.</span></Tooltip>}
                    </div>
                    {props.competitionTab !== AppConstants.competitionTabHeading[0] && <div>
                        {downloadSummaryError && <div className="errorSuccessAlertDeclareResult"><ErrorSuccessAlertMessage message={ErrorMessages.downloadCompetitionSummaryError} varient={AppConstants.alertVarient[1]} /></div>}
                        {declareResultsError && <div className="errorSuccessAlertDeclareResult"><ErrorSuccessAlertMessage message={declareResultsError} varient={declareResultVarient} /></div>}
                        {downloadZipError && <div className="errorSuccessAlertDeclareResult"><ErrorSuccessAlertMessage message={downloadZipError} varient={AppConstants.alertVarient[1]} /></div>}
                    </div>}
                </div>
            }
            {viewDetailsSection && <div className="expertViewCompetitionResultViewSection">
                <FaArrowLeft className="goBackButton" onClick={() => { backToGrid() }} />
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">Participant Qrencia Id</p>
                        <p className="detailsSubHeading">{viewDetailsSectionArray.studentQrenciaId}</p>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">Participant Name</p>
                        <p className="detailsSubHeading">{viewDetailsSectionArray.studentName}</p>
                    </div>
                </div>
                <div className="row py-2">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">Competition Id</p>
                        <p className="detailsSubHeading">{props.competitionDetails.competitionId}</p>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">Competition Name</p>
                        <p className="detailsSubHeading">{props.competitionDetails.title}</p>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">Start Time</p>
                        <p className="detailsSubHeading"><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{props.competitionDetails.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{props.competitionDetails.startDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${props.competitionDetails.preferredTimezone})`}</p>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">End Time</p>
                        <p className="detailsSubHeading"><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{props.competitionDetails.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{props.competitionDetails.endDateTimeUTC.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${props.competitionDetails.preferredTimezone})`}</p>
                    </div>
                    {props.competitionDetails.checkCompetitionRubrics === 1 && <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 ps-0">
                        <p className="detailsHeading">Judged By</p>
                        <p className="detailsSubHeading">{getJudgeNames()}</p>
                    </div>}
                </div>
                {props.competitionDetails.checkCompetitionRubrics === 1 && <div className={`ag-theme-alpine ${classes.agGridMobile}`}>
                    <AgGridReact
                        headerHeight={AppConstants.gridConstants.headerHeight}
                        defaultColDef={defaultColDef}
                        rowData={studentMarksDetails}
                        columnDefs={columnDefsStudentMarksGrid}
                        enableBrowserTooltips={AppConstants.trueText}
                        domLayout='autoHeight'>
                    </AgGridReact>
                </div>}
                {props.competitionDetails.checkCompetitionRubrics === 1 && <div className={`ag-theme-alpine ${classes.agGridDesktop}`}>
                    <AgGridReact
                        headerHeight={AppConstants.gridConstants.headerHeight}
                        defaultColDef={{ ...defaultColDef, flex: 1 }}
                        rowData={studentMarksDetails}
                        columnDefs={columnDefsStudentMarksGrid}
                        enableBrowserTooltips={AppConstants.trueText}
                        domLayout='autoHeight'>
                    </AgGridReact>
                </div>}
                {viewDetailsSectionArray.submissionFilePath && <Button className="expertViewCompetitionDownloadCertificateBtn" onClick={() => { downloadSubmission(viewDetailsSectionArray) }}>Submission <BiLinkExternal /></Button>}
                {viewDetailsSectionArray.templateId !== 0 && viewDetailsSectionArray.templateId !== AppConstants.nullText && props.competitionCompletionStatus !== AppConstants.completitionStatus.incomplete && <Button className="expertViewCompetitionDownloadCertificateBtn" onClick={() => { downloadCertificate(viewDetailsSectionArray) }}>Certificate {certificateSpinner ? <BiLoaderAlt className="expertSpinnerIcon" /> : <FiDownloadCloud />}</Button>}
                {props.competitionCompletionStatus !== AppConstants.completitionStatus.incomplete && props.competitionDetails.checkCompetitionRubrics === 1 && <Button className="expertViewCompetitionDownloadCertificateBtn" onClick={() => { downloadResult(viewDetailsSectionArray) }}>Result {downloadResultSpinner ? <BiLoaderAlt className="expertSpinnerIcon" /> : <FiDownloadCloud />}</Button>}
                {downloadResultError && <div className="errorSuccessAlertDownloadStudentResult"><ErrorSuccessAlertMessage message={ErrorMessages.downloadCompetitionResultError} varient={AppConstants.alertVarient[1]} /></div>}
                {downloadCertificateError && <div className="errorSuccessAlertDownloadStudentResult"><ErrorSuccessAlertMessage message={ErrorMessages.downloadIndividualCertificateError} varient={AppConstants.alertVarient[1]} /></div>}
            </div>}
        </>
    )
}
