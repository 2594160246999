export const SuccessMessage = {
    "signupSuccessSalutation1": "Welcome, ",
    "signupSuccessSalutation2": "!",
    "signupSuccess": "You are now part of Qrencia",
    "signupSuccess2": "a global passion exploration community",
    "signupSuccessSubtitle1": "Please ",
    "signupSuccessSubtitle": "to continue",
    "basicCompetitionDetailsAdded": "Basic Details of Competition has been added successfully",
    "problemStatementAdded": "Problem Statement has been added successfully.",
    "rubricsAdded": "Rubrics Uploaded successfully",
    "competitionPublished": "Competition Published successfully",
    "success": "Success",
    "linkCopiedToClipboard": "Link copied to clip board!",
    "expertCancelCompetitionSuccess": " cancelled successfully!",
    "expertCompetitionCompletedSuccess": "Congratulations! Competition is closed successfully",
    "expertEnableJudgingSuccess": "Judging successfully enabled!",
    "expertDisableJudgingSuccess": "Judging successfully disabled!",
    "competitionCreatedSuccessfullyText": "Competition Created Successfully",
    "competitionUploadSolutionSuccess": "Solution submitted successfully!",
    "submitMarksSuccess": "Marks submitted successfully!",
    "studentEnrollmentSuccess": "Congratulations! You've been successfully enrolled.",
    "studentCompetitionEnrolSuccessMailInfo": "All the competition related details have been sent to your registered email -",
    "studentEnrolSuccessMailInfo": "An email with necessary details has been sent to you.",
    "otpSentSuccessMessage": "OTP sent successfully to your registered email address",
    "otpSentSuccessMobile": "OTP sent successfully to your registered mobile number",
    "mobileVerificationSuccess": "Mobile number verified successfully",
    "completeProfileSubmitSuccess": "Profile updated! Your learning adventure begins now.",
    "emailVerificationSucces": "Email address verified successfully",
    "sendJudgingLinkSuccess": "Success: The link has been sent successfully to all associated judges",
    "studentRaiseNudgeSuccess": "Success! Nudge raised successfully",
    "individualJudgingLinkSuccess": "Judging link shared successfully!",
    "expertObrSubmitted": "Success ! Values submitted",
    "obrCreatedSuccessfully": "The OBR has been successfully created, and an email has been sent to",
    "obrUpdatedSuccessfully": "OBR updated successfully!",
    "resetPasswordMailSendSuccessfully": "An email with a password reset link was just sent to",
    "emailDeliveredSuccessfully": "Email Delivered Successfully",
    "obrEmailSentSuccessMessage": "OBR link successfully sent to the user's email address",
    "expertRevertToNudgeSuccess": "Success! Your response has been successfully sent!",
    "obrSuccessStatusUpdated": "OBR form successfully updated",
    "obrRejected": "The OBR has been rejected",
    "obrApproved": "The OBR has been approved",
    "obrInDraft": "The OBR is sent to the expert for update",
    "editCompetitionSuccessful": "Edit successful! The competition has been updated on Qrencia.",
    "obrDeactivateSuccess": "OBR successfully deactivated",
    "obrActivatedSuccecss": "OBR successfully activated",
    "obrApporveSuccess": "OBR approved successfully",
    "obrRejectSucecss": "OBR rejected successfully",
    "obrDetailsSavedSuccessFully": "Your details have been saved successfully",
    "obrDetailsSentForReview": "Details submitted successfully! We will review your information and contact you soon regarding your onboarding process. Thank you!",
    "passwordResetSuccess": "Password reset successful!",
    "courseCreatedSuccessfully": "Congratulations! Your course has been successfully created. Take the next step and publish your course to list it on Qrencia.",
    "publishedSuccessfullyText": "published successfully",
    "unpublishedSuccessfullyText": "unpublished successfully",
    "courseUpdateSuccessful": "Edit successful! The course has been updated on Qrencia.",
    "studentQueryResponseSuccess": "Success! Your response has been successfully sent!",
    "StudentCourseQuerySentMessage": "Your query has been sent to the organizer. Please wait for the response.",
    "adminApproveCompetitionSuccess": "Fee waiver request have been approved successfully for this competition. We have updated the organizer on the status of competition.",
    "feeWaiverCompetitionCreatedSuccess": "Competition created successfully. The competition details have been shared with our admin team for their review and approval of your fee waiver request.",
    "eoiFormSubmittedSuccess": "Thank you for expressing your interest! Your submission has been received. Our team will be reaching out to you soon",
    "bulkSheetEnrolStudentsSuccess": "Success! We have informed the participants about their enrolment status through email.",
    "agencyMapStudentsSuccessMessage": "Success!! We have updated the students about their mapping with the school through an email",
    "mappingLinkSentSuccessfullyMessage": "Invitation link have been successfully sent to the expert.",
    "expertAcceptedInvitationRequestMessage": "The invitation have been accepted!",
    "expertRejectedInvitationRequestMessage": "The invitation have been rejected!",
    "paymentInitiatedMessage": "Initiating your payment. Please wait while we open the checkout window for you.",
    "paymentVerifiedMessage": "Payment Verified",
    "nudgeRaisedSuccessfullyMessage": "You have successfully raised the nudge.",
    "workshopCreatedSuccessfully": "Congratulations! Your workshop has been successfully created. Take the next step and publish your workshop to list it on Qrencia.",
    "workshopUpdateSuccessful": "Edit successful! The workshop has been updated on Qrencia.",
    "reviewSharedSuccessfullyMessage": "Your review has been submitted successfully!",
    "leadCreatedSuccessfully": "Lead has been created successfully!",
    "obrReminderEmailSentSuccessfully": "Reminder email has been successfully sent to",
    "offeringsReminderEmailSentSuccessfully": "Reminder email to list the offerings has been successfully sent to",
    "adminBulkSignupSheetUploadSuccess": "Sheet has been uploaded successfully and an email has been sent to the learners to reset their password",
    "adminBulkEnrolSheetUploadSuccess": "Sheet has been uploaded successfully and an enrollment confirmation email has been sent to all the learners",
    "courseSubscriptionReminderSentSuccessfully": "Reminder email to add more classes has been sent successfully to",
    "attendanceMarkedSuccessfullyMessage": "Attendance has been marked successfully.",
    "classesTrackedSuccessFullyMessage": "Classes have been marked successfully.",
    "addClassSuccessMessage": "Congratulations! Your course enrolment has been successfully updated with additional classes",
    "addDurationSuccessMessage": "Congratulations! Your course enrolment has been successfully renewed.",
    "offlinePaymentRequestSentSuccess": "Your enrolment request has been sent to {{creatorName}}. To confirm your enrolment, please complete your payment directly with the {{creatorRole}}. You will receive a confirmation email once your enrolment is approved.",
    "ambassadorChallengeAcceptedMessage": "Congratulations! You have successfully accepted the ambassador challenge.",
    "adminBulkUploadCreatorSheetSuccess": "Leads uploaded successfully",
    "bulkFeedbackSharedSuccessfullyMessage": "Feedback has been successfully shared with the enrolled learners",
    "feedbackSharedSuccessfullyMessage": "Feedback has been successfully shared with the learners",
    "feedbackUpdatedSuccessfullyMessage": "Feedback updated successfully",
    "feedbackSharedSuccessfullyWithLearnerMessage": "Feedback has been successfully shared with the learner",
    "courseFeedbackSuccessfullyDeleted": "Feedback deleted successfully."
}