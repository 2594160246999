import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FaCalendarAlt, FaChild, FaRegCalendarTimes, FaBuilding } from 'react-icons/fa';
import { BiLinkExternal } from 'react-icons/bi';
import { SiGoogleclassroom } from 'react-icons/si';
import { BsFillStopwatchFill } from 'react-icons/bs';
import { RiGitRepositoryPrivateFill } from 'react-icons/ri';
import { DateTime } from "luxon";
import { AiOutlineExclamationCircle, AiOutlineCheckCircle } from 'react-icons/ai';
import classes from './RightCompetitionDetailsSection.module.css';
import { AppConstants } from '../../constants/AppConstants';
import { Button, Row, Col } from 'react-bootstrap';
import Moment from 'react-moment';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import Auth from '../../utils/Auth';
import { EnrolmentModal } from '../modal/EnrolmentModal';
import { PaymentsUtil } from '../../utils/PaymentsUtil';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { BiWallet } from 'react-icons/bi';
import { PostStartEnrolmentModal } from '../modal/PostStartEnrolmentModal';
import { PayOfflineConfirmationModal } from '../modal/PayOfflineConfirmationModal';
import { useEnrolment } from '../../hooks/useEnrolment';
import useWarnOnLeave from '../../hooks/useWarnOnLeave';

export const RightCompetitionDetailsSection = (props) => {
    const [competitionDetailsObj, setCompetitionDetailsObj] = useState(props.competitionData);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);
    const [searchParams, setSearchParams] = useSearchParams();
    const [signupTimerFlag, setSignupTimerFlag] = useState(AppConstants.falseText);
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));
    const [payloadData, setPayloadData] = useState({});
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [isEnrolButtonClickedOnce, setIsEnrolledButtonClickedOnce] = useState(AppConstants.falseText);
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const { enrolInLearningActivity } = useEnrolment();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    useWarnOnLeave(shouldWarn);

    useEffect(() => {
        const openSignupModalAfterTimer = (isEnrolButtonClickedOnce) => {
            if (!isEnrolButtonClickedOnce) {
                setShowModal(AppConstants.trueText);
                setMessage(ErrorMessages.enrollmentUserNotLoggedInError)
                setSignupTimerFlag(AppConstants.trueText);
            }
        }
        if (!Auth.isLogin() && competitionDetailsObj.competitionId && DateTime.fromISO(competitionDetailsObj.competitionUTCRegistrationCloseTime?.replace(' ', 'T') + 'Z').setZone(timeZone) > DateTime.local().setZone(timeZone) && props.signupTimerDuration) {
            let timer = setTimeout(openSignupModalAfterTimer, props.signupTimerDuration * 1000, isEnrolButtonClickedOnce);
            return () => { clearTimeout(timer) };
        }

    }, [competitionDetailsObj, props.signupTimerDuration, isEnrolButtonClickedOnce]);

    useEffect(() => {
        const verifyPayment = async () => {
            try {
                if (paymentParam && competitionDetailsObj?.competitionId) {
                    const data = JSON.parse(window.atob(paymentParam));
                    setPayloadData(data);
                    setPaymentStatus(AppConstants.paymentStatus.pending);
                    data.learningActivityName = competitionDetailsObj.competitionTitle;
                    PaymentsUtil.verifyPayment(data).then((response) => {
                        if (response.verificationStatus === AppConstants.paymentStatus.verified) {
                            setPaymentStatus(AppConstants.paymentStatus.verified);
                            setTransactionId(response.transactionId);
                            enrolHere(competitionDetailsObj.competitionStringId, competitionDetailsObj.competitionTitle, competitionDetailsObj.competitionId, AppConstants.paymentStatus.verified);
                        } else {
                            setModalTypeError(true);
                            setMessage(response.message);
                            setTransactionId(response.transactionId);
                            setPaymentStatus(AppConstants.emptyString);
                            handleModalShow();
                        }
                    }).catch((error) => {
                        setModalTypeError(true);
                        setMessage(ErrorMessages.verifyPaymentFailedError);
                        setPaymentStatus(AppConstants.emptyString);
                        handleModalShow();
                    });
                }
            } catch (error) {
                setPaymentStatus(AppConstants.emptyString);
            }
        };

        if (paymentParam) {
            setPaymentStatus(AppConstants.paymentStatus.pending);
        }
        const timer = setTimeout(() => {
            verifyPayment();
        }, AppConstants.paymentVerificationTimeout);

        return () => clearTimeout(timer);
    }, [paymentParam, competitionDetailsObj]);

    const confirmBeforeEnrolment = () => {
        setIsEnrolledButtonClickedOnce(AppConstants.trueText);
        setConfirmationPopupActivityDetails({ id: competitionDetailsObj.competitionStringId, title: competitionDetailsObj.competitionTitle, uniqueId: competitionDetailsObj.competitionId, payOfflineFlag: competitionDetailsObj.payOfflineFlag, creatorName: competitionDetailsObj.userRole === AppConstants.userRoleExpert ? competitionDetailsObj.expertName : competitionDetailsObj.agencyName, creatorRole: competitionDetailsObj.userRole, type: AppConstants.learningActivitiesTypes.competition });
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            const startDateTime = DateTime.fromISO(competitionDetailsObj.competitionUTCStartTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
            const currentDateTime = DateTime.local().setZone(timeZone);
            if (currentDateTime > startDateTime) {
                // show confirmation modal to the user.
                setShowConfirmationModal(AppConstants.trueText);
            } else {
                if (competitionDetailsObj.payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    enrolHere(competitionDetailsObj.competitionStringId, competitionDetailsObj.competitionTitle, competitionDetailsObj.competitionId);
                }
            }
        } else {
            enrolHere(competitionDetailsObj.competitionStringId, competitionDetailsObj.competitionTitle, competitionDetailsObj.competitionId);
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolHere(competitionDetailsObj.competitionStringId, competitionDetailsObj.competitionTitle, competitionDetailsObj.competitionId);
            }
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolHere(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const enrolHere = async (id, title, uniqueId, status) => {
        const cost = PaymentsUtil.calculateCost({ cost: competitionDetailsObj.competitionCost, discount: 0 });
        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setMessage(ErrorMessages.enrollmentUserNotStudentError);
            setModalTypeError(AppConstants.trueText);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
            return;
        }

        let response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: title, learningActivityId: id, learningActivityType: AppConstants.learningActivitiesTypes.competition }, status);
        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzpay = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShowModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setShouldWarn(AppConstants.trueText);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setTimeout(async () => {
                    enrolHere(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityTitle, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }

        });

        rzpay.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzpay.open();
    };

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        enrolHere(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    };

    const handleModalClose = () => {
        if (signupTimerFlag) {
            setSignupTimerFlag(AppConstants.falseText);
            setMessage(AppConstants.emptyString);
            setShowModal(AppConstants.falseText);
        } else {
            if (paymentParam) {
                window.location.replace(window.location.href.split(AppConstants.questionMark)[0]);
            } else if (transactionId) {
                window.location.reload();
            } else {
                if (userLoggedInAndEnroled) {
                    window.location.reload();
                } else {
                    if (modalTypeError) {
                        setShowModal(false);
                    } else {
                        setShowModal(false);
                        window.location.reload();
                    }
                }
            }
        }
    };

    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    }

    return (
        <>
            <div className={classes.rightContainer}>
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && competitionDetailsObj.disablePublicEnrolmentFlag === "N" && !competitionDetailsObj.competitionLive && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCRegistrationCloseTime) > new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        {competitionDetailsObj.hasStudentEnrolled ? <p className={classes.competitionEnrollText}>{AppConstants.alreadyEnrolledinCompetitionText}</p> : <p className={classes.competitionEnrollText}>{AppConstants.wantToParticipateText}</p>}
                        {competitionDetailsObj.hasStudentEnrolled ? <AiOutlineCheckCircle className='text-success fs-4' /> : <Button disabled={competitionDetailsObj.isOfflinePaymentRequested === AppConstants.yText} className={classes.competitionEnrollButton} onClick={() => confirmBeforeEnrolment({ id: competitionDetailsObj.competitionStringId, title: competitionDetailsObj.competitionTitle, uniqueId: competitionDetailsObj.competitionId, payOfflineFlag: competitionDetailsObj.payOfflineFlag, creatorName: competitionDetailsObj.userRole === AppConstants.userRoleExpert ? competitionDetailsObj.expertName : competitionDetailsObj.agencyName, creatorRole: competitionDetailsObj.userRole })}>{competitionDetailsObj.isOfflinePaymentRequested === AppConstants.yText ? "Requested" : "Enrol Now"}</Button>}
                    </div>}
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && !competitionDetailsObj.competitionLive && competitionDetailsObj.disablePublicEnrolmentFlag === "Y" && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCRegistrationCloseTime) > new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        {competitionDetailsObj.hasStudentEnrolled ? <p className={classes.competitionEnrollText}>{AppConstants.alreadyEnrolledinCompetitionText}</p> : <p className={classes.competitionEnrollText}>{AppConstants.privateCompetitionText}</p>}
                        {competitionDetailsObj.hasStudentEnrolled ? <AiOutlineCheckCircle className='text-success fs-4' /> : <span><RiGitRepositoryPrivateFill /></span>}
                    </div>}
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && !competitionDetailsObj.competitionLive && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCRegistrationCloseTime) < new Date() && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCEndTime) > new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        {competitionDetailsObj.hasStudentEnrolled ? <p className={classes.competitionEnrollText}>{AppConstants.alreadyEnrolledinCompetitionText}</p> : <p className={classes.competitionEnrollText}>{AppConstants.registrationAreClosedText}</p>}
                        {competitionDetailsObj.hasStudentEnrolled ? <AiOutlineCheckCircle className='text-success fs-4' /> : <AiOutlineExclamationCircle className='text-danger fs-4' />}
                    </div>}
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && competitionDetailsObj.competitionLive && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCRegistrationCloseTime) > new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        {competitionDetailsObj.hasStudentEnrolled && <p className={classes.competitionEnrollText}>Competition is now <strong>LIVE &nbsp;</strong><div className={classes.liveDot}> &#x1F534;</div> </p>}
                        {!competitionDetailsObj.hasStudentEnrolled && <p className={classes.competitionEnrollText}>{AppConstants.wantToParticipateText}</p>}
                        {!competitionDetailsObj.hasStudentEnrolled && <Button disabled={competitionDetailsObj.isOfflinePaymentRequested === AppConstants.yText} className={classes.competitionEnrollButton} onClick={() => confirmBeforeEnrolment({ id: competitionDetailsObj.competitionStringId, title: competitionDetailsObj.competitionTitle, uniqueId: competitionDetailsObj.competitionId, payOfflineFlag: competitionDetailsObj.payOfflineFlag, creatorRole: competitionDetailsObj.userRole, creatorName: competitionDetailsObj.userRole === AppConstants.userRoleExpert ? competitionDetailsObj.expertName : competitionDetailsObj.agencyName })}>{competitionDetailsObj.isOfflinePaymentRequested === AppConstants.yText ? "Requested" : "Enrol Now"}</Button>}
                        {(Auth.isLogin() && competitionDetailsObj.roleStudent && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.competitionType.toUpperCase() === AppConstants.onlineText && competitionDetailsObj.competitionVcLink !== null)}
                        {(Auth.isLogin() && competitionDetailsObj.roleStudent && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.competitionType.toUpperCase() === AppConstants.offlineText) &&
                            <Button href={competitionDetailsObj.competitionGeoLocationLink} target={AppConstants.openInNewTab} className={classes.competitionEnrollButton}>Location <BiLinkExternal /></Button>}
                    </div>
                }
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && competitionDetailsObj.competitionLive && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCRegistrationCloseTime) < new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        {competitionDetailsObj.hasStudentEnrolled && <p className={classes.competitionEnrollText}>Competition is now <strong>LIVE &nbsp;</strong> <span className={classes.liveDot}> &#x1F534;</span></p>}
                        {(Auth.isLogin() && competitionDetailsObj.roleStudent && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.competitionType.toUpperCase() === AppConstants.offlineText) &&
                            <Button href={competitionDetailsObj.competitionGeoLocationLink} target={AppConstants.openInNewTab} className={classes.competitionEnrollButton}>Location <BiLinkExternal /></Button>}

                        {!competitionDetailsObj.hasStudentEnrolled && <p className={classes.competitionEnrollText}>{AppConstants.registrationAreClosedText}</p>}
                        {!competitionDetailsObj.hasStudentEnrolled && <AiOutlineExclamationCircle className='text-danger fs-4' />}
                    </div>
                }
                {(competitionDetailsObj.competitionComplete === AppConstants.falseText && DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCEndTime) <= new Date()) &&
                    <div className={classes.competitionEnrollSection}>
                        <p className={classes.competitionEnrollText}>{AppConstants.competitionEndedText}</p>
                        <AiOutlineExclamationCircle className='text-danger fs-4' />
                    </div>}
                {(competitionDetailsObj.competitionComplete) &&
                    <div className={classes.competitionEnrollSection}>
                        <p className={classes.competitionEnrollText}>{AppConstants.competitionEndedText}</p>
                        <AiOutlineExclamationCircle className='text-danger fs-4' />
                    </div>}
                <div className={classes.competationDetailsCard}>
                    <Row xs={1} sm={1} md={2} lg={1} xl={1}>
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <BiWallet className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Fee</p>
                                    <p className={classes.detailsSubHeading}>{PaymentsUtil.calculateCost({ cost: competitionDetailsObj.competitionCost, discount: 0 })}</p>
                                </div>
                            </div>
                        </Col>
                        {/* <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <FaUsers className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Enrolments</p>
                                    <p className={classes.detailsSubHeading}>{competitionDetailsObj.noOfEnrolledParticipants}</p>
                                </div>
                            </div>
                        </Col> */}
                        {competitionDetailsObj.schoolName != null && <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <FaBuilding className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>School</p>
                                    <p className={classes.detailsSubHeading}>{competitionDetailsObj.schoolName}</p>
                                </div>
                            </div>
                        </Col>}
                        {competitionDetailsObj.selectedClasses != null && <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <SiGoogleclassroom className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Class</p>
                                    <p className={classes.detailsSubHeading}>{competitionDetailsObj.selectedClasses}</p>
                                </div>
                            </div>
                        </Col>}
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <BsFillStopwatchFill className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Registration Deadline</p>
                                    <p className={classes.detailsSubHeading}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCRegistrationCloseTime?.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCRegistrationCloseTime?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</p>
                                </div>
                            </div>
                        </Col>
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <FaCalendarAlt className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Start Time</p>
                                    <p className={classes.detailsSubHeading}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</p>
                                </div>
                            </div>
                        </Col>
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <FaRegCalendarTimes className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>End Time</p>
                                    <p className={classes.detailsSubHeading}> <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</p>
                                </div>
                            </div>
                        </Col>
                        <Col className='p-0'>
                            <div className={classes.detailsGroup}>
                                <div className={classes.detailsIconSection}>
                                    <FaChild className={classes.detailsIcon} />
                                </div>
                                <div className={classes.detailsSubsection}>
                                    <p className={classes.detailsHeading}>Eligibility</p>
                                    {competitionDetailsObj.competitionMinAgeLimit === 0 && competitionDetailsObj.competitionMaxAgeLimit === 0 && <p className={classes.detailsSubHeading}>All age groups</p>}
                                    {competitionDetailsObj.competitionMinAgeLimit !== 0 && competitionDetailsObj.competitionMaxAgeLimit !== 0 && <p className={classes.detailsSubHeading}>{competitionDetailsObj.competitionMinAgeLimit} - {competitionDetailsObj.competitionMaxAgeLimit} Years</p>}
                                    {competitionDetailsObj.competitionMinAgeLimit !== 0 && competitionDetailsObj.competitionMaxAgeLimit === 0 && <p className={classes.detailsSubHeading}>{competitionDetailsObj.competitionMinAgeLimit}{AppConstants.plusYearText}</p>}
                                    {competitionDetailsObj.competitionMinAgeLimit === 0 && competitionDetailsObj.competitionMaxAgeLimit !== 0 && <p className={classes.detailsSubHeading}>5 - {competitionDetailsObj.competitionMaxAgeLimit}{AppConstants.yearsText}</p>}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {(message !== AppConstants.emptyString || paymentParam) && (showModal || paymentParam) && <EnrolmentModal signupTimerFlag={signupTimerFlag} onShow={handleModalShow} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} enrolInCompetition={enrolHere} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} paymentStatus={paymentStatus} transactionId={transactionId} confirmBeforeEnrolment={confirmBeforeEnrolment} creatorName={competitionDetailsObj.userRole === AppConstants.userRoleExpert ? competitionDetailsObj.expertName : competitionDetailsObj.agencyName} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
                    {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
                    {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
                </div>
                {competitionDetailsObj.competitionPromotionalAdImageOne !== null && <div className={classes.competitionAdsSection}>
                    <div>
                        <img src={competitionDetailsObj.competitionPromotionalAdImageOne} className={classes.competitionAdImage} alt="Advertisment" />
                    </div>
                </div>}
            </div>
        </>
    );
}