import { useState } from "react"
import { Button } from "react-bootstrap"
import { AppConstants } from "../../constants/AppConstants";
import { BiLoaderAlt } from "react-icons/bi";
import { BsMagic } from 'react-icons/bs';
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { ErrorMessages } from "../../constants/ErrorMessages";

export const GenerateAiImagesComponent = (props) => {
    const [aiGeneratedImageSpinner, setAiGeneratedImageSpinner] = useState(AppConstants.falseText);

    const getImageBinary = (aiImages) => {
        return aiImages.map(async (image) => {
            return fetch(image.imageURL)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(ErrorMessages.aiImageGenerationError);
                    }
                    return response.arrayBuffer();
                }).then(imageData => {
                    const dataTransfer = new DataTransfer();
                    const file = new File([imageData], image.name, { type: image.mimeType });
                    dataTransfer.items.add(file);
                    return { id: image.id, dataTransfer, imageURL: image.imageURL };
                }).catch(error => {
                    return AppConstants.nullText;
                });
        });
    };

    const fetchAiGeneratedImagesForCompetitions = async () => {
        try {
            const url = AppConstants.getAIBannerImagesAPI;
            setAiGeneratedImageSpinner(AppConstants.trueText);
            props.handleShowModal();
            const data = { ...props.payload };
            data.skillName = props.skills.filter(skill => data.skillName === skill.skillId)[0].skillName;

            const response = await AuthorizedDataService.postRequest(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response.ok) {
                setAiGeneratedImageSpinner(AppConstants.falseText);
                const body = await response.json();
                let responseArray = getImageBinary(body.data.s3Objects);
                if (responseArray) {
                    let imageData = await Promise.all(responseArray)
                    props.getAiImages(imageData);
                    props.setImageGeneratedArgs(props.payload);
                } else {
                    props.getAiImages(AppConstants.nullText);
                }
            } else {
                setAiGeneratedImageSpinner(AppConstants.falseText);
                props.getAiImages(AppConstants.nullText);
            }
        } catch (error) {
            setAiGeneratedImageSpinner(AppConstants.falseText);
            props.getAiImages(AppConstants.nullText);
        }
    };

    return (
        <div>
            <Button disabled={props.disabled || aiGeneratedImageSpinner} onClick={fetchAiGeneratedImagesForCompetitions}>Generate creatives using AI {aiGeneratedImageSpinner ? <BiLoaderAlt className="spinner" /> : <BsMagic />}</Button>
        </div>
    )
};
