import { useState, useEffect } from "react"
import { Row, Col, Button } from "react-bootstrap"
import classes from "../../editCompetition//EditJudges.module.css";
import { AddJudgesComponent } from "../../judgesComponent/AddJudgesComponent/AddJudgesComponent";
import { ErrorSuccessAlertMessage } from "../../errorSuccessMessage/ErrorSuccessAlertMessage";
import { ViewJudgeCard } from "../../cards/viewJudgesCard/ViewJudgeCard"
import { AppConstants } from "../../../constants/AppConstants";
import { ErrorMessages } from "../../../constants/ErrorMessages";

export const AddViewJudges = ({ competitionStatus, changeJudgesData, judgeDetails, rubricsConfigured, stage, saveCurrentStep }) => {
    const stages = AppConstants.createCompetitionActions;
    const [judges, setJudges] = useState([])
    const [emails, setEmails] = useState([]);
    const [emailError, setEmailError] = useState(AppConstants.emptyString);
    const [showDuplicateEmailError, setShowDuplicateEmailError] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowDuplicateEmailError(false);
        }, AppConstants.messageDisappearTime);

        return () => clearTimeout(timer);
    }, [showDuplicateEmailError]);

    useEffect(() => {
        const saveJudgesData = () => {
            changeJudgesData(judges);
        }
        saveJudgesData();
    }, [judges]);

    useEffect(() => {
        setJudges(judgeDetails);
        setEmails(judgeDetails.map(judge => judge.judgeEmail));
    }, [judgeDetails]);

    const addJudgeToList = (judge) => {
        if (!emails.some(emailId => emailId.toLowerCase() === judge.judgeEmail.toLowerCase())) {
            emails.push(judge.judgeEmail);
            setJudges([...judges, judge])
        } else {
            setShowDuplicateEmailError(true);
            setEmailError(ErrorMessages.judgeEmailAlreadyAdded);
        }
    };

    const removeJudgeFromList = (judgeToRemove) => {
        setJudges(judges.filter(judge => judge.judgeEmail !== judgeToRemove.judgeEmail));
        setEmails(emails.filter(id => id !== judgeToRemove.judgeEmail));
    };

    const handleSubmit = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    };

    return <div className={`${classes.addViewJudgesContainer}`}>
        <AddJudgesComponent competitionStatus={competitionStatus} addJudgeToList={addJudgeToList} rubricsConfigured={rubricsConfigured} />
        {showDuplicateEmailError && <div className={classes.alertMessageContainer}> <ErrorSuccessAlertMessage message={emailError} varient={AppConstants.alertVarient[1]} /></div>}
        {judges?.length > 0 && rubricsConfigured && <div className={classes.viewJudgeContainer}>
            <div className={classes.judgeHeadingText}>{`Judges (${judges.length})`}</div>
            <Row xs={1} sm={2} md={2} lg={2} xl={3} className="mb-2">
                {
                    judges?.map((judge, i) => <Col> <ViewJudgeCard
                        key={i} judgeDetails={judge}
                        removeJudge={removeJudgeFromList}
                        showDeleteButton={true}
                        showSendMailButton={false}
                    /></Col>)
                }
            </Row >
        </div>}
        <div className={classes.nextBtn}>
            <Button type="submit" onClick={handleSubmit}>{AppConstants.nextText}</Button>
        </div>
    </div>
}