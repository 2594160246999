import parse from 'html-react-parser';
import classes from './IndividualSkillsActivitiesSection.module.css';
import { IndividualSkillsActivities } from "./IndividualSkillsActivities";

export const IndividualSkillsActivitiesSection = (props) => {

    return (
        <>
            <div className={classes.activitiesSection}>
                <div className={classes.activitiesSectionContent}>
                    <div className={classes.activitiesContentSection}>
                        <h2 className={classes.heading}>{props.activitySectionStaticContent.bannerImage.title != null && parse(props.activitySectionStaticContent.bannerImage.title)} <span>{props.activitySectionStaticContent.bannerImage.title != null && props.skill}</span> {props.activitySectionStaticContent.bannerImage.subTitle != null && parse(props.activitySectionStaticContent.bannerImage.subTitle)}</h2>
                        {props.activitySectionStaticContent.bannerImage.description != null && <h2 className={classes.subHeading}>{parse(props.activitySectionStaticContent.bannerImage.description)}</h2>}
                        <IndividualSkillsActivities skillId={props.skillId} activitiesList={props.activitiesList} navigationSelectedActivity={props.navigationSelectedActivity} skill={props.skill} allActivitiesForSkill={props.allActivitiesForSkill} expertExistsForSkill={props.expertExistsForSkill} />
                    </div>
                </div>
            </div>
        </>
    );
}