import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import modalClass from "../cards/CompetitionsCards.module.css";
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from 'react-icons/ai';
import { AppConstants } from '../../constants/AppConstants';
import classes from "./PostStartEnrolmentModal.module.css";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { BiLoaderAlt } from 'react-icons/bi';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { EnrolInCourse } from '../studentPageComponents/EnrolInCourse';
import { EnrolInCompetition } from '../studentPageComponents/EnrolInCompetition';
import { EnrolInWorkshop } from '../studentPageComponents/EnrolInWorkshop';
import { StringUtils } from "../../utils/StringUtils.js";
import { SuccessMessage } from '../../constants/SuccessMessage';

export const PayOfflineConfirmationModal = ({ learningActivityDetails, getLearnerPaymentMethod }) => {
    const [offlinePaymentRequestedFlag, setOfflinePaymentRequestedFlag] = useState(AppConstants.falseText);
    const [offlinePaymentRequestGranted, setOfflinePaymentRequestGranted] = useState(AppConstants.falseText);
    const [offlinePaymentRequestError, setOfflinePaymentRequestError] = useState(AppConstants.falseText);
    const [offlinePaymentErrorMessage, setOfflinePaymentErrorMessage] = useState(AppConstants.emptyString);

    const getOfflinePaymentRequestStatus = (data) => {
        let message = AppConstants.emptyString;

        if (learningActivityDetails.type === AppConstants.learningActivitiesTypes.course) {
            message = EnrolInCourse.getEnrolmentStatus(data);
        } else if (learningActivityDetails.type === AppConstants.learningActivitiesTypes.competition) {
            message = EnrolInCompetition.getEnrolmentStatus(data);
        } else if (learningActivityDetails.type === AppConstants.learningActivitiesTypes.workshop) {
            message = EnrolInWorkshop.getEnrolmentStatus(data);
        }

        return (message === ErrorMessages.enrollmentFailError ? ErrorMessages.codRequestSendError : message);
    };

    const sendOfflineEnrolmentRequest = async () => {
        try {
            setOfflinePaymentRequestedFlag(AppConstants.trueText);
            const url = AppConstants.raisePayOfflineRequestAPI;
            const data = {
                learningActivityUniqueId: learningActivityDetails.uniqueId,
                learningActivityType: learningActivityDetails.type
            };

            const response = await AuthorizedDataService.postRequestWithResponseCode(
                url,
                data,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (!response) throw new Error(ErrorMessages.codRequestSendError);

            if (response.status === AppConstants.httpResponseCodes.responseCode403) {
                setOfflinePaymentErrorMessage(ErrorMessages.sessionExpiredCourseEnrolmentError);
                setOfflinePaymentRequestGranted(AppConstants.falseText);
                setOfflinePaymentRequestError(AppConstants.trueText);
                return;
            }

            const body = await response.json();
            if (body === 1) {
                setOfflinePaymentRequestGranted(AppConstants.trueText);
            } else {
                setOfflinePaymentErrorMessage(getOfflinePaymentRequestStatus(body));
                setOfflinePaymentRequestGranted(AppConstants.falseText);
                setOfflinePaymentRequestError(AppConstants.trueText);
            }
        } catch (error) {
            setOfflinePaymentErrorMessage(ErrorMessages.codRequestSendError)
            setOfflinePaymentRequestGranted(AppConstants.falseText);
            setOfflinePaymentRequestError(AppConstants.trueText);
        }
    };

    return (
        offlinePaymentRequestedFlag ? <Modal show={true} onHide={() => getLearnerPaymentMethod(AppConstants.falseText)} backdrop="static" keyboard={false} centered>
            <Modal.Body className={modalClass.modalBody}>
                {offlinePaymentRequestError && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                {offlinePaymentRequestGranted && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                <div className={modalClass.modalAlertText}>
                    {(offlinePaymentRequestGranted || offlinePaymentRequestError) && <p><b>{learningActivityDetails.id} | {learningActivityDetails.title}</b></p>}
                    {!offlinePaymentRequestError && !offlinePaymentRequestGranted && <div className={modalClass.modalSuccessIcon}><BiLoaderAlt className='spinner' /></div>}
                    {!offlinePaymentRequestError && !offlinePaymentRequestGranted && <p>Please wait while we send your enrolment request to the {learningActivityDetails.creatorRole?.toLowerCase()}.</p>}
                    {offlinePaymentRequestGranted && <p>{StringUtils.getParsedMessage(SuccessMessage.offlinePaymentRequestSentSuccess, { creatorName: learningActivityDetails.creatorName, creatorRole: learningActivityDetails.creatorRole?.toLowerCase() })}</p>}
                    {offlinePaymentRequestError && <p>{offlinePaymentErrorMessage}</p>}
                </div>
            </Modal.Body>
            {(offlinePaymentRequestGranted || offlinePaymentRequestError) && <Modal.Footer className={modalClass.modalFooter}>
                {<Button className={classes.modalCloseButton} onClick={() => getLearnerPaymentMethod(AppConstants.emptyString, learningActivityDetails.type)}>Close</Button>}
            </Modal.Footer>}
        </Modal > :
            <Modal show={true} onHide={() => getLearnerPaymentMethod(AppConstants.falseText)} backdrop="static" keyboard={false} centered>
                <Modal.Body className={modalClass.modalBody}>
                    {<div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>
                        <p><b>{learningActivityDetails.id} | {learningActivityDetails.title}</b></p>
                        <p className='mt-2'>Please select your payment method</p>
                    </div>
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    {<Button className={classes.offlinePaymentButton} onClick={() => getLearnerPaymentMethod(AppConstants.paymentMethod.online, learningActivityDetails.type)}>Pay Now</Button>}
                    {<Button className={classes.modalYesButton} onClick={sendOfflineEnrolmentRequest}>Pay Offline</Button>}
                    {<Button className={classes.modalNoButton} onClick={() => getLearnerPaymentMethod(AppConstants.emptyString, learningActivityDetails.type)}>Cancel</Button>}
                </Modal.Footer>
            </Modal>
    );
}