import React, { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { AppConstants } from '../../constants/AppConstants';
import { DataService } from '../../services/DataService';
import expertMicrositeLayout from './ExpertMicrositeLayout.module.css';
import { BiLoaderAlt } from 'react-icons/bi';
import { MicrositePageBanner } from "./MicrositePageBanner";
import { MicrositePageLeftSection } from "./MicrositePageLeftSection";
import { MicrositePageRightSection } from "./MicrositePageRightSection";
import { AgencyMicrositePageLeftSection } from "../agencyMicrosite/AgencyMicrositePageLeftSection";
import { AgencyMicrositePageRightSection } from "../agencyMicrosite/AgencyMicrositePageRightSection";
import { ShareLinkToSocialMediaUtil } from "../../utils/ShareLinkToSocialMediaUtil";

export const ExpertMicrositeLayout = () => {
    const { micrositeSlug } = useParams();
    const location = useLocation();
    const pathnames = location.pathname.split(AppConstants.forwardSlash).filter((x) => x);
    const userRoleMicrosite = pathnames[AppConstants.zeroIndex];
    const navigate = useNavigate();
    const [micrositeDetails, setMicrositeDetails] = useState([]);
    const [micrositeDetailsLoader, setMicrositeDetailsLoader] = useState(AppConstants.trueText);
    const linkToShare = (userRoleMicrosite === AppConstants.expertText) ? `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.expertText}${AppConstants.forwardSlash}${micrositeSlug}` : `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.agency}${AppConstants.forwardSlash}${micrositeSlug}`;
    const [userRole, setUserRole] = useState(AppConstants.emptyString);
    const [creatorGalleryContent, setCreatorGalleryContent] = useState([]);

    useEffect(() => {
        //fetch microsite details
        async function getExpertMicrositeDetails() {
            setMicrositeDetailsLoader(AppConstants.trueText);
            let url = AppConstants.emptyString;
            setUserRole(userRoleMicrosite);

            if (userRoleMicrosite?.toLocaleLowerCase() === AppConstants.userRoleAgency.toLocaleLowerCase()) {
                url = `${AppConstants.getAgencyMicrositeDetailsAPI}${micrositeSlug}`;
            } else if (userRoleMicrosite?.toLocaleLowerCase() === AppConstants.userRoleExpert.toLocaleLowerCase()) {
                url = `${AppConstants.getExpertMicrositeDetailsAPI}${micrositeSlug}`;
            } else {
                navigate(AppConstants.asterisk);
            }

            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                if (response.galleryMediaPaths?.length > 0) {
                    setCreatorGalleryContent(response.galleryMediaPaths.split(",")?.map(value => ({
                        url: value,
                        type: AppConstants.supportedImageFormats.includes(AppConstants.contentTypeMap[value.split('.').pop()]) ? AppConstants.mediaTypes.image : AppConstants.mediaTypes.video
                    })));
                }
                setMicrositeDetails(response);
                setMicrositeDetailsLoader(AppConstants.falseText);
            } else {
                setMicrositeDetails([]);
                setMicrositeDetailsLoader(AppConstants.falseText);
                navigate(AppConstants.asterisk);
            }
        };
        getExpertMicrositeDetails();
    }, []);


    return (
        <>
            {!micrositeDetailsLoader && micrositeDetails.length !== AppConstants.zeroIndex && <MicrositePageBanner userRole={userRole} micrositeBannerData={micrositeDetails} />}
            {!micrositeDetailsLoader && micrositeDetails.length !== AppConstants.zeroIndex && <div className={expertMicrositeLayout.shareIconPannelContainer}>
                <ShareLinkToSocialMediaUtil className={expertMicrositeLayout} linkToShare={linkToShare} />
            </div>}
            {micrositeDetailsLoader && <div className={expertMicrositeLayout.errorContainer}>
                <p className="loaderIcon"><BiLoaderAlt className="spinner" /></p>
            </div>}
            {!micrositeDetailsLoader && micrositeDetails.length !== AppConstants.zeroIndex &&
                <div className={`row mb-3 ${expertMicrositeLayout.micorsiteContainer}`}>
                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                        {userRole === AppConstants.userRoleExpert.toLocaleLowerCase() && <MicrositePageLeftSection micrositeData={micrositeDetails} creatorGalleryContent={creatorGalleryContent} />}
                        {userRole === AppConstants.userRoleAgency.toLocaleLowerCase() && <AgencyMicrositePageLeftSection micrositeData={micrositeDetails} creatorGalleryContent={creatorGalleryContent} />}
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                        {userRole === AppConstants.userRoleExpert.toLocaleLowerCase() && <MicrositePageRightSection micrositeData={micrositeDetails} />}
                        {userRole === AppConstants.userRoleAgency.toLocaleLowerCase() && <AgencyMicrositePageRightSection micrositeData={micrositeDetails} />}
                    </div>
                </div>}
        </>
    );

}