import { Button, Card, Col, Row } from "react-bootstrap";
import Moment from "react-moment";
import { AppConstants } from "../../../constants/AppConstants";
import { MdOpenInNew } from "react-icons/md";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { TiArrowLeft } from "react-icons/ti";
import { AgGridReact } from "ag-grid-react";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { useEffect, useState } from "react";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { BiLoaderAlt } from "react-icons/bi";
import parse from 'html-react-parser';
import cardClass from "../../adminDashboardComponents/DashboardCards.module.css";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import nudgeStyles from "../../../common/NudgesDetailsSectionStyle.module.css";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import classes from "./LearningActivityDetailsComponent.module.css";
import { ErrorSuccessAlertMessage } from "../../errorSuccessMessage/ErrorSuccessAlertMessage";
import moment from "moment";
import { MathUtil } from "../../../utils/MathUtil";

export const LearningActivityDetailsComponent = ({ learningActivityDetails, learningActivityType, hideLearningActivityDetailsSection, uniqueId }) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [downloadSpinner, setDownloadSpinner] = useState(AppConstants.falseText);
    const [downloadSheetError, setDownloadSheetError] = useState(AppConstants.emptyString);
    const [nudgeResponses, setNudgeResponses] = useState([]);

    const getSubscriptionStatusClass = ({ value }) => {
        if (value === AppConstants.subscriptionStatus.active) {
            return `${classes.greenText}`;
        } else if (value === AppConstants.subscriptionStatus.expired) {
            return `${classes.redText}`;
        }
    };

    const enrolledStudentDetails = [
        {
            headerName: `Name`,
            field: "studentName",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.studentName}`,
            valueGetter: (params) => {
                return `${params.data.studentName}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: "Age",
            field: "studentAge",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.studentAge}`,
            valueGetter: (params) => {
                return params.data.studentAge;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Email Id`,
            field: "studentEmail",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.studentEmail}`,
            valueGetter: (params) => {
                return `${params.data.studentEmail}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Phone Number`,
            field: "studentPhoneNumber",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.studentPhoneNumber}`,
            valueGetter: (params) => {
                return `${params.data.studentPhoneNumber}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Enroled On`,
            field: "enrolmentDate",
            width: 300,
            tooltipValueGetter: ({ data }) => `${moment.utc(data.enrolmentDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.enrolmentDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`
            },
            filter: 'agTextColumnFilter'
        },
    ];

    const classBasedColumnColumnDefs = [
        {
            headerName: `Availed Classes`,
            field: "availedClasses",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.availedClasses}`,
            valueGetter: (params) => {
                return `${params.data.availedClasses}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Remaining Classes`,
            field: "remainingClasses",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.remainingClasses}`,
            valueGetter: (params) => {
                return `${params.data.remainingClasses}`;
            },
            filter: 'agTextColumnFilter',
        },
        {
            headerName: "Validity",
            field: "subscriptionEndDate",
            maxWidth: 120,
            tooltipValueGetter: ({ data }) => data.subscriptionEndDate ? `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : "N/A",
            valueGetter: ({ data }) => {
                return data.subscriptionEndDate ? `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}` : "N/A";
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
        },
        {
            headerName: `Subscription Status`,
            field: "subscriptionStatus",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.subscriptionStatus}`,
            valueGetter: (params) => {
                return `${params.data.subscriptionStatus}`;
            },
            filter: 'agTextColumnFilter',
            cellClass: getSubscriptionStatusClass
        },
    ];

    const durationBasedBasedColumnColumnDefs = [
        {
            headerName: "Subscription Start Date",
            field: "subscriptionStartDate",
            maxWidth: 120,
            tooltipValueGetter: ({ data }) => `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.subscriptionStartDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`;
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
        },
        {
            headerName: "Subscription End Date",
            field: "subscriptionEndDate",
            maxWidth: 120,
            tooltipValueGetter: ({ data }) => `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.subscriptionEndDate).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`;
            },
            filter: 'agTextColumnFilter',
            headerClass: 'multi-line-header',
        },
        {
            headerName: `Subscription Status`,
            field: "subscriptionStatus",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.subscriptionStatus}`,
            valueGetter: (params) => {
                return `${params.data.subscriptionStatus}`;
            },
            filter: 'agTextColumnFilter',
            cellClass: getSubscriptionStatusClass
        },
    ];

    useEffect(() => {
        const fetchNudgeResponse = async () => {
            const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
            const url = `${AppConstants.getExpertResponsesForNudgeAPI}${uniqueId}`;
            const requestHeaders = {
                preferredTimezone: timeZone
            }
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString,
                requestHeaders
            );
            if (response !== undefined) {
                setNudgeResponses(response);
            } else {
                setNudgeResponses([]);
            }
        }
        if (learningActivityType === AppConstants.learningActivitiesTypes.nudge) {
            fetchNudgeResponse()
        }
    }, [uniqueId, learningActivityType]);

    const downloadStudentList = async () => {
        setDownloadSpinner(AppConstants.trueText);
        const url = `${AppConstants.downloadEnrolledStudentsForAdminAPI}${learningActivityType}&learningActivityUniqueId=${uniqueId}`;
        const name = `${learningActivityDetails.learningActivityTitle}${AppConstants.hyphen}${AppConstants.registeredParticipantsFileText}`
        const fileName = name.replace(/\s+/g, AppConstants.hyphen);
        const response = await FileOperationsUtil.downloadFile(
            url,
            fileName,
            AppConstants.emptyString,
            AppConstants.emptyString,
            { preferredTimezone: DateTimeUtil.getPreferredTimeZoneForUser() }
        );
        if (response !== undefined) {
            if (response.ok) {
                setDownloadSpinner(AppConstants.falseText);
            } else {
                setDownloadSpinner(AppConstants.falseText);
                setDownloadSheetError(`${ErrorMessages.fileDownloadError}${AppConstants.participantsListText}${ErrorMessages.fileDownloadErrorPartTwo}`);
                let timer = setTimeout(() => {
                    setDownloadSheetError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return () => { clearTimeout(timer) };
            }
        } else {
            setDownloadSpinner(AppConstants.falseText);
            setDownloadSheetError(`${ErrorMessages.fileDownloadError}${AppConstants.participantsListText}${ErrorMessages.fileDownloadErrorPartTwo}`);
            let timer = setTimeout(() => {
                setDownloadSheetError(AppConstants.emptyString);
            }, AppConstants.messageDisappearTime);
            return () => { clearTimeout(timer) };
        }
    };

    const getColumnDefs = () => {
        if (learningActivityType === AppConstants.learningActivitiesTypes.course) {
            return learningActivityDetails.courseType === AppConstants.flexibleCourseType ? (learningActivityDetails.trackingType === AppConstants.classBasedTrackingText ? [...enrolledStudentDetails, ...classBasedColumnColumnDefs] : [...enrolledStudentDetails, ...durationBasedBasedColumnColumnDefs]) : enrolledStudentDetails;
        } else {
            return enrolledStudentDetails;
        }
    }

    const getWeekTimings = (value) => {
        let options = AppConstants.weekDaysOptions;
        let arr = value.split(AppConstants.comma);
        if (arr.length === 7) {
            return AppConstants.allDaysOfWeekText;
        } else {
            let dayString = AppConstants.emptyString;
            options.forEach(opt => {
                if (value.includes(opt.name)) dayString += `${opt.key}, `
            })
            return `On ${dayString.replace(/,\s*$/, AppConstants.emptyString)}`
        }
    };

    return (
        <div className={classes.dashboardContainer}>
            <TiArrowLeft onClick={hideLearningActivityDetailsSection} className={classes.obrBackButton} />
            {learningActivityType !== AppConstants.learningActivitiesTypes.nudge && <>
                <div className={cardClass.dashboardCards}>
                    <Card className={`${cardClass.countCards} ${cardClass.enrolmentCard}`}>
                        <Card.Body>
                            <Card.Title className={cardClass.cardTitle}>Enrolments</Card.Title>
                            <Card.Text className={`${cardClass.cardCount} ${cardClass.enrolmentCount}`}>{MathUtil.formatNumberToLocaleString(learningActivityDetails.totalEnrolments)}</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${cardClass.countCards} ${cardClass.costCard}`}>
                        <Card.Body>
                            <Card.Title className={cardClass.cardTitle}>Cost</Card.Title>
                            <Card.Text className={`${cardClass.cardCount} ${cardClass.absentCount}`}>{!learningActivityDetails.learningActivityCost ? AppConstants.freeText : MathUtil.formatNumberToLocaleString(learningActivityDetails.learningActivityCost)}</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${cardClass.countCards} ${cardClass.feeCollectedCard}`}>
                        <Card.Body>
                            <Card.Title className={cardClass.cardTitle}>Fee Collected</Card.Title>
                            <Card.Text className={`${cardClass.cardCount} ${cardClass.absentCount}`}>{MathUtil.formatNumberToLocaleString(learningActivityDetails.totalFeeCollected)}</Card.Text>
                        </Card.Body>
                    </Card>
                    {learningActivityType === AppConstants.learningActivitiesTypes.competition && <Card className={`${cardClass.countCards} ${cardClass.attemptCard}`}>
                        <Card.Body>
                            <Card.Title className={cardClass.cardTitle}>Participation</Card.Title>
                            {(learningActivityDetails.isChallengeAdded === AppConstants.yText && learningActivityDetails.learningActivityStatus !== AppConstants.competitionStatus.upcoming) && <Card.Text className={`${cardClass.cardCount} ${cardClass.attemptCount}`}>{MathUtil.formatNumberToLocaleString(learningActivityDetails.totalAttempt)}</Card.Text>}
                            {(learningActivityDetails.isChallengeAdded === AppConstants.nText || learningActivityDetails.learningActivityStatus === AppConstants.competitionStatus.upcoming) && <Card.Text className={`${cardClass.cardCount} ${cardClass.attemptCount}`}>{AppConstants.notAvailableText}</Card.Text>}
                        </Card.Body>
                    </Card>}
                    {learningActivityType === AppConstants.learningActivitiesTypes.competition && <Card className={`${classes.countCards} ${classes.submissionCard}`}>
                        <Card.Body>
                            <Card.Title className={cardClass.cardTitle}>Submissions</Card.Title>
                            {(learningActivityDetails.allowSubmission === AppConstants.yText && learningActivityDetails.learningActivityStatus !== AppConstants.competitionStatus.upcoming) && <Card.Text className={`${cardClass.cardCount} ${cardClass.submissionCount}`}>{MathUtil.formatNumberToLocaleString(learningActivityDetails.totalSubmission)}</Card.Text>}
                            {(learningActivityDetails.allowSubmission === AppConstants.nText || learningActivityDetails.learningActivityStatus === AppConstants.competitionStatus.upcoming) && <Card.Text className={`${cardClass.cardCount} ${cardClass.notAttemptCount}`}>{AppConstants.notAvailableText}</Card.Text>}
                        </Card.Body>
                    </Card>}
                </div>

                <h6 className='mt-5'>{learningActivityType} Details:</h6>
                <hr />
                <Row className={classes.expertObrRowLeftBorder}>
                    <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Title</p>
                            <p className={classes.detailsText}>{learningActivityDetails.learningActivityTitle}</p>
                        </div>
                    </Col>
                    {<Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>{learningActivityType} Page</p>
                            <a className={classes.detailsLinkLabel} target={AppConstants.openInNewTab} href={`${process.env.REACT_APP_FRONT_END_URL}${learningActivityDetails.learningActivityPageLink}`}>Click Here <MdOpenInNew /></a>
                        </div>
                    </Col>}
                    {<Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Status</p>
                            <p className={classes.detailsText}>{learningActivityDetails.learningActivityStatus}</p>
                        </div>
                    </Col>}
                    {learningActivityType === AppConstants.learningActivitiesTypes.course && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Course Type</p>
                            <p className={classes.detailsText}>{learningActivityDetails.courseType}</p>
                        </div>
                    </Col>}
                    <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Publish Date</p>
                            <p className={classes.detailsText}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{learningActivityDetails.dateOfPublish?.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{learningActivityDetails.dateOfPublish?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${AppConstants.utcTimezone})`}</p>
                        </div>
                    </Col>
                    {learningActivityDetails?.registrationClosingDate && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Registration Deadline</p>
                            <p className={classes.detailsText}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{learningActivityDetails.registrationClosingDate?.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{learningActivityDetails.registrationClosingDate?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${AppConstants.utcTimezone})`}</p>
                        </div>
                    </Col>}
                    {!(learningActivityType === AppConstants.learningActivitiesTypes.course && learningActivityDetails.courseType === AppConstants.flexibleCourseType) && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Start Time</p>
                            {learningActivityType !== AppConstants.learningActivitiesTypes.course && <p className={classes.detailsText}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{learningActivityDetails.startDate?.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{learningActivityDetails.startDate?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${AppConstants.utcTimezone})`}</p>}
                            {learningActivityType === AppConstants.learningActivitiesTypes.course && <p className={classes.detailsText}>{learningActivityDetails.startDate} {timeZone === AppConstants.utcTimezone && `(${AppConstants.utcTimezone})`}</p>}
                        </div>
                    </Col>}
                    {!(learningActivityType === AppConstants.learningActivitiesTypes.course && learningActivityDetails.courseType === AppConstants.flexibleCourseType) && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>End Time</p>
                            {learningActivityType !== AppConstants.learningActivitiesTypes.course && <p className={classes.detailsText}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{learningActivityDetails.endDate?.replace(' ', 'T') + 'Z'}</Moment>, <Moment format="DD MMM YYYY" tz={timeZone}>{learningActivityDetails.endDate?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${AppConstants.utcTimezone})`}</p>}
                            {learningActivityType === AppConstants.learningActivitiesTypes.course && <p className={classes.detailsText}>{learningActivityDetails.endDate} {timeZone === AppConstants.utcTimezone && `(${AppConstants.utcTimezone})`}</p>}

                        </div>
                    </Col>}
                    {(learningActivityType === AppConstants.learningActivitiesTypes.course && learningActivityDetails.courseType === AppConstants.flexibleCourseType) && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Class Timing</p>
                            <p className={classes.detailsText}>{learningActivityDetails.startDate} - {learningActivityDetails.endDate}</p>
                        </div>
                    </Col>}

                    {learningActivityType === AppConstants.learningActivitiesTypes.course && learningActivityDetails.weekDays && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Weekdays</p>
                            <p className={classes.detailsText}>{getWeekTimings(learningActivityDetails.weekDays)}</p>
                        </div>
                    </Col>}
                    <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Mode</p>
                            <p className={classes.detailsText}>{learningActivityDetails.learningActivityMode}</p>
                        </div>
                    </Col>
                    {learningActivityType === AppConstants.learningActivitiesTypes.competition && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Judging Enabled</p>
                            <p className={classes.detailsText}>{learningActivityDetails.isJudgingEnabled === AppConstants.yText ? "Yes" : "No"}</p>
                        </div>
                    </Col>}
                    {learningActivityType === AppConstants.learningActivitiesTypes.course && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Tracking</p>
                            <p className={classes.detailsText}>{learningActivityDetails.trackingType}</p>
                        </div>
                    </Col>}
                    {learningActivityType === AppConstants.learningActivitiesTypes.course && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Attendance Enabled</p>
                            <p className={classes.detailsText}>{learningActivityDetails.isAttendanceEnabled === AppConstants.yText ? "Yes" : "No"}</p>
                        </div>
                    </Col>}
                    {learningActivityType === AppConstants.learningActivitiesTypes.course && learningActivityDetails.trackingType === AppConstants.durationBasedTrackingText && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Duration</p>
                            <p className={classes.detailsText}>{learningActivityDetails.packageDuration} {learningActivityDetails.packageDuration > 1 ? "Days" : "Day"}</p>
                        </div>
                    </Col>}
                    {learningActivityType === AppConstants.learningActivitiesTypes.course && learningActivityDetails.trackingType === AppConstants.classBasedTrackingText && learningActivityDetails.courseType === AppConstants.flexibleCourseType && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Number of Classes</p>
                            <p className={classes.detailsText}>{learningActivityDetails.numberOfClasses} {learningActivityDetails.numberOfClasses > 1 ? "Classes" : "Class"}</p>
                        </div>
                    </Col>}
                    {learningActivityType === AppConstants.learningActivitiesTypes.course && learningActivityDetails.trackingType === AppConstants.classBasedTrackingText && learningActivityDetails.courseType === AppConstants.flexibleCourseType && learningActivityDetails.validity > 0 && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Class Validity</p>
                            <p className={classes.detailsText}>{learningActivityDetails.validity} {learningActivityDetails.validity > 1 ? "Days" : "Day"}</p>
                        </div>
                    </Col>}

                    <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Offline Payment Enabled</p>
                            <p className={classes.detailsText}>{learningActivityDetails.paymentOfflineFlag === AppConstants.yText ? "Yes" : "No"}</p>
                        </div>
                    </Col>
                    {learningActivityType === AppConstants.learningActivitiesTypes.course && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Enrolments Enabled</p>
                            <p className={classes.detailsText}>{learningActivityDetails.enrolmentDisableFlag === AppConstants.yText ? "No" : "Yes"}</p>
                        </div>
                    </Col>}
                    {<Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Skill</p>
                            <p className={classes.detailsText}>{learningActivityDetails.skill}</p>

                        </div>
                    </Col>}
                    {<Col xs={12} sm={6} md={4} lg={4} xl={4}>
                        <div className={classes.detailsColumContainer}>
                            <p className={classes.detailsLabel}>Creator Name</p>
                            <p className={classes.detailsText}><a className={classes.creatorBrandingPageLink} target={AppConstants.openInNewTab} href={`${process.env.REACT_APP_FRONT_END_URL}${learningActivityDetails.creatorBrandingPageLink}`}>{learningActivityDetails.creatorUserRole === AppConstants.userRoleExpert ? learningActivityDetails.creatorName : learningActivityDetails.agencyName} <MdOpenInNew /></a></p>
                        </div>
                    </Col>}
                </Row>
                <div className={classes.studentListSection}>
                    <div className={classes.enrolledStudentHeading}>Enroled Learner Details:</div>
                    <Row className="mb-1">
                        < Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div className="ag-theme-alpine">
                                <AgGridReact
                                    className="expertObrDetailsGrid"
                                    columnDefs={getColumnDefs()}
                                    pagination={true}
                                    paginationPageSize={AppConstants.adminDashboardPaginationSize}
                                    rowData={learningActivityDetails.enrolledStudents?.length > 0 ? learningActivityDetails.enrolledStudents : []}
                                    enableBrowserTooltips={true}
                                    domLayout='autoHeight'
                                    suppressRowHoverHighlight={true}
                                    defaultColDef={{
                                        filter: true,
                                        sortable: true,
                                        flex: 1,
                                        minWidth: 100,
                                        resizable: true,
                                        suppressMenu: true,
                                        suppressHorizontalScroll: true,
                                        floatingFilterComponentParams: { suppressFilterButton: true },
                                        floatingFilter: true,
                                    }}
                                    enableFilter={true}
                                    enableSorting={true}
                                    enableSearching={true}
                                    headerClass={classes.obrGridHeader}
                                    rowClass={classes.obrGridTable}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div>
                            {learningActivityDetails.enrolledStudents?.length > 0 && <Button className={`${classes.selectButton} mt-2`} onClick={downloadStudentList}>Download {downloadSpinner && <BiLoaderAlt className="spinner" />}</Button>}
                        </div>
                        {downloadSheetError && <ErrorSuccessAlertMessage message={downloadSheetError} varient={AppConstants.alertVarient[1]} />}
                    </Row>
                </div>
            </>}
            {
                learningActivityType === AppConstants.learningActivitiesTypes.nudge && <>
                    <h6 className='mt-5'>{learningActivityType} Details:</h6>
                    <hr />
                    <Row className={classes.expertObrRowLeftBorder}>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>SKill</p>
                                <p className={classes.detailsText}>{learningActivityDetails.skill}</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>Nudge Raised On</p>
                                <p className={classes.detailsText}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{learningActivityDetails.nudgeRaisedOn?.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{learningActivityDetails.nudgeRaisedOn?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${AppConstants.utcTimezone})`}</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>Nudge Closed On</p>
                                {learningActivityDetails.nudgeClosureDate && <p className={classes.detailsText}><Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{learningActivityDetails.nudgeClosureDate?.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{learningActivityDetails.nudgeClosureDate?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${AppConstants.utcTimezone})`}</p>}
                                {!learningActivityDetails.nudgeClosureDate && <p className={classes.detailsText}>{AppConstants.notAvailableText}</p>}
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>Learner Name</p>
                                <p className={classes.detailsText}>{learningActivityDetails.learnerName}</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>Raised to</p>
                                <p className={classes.detailsText}>{learningActivityDetails.assignToSpecificExpertFlag === AppConstants.nText ? "Raised to All" : learningActivityDetails.creatorName}</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <p className={classes.detailsLabel}>Status</p>
                                <p className={classes.detailsText}>{learningActivityDetails.learningActivityStatus}</p>
                            </div>
                        </Col>
                        {learningActivityDetails !== AppConstants.nullText && learningActivityDetails.studentNudgeFilePath !== AppConstants.nullText && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                            <div className={classes.detailsColumContainer}>
                                <div className={classes.detailsLabel}>View Work</div>
                                <a className={classes.detailsLinkLabel} target={AppConstants.openInNewTab} href={learningActivityDetails.studentNudgeFilePath}>Click Here <MdOpenInNew /></a>
                            </div>
                        </Col>}
                        <Col xs={12} sm={6} md={12} lg={12} xl={12}>
                            <div className={classes.detailsColumContainer}>
                                <div className={classes.detailsLabel}>Question</div>
                                <div className={classes.detailsText}>{learningActivityDetails.nudgeQuestion}</div>
                            </div>
                        </Col>
                        <Col>
                            <div className={classes.detailsColumContainer}>
                                <div className={classes.detailsLabel}>Nudge Description</div>
                                <div className={classes.detailsText}>{learningActivityDetails?.nudgeDescription ? parse(learningActivityDetails?.nudgeDescription) : ""}</div>
                            </div>
                        </Col>

                        <h6 className='mt-5'>Nudge Responses:</h6>
                        {nudgeResponses.length !== 0 && <div className={classes.nudgeResponseSection}>
                            {nudgeResponses.map((expert, index) => (<div className={nudgeStyles.previousResponsesContainer}>
                                <div className={nudgeStyles.expertNameContainer}>
                                    <div key={index} className={nudgeStyles.profilePicMainCnt}>
                                        <div className={nudgeStyles.profilePicPreviewCnt}>
                                            {expert !== AppConstants.nullText && expert.expertProfileImage !== AppConstants.nullText && <a className={nudgeStyles.linkText} target={AppConstants.openInNewTab} href={`/${expert.expertBrandingPageLink}`}><img alt='Profile Avtar' className={nudgeStyles.previewProfilePicOnModal} src={expert !== AppConstants.nullText && expert.expertProfileImage}></img></a>}
                                            {expert !== AppConstants.nullText && expert.expertProfileImage === AppConstants.nullText && <a className={nudgeStyles.linkText} target={AppConstants.openInNewTab} href={`/${expert.expertBrandingPageLink}`}><p className={nudgeStyles.initialProfilePreviewOnModal}>{expert !== AppConstants.nullText && expert.expertInitials}</p></a>}
                                        </div>
                                        <div className={nudgeStyles.profilePicPreviewDetails}>
                                            <a className={nudgeStyles.linkText} target={AppConstants.openInNewTab} href={`/${expert.expertBrandingPageLink}`}><p className={nudgeStyles.expertName}>{expert !== AppConstants.nullText && expert.expertName}</p></a>
                                        </div>
                                    </div>
                                    {expert.rating !== null && <div>
                                        {AppConstants.rateNudge5starArray.map((value) => (
                                            <span
                                                key={value}
                                                className={nudgeStyles.ratingStars}>
                                                {value <= expert.rating.substr(0, 1) ? <AiFillStar /> : <AiOutlineStar />}
                                            </span>
                                        ))}
                                    </div>}
                                </div>
                                <div>
                                    {expert.expertResponses.map((response, index) => (
                                        <div key={index}>
                                            <div className={nudgeStyles.responseCardContainer}>
                                                <div className={`${nudgeStyles.responseCard} ql-editor`}>{parse(response.response)}
                                                    {response.nudgeResponseFilePath !== null && <Button href={response.nudgeResponseFilePath} className={nudgeStyles.showExpertFileButton}>{AppConstants.viewExpertNudgeAttachment}</Button>}
                                                </div>
                                                <p className={nudgeStyles.responseDateAndTime}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{response.respondedOn.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${AppConstants.utcTimezone})`}</p>
                                            </div>
                                        </div>))}
                                </div>
                            </div>))}
                        </div>}
                        {!nudgeResponses.length && <div className="mt-2">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <p className={nudgeStyles.fallBackMessage}>No Responses</p>
                                </Col>
                            </Row>
                        </div>}
                    </Row>
                </>
            }
        </div>
    )
}