import { useState, useEffect } from 'react';
import { AppConstants } from '../../constants/AppConstants';
import classes from './ViewSelectedWeekDays.module.css';

export const ViewSelectedWeekDays = (props) => {
    const { selectedValues } = props;
    const weekDays = AppConstants.weekDaysOptions;
    const [selectedDays, setSelectedDays] = useState([]);
    const [activeDays, setActiveDays] = useState(new Array(7).fill(false));

    useEffect(() => {
        let activeArr = new Array(7).fill(false);
        let daysArr = [];

        weekDays.forEach((day, i) => {
            if (selectedValues.includes(day.name)) {
                activeArr[i] = true;
                daysArr.push(day.name)
            }
        });

        setActiveDays(activeArr);
        setSelectedDays(daysArr);
    }, []);

    return (
        <div className={classes.container}>
            {
                weekDays.map((day, i) => <div
                    className={`${classes.day} ${activeDays[i] && classes.activeDay}`} key={day.key}>
                    {day.key}
                </div>)
            }
        </div>
    )
}