import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { AppConstants } from "../../constants/AppConstants";
import { DataService } from '../../services/DataService';
import { useNavigate } from "react-router-dom";
import { BiLoaderAlt } from 'react-icons/bi';
import Moment from 'react-moment';
import 'moment-timezone';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import expertViewJudingScreen from "./ExpertViewJudgingScreen.module.css";
import { ExpertJudgingScreenBanner } from './ExpertJudgingScreenBanner';
import "../expertManageCompetitionComponent/dataGrid.css";
import { MathUtil } from "../../utils/MathUtil";

export const ExpertViewJudgingScreen = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const { competitionSlug, judgeUniqueId } = useParams();
    const [judgingEnabledFlag, setJudgingEnabledFlag] = useState();
    const [judgingPageLoader, setJudgingPageLoader] = useState(true);
    const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
    const [judgeDetails, setJudgeDetails] = useState();
    const [competitionDetails, setCompetitionDetails] = useState();
    const [competitionParticipants, setCompetitionParticipants] = useState();
    const [judgedCount, setJudgedCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const navigate = useNavigate();
    const [judgingGridWidth, setJudgingGridWidth] = useState();
    const gridRef = useRef(null);

    useEffect(() => {
        async function getCompetitionInformation() {
            const url = `${AppConstants.getCompetitionSummaryAPI}${competitionSlug}${AppConstants.getCompetitionSummaryAPIPart2}${timeZone}`;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined && response !== null) {
                setCompetitionDetails(response);
            }
            else {
                navigate(errorPageUrl);
            }
        };
        getCompetitionInformation();
    }, []);

    useEffect(() => {
        if (competitionDetails != undefined) {
            async function getJudgingEnabledFlag() {
                setJudgingPageLoader(true);
                const url = `${AppConstants.getJudgingEnabledFlagAPI}${competitionDetails.competitionId}`;
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setJudgingEnabledFlag(response);
                    setJudgingPageLoader(false);
                    if (response === false) {
                        navigate(errorPageUrl);
                    }
                }
                else {
                    setJudgingEnabledFlag(false);
                    setJudgingPageLoader(false);
                    navigate(errorPageUrl);
                }
            };
            getJudgingEnabledFlag();

            async function getJudgeInformation() {
                const url = `${AppConstants.getJudgeDetailsAPI}${judgeUniqueId}${AppConstants.getJudgeDetailsAPISecondParam}${competitionDetails.competitionId}`;
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined && response !== null) {
                    setJudgeDetails(response);
                }
                else {
                    navigate(errorPageUrl);
                }

            };
            getJudgeInformation();

            async function getCompetitionParticipantsInformation() {
                const url = `${AppConstants.getParticipantsInCompetitionAPIPartOne}${competitionDetails.competitionId}${AppConstants.getParticipantsInCompetitionAPIPartTwo}${judgeUniqueId}`;
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                setCompetitionParticipants(response.sort((a, b) => {
                    return a.solutionSubmissionFlag === "Y" ? -1 : 1; // Trending skills first
                }));
                setJudgedCount(response.filter(item => item.competitionMarkedFlag === AppConstants.doneText).length);
                setTotalCount(response.length);
            };
            getCompetitionParticipantsInformation();
        }

    }, [competitionDetails]);


    useEffect(() => {
        const getGridWidth = () => {
            if (gridRef.current) {
                const gridWidth = gridRef.current.offsetWidth;
                setJudgingGridWidth(gridWidth);
            }
            defineMarkingTable();
        }
        getGridWidth();
    }, [competitionDetails]);

    const getWidth = (percentageWidth) => {
        return Math.floor((percentageWidth * judgingGridWidth) / 100);
    };


    //resize columns above 1400px
    const onGridReady = useCallback((params) => {
        if (window.innerWidth >= 1400 && gridRef.current) {
            params.api.sizeColumnsToFit();
        }
    }, []);

    const [columnDefsAllParticipantsGrid, setColumnDefsAllParticipantsGrid] = useState([]);


    const defineMarkingTable = () => {
        setColumnDefsAllParticipantsGrid([
            {
                headerName: "S. No",
                valueGetter: "node.rowIndex + 1",
                maxWidth: 70
            },
            { field: 'participantQrenciaId', tooltipField: 'participantQrenciaId', headerName: 'Qrencia ID', suppressMovable: true, width: 200 },
            { field: 'participantName', tooltipField: 'participantName', headerName: 'Name', suppressMovable: true },
            {
                field: 'marks', width: 200, tooltipValueGetter: function getValue(params) { return `${params.data.marksAllotted}/${params.data.competitionTotalMarks}`; },
                headerName: 'Marks', valueGetter:
                    function getValue(params) {
                        return `${params.data.marksAllotted}/${params.data.competitionTotalMarks}`;
                    }, suppressMovable: true
            },
            {
                field: 'competitionMarkedFlag', width: 200, tooltipField: 'competitionMarkedFlag', headerName: 'Judging Status', cellStyle: params => {
                    if (params.value === AppConstants.toDoText) {
                        return { color: 'red', fontWeight: "500" };
                    }
                    if (params.value === AppConstants.doneText) {
                        return { color: 'green', fontWeight: "500" };
                    }
                    return null;
                }, suppressMovable: true
            },
            {
                field: 'judge', width: 200, headerName: 'Action', tooltipValueGetter: (params) => (params.data.solutionSubmissionFlag === AppConstants.nText && competitionDetails?.allowedSubmissionFlag === AppConstants.yText) ? "Submission is not uploaded by the participant" : null
                , suppressMovable: true, cellRendererFramework: (params) => <div><Button disabled={params.data.solutionSubmissionFlag === AppConstants.nText && competitionDetails?.allowedSubmissionFlag === AppConstants.yText} className="gridButton" onClick={() => { handleView(params.data) }}>Judge</Button></div>
            }
        ])
    };


    const defaultColDefDesktop = useMemo(() => ({
        width: '100%',
        resizable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true,
        flex: 1,
        suppressHorizontalScroll: true,
        floatingFilterComponentParams: { suppressFilterButton: true }
    }), []);

    const defaultColDefMobile = useMemo(() => ({
        width: '100%',
        resizable: true,
        filter: true,
        floatingFilter: true,
        suppressMenu: true,
        suppressHorizontalScroll: true,
        floatingFilterComponentParams: { suppressFilterButton: true }
    }), []);

    //handle click on view button
    async function handleView(params) {
        const url = AppConstants.studentText + AppConstants.forwardSlash + params.participantQrenciaId;
        navigate(url);
    }


    return (
        <>
            {judgingPageLoader && <div className={expertViewJudingScreen.errorContainer}>
                <p className={expertViewJudingScreen.loaderIcon}><BiLoaderAlt className={expertViewJudingScreen.spinner} /></p>
            </div>}
            {!judgingPageLoader && competitionDetails !== null && competitionDetails !== undefined && <ExpertJudgingScreenBanner competitionDetail={competitionDetails} />}
            {!judgingPageLoader && competitionDetails !== null && competitionDetails !== undefined &&
                <div className={expertViewJudingScreen.participantSection}>
                    <div className={expertViewJudingScreen.thumbnailSection}>
                        <div className={expertViewJudingScreen.thumbnailContainer}>
                            <img className={expertViewJudingScreen.thumbnail} src={competitionDetails.thumbnailImage !== null ? competitionDetails.thumbnailImage : ""} alt="Competition Thumbnail"></img>
                        </div>
                        <div className={expertViewJudingScreen.thumbnailDetailsSection}>
                            <div className={expertViewJudingScreen.competitionTitle}>{competitionDetails.competitionTitle}</div>
                            <div className={expertViewJudingScreen.competitionDescription}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetails.competitionStartTime.replace(' ', 'T') + 'Z'}</Moment> <p className={expertViewJudingScreen.hyphenText}>at</p> <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetails.competitionStartTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> <p className={expertViewJudingScreen.hyphenText}>{AppConstants.hyphen}</p> <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetails.competitionEndTime.replace(' ', 'T') + 'Z'}</Moment> <p className={expertViewJudingScreen.hyphenText}>at</p> <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetails.competitionEndTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetails.preferredTimezone})`} {competitionDetails.venueLocation != null && <span className={expertViewJudingScreen.location}> ({competitionDetails.venueLocation})</span>}</div>
                            <div className={expertViewJudingScreen.competitionDescription}>{MathUtil.formatNumberToLocaleString(competitionDetails.enrolledCandidates)} Enrollments</div>
                            <div className={expertViewJudingScreen.competitionDescription}><strong>Judge:</strong> {judgeDetails?.judgeName} ({judgeDetails?.judgeEmail})</div>
                        </div>
                    </div>
                    <div className={expertViewJudingScreen.headingSection}>
                        <div className={expertViewJudingScreen.headingSectionDiv}>
                            <h1 className={expertViewJudingScreen.sectionHeading}>Participants</h1>
                        </div>
                        <div className={expertViewJudingScreen.judingCountDiv}>
                            <p className={expertViewJudingScreen.participantsJudgedCount}>Participants Judged: {MathUtil.formatNumberToLocaleString(judgedCount)} out of {MathUtil.formatNumberToLocaleString(totalCount)}</p>
                        </div>
                    </div>
                    {<div className={expertViewJudingScreen.agGridDesktop}>
                        <div className="allParticipantsGrid">
                            <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                <AgGridReact
                                    className={expertViewJudingScreen.gridSection}
                                    ref={gridRef}
                                    defaultColDef={defaultColDefDesktop}
                                    headerHeight={AppConstants.gridConstants.headerHeight}
                                    rowData={competitionParticipants}
                                    columnDefs={columnDefsAllParticipantsGrid}
                                    enableBrowserTooltips={true}
                                    domLayout='autoHeight'
                                    suppressRowHoverHighlight={true}
                                    onGridReady={onGridReady}
                                >
                                </AgGridReact>
                            </div>
                        </div>
                    </div>
                    }{
                        <div className={expertViewJudingScreen.agGridMobile}>
                            <div className="allParticipantsGrid">
                                <div className="ag-theme-alpine" style={{ width: '100%' }}>
                                    <AgGridReact
                                        className={expertViewJudingScreen.gridSection}
                                        ref={gridRef}
                                        defaultColDef={defaultColDefMobile}
                                        headerHeight={AppConstants.gridConstants.headerHeight}
                                        rowData={competitionParticipants}
                                        columnDefs={columnDefsAllParticipantsGrid}
                                        enableBrowserTooltips={true}
                                        domLayout='autoHeight'
                                        suppressRowHoverHighlight={true}
                                        onGridReady={onGridReady}
                                    >
                                    </AgGridReact>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            }
        </>
    )
}
