import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdvanceProfilingStep1 } from '../../../component/studentAdvanceProfile/AdvanceProfilingStep1';
import { AdvanceProfilingStep2 } from '../../../component/studentAdvanceProfile/AdvanceProfilingStep2';
import { AdvanceProfilingStep3 } from '../../../component/studentAdvanceProfile/AdvanceProfilingStep3';
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { FaRegCircle } from "react-icons/fa";
import { AuthorizedDataService } from '../../../services/AuthorizedDataService';
import { Button } from "react-bootstrap";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { FaCircle } from "react-icons/fa";
import { StudentHeader } from '../../../layout/StudentPageLayout/StudentHeader';
import { AppConstants } from "../../../constants/AppConstants";
import Auth from '../../../utils/Auth';
import { SuccessMessage } from '../../../constants/SuccessMessage';
import { ErrorSuccessAlertMessage } from '../../../component/errorSuccessMessage/ErrorSuccessAlertMessage';
import classes from "./StudentCompleteProfilePage.module.css";
import { StringUtils } from '../../../utils/StringUtils';

export const StudentCompleteProfilePage = () => {
  const initialValues = { userImagePath: AppConstants.emptyString, countryCode: AppConstants.emptyString, userName: AppConstants.emptyString, firstName: AppConstants.emptyString, lastName: AppConstants.emptyString, userEmail: AppConstants.emptyString, country: AppConstants.emptyString, mobileNumber: AppConstants.emptyString, state: AppConstants.emptyString, city: AppConstants.emptyString, dob: AppConstants.emptyString, gender: AppConstants.emptyString, selectedSkills: [] };
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3;
  const [allSelectedSkills, setAllSelectedSkills] = useState([]);
  const [formValues, setFormValues] = useState(initialValues);
  const [showExitWindow, setShowExitWindow] = useState(false);
  const [profilePictureFile, setProfilePictureFile] = useState(null);
  const [showWelcomeWindow, setShowWelcomeWindow] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(AppConstants.emptyString);
  const [errorSuccessAlertVisiblity, setErrorSuccessAlertVisiblity] = useState(AppConstants.falseText);
  const [errorSuccessAlertVarient, setErrorSuccessAlertVarient] = useState(AppConstants.emptyString);
  const [errorSuccessAlertMessage, setErrorSuccessAlertMessage] = useState(AppConstants.emptyString);
  const [selectSkillErrorMessage, setSelectSkillErrorMessage] = useState(AppConstants.emptyString);
  const displayTexts = AppConstants.learnerCompleteProfileStaticTexts;
  const [detailsUpdated, setDetailsUpdated] = useState(AppConstants.emptyString);
  const errorPageUrl = AppConstants.forwardSlash + AppConstants.asterisk;
  const navigate = useNavigate();


  const fetchUserInfo = useCallback(async (isInitialRender) => {
    const url = AppConstants.fetchLoggedInStudentInfoAPI + Auth.getLoggedInUserDetails().userId;
    const response = await AuthorizedDataService.get(
      url,
      AppConstants.emptyString,
      AppConstants.emptyString
    );

    const loggedUserInfo = response;
    if (loggedUserInfo.profileCompletionFlag === AppConstants.yText && isInitialRender) {
      navigate(errorPageUrl);
    }

    setSelectedCountry(response.countryCode);

    setFormValues({
      userName: loggedUserInfo.userName,
      firstName: loggedUserInfo.firstName,
      lastName: loggedUserInfo.lastName,
      userEmail: loggedUserInfo.userEmail,
      mobileNumber: loggedUserInfo.mobileNumber,
      gender: loggedUserInfo.gender || AppConstants.emptyString,
      dob: loggedUserInfo.dob,
      country: response?.countryCode,
      countryCode: response?.countryId,
      city: loggedUserInfo.city || AppConstants.emptyString,
      state: loggedUserInfo.state || AppConstants.emptyString,
    });

    const userDetails = Auth.getLoggedInUserDetails();
    localStorage.setItem(AppConstants.sessionKeyUserDetails, JSON.stringify({
      ...userDetails,
      profileImagePreSignedUrl: loggedUserInfo.userImagePath,
    }));
  }, [errorPageUrl, navigate]);


  useEffect(() => {
    // initial render
    fetchUserInfo(AppConstants.trueText);
  }, [fetchUserInfo]);


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWelcomeWindow(false);
    }, 5000);

    return () => clearTimeout(timer);
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setSelectSkillErrorMessage(AppConstants.emptyString);
    }, AppConstants.messageDisappearTime);

    return () => clearTimeout(timer);
  }, [selectSkillErrorMessage]);

  const nextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const navigateToHomePage = () => {
    window.open(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.studentText}${AppConstants.forwardSlash}home`, AppConstants.newPageTargetSelf);
  }

  useEffect(() => {
    if (detailsUpdated === AppConstants.yText) {
      const timer = setTimeout(() => {
        navigateToHomePage();
      }, AppConstants.searchSkillTextChangeDuration, navigateToHomePage);

      return () => clearTimeout(timer);
    }

  }, [detailsUpdated]);

  const setUserInterestSkills = (skills) => {
    setAllSelectedSkills(skills);
    if (skills.length > 0) {
      setSelectSkillErrorMessage(AppConstants.emptyString);
    }
  }

  const handleClick = () => {
    if (currentStep === totalSteps) {
      setShowExitWindow(true);
      submitUserDetails()
    } else {
      if (currentStep === 1 && allSelectedSkills?.length === 0) {
        setSelectSkillErrorMessage(ErrorMessages.advanceProfileSelectSkillError);
      } else {
        nextStep();
      }
    }
  };

  function isFileObjectValid(fileObject) {
    if (fileObject === undefined) {
      return false;
    }
    return (
      fileObject &&
      fileObject.name &&
      fileObject.name !== AppConstants.emptyString &&
      fileObject.size > 0
    );
  };

  function hideErrorMessage() {
    let timer = setTimeout(() => setErrorSuccessAlertVisiblity(AppConstants.falseText), AppConstants.messageDisappearTime);
    return () => {
      clearTimeout(timer);
    };
  }


  const getPayloadData = (imagePath) => {
    return {
      ...formValues,
      userImagePath: imagePath,
      userQrenciaId: Auth.getLoggedInUserDetails().userId,
      selectedSkills: allSelectedSkills,
      gender: formValues.gender || AppConstants.nullText,
      state: formValues.state || AppConstants.nullText,
      city: formValues.city || AppConstants.nullText,
    }
  };

  const isFormValid = () => {
    if (allSelectedSkills?.length === 0) {
      setErrorSuccessAlertMessage(SuccessMessage.noInterestSelectedMessage);
      setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
      setErrorSuccessAlertVisiblity(AppConstants.trueText);
      hideErrorMessage();
      return AppConstants.falseText;
    }

    return AppConstants.trueText;
  }

  async function submitUserDetails() {
    try {
      if (isFormValid()) {
        let imagePath = AppConstants.nullText;

        if (isFileObjectValid(profilePictureFile)) {
          const path = `${AppConstants.s3ProfilePictureFolder}${AppConstants.forwardSlash}${Auth.getLoggedInUserDetails().userId}${AppConstants.hyphen}${profilePictureFile.name}`;
          const result = await FileOperationsUtil.uploadFileThroughPreSignedURL(profilePictureFile, path, AppConstants.s3filePermissionPublic, AppConstants.s3FileContentDispositionInline);

          if (result.objectKey !== undefined) {
            imagePath = result.objectKey;
          } else {
            imagePath = null;
          }
        }

        const url = AppConstants.postCompleteProfileDetailsAPI;
        const data = getPayloadData(imagePath)
        const response = await AuthorizedDataService.post(
          url,
          data,
          AppConstants.emptyString,
          AppConstants.emptyString
        );

        if (!response.ok) {
          setErrorSuccessAlertMessage(ErrorMessages.completeProfileInternalServerError);
          setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
          setErrorSuccessAlertVisiblity(AppConstants.trueText);
          setTimeout(() => {
            navigateToHomePage();
          }, AppConstants.searchSkillTextChangeDuration);
          hideErrorMessage();
        } else if (response.ok && response.status === 200) {
          setErrorSuccessAlertMessage(SuccessMessage.completeProfileSubmitSuccess);
          setErrorSuccessAlertVarient(AppConstants.alertVarient[0]);
          setErrorSuccessAlertVisiblity(AppConstants.trueText);
          fetchUserInfo();
          setTimeout(() => {
            setDetailsUpdated(AppConstants.yText);
          }, AppConstants.searchSkillTextChangeDuration);
          hideErrorMessage();
        }
        return null;
      }
    } catch (error) {
      setErrorSuccessAlertMessage(ErrorMessages.completeProfileInternalServerError);
      setErrorSuccessAlertVarient(AppConstants.alertVarient[1]);
      setErrorSuccessAlertVisiblity(AppConstants.trueText);
      setTimeout(() => {
        navigateToHomePage();
      }, AppConstants.searchSkillTextChangeDuration);
      hideErrorMessage();
    }
  }

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <AdvanceProfilingStep1 setUserInterestSkills={setUserInterestSkills} selectSkillErrorMessage={selectSkillErrorMessage} />;
      case 2:
        return <AdvanceProfilingStep2 formValues={formValues} setFormValues={setFormValues} selectedCountry={selectedCountry} />;
      case 3:
        return <AdvanceProfilingStep3 formValues={formValues} setFormValues={setFormValues} profilePictureFile={profilePictureFile} setProfilePictureFile={setProfilePictureFile} />;
      default:
        return <div>Unknown Step</div>;
    }
  };

  return (
    <div className='profileFormBody'>
      <StudentHeader className={classes.headerCustom} page={'profileUpdate'} hasShadow={true} />
      {showWelcomeWindow && !showExitWindow && (
        <div className={classes.welcomeTextDiv}>
          <h5 className={`${classes.welcomeHeading} ${classes.animatedContent}`}>{displayTexts.welcomeHeading}</h5>
          <p className={`${classes.welcomeContent} ${classes.animatedContent}`}>
            <div className={classes.contentAnimation}>
              {displayTexts.welcomeDescription}
            </div>
          </p>
        </div>
      )}

      {!showWelcomeWindow && !showExitWindow && (
        <div className={classes.stepwiseContainer}>
          <div className={classes.stepText}>{`Step ${currentStep} of 3`}</div>
          <div className={classes.stepProgress}>
            {[...Array(totalSteps)].map((_, index) => (
              <div className={classes.barCircleDiv} key={index}>
                <div className={`${index + 1 <= currentStep ? classes.progressBarLineDark : classes.progressBarLineLight}`}></div>
                <div className={`${classes.step} ${index < currentStep ? classes.completed : ''} ${index === currentStep - 1 ? classes.current : ''}`}>
                  <div className={classes.stepCircle}>
                    {index + 1 <= currentStep ? <FaCircle className='full-circle' /> : <FaRegCircle className='circle' />}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="step-content">{renderStepContent()}</div>
          {errorSuccessAlertVisiblity &&
            <ErrorSuccessAlertMessage message={errorSuccessAlertMessage} varient={errorSuccessAlertVarient} />
          }
          <div className={classes.nextButtonDiv}>
            <Button className={`${classes.submitButton} ${classes.nextButton}`} onClick={handleClick}>
              {currentStep === totalSteps ? 'Finish' : 'Next'}
            </Button>
          </div>
        </div>
      )}

      {showExitWindow && (
        <div className={classes.welcomeTextDiv}>
          <h6 className={`${classes.welcomeHeading} ${classes.animatedContent}`}>{displayTexts.finishHeading}</h6>
          <p className={`${classes.welcomeFinishingContent} ${classes.animatedContent}`}>
            {detailsUpdated === AppConstants.yText && <span className={classes.contentAnimation}>
              {StringUtils.getParsedMessage(displayTexts.updateCompleteDescription, { firstName: Auth.getLoggedInUserDetails().firstName })}
            </span>}
            {detailsUpdated !== AppConstants.yText && <span className={classes.contentAnimation}>
              {displayTexts.finishDescription}
            </span>}
          </p>
        </div>
      )}
    </div>
  )
}

