import { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styles from "../../../common/NudgesCardsStyle.module.css";
import { FaCalendarAlt, FaCalendarCheck } from "react-icons/fa";
import { AppConstants } from "../../../constants/AppConstants";
import Moment from "react-moment";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import Auth from "../../../utils/Auth";

export const StudentViewNudgesCards = (props) => {
    const [cardCount, setCardCount] = useState(4);
    const nudgeDetailsArray = props.nudgesData;
    const [timeZone, setTimeZone] = useState(DateTimeUtil.getPreferredTimeZoneForUser());

    return (
        <>
            {props.nudgesData.length !== 0 && <div className={styles.container}>
                <Row>
                    {nudgeDetailsArray.slice(0, cardCount).map((nudge, index) => (<Col key={nudge.id} className={styles.columnPadding} xs={12} sm={6} md={12} lg={6} xl={6}>
                        <div className={styles.cardsContainer}>
                            <div className={styles.responseCountContainer}>
                                <p className={styles.questionHeading}>Question</p>
                                <p className={styles.responseCountText}>{nudge.totalResponse <= 1 ? nudge.totalResponse + " Response" : nudge.totalResponse + " Responses"}</p>
                            </div>
                            <div className={styles.summaryContainer}>
                                <p className={styles.summaryText}>{nudge.summary}</p>
                            </div>
                            <div className={styles.bottomContainer}>
                                <div className={styles.detailsGroup}>
                                    {nudge.raisedOnUtc !== null && <div className={styles.detailsIconSection}>
                                        <FaCalendarAlt className={styles.detailsIcon} />
                                    </div>}
                                    {nudge.raisedOnUtc !== null && <div className={styles.detailsSubsection}>
                                        <p className={styles.detailsSubHeading}>Raised</p>
                                        <p className={styles.detailsHeading}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{nudge.raisedOnUtc.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${nudge.preferredTimezone})`}</p>
                                    </div>}
                                </div>
                                {props.closedNudges && <div className={styles.detailsGroup}>
                                    {nudge.closedOnUtc !== null && <div className={styles.detailsIconSection}>
                                        <FaCalendarCheck className={styles.detailsIcon} />
                                    </div>}
                                    {nudge.closedOnUtc !== null && <div className={styles.detailsSubsection}>
                                        <p className={styles.detailsSubHeading}>Closed</p>
                                        <p className={styles.detailsHeading}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{nudge.closedOnUtc.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${nudge.preferredTimezone})`}</p>
                                    </div>}
                                </div>}
                                <div className={styles.viewButtonContainer}>
                                    <Button className={styles.viewButton} onClick={() => props.onViewClick(nudge)}>View</Button>
                                </div>
                            </div>
                        </div>
                    </Col>))}
                </Row>
                <Row xs={1} sm={1} md={1} lg={1} xl={1} className={styles.loadMoreContainer}>
                    <Col>
                        {cardCount < nudgeDetailsArray.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + 4)}>Load more</button>}
                    </Col>
                </Row>
            </div>}
            {props.nudgesData.length === 0 && <div className={styles.containerFallback}>
                <div>
                    {!props.closedNudges && <p className={styles.fallBackMessage}>{ErrorMessages.studentViewNudgesFallbackMessages[0]}</p>}
                    {props.closedNudges && <p className={styles.fallBackMessage}>{ErrorMessages.studentViewNudgesFallbackMessages[1]}</p>}
                </div>
            </div>}
        </>
    )
}