import { Tabs, Tab } from "react-bootstrap";
import { StudentTemplatesConstants } from "../../constants/StudentTemplatesConstants";
import classes from "./StudentLearningActivityTabs.module.css";
import "./StudentLearningActivityTabs.css";

export const StudentLearningActivityTabs = (props) => {
    const learningActivityTabs = StudentTemplatesConstants.learningActivityTabs;
    //Handler to return selected tab Value
    function selectLearningActivity(e) {
        props.onTabChange(e);
    };

    return (
        <div className={`${classes.studentLearningActivityTabsContainer} studentLearningActivitiesTabs`}>
            <Tabs className={classes.studentLearningActivityTabs}
                defaultActiveKey={props.defaultKey}
                id="studentLearningActivityTabs"
                fill
                onSelect={(e) => selectLearningActivity(e)}
            >
                <Tab eventKey={learningActivityTabs.competition.key} title={learningActivityTabs.competition.displayText}></Tab>
                <Tab eventKey={learningActivityTabs.Courses.key} title={learningActivityTabs.Courses.displayText}></Tab>
                <Tab eventKey={learningActivityTabs.workshop.key} title={learningActivityTabs.workshop.displayText}></Tab>
                <Tab eventKey={learningActivityTabs.nudge.key} title={learningActivityTabs.nudge.displayText}></Tab>
            </Tabs>
        </div>
    );
}