import { LandingPageFooter } from "../../layout/LandingPageLayout/LandingPageFooter";
import { useLoaderData } from 'react-router-dom';
import { LoginPageTopNavigation } from "../../layout/LoginPageLayout/LoginPageTopNavigation";
import classes from "./ExpressionOfInterestPage.module.css";
import { ExpressionOfInterestForm } from "../../component/formControls/ExpressionOfInterestForm";
import { ExpressionOfInterestDetailsSection } from "../../component/eoiPageComponents/expressionOfInterestDetailsSection/ExpressionOfInterestDetailsSection";
import { ExpressionOfInterestVideoSection } from "../../component/eoiPageComponents/expressionOfInterestDetailsVideoSection/ExpressionOfInterestVideoSection";
import { ExpressionOfInterestPageBanner } from "../../component/eoiPageComponents/expressionOfInterestPageBanner/ExpressionOfInterestPageBanner";
import { ExpresionOfInterestCarouselSection } from "../../component/eoiPageComponents/expressionOfInterestMobileCarousel/ExpressionOfInterestCarouselSection";

export const ExpressionOfIntrestPage = () => {
    const [landingPageData, eoiImageData, eoiCarouselData] = useLoaderData();

    return (
        <>
            <LoginPageTopNavigation landingPageData={landingPageData} />
            <div className="row">
                <ExpressionOfInterestPageBanner eoiCarouselData={eoiCarouselData} />
            </div>
            <div className={`row mb-3 ${classes.section}`}>
                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                    <ExpressionOfInterestDetailsSection />
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <ExpressionOfInterestVideoSection />
                </div>
            </div>
            <div className="row">
                <ExpresionOfInterestCarouselSection eoiImageData={eoiImageData} />
            </div>
            <div className={`row mt-4 mb-3 ${classes.section}`}>
                <ExpressionOfInterestForm />
            </div>
            <LandingPageFooter landingPageData={landingPageData} />
        </>
    )
}