import { Outlet } from 'react-router-dom';
import { useState } from 'react';
import { Row, Col, Button } from "react-bootstrap";
import { AgencyHeader } from '../../layout/AgencyPageLayout/AgencyHeader';
import { AgencyLeftNav } from '../../layout/AgencyPageLayout/AgencyLeftNav';
import { RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import { AgencyFooter } from '../../layout/AgencyPageLayout/AgencyFooter';
import { AgencyRightWidgetSpace } from '../../layout/AgencyPageLayout/AgencyRightWidgetSpace';
import classes from './AgencyTemplate.module.css';
import { useLoaderData } from 'react-router-dom';

export const AgencyTemplate = () => {
    const [footerDetails, agencyDetails] = useLoaderData();
    const [open, setOpen] = useState(false);
    const myActivitiesHandler = () => {
        if (open === true) {
            setOpen(false);
        }
        else {
            setOpen(true);
        }
    }

    return (
        <>
            <AgencyHeader />
            <div>
                <div className={classes.myActivitiesSection}>
                    <Button className={classes.myActivitiesButton} onClick={myActivitiesHandler}>My Activities {!open && <RiArrowDownSLine className={classes.activityIcon} />}
                        {open && <RiArrowUpSLine className={classes.activityIcon} />}</Button>
                </div>
                <Row>
                    <Col lg={2}><AgencyLeftNav agencyDetails={agencyDetails} /></Col>
                    <div className={classes.mobileViewRightWidgetSection}>{open && <AgencyRightWidgetSpace />}</div>
                    <Col xs={12} sm={12} md={8} lg={7}>
                        <Outlet />
                    </Col>
                    <Col md={4} lg={3} className="d-none d-md-block d-lg-block" ><AgencyRightWidgetSpace /></Col>
                </Row>
            </div>
            {footerDetails != null && <AgencyFooter footerDetails={footerDetails} />}
        </>
    )
}

