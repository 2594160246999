import { AgGridReact } from "ag-grid-react"
import moment from "moment";
import { Card, Col, Row } from "react-bootstrap"
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { AppConstants } from "../../../constants/AppConstants";
import { useCallback, useEffect, useState } from "react";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import classes from "../AdminNumberTrackingComponents.module.css";
import dashboardClass from "../../adminDashboardComponents/DashboardCards.module.css";
import { DateFilterComponent } from "../../adminDashboardComponents/dateFilterComponent/DateFilterComponent";
import { FaFilter } from "react-icons/fa6";
import { MathUtil } from "../../../utils/MathUtil";

export const NudgeDetailsStats = () => {
    const [nudgeDetails, setNudgeDetails] = useState([]);
    const [dateDisplayValue, setDateDisplayValue] = useState(AppConstants.dateFilterPresets.allTime.label);
    const [showDateFilterModal, setShowFilterDateModal] = useState(AppConstants.falseText);
    const [nudgeStats, setNudgeStats] = useState({});
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    const constructURL = (url, fromDateString, toDateString) => {
        if (fromDateString) {
            url = `${url}?${AppConstants.selectedDateStartTimeParam}${encodeURIComponent(fromDateString)}`
        }
        if (toDateString && !fromDateString) {
            url = `${url}?${AppConstants.selectedDateEndTimeParam}${encodeURIComponent(toDateString)}`
        } else if (toDateString && fromDateString) {
            url = `${url}&${AppConstants.selectedDateEndTimeParam}${encodeURIComponent(toDateString)}`
        }
        if (fromDateString || toDateString) {
            url = `${url}&locale=${DateTimeUtil.getPreferredTimeZoneForUser()}`
        }

        return url;
    };

    const fetchNudgeDetails = useCallback(async (fromDateString, toDateString) => {
        let url;
        if (fromDateString || toDateString) {
            url = constructURL(AppConstants.getQrenciaStatsForSelectedDateRangeAPI, fromDateString, toDateString);
        } else {
            url = AppConstants.getQrenciaStatsForAdminAPI;
        };

        let response = await AuthorizedDataService.getRequest(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        )

        if (response) {
            setNudgeStats(response);
            setNudgeDetails(response?.allResponses);
        } else {
            setNudgeStats({});
            setNudgeDetails([]);
        }
    }, []);

    useEffect(() => {
        fetchNudgeDetails();
    }, [fetchNudgeDetails]);


    const nudgeDetailsColumnDef = [
        {
            headerName: `Nudge ID`,
            field: "nudgeId",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.nudgeId}`,
            valueGetter: (params) => {
                return params.data.nudgeId;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Raised By`,
            field: "raisedBy",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.raisedBy}`,
            valueGetter: (params) => {
                return `${params.data.raisedBy}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Skill`,
            field: "skill",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.skill}`,
            valueGetter: (params) => {
                return `${params.data.skill}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Status`,
            field: "status",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.status}`,
            valueGetter: (params) => {
                return `${params.data.status}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Responded By`,
            field: "expertName",
            width: 300,
            tooltipValueGetter: ({ data }) => `${data.expertName}`,
            valueGetter: (params) => {
                return `${params.data.expertName}`;
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Raised On`,
            field: "raisedOn",
            width: 300,
            tooltipValueGetter: ({ data }) => `${moment.utc(data.raisedOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.raisedOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`
            },
            filter: 'agTextColumnFilter'
        },
        {
            headerName: `Responded On`,
            field: "respondedOn",
            width: 300,
            tooltipValueGetter: ({ data }) => `${moment.utc(data.respondedOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`,
            valueGetter: ({ data }) => {
                return `${moment.utc(data.respondedOn).tz(timeZone).format('D MMM YYYY')} ${timeZone === AppConstants.utcTimezone ? "(UTC)" : ""}`
            },
            filter: 'agTextColumnFilter'
        }
    ];

    const onDateSelectionHandle = ({ fromDateString, toDateString, displayValue }) => {
        setShowFilterDateModal(AppConstants.falseText);
        setDateDisplayValue(displayValue);
        fetchNudgeDetails(fromDateString, toDateString);
    };

    const handleDateSelectionModalClose = () => {
        setShowFilterDateModal(AppConstants.falseText);
    };

    return (
        <div>
            <div className={`${classes.dateFilterText}`} onClick={() => setShowFilterDateModal(AppConstants.trueText)}>{dateDisplayValue}<FaFilter /></div>
            {showDateFilterModal && <DateFilterComponent onDateSelectionHandle={onDateSelectionHandle} handleDateSelectionModalClose={handleDateSelectionModalClose} dateFilterDisplayValue={dateDisplayValue} />}
            <Row>
                <div className={`${dashboardClass.dashboardCards} mb-4`}>
                    <Card className={`${dashboardClass.countCards} ${dashboardClass.nudgeCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Nudge Raised</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.enrolmentCount}`}>{nudgeStats.totalNudgeRaised ? MathUtil.formatNumberToLocaleString(nudgeStats.totalNudgeRaised) : "0"}</Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className={`${dashboardClass.countCards} ${dashboardClass.nudgeCard}`}>
                        <Card.Body>
                            <Card.Title className={dashboardClass.cardTitle}>Responses</Card.Title>
                            <Card.Text className={`${dashboardClass.cardCount} ${dashboardClass.absentCount}`}>{nudgeStats.totalNudgeResponse ? MathUtil.formatNumberToLocaleString(nudgeStats.totalNudgeResponse) : "0"}</Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="ag-theme-alpine">
                        <AgGridReact
                            className="expertObrDetailsGrid"
                            columnDefs={nudgeDetailsColumnDef}
                            pagination={true}
                            paginationPageSize={AppConstants.adminDashboardPaginationSize}
                            rowData={nudgeDetails}
                            enableBrowserTooltips={true}
                            domLayout='autoHeight'
                            suppressRowHoverHighlight={true}
                            defaultColDef={{
                                filter: true,
                                sortable: true,
                                flex: 1,
                                minWidth: 100,
                                resizable: true,
                                suppressMenu: true,
                                suppressHorizontalScroll: true,
                                floatingFilterComponentParams: { suppressFilterButton: true },
                                floatingFilter: true,
                            }}
                            enableFilter={true}
                            enableSorting={true}
                            enableSearching={true}
                            headerClass={classes.obrGridHeader}
                            rowClass={classes.obrGridTable}
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}