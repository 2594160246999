import React, { useState, useEffect } from "react";
import classes from "../../createCourseComponent/addCourseDetails/AddCourseDetails.module.css";
import { AppConstants } from "../../../constants/AppConstants";
import { RiInformationFill } from "react-icons/ri";
import { Tooltip } from "react-tooltip";

export const EditAdvancedCourseSettings = (props) => {
    const { selectedCourseData, cost, discount } = props;
    const [enableOfflinePayment, setEnableOfflinePayment] = useState(selectedCourseData.payOfflineFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
    const [isOfflineFlagUpdatedOnce, setIsOfflineFlagUpdatedOnce] = useState(AppConstants.falseText);

    useEffect(() => {
        if (selectedCourseData.id) {
            setEnableOfflinePayment(selectedCourseData.payOfflineFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
        } else {
            setEnableOfflinePayment(AppConstants.falseText);
        }
    }, [selectedCourseData]);

    useEffect(() => {
        const handleChange = () => {
            props.changeAdvancedSettings({
                enableOfflinePayment
            });
        }
        handleChange();
    }, [enableOfflinePayment]);


    useEffect(() => {
        let netAmount = (discount && cost) ? parseInt(cost) - parseInt(discount) : cost ? parseInt(cost) : 0;
        if (netAmount <= 0 && isOfflineFlagUpdatedOnce) {
            setEnableOfflinePayment(AppConstants.falseText);
        }
        if (!isOfflineFlagUpdatedOnce) {
            setIsOfflineFlagUpdatedOnce(AppConstants.trueText);
        }
    }, [cost, discount, isOfflineFlagUpdatedOnce]);

    const isOfflinePayemtnDisabled = () => {
        let netAmount = (discount && cost) ? parseInt(cost) - parseInt(discount) : cost ? parseInt(cost) : 0;
        if (netAmount <= 0) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className={classes.mainContainer}>
            <div className={classes.switchContainer}>
                <div className={classes.switchText}>Enable Offline Payment <RiInformationFill className={classes.infoButton} data-tooltip-id="paymentInfoTooltip" /><span><input type="checkbox" disabled={isOfflinePayemtnDisabled()} checked={enableOfflinePayment} onChange={() => setEnableOfflinePayment(!enableOfflinePayment)} /></span></div>
                <Tooltip className={classes.tooltipMessage} id="paymentInfoTooltip"><span className={classes.tooltipMessage}>{AppConstants.offlinePaymentTooltipInfo}</span></Tooltip>
            </div>
        </div>
    )
};