import { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
import validator from "validator";
import { MdDelete } from 'react-icons/md'
import { RiInformationFill } from 'react-icons/ri';
import { Tooltip } from "react-tooltip";
import { AppConstants } from "../../../constants/AppConstants";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import classes from "./AddCourseDetails.module.css"
import formClass from "../../../common/FormStyling.module.css"
import { WeekdaysSelect } from "../../calendar/WeekDaysSelect";
import moment from "moment";
import ReactQuill, { Quill } from 'react-quill';
import parse from 'html-react-parser';
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { DateTime } from "luxon";
import { FileUploadInput } from "../../fileUploadInput/FileUploadInput";
import { FileOperationsUtil } from "../../../utils/FileOperationsUtil";
import { BiLoaderAlt } from "react-icons/bi";
import { AiOutlineDownload } from "react-icons/ai";

export const AddCourseDetails = (props) => {
    const { skills, saveCurrentStep, stage, changeBasicFormDetailsData, changeBasicDetailsFormError, selectedCourseData } = props;
    const formLabels = AppConstants.courseDetailsFormLabels;
    const supportedImageFormats = AppConstants.supportedImageFormats;
    const modeOfDelivery = AppConstants.modeOfDelivery;
    const courseTypes = AppConstants.courseTypes;
    const stages = AppConstants.createCoursesActions;
    const [weekDaysArray, setWeekDaysArray] = useState([]);
    const [selectedCourseType, setSelectedCourseType] = useState(AppConstants.structuredCourseType);
    const [registrationDateAdded, setRegistrationDateAdded] = useState(false);
    const [registrationTimeAdded, setRegistrationTimeAdded] = useState(false);
    const [description, setDescription] = useState(AppConstants.emptyEditorString);
    const [videoSpinner, setVideoSpinner] = useState(false);
    const [thumbnailSpinner, setThumbnailSpinner] = useState(false);
    const [promotionImageWebSpinner, setPromotionImageWebSpinner] = useState(false);
    const [promotionImageTabletSpinner, setPromotionImageTabletSpinner] = useState(false);
    const [promotionImageMobileSpinner, setPromotionImageMobileSpinner] = useState(false);
    const [descriptionDocumentSpinner, setDescriptionDocumentSpinner] = useState(false);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();

    let initialValues = {
        courseType: selectedCourseData.courseType || courseTypes[0],
        title: selectedCourseData.courseTitle || AppConstants.emptyString,
        descriptionDocument: AppConstants.emptyString,
        skill: selectedCourseData.skillUniqueId ? selectedCourseData.skillUniqueId : skills?.length === 1 ? skills[0]?.skillId : AppConstants.emptyString,
        startDate: AppConstants.emptyString,
        endDate: AppConstants.emptyString,
        numberOfClasses: selectedCourseData.numberOfClasses || AppConstants.emptyString,
        durationInHours: selectedCourseData.duration ? selectedCourseData.duration : AppConstants.emptyString,
        modeOfDelivery: selectedCourseData.modeOfDelivery || AppConstants.emptyString,
        windowStartTime: selectedCourseData.startTime ? DateTime.fromFormat(selectedCourseData.startTime.split(",")[0], 'hh:mm a').toFormat('HH:mm') : AppConstants.emptyString,
        windowEndTime: selectedCourseData.endTime ? DateTime.fromFormat(selectedCourseData.endTime.split(",")[0], 'hh:mm a').toFormat('HH:mm') : AppConstants.emptyString,
        courseWeekDays: selectedCourseData.weekDays || AppConstants.emptyString,
        address: selectedCourseData.address || AppConstants.emptyString,
        sessionLink: AppConstants.emptyString,
        geoLocation: selectedCourseData.geoLocation || AppConstants.emptyString,
        cost: selectedCourseData.cost || AppConstants.emptyString,
        discount: (selectedCourseData.discount && selectedCourseData.discount >= 0) ? selectedCourseData.discount : AppConstants.emptyString,
        expertName: selectedCourseData.spocName || AppConstants.emptyString,
        expertEmail: selectedCourseData.spocEmail || AppConstants.emptyString,
        expertMobile: selectedCourseData.spocMobileNumber || AppConstants.emptyString,
        thumbnailImage: AppConstants.emptyString,
        promotionImageForWeb: AppConstants.emptyString,
        promotionImageForTablet: AppConstants.emptyString,
        promotionImageForMobile: AppConstants.emptyString,
        promotionVideo: AppConstants.emptyString,
        registrationClosingDate: AppConstants.emptyString,
        registrationClosingTime: AppConstants.emptyString,
    };

    const Link = Quill.import('formats/link');
    Link.sanitize = function (url) {
        try {
            // quill by default creates relative links if scheme is missing.
            if (!url.startsWith('http://') && !url.startsWith('https://')) {
                return `http://${url}`
            }
            return url;
        } catch (e) {
            return url;
        }
    };

    const validationSchema = Yup.object().shape({
        courseType: Yup.string().required(ErrorMessages.selectCourseTypeError),
        title: Yup.string().trim().max(100, ErrorMessages.invalidCourseTitile).matches(AppConstants.quotesRegex, { message: ErrorMessages.doubleQuotesTitleError }).required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.title.label}`),
        descriptionDocument: Yup.mixed().notRequired().test("file_size_description", ErrorMessages.courseDescriptionSizeLimit, (value) => { return value ? (value.size <= 50 * 1000 * 1000) : true }).test('file_Type', ErrorMessages.courseDescriptionPdfFormat, (value) => { return value ? (["application/pdf"].includes(value.type)) : true }),
        skill: Yup.string().required(ErrorMessages.selectSkill).oneOf(skills?.map(s => s.skillId)),
        startDate: selectedCourseType === AppConstants.structuredCourseType ? (Yup.date().required(ErrorMessages.courseStartDateRequired).min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.startDate.label} ${ErrorMessages.pastDateError}`)) : Yup.date().notRequired(),
        endDate: selectedCourseType === AppConstants.structuredCourseType ? (Yup.date().required(ErrorMessages.courseEndDateRequired).min(Yup.ref(formLabels.startDate.name), `${ErrorMessages.courseEndDateError}`)) : Yup.date().notRequired(),
        numberOfClasses: Yup.number().required(ErrorMessages.courseClassesRequiredError).min(1, ErrorMessages.courseClassesError).integer(`Number of classes ${ErrorMessages.invalidDecimalNumber}`),
        durationInHours: Yup.number().required(ErrorMessages.courseDurationRequired).min(0.1, ErrorMessages.courseDurationError).test(
            "step_of_0_5",
            ErrorMessages.courseDurationIn30intervalError,
            function (value) {
                return Math.abs(value % 0.5) === 0
            }
        ),
        modeOfDelivery: Yup.string().required(`${ErrorMessages.pleaseSelectText}${AppConstants.space}${AppConstants.modeOfDeliveryText}`).oneOf(AppConstants.modeOfDelivery),
        address: Yup.string().when(formLabels.modeOfDelivery.name, {
            is: (val) => val === AppConstants.competitionTypeOffline,
            then: () => Yup.string().trim().required(ErrorMessages.enterAddressError)
        }),
        sessionLink: Yup.string().when(formLabels.modeOfDelivery.name, {
            is: (val) => val === AppConstants.competitionTypeOnline,
            then: () => Yup.string().test("url_validation", `${ErrorMessages.enterValidUrl} for Session Link`,
                function (value = "") { return validator.isURL(value) })
        }),
        geoLocation: Yup.string().when(formLabels.modeOfDelivery.name, {
            is: (val) => val === AppConstants.competitionTypeOffline,
            then: () => Yup.string().test("url_validation", `${ErrorMessages.enterValidUrl} for geo location`,
                function (value = "") { return validator.isURL(value) })
        }),
        windowStartTime: selectedCourseType === AppConstants.structuredCourseType ? (Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space} start time`).test(
            "start_time_test", ErrorMessages.courseStartTimeError, function (value) {
                const { startDate } = this.parent;
                if (startDate) {
                    const d = new Date(startDate);
                    let hourMins = value.split(":");
                    const currentDateTime = DateTime.now().setZone(timeZone);
                    const selectedDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                    return selectedDateTime.toMillis() > currentDateTime.toMillis();
                } else {
                    return AppConstants.trueText;
                }
            }
        )) : Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space} start time`),
        windowEndTime: Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}end time`).test(
            "end_time_test", ErrorMessages.courseEndTimeError,
            function (value) {
                const { windowStartTime } = this.parent;
                return moment(windowStartTime, 'HH:mm').isBefore(moment(value, 'HH:mm'));
            }
        ),
        courseWeekDays: Yup.string().required(ErrorMessages.selectCourseWeekdaysError),
        registrationClosingDate: selectedCourseType === AppConstants.structuredCourseType ? (registrationTimeAdded ? Yup.date().required(`${ErrorMessages.pleaseAddText}${AppConstants.space}${formLabels.registrationClosingDate.label}`).min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.registrationClosingDate.label} ${ErrorMessages.pastDateError}`).max(Yup.ref(formLabels.endDate.name), `${ErrorMessages.courseRegistrationDateBeforeEndDate}`)
            : registrationDateAdded ? Yup.date().min(DateTimeUtil.getConvertedJsDateInPreferredTimeZone(new Date(Date.now() - 86400000), timeZone), `${formLabels.registrationClosingDate.label} ${ErrorMessages.pastDateError}`).max(Yup.ref(formLabels.endDate.name), `${ErrorMessages.courseRegistrationDateBeforeEndDate}`) : Yup.date().notRequired()) : Yup.date().notRequired(),
        registrationClosingTime: selectedCourseType === AppConstants.structuredCourseType ? (registrationDateAdded ? Yup.string().required(`${ErrorMessages.pleaseEnterText}${AppConstants.space}${formLabels.registrationClosingTime.label}`).test(
            "registration_time_test", ErrorMessages.courseRegistrationTimeBeforeEndTimeError,
            function (value) {
                const { windowEndTime, registrationClosingDate, endDate } = this.parent;
                if (moment(registrationClosingDate).isSame(moment(endDate)))
                    return moment(windowEndTime, 'HH:mm').isAfter(moment(value, 'HH:mm'));
                else return true;
            }).test("registration_time_before_current_test", ErrorMessages.registrationBeforeCurrentTimeError, function (value) {
                const { registrationClosingDate } = this.parent;
                const d = new Date(registrationClosingDate);
                let hourMins = value.split(":");
                let currentDateTime = DateTime.now().setZone(timeZone);
                const selectedDateTime = DateTime.fromObject({ year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate(), hour: hourMins[0], minute: hourMins[1], second: "00" }, { zone: timeZone });
                return selectedDateTime.toMillis() > currentDateTime.toMillis();
            }) : Yup.string().notRequired()) : Yup.string().notRequired(),
        cost: Yup.number().required("Please enter a valid course cost").min(selectedCourseType === AppConstants.structuredCourseType ? 0 : AppConstants.flexibleCourseMinimumCost, selectedCourseType === AppConstants.structuredCourseType ? ErrorMessages.minimumCostError : ErrorMessages.minimumCostFlexibleCourseError),
        discount: Yup.number().typeError("Please enter valid discount").notRequired().min(0, ErrorMessages.minimumDiscountError).test("minimum_discount_test", selectedCourseType === AppConstants.structuredCourseType ? "Discount cannot be greater than course cost" : "Discount should be less than course cost", function (value) {
            const { cost } = this.parent;
            if (selectedCourseType === AppConstants.structuredCourseType) {
                if (value && value > cost) return false;
                else return true;
            } else {
                if (value && value + 1 > cost) return false;
                else return true;
            }
        }),
        expertEmail: Yup.string().notRequired().matches(/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/, ErrorMessages.invalidEmailFormat),
        expertName: Yup.string().notRequired().matches(/^[a-zA-Z .]*$/, ErrorMessages.invalidUserNameWithRegex),
        expertMobile: Yup.string().notRequired().matches(/^[1-9]{1}\d{5,}$/, "Please Enter a valid mobile number"),
        thumbnailImage: Yup.mixed().notRequired().test('fileTypeTablet', `Thumbnail image ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizetablet', `Thumbnail image ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true }),
        promotionImageForWeb: Yup.mixed().notRequired().test('fileTypeTablet', `Promotion image for web ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizetablet', `Promotion image for web ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true }),
        promotionImageForTablet: Yup.mixed().notRequired().test('fileTypeTablet', `Promotion image for tablet ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizetablet', `Promotion image for tablet ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true }),
        promotionImageForMobile: Yup.mixed().notRequired().test('fileTypeMobile', `Promotion image for mobile ${ErrorMessages.fileTypeOtherThanJpgOrPngError}`, (value) => { return value ? (supportedImageFormats.includes(value.type)) : true }).test('fileSizemobile', `Promotion image for mobile ${ErrorMessages.fileSizeMoreThan5mbError}`, (value) => { return value ? (value.size <= AppConstants.image5MBSizeLimit) : true }),
        promotionVideo: Yup.mixed().notRequired().test("fileTypeVideo", ErrorMessages.promotionVideoTypeError, (value) => { return value ? (["video/mp4", "video/mkv", "video/x-m4v", "video/quicktime", "video/ai", "video/ogg", "video/webm", "video/x-msvideo"].includes(value.type)) : true }).test('fileSizeVideo', ErrorMessages.promotionVideoFileSizeError, (value) => { return value ? (value.size <= 200 * 1000 * 1000) : true })
    });

    const { handleSubmit, values, touched, errors, setFieldValue, handleBlur, handleChange, validateForm } = useFormik({
        initialValues,
        validationSchema,
        enableReinitialize: true,
        handleSubmit: () => { }
    });

    useEffect(() => {
        const newValues = { ...values, description: description };
        if (JSON.stringify(values) !== JSON.stringify(newValues)) {
            changeBasicFormDetailsData(newValues);
        }
    }, [values, description]);

    useEffect(() => {
        if (!values.startDate && selectedCourseData.id && values.title) {
            validateForm();
        }
    }, [values.startDate, selectedCourseData.id, values.title]);

    useEffect(() => {
        const changeParentState = () => {
            if (description?.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
                if (errors == {} && values.title === AppConstants.emptyString) {
                    changeBasicDetailsFormError(errors);
                } else if (values.title === AppConstants.emptyString || values.title?.includes(`"`)) {
                    changeBasicDetailsFormError(errors);
                } else {
                    changeBasicDetailsFormError({ description: "Please enter a description", ...errors });
                }
            } else {
                changeBasicDetailsFormError(errors);
            }
        }
        changeParentState();
    }, [errors, description]);

    useEffect(() => {
        const getUploadedFiles = async () => {
            if (selectedCourseData.courseDescriptionDocumentAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedCourseData.courseDescriptionDocumentAbsolutePath, formLabels.descriptionDocument.name);
                setFieldValue(formLabels.descriptionDocument.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedCourseData.courseThumbnailImageAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedCourseData.courseThumbnailImageAbsolutePath, formLabels.thumbnailImage.name);
                setFieldValue(formLabels.thumbnailImage.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedCourseData.coursePromotionMobileImageAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedCourseData.coursePromotionMobileImageAbsolutePath, formLabels.promotionImageForMobile.name);
                setFieldValue(formLabels.promotionImageForMobile.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedCourseData.coursePromotionTabletImageAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedCourseData.coursePromotionTabletImageAbsolutePath, formLabels.promotionImageForTablet.name);
                setFieldValue(formLabels.promotionImageForTablet.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedCourseData.coursePromotionWebImageAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedCourseData.coursePromotionWebImageAbsolutePath, formLabels.promotionImageForWeb.name);
                setFieldValue(formLabels.promotionImageForWeb.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
            if (selectedCourseData.coursePromotionVideoAbsolutePath) {
                let fileData = await FileOperationsUtil.fetchFileBinaryData(selectedCourseData.coursePromotionVideoAbsolutePath, formLabels.promotionVideo.name);
                setFieldValue(formLabels.promotionVideo.name, fileData ? fileData.dataTransfer.files.item(0) : AppConstants.emptyString);
            }
        }

        if (selectedCourseData.id) {
            setDescription(selectedCourseData.courseDescription);
            setSelectedCourseType(selectedCourseData.courseType);
            setWeekDaysArray(selectedCourseData.weekDays?.split(","));
            getUploadedFiles();
        } else {
            setDescription(AppConstants.emptyEditorString);
        }
    }, [selectedCourseData]);


    const changeModeOfDelivery = (event) => {
        setFieldValue(formLabels.modeOfDelivery.name, event.target.value);
        if (event.target.value) {
            if (event.target.value === AppConstants.competitionTypeOffline) {
                setFieldValue(formLabels.sessionLink.name, AppConstants.emptyString);
            } else if (event.target.value === AppConstants.competitionTypeOnline) {
                setFieldValue(formLabels.address.name, AppConstants.emptyString);
                setFieldValue(formLabels.geoLocation.name, AppConstants.emptyString);
            }
        } else {
            setFieldValue(formLabels.address.name, AppConstants.emptyString);
            setFieldValue(formLabels.sessionLink.name, AppConstants.emptyString);
            setFieldValue(formLabels.geoLocation.name, AppConstants.emptyString);
        }
    };

    const changeCourseType = (event) => {
        setSelectedCourseType(event.target.value);
        setFieldValue(formLabels.courseType.name, event.target.value);
        setFieldValue(formLabels.startDate.name, AppConstants.emptyString);
        setFieldValue(formLabels.endDate.name, AppConstants.emptyString);
        setFieldValue(formLabels.registrationClosingDate.name, AppConstants.emptyString);
        setFieldValue(formLabels.registrationClosingTime.name, AppConstants.emptyString);
    };

    const changeStartDate = (event) => {
        setFieldValue(formLabels.startDate.name, event.target.value);
        if (!event.target.value) {
            setFieldValue(formLabels.endDate.name, AppConstants.emptyString);
        }
    };

    const checkValidCost = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }

    const checkValidMobile = (event) => {
        let excludedKeywords = ["-", "e", "+", "E", ".", AppConstants.emptyString];
        if (excludedKeywords.includes(event.key)) event.preventDefault();
    }
    const getFileExtension = (url) => {
        return url.split(".").pop();
    };

    const downloadFile = async (fileUrl, name) => {
        let ext = getFileExtension(fileUrl)
        let resp = await FileOperationsUtil.downloadFileWithLoader(fileUrl, `${selectedCourseData.courseTitle}-${name}${AppConstants.dot}${ext}`);
        if (resp) {
            if (name === formLabels.descriptionDocument.name) {
                setDescriptionDocumentSpinner(false);
            } else if (name === formLabels.thumbnailImage.name) {
                setThumbnailSpinner(false);
            } else if (name === formLabels.promotionImageForWeb.name) {
                setPromotionImageWebSpinner(false);
            } else if (name === formLabels.promotionImageForTablet.name) {
                setPromotionImageTabletSpinner(false);
            } else if (name === formLabels.promotionImageForMobile.name) {
                setPromotionImageMobileSpinner(false);
            }
        } else {
            if (name === formLabels.descriptionDocument.name) {
                setDescriptionDocumentSpinner(false);
            } else if (name === formLabels.thumbnailImage.name) {
                setThumbnailSpinner(false);
            } else if (name === formLabels.promotionImageForWeb.name) {
                setPromotionImageWebSpinner(false);
            } else if (name === formLabels.promotionImageForTablet.name) {
                setPromotionImageTabletSpinner(false);
            } else if (name === formLabels.promotionImageForMobile.name) {
                setPromotionImageMobileSpinner(false);
            }
        }
    };

    const downloadVideoFile = async (fileUrl, name) => {
        setVideoSpinner(true);
        let resp = await FileOperationsUtil.downloadFileWithLoader(fileUrl, `${selectedCourseData.courseTitle}-${name}`);
        if (resp) {
            setVideoSpinner(false);
        } else {
            setVideoSpinner(false);
        }
    }

    const removeFile = (id) => {
        const input = document.getElementById(id);
        input.value = AppConstants.emptyString;
        input.type = AppConstants.inputFieldTypes.file;
        setFieldValue(id, AppConstants.emptyString);
    };

    const moveToNextSection = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    };

    const handleDaysSelection = (days) => {
        setWeekDaysArray(days);
        setFieldValue(formLabels.courseWeekDays.name, days.join(AppConstants.comma));
    };

    const changeRegistrationClosingDate = (e) => {
        setFieldValue(formLabels.registrationClosingDate.name, e.target.value)
        if (e.target.value) {
            setRegistrationDateAdded(true);
        } else {
            setRegistrationDateAdded(false);
        }
    };

    const changeRegistrationClosingTime = (e) => {
        setFieldValue(formLabels.registrationClosingTime.name, e.target.value);
        if (e.target.value) {
            setRegistrationTimeAdded(true)
        } else {
            setRegistrationTimeAdded(false)
        }
    };

    return (
        <div className={classes.formContainer}>
            <Form noValidate>
                <Row>
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.courseType.label}<span className="redAstrisk">*</span> <RiInformationFill className={classes.infoButton} data-tooltip-id="courseTypeInfo" /></Form.Label>
                        <Form.Select className={formClass.selectInput}
                            name={formLabels.courseType.name}
                            value={values.courseType} onBlur={handleBlur}
                            onChange={(event) => changeCourseType(event)}
                            isInvalid={touched.courseType && errors.courseType}>
                            {courseTypes?.map((type, i) => (
                                <option key={i} value={type}>
                                    {type}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.courseType}</Form.Control.Feedback>
                        <Tooltip className={classes.tooltipMessage} id="courseTypeInfo"><span className={classes.tooltipMessage}>{parse(AppConstants.courseTypeDropdownInfo)}</span></Tooltip>
                    </Form.Group>
                </Row>
                <Row className="mb-2" >
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.title.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            required placeholder="Course Title"
                            type="text" maxLength={100}
                            name={formLabels.title.name}
                            value={values.title} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.title && errors.title}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.title}</Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Row className={`mb-12 mt-2 ${classes.textEditorContainer}`}>
                    <Form.Group className={classes.textEditorContainer} controlId="basicDetailsGuidelinesText">
                        <Form.Label className={`${formClass.formLabel} ${classes.textEditorLabel}`}>{formLabels.description.label}<span className="redAstrisk">*</span><RiInformationFill data-tooltip-id="courseDescriptionInfo" className={classes.infoButton} ></RiInformationFill></Form.Label>
                        <ReactQuill
                            formats={AppConstants.richTextEditorFormats} modules={AppConstants.richTextEditorModules}
                            onChange={setDescription}
                            value={description}
                            className={classes.textEditor} theme="snow" placeholder="Description of your course"
                        />
                        <Tooltip className={classes.tooltipMessage} id="courseDescriptionInfo"><span className={classes.tooltipMessage}>{AppConstants.courseDescriptionInfo}</span></Tooltip>
                    </Form.Group>
                </Row>

                <Row className="mb-2 mt-3">
                    <div className={classes.uploadFileRow}>
                        <div>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                                <FileUploadInput id={formLabels.descriptionDocument.name} name={formLabels.descriptionDocument.name} accept=".pdf" label={formLabels.descriptionDocument.label} handleFileChange={e => setFieldValue(formLabels.descriptionDocument.name, e.target.files[0])} infoMessage={AppConstants.courseBrochureInfo} inputFile={values.descriptionDocument} />
                                {errors.descriptionDocument && <div className="errorText">{errors.descriptionDocument}</div>}
                            </Form.Group>
                        </div>
                        {selectedCourseData.courseDescriptionDocumentAbsolutePath && <button type="button" onClick={() => { downloadFile(selectedCourseData.courseDescriptionDocumentAbsolutePath, formLabels.descriptionDocument.name); setDescriptionDocumentSpinner(true) }} data-tooltip-id="downloaddescriptionDoc" className={errors.descriptionDocument ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{descriptionDocumentSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        <button type="button" onClick={() => removeFile(formLabels.descriptionDocument.name)} data-tooltip-id="removedescriptionDoc" className={classes.removeBtn}><MdDelete /></button>
                        <Tooltip className={classes.tooltipMessage} id="removedescriptionDoc"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                    </div>
                </Row>
                <Row>
                    {skills?.length > 1 && <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.skill.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Select className={formClass.selectInput}
                            name={formLabels.skill.name}
                            value={values.skill} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.skill && errors.skill}>
                            <option value={AppConstants.emptyString} defaultValue>Select Skill</option>
                            {skills?.map(option => (
                                <option key={option.skillId} value={option.skillId}>
                                    {option.skillName}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.skill}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>
                {values.courseType === AppConstants.structuredCourseType && <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                    <Form.Group className={values.startDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.startDate.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.startDate.name} min={new Date().toISOString().split('T')[0]}
                            type="date"
                            value={values.startDate} onBlur={handleBlur}
                            onChange={(e) => changeStartDate(e)}
                            isInvalid={touched.startDate && errors.startDate}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.startDate}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.endDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.endDate.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.endDate.name} min={new Date().toISOString().split('T')[0]}
                            type="date"
                            value={values.endDate} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.endDate && errors.endDate}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.endDate}</Form.Control.Feedback>
                    </Form.Group>
                </Row>}
                <Row className="mb-2">
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.numberOfClasses.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="number"
                            min={0}
                            placeholder="Number of classes"
                            name={formLabels.numberOfClasses.name}
                            value={values.numberOfClasses} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidMobile}
                            isInvalid={touched.numberOfClasses && errors.numberOfClasses}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.numberOfClasses}</Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.durationInHours.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="number"
                            min={0}
                            placeholder="Total learning hours"
                            name={formLabels.durationInHours.name}
                            value={values.durationInHours} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidCost}
                            isInvalid={touched.durationInHours && errors.durationInHours}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.durationInHours}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.modeOfDelivery.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Select className={formClass.selectInput}
                            name={formLabels.modeOfDelivery.name}
                            value={values.modeOfDelivery} onBlur={handleBlur}
                            onChange={(event) => changeModeOfDelivery(event)}
                            isInvalid={touched.modeOfDelivery && errors.modeOfDelivery}>
                            <option value={AppConstants.emptyString} defaultValue>Select Type</option>
                            {modeOfDelivery?.map((type, i) => (
                                <option key={i} value={type}>
                                    {type}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.competitionType}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2">
                    {values.modeOfDelivery === modeOfDelivery[0] && <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>{formLabels.sessionLink.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="url" placeholder="Course session link"
                            name={formLabels.sessionLink.name}
                            value={values.sessionLink} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.sessionLink && errors.sessionLink} />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.sessionLink}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>
                <Row className="mb-2">
                    {values.modeOfDelivery === modeOfDelivery[1] && <Form.Group className="mb-2" as={Col} controlId="basicDetailVenue">
                        <Form.Label className={formClass.formLabel}>{formLabels.geoLocation.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            placeholder="Course venue location URL"
                            name={formLabels.geoLocation.name}
                            value={values.geoLocation} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.geoLocation && errors.geoLocation} />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.geoLocation}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>
                <Row>
                    {values.modeOfDelivery === modeOfDelivery[1] && <Form.Group className="mb-2" as={Col} controlId="basicDetailVenue">
                        <Form.Label className={formClass.formLabel}>{formLabels.address.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formTextAreaInput}
                            as="textarea"
                            placeholder="Course delivery address"
                            name={formLabels.address.name}
                            value={values.address} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.address && errors.address} />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.address}</Form.Control.Feedback>
                    </Form.Group>}
                </Row>
                <hr />
                <Row className="mb-2">
                    <Form.Label className={classes.contactLabel}>Class timings for the course</Form.Label>
                </Row>
                <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                    <Form.Group className={values.windowStartTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsStarttime">
                        <Form.Label className={formClass.formLabel}>{formLabels.windowStartTime.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.windowStartTime.name}
                            type="time"
                            value={values.windowStartTime} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.windowStartTime && errors.windowStartTime}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.windowStartTime}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.windowEndTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsEndTime">
                        <Form.Label className={formClass.formLabel}>{formLabels.windowEndTime.label}<span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.windowEndTime.name}
                            type="time"
                            value={values.windowEndTime} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.windowEndTime && errors.windowEndTime}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.windowEndTime}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2">
                    <Form.Label className={classes.contactLabel}>Select days<span className="redAstrisk">*</span></Form.Label>
                </Row>
                <div className={classes.weekCalendar}>
                    <WeekdaysSelect selectedValues={weekDaysArray} handleDaysSelection={handleDaysSelection} />
                    {errors.courseWeekDays && <div className="errorText mt-1">{errors.courseWeekDays}</div>}
                </div>
                <hr />
                {values.courseType === AppConstants.structuredCourseType && <Row className="mb-2 mt-2">
                    <Form.Group controlId="basicDetailsTitle" className={`${classes.registrationDetails} mb-2 mt-2`}>
                        <Form.Label className={`${formClass.formLabel} ${classes.registrationDetailsLabel}`}>Registration window <RiInformationFill className={classes.infoButton} data-tooltip-id="registrationEndInfoIcon" /></Form.Label>
                        <Tooltip className={classes.tooltipMessage} id="registrationEndInfoIcon"><span className={classes.tooltipMessage}>{AppConstants.courseRegistrationDeadlineInfo}</span></Tooltip>
                    </Form.Group>
                </Row>}

                {values.courseType === AppConstants.structuredCourseType && <Row xs={1} sm={2} md={2} lg={2} xl={2} className="mb-2">
                    <Form.Group className={values.registrationClosingDate === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="basicDetailsRegistrationDate">
                        <Form.Label className={formClass.formLabel}>{formLabels.registrationClosingDate.label}{(registrationTimeAdded || registrationDateAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.registrationClosingDate.name}
                            type="date"
                            min={new Date().toISOString().split('T')[0]}
                            isInvalid={touched.registrationClosingDate && errors.registrationClosingDate}
                            value={values.registrationClosingDate} onBlur={handleBlur}
                            onChange={(e) => changeRegistrationClosingDate(e)}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.registrationClosingDate}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className={values.registrationClosingTime === AppConstants.emptyString ? `mb-2 ${formClass.dateTimeEmptyInput}` : `mb-2 ${formClass.dateTimeInput}`} as={Col} controlId="registrationClosingTime">
                        <Form.Label className={formClass.formLabel}>{formLabels.registrationClosingTime.label}{(registrationTimeAdded || registrationDateAdded) && <span className="redAstrisk">*</span>}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            name={formLabels.registrationClosingTime.name}
                            type="time"
                            value={values.registrationClosingTime} onBlur={handleBlur}
                            onChange={(e) => changeRegistrationClosingTime(e)}
                            isInvalid={touched.registrationClosingTime && errors.registrationClosingTime}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.registrationClosingTime}</Form.Control.Feedback>
                    </Form.Group>
                </Row>}
                <Row xs={1} sm={2} md={1} lg={2} xl={2} className="mb-2">
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>Cost (INR) <span className="redAstrisk">*</span></Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="number"
                            placeholder="Course cost"
                            name={formLabels.cost.name}
                            value={values.cost} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidCost}
                            isInvalid={touched.cost && errors.cost}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.cost}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2" as={Col}>
                        <Form.Label className={formClass.formLabel}>Discount (INR) </Form.Label>
                        <Form.Control className={formClass.formInput}
                            type="number"
                            placeholder="Discount amount"
                            name={formLabels.discount.name}
                            value={values.discount} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidCost}
                            isInvalid={touched.discount && errors.discount}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid">{errors.discount}</Form.Control.Feedback>
                    </Form.Group>
                </Row>

                <Row>
                    <Form.Label className={classes.contactLabel}>SPOC Details</Form.Label>
                </Row>
                <Row className="mb-2" xs={1} sm={2} md={1} lg={2} xl={2}>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.expertName.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            placeholder="SPOC name"
                            type="text" maxLength={30}
                            name={formLabels.expertName.name}
                            value={values.expertName} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.expertName && errors.expertName}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.expertName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.expertMobile.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            placeholder="SPOC mobile number"
                            type="number"
                            name={formLabels.expertMobile.name}
                            value={values.expertMobile} onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={checkValidMobile}
                            isInvalid={touched.expertMobile && errors.expertMobile}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.expertMobile}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2">
                    <Form.Group className="mb-2">
                        <Form.Label className={formClass.formLabel}>{formLabels.expertEmail.label}</Form.Label>
                        <Form.Control className={formClass.formInput}
                            placeholder="SPOC email id"
                            type="text" maxLength={50}
                            name={formLabels.expertEmail.name}
                            value={values.expertEmail} onBlur={handleBlur}
                            onChange={handleChange}
                            isInvalid={touched.expertEmail && errors.expertEmail}
                        />
                        <Form.Control.Feedback className="errorText" type="invalid" >{errors.expertEmail}</Form.Control.Feedback>
                    </Form.Group>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <div>
                            <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                                <FileUploadInput id={formLabels.thumbnailImage.name} name={formLabels.thumbnailImage.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.thumbnailImage.label} handleFileChange={e => setFieldValue(formLabels.thumbnailImage.name, e.target.files[0])} inputFile={values.thumbnailImage} infoMessage={AppConstants.expertCreateCompetitionsThumbnailMessage} />
                                {errors.thumbnailImage && <div className="errorText">{errors.thumbnailImage}</div>}
                            </Form.Group>
                        </div>
                        {selectedCourseData.courseThumbnailImageAbsolutePath && <button type="button" onClick={() => { downloadFile(selectedCourseData.courseThumbnailImageAbsolutePath, formLabels.thumbnailImage.name); setThumbnailSpinner(true) }} data-tooltip-id="downloadThumbnail" className={errors.thumbnailImage ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{thumbnailSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        <button type="button" onClick={() => removeFile(formLabels.thumbnailImage.name)} data-tooltip-id="removeThumbnail" className={classes.removeBtn}><MdDelete /></button>
                        <Tooltip className={classes.tooltipMessage} id="removeThumbnail"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col} >
                            <FileUploadInput id={formLabels.promotionImageForWeb.name} name={formLabels.promotionImageForWeb.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForWeb.label} handleFileChange={e => setFieldValue(formLabels.promotionImageForWeb.name, e.target.files[0])} inputFile={values.promotionImageForWeb} infoMessage={AppConstants.expertCreateCompetitionsWebBannerMessage} />
                            {errors.promotionImageForWeb && <div className="errorText">{errors.promotionImageForWeb}</div>}
                        </Form.Group>
                        {selectedCourseData.coursePromotionWebImageAbsolutePath && <button type="button" onClick={() => { downloadFile(selectedCourseData.coursePromotionWebImageAbsolutePath, formLabels.promotionImageForWeb.name); setPromotionImageWebSpinner(true) }} data-tooltip-id="downloadWebImage" className={errors.promotionImageForWeb ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageWebSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        <button type="button" onClick={() => removeFile(formLabels.promotionImageForWeb.name)} data-tooltip-id="removeWebImage" className={classes.removeBtn}><MdDelete /></button>
                        <Tooltip className={classes.tooltipMessage} id="removeWebImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                            <FileUploadInput id={formLabels.promotionImageForTablet.name} name={formLabels.promotionImageForTablet.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForTablet.label} handleFileChange={e => setFieldValue(formLabels.promotionImageForTablet.name, e.target.files[0])} inputFile={values.promotionImageForTablet} infoMessage={AppConstants.expertCreateCompetitionsTabletBannerMessage} />
                            {errors.promotionImageForTablet && <div className="errorText">{errors.promotionImageForTablet}</div>}
                            <Tooltip className={classes.tooltipMessage} id="courseTabletBannerInfo"><span className={classes.tooltipMessage}>{parse(AppConstants.expertCreateCompetitionsTabletBannerMessage)}</span></Tooltip>
                        </Form.Group>
                        {selectedCourseData.coursePromotionTabletImageAbsolutePath && <button type="button" onClick={() => { downloadFile(selectedCourseData.coursePromotionTabletImageAbsolutePath, formLabels.promotionImageForTablet.name); setPromotionImageTabletSpinner(true) }} data-tooltip-id="downloadTabletImage" className={errors.promotionImageForTablet ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageTabletSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        <button type="button" onClick={() => removeFile(formLabels.promotionImageForTablet.name)} data-tooltip-id="removeTabletImage" className={classes.removeBtn}><MdDelete /></button>
                        <Tooltip className={classes.tooltipMessage} id="removeTabletImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                            <FileUploadInput id={formLabels.promotionImageForMobile.name} name={formLabels.promotionImageForMobile.name} accept="image/png,image/jpeg,image/jpg" label={formLabels.promotionImageForMobile.label} handleFileChange={e => setFieldValue(formLabels.promotionImageForMobile.name, e.target.files[0])} inputFile={values.promotionImageForMobile} infoMessage={AppConstants.expertCreateCompetitionsMobileBannerMessage} />
                            {errors.promotionImageForMobile && <div className="errorText">{errors.promotionImageForMobile}</div>}
                        </Form.Group>
                        {selectedCourseData.coursePromotionMobileImageAbsolutePath && <button type="button" onClick={() => { downloadFile(selectedCourseData.coursePromotionMobileImageAbsolutePath, formLabels.promotionImageForMobile.name); setPromotionImageMobileSpinner(true); }} data-tooltip-id="downloadMoblieImage" className={errors.promotionImageForMobile ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{promotionImageMobileSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        <button type="button" onClick={() => removeFile(formLabels.promotionImageForMobile.name)} data-tooltip-id="removeMobileImage" className={classes.removeBtn}><MdDelete /></button>
                        <Tooltip className={classes.tooltipMessage} id="removeMobileImage"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                    </div>
                </Row>
                <Row className="mb-2">
                    <div className={classes.uploadFileRow}>
                        <Form.Group className={`${formClass.fileUpload} mb-2`} as={Col}>
                            <FileUploadInput id={formLabels.promotionVideo.name} name={formLabels.promotionVideo.name} accept="video/mp4,video/mkv,video/x-m4v,video/*" label={formLabels.promotionVideo.label} handleFileChange={e => setFieldValue(formLabels.promotionVideo.name, e.target.files[0])} inputFile={values.promotionVideo} infoMessage={AppConstants.coursePromotionVideoInfo} />
                            {errors.promotionVideo && <div className="errorText">{errors.promotionVideo}</div>}
                        </Form.Group>
                        {selectedCourseData.coursePromotionVideoAbsolutePath && <button type="button" onClick={() => downloadVideoFile(selectedCourseData.coursePromotionVideoAbsolutePath, `${formLabels.promotionVideo.name}.${getFileExtension(selectedCourseData.coursePromotionVideoRelativePath)}`)} data-tooltip-id="downloadVideo" className={errors.promotionVideo ? `${classes.removeBtn} ${classes.iconError}` : classes.removeBtn}>{videoSpinner ? <BiLoaderAlt className="spinner" /> : <AiOutlineDownload />}</button>}
                        <button type="button" onClick={() => removeFile(formLabels.promotionVideo.name)} data-tooltip-id="removePromotionVideo" className={classes.removeBtn}><MdDelete /></button>
                        <Tooltip className={classes.tooltipMessage} id="removePromotionVideo"><span className={classes.tooltipMessage}>Remove file</span></Tooltip>
                    </div>
                </Row>
            </Form>
            <div className={classes.nextBtn}>
                <Button onClick={() => moveToNextSection()}>{AppConstants.nextText}</Button>
            </div>
        </div>
    )
}