import detailsSectionClasses from "./ExpressionOfInterestDetailsSection.module.css";
import { AppConstants } from "../../../constants/AppConstants";
import { CgEditBlackPoint } from 'react-icons/cg';

export const ExpressionOfInterestDetailsSection = () => {
    const detailsSectionHeading = AppConstants.eoiPageBenifitsSectionHeading;
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const skyRocketSectionContent = AppConstants.eoiPageSkyrocketQrenciaSection;
    const tapStudentsImage = `${mediaContent}${AppConstants.expressionOfInterestS3Path}/${AppConstants.eoiPageBenefitsTapStudentsGloballyPath}`
    const newModesPath = `${mediaContent}${AppConstants.expressionOfInterestS3Path}/${AppConstants.eoiPageBenefitsNewModesPath}`
    const aiTeachingPath = `${mediaContent}${AppConstants.expressionOfInterestS3Path}/${AppConstants.eoiPageBenefitsAiTeachingPath}`
    const productivityPath = `${mediaContent}${AppConstants.expressionOfInterestS3Path}/${AppConstants.eoiPageBenefitsBoostProductivityPath}`
    const highPayoutsPath = `${mediaContent}${AppConstants.expressionOfInterestS3Path}/${AppConstants.eoiPageBenefitsHighPayoutsPath}`

    return (
        <div>
            <p className={detailsSectionClasses.aboutQrenciaHeading}>About Qrencia</p>
            <div className={detailsSectionClasses.section}>
                <p className={detailsSectionClasses.aboutQrenciaText}>{AppConstants.eoiPageAboutQrenciaSection}</p>

                <div className={detailsSectionClasses.aboutQrenciaText}><p className={detailsSectionClasses.youCanOfferText}>You can offer</p>
                </div>
                <div className={detailsSectionClasses.aboutQrenciaText}>
                    <div className={detailsSectionClasses.offeringsSection}>
                        <div className={detailsSectionClasses.courseOffer}><CgEditBlackPoint /> Courses</div>
                        <div className={detailsSectionClasses.competitionOffer}><CgEditBlackPoint /> Competitions</div>
                        <div className={detailsSectionClasses.feedbackOffer}><CgEditBlackPoint /> Feedback</div>
                        <div className={detailsSectionClasses.workshopOffer}><CgEditBlackPoint /> Workshops</div>
                        <div className={detailsSectionClasses.mentorshipOffer}><CgEditBlackPoint /> Mentorships and More!</div>
                    </div>
                </div>
            </div>
            <p className={`mt-4 ${detailsSectionClasses.aboutQrenciaHeading}`}>{detailsSectionHeading}</p>

            <div className={detailsSectionClasses.section}>
                <div className={detailsSectionClasses.benefits}>
                    <img src={tapStudentsImage} className={detailsSectionClasses.sectionIcons} /><p className={detailsSectionClasses.benifitsMainText}><strong>Build Brand</strong>
                        <p className={detailsSectionClasses.benifitsSubText}>{skyRocketSectionContent.buildBrand}</p></p>
                </div>
                <div className={detailsSectionClasses.benefits}>
                    <img src={newModesPath} className={detailsSectionClasses.sectionIcons} /><p className={detailsSectionClasses.benifitsMainText}><strong>Earn More</strong>
                        <p className={detailsSectionClasses.benifitsSubText}>{skyRocketSectionContent.earnMore} </p></p>
                </div>
                <div className={detailsSectionClasses.benefits}>
                    <img src={aiTeachingPath} className={detailsSectionClasses.sectionIcons} /><p className={detailsSectionClasses.benifitsMainText}><strong>Expand Reach</strong>
                        <p className={detailsSectionClasses.benifitsSubText} >{skyRocketSectionContent.expandReach}</p></p>
                </div>
                <div className={detailsSectionClasses.benefits}>
                    <img src={productivityPath} className={detailsSectionClasses.sectionIcons} /><p className={detailsSectionClasses.benifitsMainText}><strong>Reduce Time</strong>
                        <p className={detailsSectionClasses.benifitsSubText} >{skyRocketSectionContent.reduceTime} </p></p>
                </div>
                <div className={`${detailsSectionClasses.benefits} ${detailsSectionClasses.lastLine}`}>
                    <img src={highPayoutsPath} className={detailsSectionClasses.sectionIcons} /><p className={detailsSectionClasses.benifitsMainText}><strong>Grow Business</strong>
                        <p className={detailsSectionClasses.benifitsSubText} > {skyRocketSectionContent.growBusiness}</p></p>
                </div>
            </div>
        </div>
    )
}
