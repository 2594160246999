import React, { useState, useEffect, useRef } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, Element } from 'react-scroll';
import { BiLinkExternal, BiLoaderAlt } from 'react-icons/bi';
import { FiDownloadCloud } from 'react-icons/fi';
import { AppConstants } from '../../constants/AppConstants';
import { ErrorMessages } from '../../constants/ErrorMessages';
import { SuccessMessage } from '../../constants/SuccessMessage';
import { ErrorSuccessAlertMessage } from '../errorSuccessMessage/ErrorSuccessAlertMessage';
import classes from "./MainCompetitionDetailsSection.module.css";
import "./MainCompetitionDetailsSectionNavbar.css"
import { RxCross2 } from 'react-icons/rx';
import { Button, Modal } from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment-timezone';
import moment from "moment";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { FileOperationsUtil } from '../../utils/FileOperationsUtil';
import parse from 'html-react-parser';
import Auth from '../../utils/Auth';
import { AiOutlineInfoCircle, AiOutlineExclamationCircle } from 'react-icons/ai';
import { Tooltip } from 'react-tooltip';
import modalClass from "../cards/CompetitionsCards.module.css";
import { FileUploadInput } from '../fileUploadInput/FileUploadInput';
import JSZip from 'jszip';
import { DateTime } from 'luxon';
import { AskQuestionModal } from '../modal/AskQuestionModal';
import { LoginModal } from '../modal/LoginModal';
import { CompetitionSolutionConfirmationModal } from '../modal/CompetitionSolutionConfimationModal';
import { FiUploadCloud } from "react-icons/fi";
import { useInAppBrowserStatus } from '../../hooks/useInAppBroswerStatus';

export const MainCompetitionDetailsSection = (props) => {
    const [competitionDetailsObj, setCompetitionDetailsObj] = useState(props.competitionData);
    const [uploadedFilePath, setUploadedFilePath] = useState(props.solutionUploadedPath);
    const [disableUploadSolution, setDisableUploadSolution] = useState(AppConstants.falseText);
    const [timeZone, setTimeZone] = useState(DateTimeUtil.getPreferredTimeZoneForUser());
    const [uploadChallenegeSpinner, setUploadChallenegeSpinner] = useState(false);
    const [uploadedSolutionFile, setUploadedSolutionFile] = useState(null);
    const [uploadedSolutionError, setUploadedSolutionError] = useState(false);
    const [uploadedSolutionErrorMessage, setUploadedSolutionErrorMessage] = useState(AppConstants.emptyString);
    const [uploadedSolutionErrorVarient, setUploadedSolutionErrorVarient] = useState(AppConstants.emptyString);
    const [certificateSpinner, setCertificateSpinner] = useState(false);
    const [resultSpinner, setResultSpinner] = useState(false);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const [showModal, setShowModal] = useState(false);
    const challengeRef = useRef(null);
    const [selectedSolutionFiles, setSelectedSolutionFiles] = useState(AppConstants.nullText);
    const [highlightSubmissions, setHighlightSubmissions] = useState(AppConstants.nullText);
    const [showQuestionModal, setShowQuestionModal] = useState(AppConstants.falseText);
    const [showLoginModal, setShowLoginModal] = useState(AppConstants.falseText);
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [emptyFileError, setEmptyFileError] = useState(AppConstants.emptyString);
    const isInAppBrowser = useInAppBrowserStatus();

    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultThumbnailImage}`;
    const solutionBgPath = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.yourPerformanceCardSolutionBgPath}`;
    const certificateBgPath = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.yourPerformanceCardCertificateBgPath}`;
    const performanceAnalysisBgPath = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.yourPerformanceCardPerformanceBgPath}`;
    const performanceRewardsPath = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.performanceAnalysisRewardBgPath}`;
    const prizeRewardsPath = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.prizeRewardBgPath}`;
    const certificateRewardsPath = `${mediaContent}${AppConstants.designImagesS3Path}${AppConstants.forwardSlash}${AppConstants.certificateRewardBgPath}`;

    //add active class on nav-item click
    function addActiveClass(e) {
        const elements = document.querySelectorAll('.active');
        elements.forEach((element, index) => {
            element.classList.remove("active");
            if (index === elements.length - 1) {
                e.target.className = "active";
            }
        });
        if (elements === null || elements === undefined || elements.length === 0) {
            e.target.className = "active";
        }
    }

    useEffect(() => {
        setDisableUploadSolution(uploadedFilePath === AppConstants.emptyString || uploadedFilePath === AppConstants.nullText ? AppConstants.falseText : AppConstants.trueText);
    }, []);

    //show challenge onload if competition is live
    useEffect(() => {
        const scrollToChallenge = () => {
            window.scrollTo({
                top: challengeRef?.current?.offsetTop - 42,
                behavior: 'smooth'
            })
        }
        if (competitionDetailsObj.competitionLive) {
            if (challengeRef?.current !== null) {
                setTimeout(() => {
                    scrollToChallenge();
                }, 100);
            }
        }
    }, [competitionDetailsObj.competitionLive, challengeRef?.current?.offsetTop]);

    useEffect(() => {
        setCompetitionDetailsObj(props.competitionData);
        let currentDate = moment(new Date());
        let competitionDate = DateTimeUtil.utcToPreferredTimezone(props.competitionData.competitionUTCStartTime);
        var duration = moment.duration(currentDate.diff(competitionDate));
        var hours = duration.asHours();

        if (hours > 0.5) {
            setHighlightSubmissions(AppConstants.trueText);
        } else {
            setHighlightSubmissions(AppConstants.falseText);
        }

    }, [props.competitionData]);

    const onCloseModal = () => {
        setShowModal(false);
    };

    //handle upload solution
    const handleSolutionFileChange = (e) => {
        setEmptyFileError(AppConstants.emptyString)
        let fileError = AppConstants.falseText;
        let fileTypeArray = AppConstants.executableFileTypes;
        let selectedFiles = e.target.files;
        let filesArray = [];
        if (selectedFiles && selectedFiles.length) {
            for (let i = 0; i < selectedFiles.length; i++) {
                if (selectedFiles[i]) {
                    const name = selectedFiles[i].name;
                    const lastDot = name.lastIndexOf('.');
                    const ext = name.substring(lastDot + 1);

                    if ((fileTypeArray.includes(ext.toUpperCase()))) {
                        document.getElementById("uploadSolutionFile").value = AppConstants.emptyString;
                        setUploadedSolutionFile(null);
                        setSelectedSolutionFiles(AppConstants.nullText)
                        setUploadedSolutionError(true);
                        fileError = AppConstants.trueText;
                        setUploadedSolutionErrorMessage(ErrorMessages.executableFileUploadError);
                        setUploadedSolutionErrorVarient(AppConstants.alertVarient[1]);
                        break;
                    } else {
                        filesArray.push({
                            file: selectedFiles[i],
                            id: Math.floor((Math.random() * 100000) + 1).toString()
                        });
                    }
                }
            }
            if (!fileError) {
                (uploadedSolutionFile !== null && uploadedSolutionFile !== undefined) ? setUploadedSolutionFile([...uploadedSolutionFile, ...selectedFiles]) : setUploadedSolutionFile([...selectedFiles]);
                (selectedSolutionFiles !== null && selectedSolutionFiles !== undefined) ? setSelectedSolutionFiles([...selectedSolutionFiles, ...filesArray]) : setSelectedSolutionFiles([...filesArray]);
            } else {
                setUploadedSolutionFile(null);
                setSelectedSolutionFiles(AppConstants.nullText)
            }
        } else {
            setUploadedSolutionFile(null);
            setSelectedSolutionFiles(AppConstants.nullText)
        }
    }

    const updateConfirmationStatus = (status) => {
        if (status) {
            uploadSolutionForCompetition(competitionDetailsObj);
        } else {
            if (uploadedSolutionErrorMessage === SuccessMessage.competitionUploadSolutionSuccess) {
                window.location.reload();
            }
            setShowConfirmationModal(AppConstants.falseText);
            setUploadChallenegeSpinner(AppConstants.falseText);
            setUploadedSolutionErrorMessage(AppConstants.emptyString);
        }
    };

    const confirmBeforeSolutionSubmission = () => {
        if (uploadedSolutionFile !== null && uploadedSolutionFile?.length > 0) {
            setShowConfirmationModal(AppConstants.trueText);
            setUploadedSolutionErrorMessage(AppConstants.emptyString);
        } else {
            setUploadChallenegeSpinner(false);
            setEmptyFileError(ErrorMessages.studentSubmitSolutionInvalidfileError);
            setTimeout(() => setEmptyFileError(AppConstants.emptyString), AppConstants.messageDisappearTime);
        }
    }

    //upload challenge
    async function uploadSolutionForCompetition(compDetails) {
        setUploadChallenegeSpinner(true);
        if (uploadedSolutionFile !== null && uploadedSolutionFile?.length > 0) {
            let fileToUpload;
            if (uploadedSolutionFile?.length === 1) {
                fileToUpload = uploadedSolutionFile[0];
            } else {
                const zip = new JSZip();
                for (let i = 0; i < uploadedSolutionFile.length; i++) {
                    const file = uploadedSolutionFile[i];
                    zip.file(file.name, file);
                }
                const content = await zip.generateAsync({ type: 'arraybuffer' });
                fileToUpload = new File([content], "solutionFile.zip", { type: AppConstants.contentTypeMap.zip });
            }
            const studentName = competitionDetailsObj.studentName.replace(/\s+/g, AppConstants.underscore)
            const path = `${AppConstants.competitionUploadFolder}${AppConstants.forwardSlash}${compDetails.competitionId}${AppConstants.forwardSlash}${AppConstants.solutionsText}${AppConstants.forwardSlash}${studentName}${AppConstants.underscore}${compDetails.studentQrenciaId}`
            const result = await FileOperationsUtil.uploadFileThroughPreSignedURL(fileToUpload, path, AppConstants.s3filePermissionPrivate, AppConstants.s3FileContentDispositionInline);
            if (result.objectKey !== undefined) {
                var solutionPath = result.objectKey;
                const data = {
                    "competitionId": compDetails.competitionId,
                    "uploadedSolutionPath": solutionPath
                }
                const url = AppConstants.competitionUploadChallengeAPI;
                const response = await AuthorizedDataService.postRequestWithResponseCode(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response !== undefined && response.ok && response.status === AppConstants.httpResponseCodes.responseCode200) {
                    document.getElementById("uploadSolutionFile").value = AppConstants.emptyString;
                    setUploadedSolutionFile(null);
                    setSelectedSolutionFiles(AppConstants.nullText)
                    setUploadChallenegeSpinner(false);
                    setUploadedSolutionError(true);
                    setUploadedSolutionErrorMessage(SuccessMessage.competitionUploadSolutionSuccess);
                    setUploadedSolutionErrorVarient(AppConstants.alertVarient[0]);
                    setDisableUploadSolution(true);
                } else if (!response.ok && response.status === AppConstants.httpResponseCodes.responseCode403) {
                    setUploadChallenegeSpinner(false);
                    setUploadedSolutionError(true);
                    setUploadedSolutionErrorMessage(ErrorMessages.studentSubmitSolutionSessionError);
                    setUploadedSolutionErrorVarient(AppConstants.alertVarient[1]);
                } else {
                    setUploadChallenegeSpinner(false);
                    setUploadedSolutionError(true);
                    setUploadedSolutionErrorMessage(ErrorMessages.studentSubmitSolutionError);
                    setUploadedSolutionErrorVarient(AppConstants.alertVarient[1]);
                }
            } else {
                setUploadChallenegeSpinner(false);
                setUploadedSolutionError(true);
                setUploadedSolutionErrorMessage(ErrorMessages.studentSubmitSolutionError);
                setUploadedSolutionErrorVarient(AppConstants.alertVarient[1]);
            }
        } else {
            setUploadChallenegeSpinner(false);
            setUploadedSolutionError(true);
            setUploadedSolutionErrorMessage(ErrorMessages.studentSubmitSolutionInvalidfileError);
            setUploadedSolutionErrorVarient(AppConstants.alertVarient[1]);
        }
    };


    async function downloadChallenge(competitionDetailsObj) {
        let response = await FileOperationsUtil.downloadProblemStatementFile(competitionDetailsObj.challengePath, competitionDetailsObj.competitionTitle);

        if (response) {
            const url = `${AppConstants.updateIsProblemStatementFlagAPI}${competitionDetailsObj.competitionId}`;
            AuthorizedDataService.put(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
        }
    }
    //Open Solution in new tab
    function downloadSolution(e, file) {
        var submissionsWindowName = AppConstants.submissionWindowName + competitionDetailsObj.competitionId + AppConstants.submissionWindowNamePartTwo + competitionDetailsObj.studentQrenciaId;
        let startIndex = file.lastIndexOf(".");
        let endIndex = file.indexOf("?");
        let fileExtension = file.substring(startIndex, endIndex);
        if (fileExtension === '.ogg' || fileExtension === '.avi' || fileExtension === '.mp4' || fileExtension === '.mov' || fileExtension === '.webm') {
            var submissionsWindow = window.open("", AppConstants.openInNewTab, "name=submissions,height=600,width=600");
            // Write the video tag to the new window.
            submissionsWindow.document.write('<video controls="controls" width="600" height="600" name="submission"><source src="' + file + '"></video>');

            // Play the video.
            submissionsWindow.document.querySelector('video').play();
        }
        else {
            var submissionsWindow = window.open(file, AppConstants.openInNewTab, "name=submissions,height=600,width=600");
            submissionsWindow.name = submissionsWindowName;
        }
        submissionsWindow.name = submissionsWindowName;
        submissionsWindow.document.title = submissionsWindowName;
    }

    // dowload certificate for competition
    async function downloadCertificate() {
        if (!certificateSpinner) {
            setCertificateSpinner(true);
            const url = `${AppConstants.downloadCertificateAPI}${competitionDetailsObj.competitionId}&studentQrenciaId=${competitionDetailsObj.studentQrenciaId}`;
            const name = `${competitionDetailsObj.competitionTitle}${AppConstants.hyphen}${AppConstants.certificateFileNameWithPDFExtension}`
            const fileName = name.replace(/\s+/g, AppConstants.hyphen);
            const response = await FileOperationsUtil.downloadFile(
                url,
                fileName,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                if (response.ok && response.status === 200) {
                    setCertificateSpinner(false);
                } else if (response.status === 409) {
                    setShowModal(true);
                    setCertificateSpinner(false);
                    setModalMessage(ErrorMessages.certificateNotAvailableError);
                } else {
                    setShowModal(true);
                    setCertificateSpinner(false);
                    setModalMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
                }
            } else {
                setShowModal(true);
                setCertificateSpinner(false);
                setModalMessage(`${ErrorMessages.fileDownloadError}${AppConstants.certificateTest}${ErrorMessages.fileDownloadErrorPartTwo}`);
            }
        }
    }

    const removeSolutionFile = () => {
        let input = document.getElementById("uploadSolutionFile");
        input.value = "";
        input.type = "";
        input.type = "file";
        setUploadedSolutionFile(null);
        setSelectedSolutionFiles(AppConstants.nullText)
    }

    async function downloadResult() {
        if (!resultSpinner) {
            setResultSpinner(true);
            const url = `${AppConstants.downloadCompetitionResultAPI}${competitionDetailsObj.competitionId}${AppConstants.downloadCompetitionResultSecondAPI}${competitionDetailsObj.studentQrenciaId}`;
            const name = `${competitionDetailsObj.title}${AppConstants.hyphen}${AppConstants.competitionResultFileName}`
            const fileName = name.replace(/\s+/g, AppConstants.hyphen);
            const response = await FileOperationsUtil.downloadFile(
                url,
                fileName,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                if (response.ok) {
                    setResultSpinner(false);
                } else {
                    setShowModal(true);
                    setResultSpinner(false);
                    setModalMessage(ErrorMessages.downloadCompetitionResultError);
                }
            } else {
                setShowModal(true);
                setResultSpinner(false);
                setModalMessage(ErrorMessages.downloadCompetitionResultError);
            }
        }
    }

    const removeAttachedFile = (fileId, indexToRemove) => {
        if (!uploadChallenegeSpinner) {
            if (selectedSolutionFiles?.length === 1) {
                removeSolutionFile();
                return;
            }
            setSelectedSolutionFiles(file => file.filter(fileData => fileData.id !== fileId));

            const updatedFiles = [];
            for (let i = 0; i < uploadedSolutionFile.length; i++) {
                if (i !== indexToRemove) {
                    updatedFiles.push(uploadedSolutionFile[i]);
                }
            }
            const dataTransfer = new DataTransfer();

            for (const file of updatedFiles) {
                dataTransfer.items.add(file);
            }

            setUploadedSolutionFile(dataTransfer.files);
        }
    };

    const handleAskQuestion = () => {
        if (Auth.isLogin()) {
            if (Auth.getLoggedInUserDetails().userRole !== AppConstants.userRoleStudent) {
                setModalMessage(ErrorMessages.onlyStudentRaiseCourseQueryError);
                setShowModal(true);
            } else {
                setShowQuestionModal(true);
            }
        } else {
            setShowLoginModal(AppConstants.trueText);
        }
    };

    const closeQuestionModal = () => {
        setShowQuestionModal(false);
    };

    const hideLoginModal = () => {
        if (Auth.isLogin()) {
            window.location.reload();
        }
        setShowLoginModal(AppConstants.falseText);
    };

    return (
        <>
            <div className={classes.mainContainerSection}>
                <div className={classes.thumbnailSection}>
                    <div className={classes.thumbnailContainer}>
                        <img className={classes.thumbnail} src={competitionDetailsObj.competitionThumbnailImagePath !== null ? competitionDetailsObj.competitionThumbnailImagePath : defaultThumbnail} alt="Competition Thumbnail"></img>
                    </div>
                    <div className={classes.thumbnailDetailsSection}>
                        <div className={classes.titleContainer}>
                            <div className={classes.competitionTitle}>{competitionDetailsObj.competitionTitle}</div>
                            {(DateTime.fromISO(competitionDetailsObj.competitionUTCEndTime?.replace(' ', 'T') + 'Z').setZone(timeZone)) > DateTime.local().setZone(timeZone) && <button onClick={handleAskQuestion} className={classes.askQuestionButton}>Ask a question</button>}
                        </div>
                        {/* <p className={classes.competitionTitle}>{competitionDetailsObj.competitionTitle}</p> */}
                        <p className={classes.competitionDate}>{competitionDetailsObj.competitionType}</p>
                        <p className={classes.competitionDate}><Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> - <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</p>
                        {competitionDetailsObj.competitionType.toUpperCase() === AppConstants.offlineText && <a className={classes.competitionLink} target={AppConstants.openInNewTab} href={competitionDetailsObj.competitionGeoLocationLink}>{competitionDetailsObj.competitionVenueAddress}</a>}
                        {competitionDetailsObj.competitionType.toUpperCase() === AppConstants.onlineText && competitionDetailsObj.competitionVcLink !== null && <p className={classes.competitionLink}>Join Now : <a className={classes.competitionLink} target={AppConstants.openInNewTab} href={competitionDetailsObj.competitionVcLink}>{competitionDetailsObj.competitionVCLinkType === "Others" ? AppConstants.emptyString : competitionDetailsObj.competitionVCLinkType}<BiLinkExternal className="mb-1" /></a></p>}
                        <div className={`${classes.competitionDate} ${classes.flexDisplay}`}> <div className={classes.marginSkillSection}>Skill &nbsp;</div><p className={classes.competitionSkillHashtag}>#{competitionDetailsObj.competitionSkillName}</p></div>
                        {(DateTime.fromISO(competitionDetailsObj.competitionUTCEndTime?.replace(' ', 'T') + 'Z').setZone(timeZone)) > DateTime.local().setZone(timeZone) && <button onClick={handleAskQuestion} className={classes.askQuestionButtonBelow}>Ask a question</button>}
                    </div>
                </div>
                <div className={classes.competitionNavBarContainer}>
                    <div className="mainCompetitionDetailsSectionNavbar">
                        <Navbar>
                            <Nav id="navList">
                                <Link onClick={(e) => { addActiveClass(e) }} to="details" spy={true} offset={-43} duration={100}>Details</Link>
                                <Link onClick={(e) => { addActiveClass(e) }} to="dates" spy={true} offset={-42} duration={100}>Dates</Link>
                                {(Auth.isLogin() && (competitionDetailsObj.challengeAdded && competitionDetailsObj.competitionLive) && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.roleStudent && competitionDetailsObj.competitionComplete === false && new Date() < new Date(DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCEndTime))) &&
                                    <Link onClick={(e) => { addActiveClass(e) }} to="challenge" spy={true} offset={-44} duration={100}>Challenge</Link>}
                                {(Auth.isLogin() && (competitionDetailsObj.submissionAllowed && competitionDetailsObj.competitionLive) && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.roleStudent && competitionDetailsObj.competitionComplete === false && new Date() < new Date(DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCEndTime))) &&
                                    <Link className={highlightSubmissions ? "highlightSubmission" : null} onClick={(e) => { addActiveClass(e) }} to="challenge" spy={true} offset={-44} duration={100}>Submissions</Link>}
                                {Auth.isLogin() && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.competitionComplete && competitionDetailsObj.roleStudent && (competitionDetailsObj.disablePerformanceResultFlag === AppConstants.nText || competitionDetailsObj.solutionUploaded) && (competitionDetailsObj.resultDeclaredFlag === "Y" || competitionDetailsObj.solutionUploaded || (competitionDetailsObj.resultDeclaredFlag === "N" && !competitionDetailsObj.resultDate)) &&
                                    <Link onClick={(e) => { addActiveClass(e) }} to="yourPerformance" spy={true} offset={-44} duration={100}>Analysis</Link>}
                                {Auth.isLogin() && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.templateId !== 0 && competitionDetailsObj.templateId !== null && competitionDetailsObj.competitionComplete && competitionDetailsObj.roleStudent && (competitionDetailsObj.resultDeclaredFlag === "Y" || (competitionDetailsObj.resultDeclaredFlag === "N" && !competitionDetailsObj.resultDate)) &&
                                    <Link onClick={(e) => { addActiveClass(e) }} to="yourPerformance" spy={true} offset={-44} duration={100}>Certificate</Link>}
                                {(competitionDetailsObj.disablePerformanceResultFlag === AppConstants.nText || (competitionDetailsObj.templateId !== null && competitionDetailsObj.templateId !== 0) || (props.rewards && props.rewards.isActiveFlag === AppConstants.yText && (props.rewards.firstPrize || props.rewards.secondPrize || props.rewards.thirdPrize || props.rewards.fourthPrize))) &&
                                    <Link onClick={(e) => { addActiveClass(e) }} to="rewards" spy={true} offset={-42} duration={100}>Rewards</Link>}
                                <Link onClick={(e) => { addActiveClass(e) }} to="organizer" spy={true} offset={-44} duration={100}>Organizer</Link>
                            </Nav>
                        </Navbar>
                    </div>
                </div>
                <Element id="details">
                    <div className={classes.competitionDetailsSection}>
                        <h1 className={classes.competitionDetailsHeading}>About the Competition</h1>
                        <ul className={classes.competitionDetailsContainer}>
                            {competitionDetailsObj.competitionDescription && <li><strong>Description</strong></li>}

                            {competitionDetailsObj.competitionDescription && <div className={`${classes.guidelinesText} quillListIndent ql-editor ${classes.quillEditor}`}>{parse(competitionDetailsObj.competitionDescription)}</div>}
                            {(competitionDetailsObj.competitionGuidelinesText !== null) && <li><strong>General Rules and Guidelines</strong></li>}
                            {(competitionDetailsObj.competitionGuidelinesText !== null) && <div className={`${classes.guidelinesText} quillListIndent ql-editor  ${classes.quillEditor}`}>{parse(competitionDetailsObj.competitionGuidelinesText)}</div>}

                            {competitionDetailsObj.competitionGuidelinesText === null && competitionDetailsObj.competitionGuidelinesDocPath !== null && <li><strong>General Rules and Guidelines</strong></li>}
                            {competitionDetailsObj.competitionGuidelinesText === null && competitionDetailsObj.competitionGuidelinesDocPath !== null && <div className='mt-2'><a target={AppConstants.openInNewTab} href={competitionDetailsObj.competitionGuidelinesDocPath}>Read Here <BiLinkExternal /></a></div>}
                            {competitionDetailsObj.competitionGuidelinesDocPath === null && competitionDetailsObj.competitionGuidelinesText === null && <li><strong>General Rules and Guidelines</strong></li>}
                            {competitionDetailsObj.competitionGuidelinesDocPath === null && competitionDetailsObj.competitionGuidelinesText === null && <div className='mt-2'>{AppConstants.notAvailableText}<br></br> </div>}
                        </ul>
                    </div>
                </Element>
                <Element id="dates">
                    <div className={classes.competitionDetailsSection}>
                        <h1 className={classes.competitionDetailsHeading}>Attempt & Submission Timelines</h1>
                        <ul>
                            <li className={`${classes.basicDetail} ${classes.dateColumn}`}><strong>Competition Date & Time</strong></li> <div className={classes.dateColumn}>: <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCStartTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> - <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', 'T') + 'Z'}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCEndTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</div><br></br>
                            <li className={`${classes.basicDetail} ${classes.dateColumn}`}><strong>Registration Closing Time</strong></li> <div className={classes.dateColumn}>: <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCRegistrationCloseTime?.replace(' ', 'T') + 'Z'}</Moment> | <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competitionDetailsObj.competitionUTCRegistrationCloseTime?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</div><br></br>
                            {competitionDetailsObj.resultDate && <li className={classes.dateColumn}><strong>Results {competitionDetailsObj.resultDeclared}</strong></li>} {competitionDetailsObj.resultDate && <div className={classes.dateColumn}>: <Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{competitionDetailsObj.resultDate}</Moment> {timeZone === AppConstants.utcTimezone && `(${competitionDetailsObj.preferredTimezone})`}</div>}
                        </ul>
                    </div>
                </Element>
                {(Auth.isLogin() && (competitionDetailsObj.challengeAdded || (competitionDetailsObj.submissionAllowed && competitionDetailsObj.competitionLive)) && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.roleStudent && competitionDetailsObj.competitionComplete === false && new Date() < new Date(DateTimeUtil.utcToPreferredTimezone(competitionDetailsObj.competitionUTCEndTime))) &&
                    <Element id="challenge">
                        <div ref={challengeRef} className={classes.competitionDetailsSection}>
                            {competitionDetailsObj.challengeAdded && <h1 className={classes.competitionDetailsHeading}>Challenge</h1>}
                            {!competitionDetailsObj.challengeAdded && competitionDetailsObj.submissionAllowed && <h1 className={classes.competitionDetailsHeading}>Submisions</h1>}
                            <>
                                {competitionDetailsObj.challengeAdded && <>
                                    {(Auth.isLogin() && competitionDetailsObj.challengeAdded && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.competitionLive && competitionDetailsObj.competitionComplete === false) ?
                                        <div className={classes.competitionChallengeContainer}>
                                            <p><strong>Challenge</strong><span data-tooltip-place="right" data-tooltip-id="downloadChallengeId"><AiOutlineInfoCircle className={classes.iButton} /></span></p>
                                            <Tooltip className={classes.tooltipMessage} id="downloadChallengeId">{AppConstants.ibuttonMessageDownloadChallenge}</Tooltip>
                                            {!isInAppBrowser ? <Button className={classes.downloadChallengeButton} onClick={() => { downloadChallenge(competitionDetailsObj) }}>Download <FiDownloadCloud /></Button> :
                                                <Button className={classes.downloadChallengeButton} href={competitionDetailsObj.challengePath} >Download <FiDownloadCloud /></Button>}
                                        </div> : <div className={classes.competitionChallengeContainer}>
                                            <p><strong>Challenge</strong><span data-tooltip-place="right" data-tooltip-id="competitionNotLiveId"><AiOutlineInfoCircle className={classes.iButton} /></span></p>
                                            <Tooltip className={classes.tooltipMessage} id="competitionNotLiveId">{AppConstants.ibuttonMessageWhenCompetitionNotLive}</Tooltip>
                                        </div>}
                                </>}
                                {(Auth.isLogin() && competitionDetailsObj.submissionAllowed && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.competitionLive && competitionDetailsObj.competitionComplete === false) && <>
                                    <div className={classes.competitionChallengeContainer}>
                                        <p><strong>Solution</strong><span data-tooltip-place="right" data-tooltip-id="uploadSolutionId"><AiOutlineInfoCircle className={classes.iButton} /></span></p>
                                        <Tooltip className={classes.tooltipMessage} border={true} id="uploadSolutionId">{AppConstants.ibuttonMessageUploadSolution}</Tooltip>
                                        {!disableUploadSolution && <div>
                                            <div className={classes.uploadSolution}>
                                                <FileUploadInput multiple={true} disabled={disableUploadSolution || uploadChallenegeSpinner} id="uploadSolutionFile" name="uploadSolutionFile" label="" handleFileChange={event => handleSolutionFileChange(event)} inputFile={uploadedSolutionFile} placeholder={"Select file(s)"} />
                                                {/* {selectedSolutionFiles?.length > 0 && <p onClick={removeSolutionFile} className='removeFileText'>Remove</p>} */}
                                            </div>
                                            {selectedSolutionFiles?.length > 0 && <div className={classes.attachedFiles}>
                                                {
                                                    selectedSolutionFiles?.map((fileData, idx) => <div className={classes.filePreview} key={fileData.id}>
                                                        <div className={classes.filePreviewName}>{fileData.file.name}</div>
                                                        <div className={`${classes.filePreviewIcon} ${uploadChallenegeSpinner ? classes.disabledRemoveFile : ""}`}><RxCross2 onClick={() => removeAttachedFile(fileData.id, idx)} /></div>
                                                    </div>)
                                                }
                                            </div>}
                                            <Button className={classes.uploadChallengeButton} onClick={confirmBeforeSolutionSubmission} disabled={disableUploadSolution || uploadChallenegeSpinner}>Upload <FiUploadCloud /></Button>
                                        </div>}
                                        {disableUploadSolution && <div className={classes.downloadButtonContainer}>
                                            <p className={classes.fileExistsMessage}>{ErrorMessages.solutionAlreadyUploaded}</p>
                                            <a className={classes.downloadSolutionButton} onClick={(e) => downloadSolution(e, uploadedFilePath)} >{ErrorMessages.downloadSolution}</a>
                                        </div>}
                                    </div>
                                </>}
                                {emptyFileError && <ErrorSuccessAlertMessage message={emptyFileError} varient={AppConstants.alertVarient[1]} />}
                            </>
                        </div>
                    </Element>}
                {Auth.isLogin() && competitionDetailsObj.hasStudentEnrolled && competitionDetailsObj.competitionComplete && competitionDetailsObj.roleStudent && (competitionDetailsObj.disablePerformanceResultFlag === AppConstants.nText || competitionDetailsObj.solutionUploaded || (competitionDetailsObj.templateId !== null && competitionDetailsObj.templateId !== 0)) && (competitionDetailsObj.resultDeclaredFlag === "Y" || (competitionDetailsObj.resultDeclaredFlag === "N" && !competitionDetailsObj.resultDate) || competitionDetailsObj.solutionUploaded) &&
                    <Element id="yourPerformance">
                        <div className={classes.competitionDetailsSection}>
                            <h1 className={classes.competitionDetailsHeading}>Your Performance</h1>
                            <div className={classes.YourPerformanceContainer}>
                                {competitionDetailsObj.solutionUploaded &&
                                    <div className={classes.YourPerformanceCard}>
                                        <img className={classes.YourPerformanceCardImage} src={solutionBgPath} alt="" />
                                        <p className={classes.YourPerformanceDownloadText}>Solution</p>
                                        <Button onClick={(e) => downloadSolution(e, uploadedFilePath)} className={classes.YourPerformanceDownloadButton}>Download <FiDownloadCloud /></Button>
                                    </div>}
                                {competitionDetailsObj.disablePerformanceResultFlag === AppConstants.nText && (competitionDetailsObj.resultDeclaredFlag === "Y" || (competitionDetailsObj.resultDeclaredFlag === "N" && !competitionDetailsObj.resultDate)) && <div className={classes.YourPerformanceCard}>
                                    <img className={classes.YourPerformanceCardImage} src={performanceAnalysisBgPath} alt="" />
                                    <p className={classes.YourPerformanceDownloadText}>Performance Analysis</p>
                                    <Button target={AppConstants.openInNewTab} href={`${AppConstants.forwardSlash}${AppConstants.studentPerformanceText}${AppConstants.forwardSlash}${competitionDetailsObj.competitionId}${AppConstants.forwardSlash}${Auth.getLoggedInUserDetails().userUniqueId}`} className={classes.YourPerformanceDownloadButton}>View Report <BiLinkExternal /></Button>
                                </div>}
                                {competitionDetailsObj.templateId !== null && competitionDetailsObj.templateId !== 0 && (competitionDetailsObj.resultDeclaredFlag === "Y" || (competitionDetailsObj.resultDeclaredFlag === "N" && !competitionDetailsObj.resultDate)) && <div className={classes.YourPerformanceCard}>
                                    <img className={classes.YourPerformanceCardImage} src={certificateBgPath} alt="" />
                                    <p className={classes.YourPerformanceDownloadText}>Competition Certificate</p>
                                    <Button onClick={downloadCertificate} className={classes.YourPerformanceDownloadButton}>Download {certificateSpinner ? <BiLoaderAlt className='spinner' /> : <FiDownloadCloud />}</Button>
                                </div>}
                            </div>
                        </div>
                    </Element>}
                {(competitionDetailsObj.disablePerformanceResultFlag === AppConstants.nText || (competitionDetailsObj.templateId !== null && competitionDetailsObj.templateId !== 0) || (props.rewards && props.rewards.isActiveFlag === AppConstants.yText && (props.rewards.firstPrize || props.rewards.secondPrize || props.rewards.thirdPrize || props.fourthPrize))) && <Element id="rewards">
                    <div className={`${classes.competitionDetailsSection}`}>
                        <h1 className={classes.competitionDetailsHeading}>Rewards</h1>
                        <div className={classes.YourPerformanceContainer}>
                            {competitionDetailsObj.disablePerformanceResultFlag === AppConstants.nText && <div className={classes.YourPerformanceCard}>
                                <img className={classes.rewardsImage} src={performanceRewardsPath} alt="" />
                                <div className={classes.rewardsSectionTextContainer}>
                                    <p className={classes.rewardsText}>Performance<br /> Analysis</p>
                                </div>
                            </div>}
                            {competitionDetailsObj.templateId !== null && competitionDetailsObj.templateId !== 0 && <div className={classes.YourPerformanceCard}>
                                <img className={classes.rewardsImage} src={certificateRewardsPath} alt="" />
                                <div className={classes.rewardsSectionTextContainer}>
                                    <p className={classes.rewardsText}>Competition<br /> Certificate</p>
                                </div>
                            </div>}
                            {props.rewards && props.rewards?.isActiveFlag === AppConstants.yText && props.rewards?.firstPrize && <div className={classes.YourPerformanceCard}>
                                <img className={classes.rewardsImage} src={prizeRewardsPath} alt="" />
                                <div className={classes.rewardsSectionTextContainer}>
                                    <p className={classes.rewardsText}>Winner <br /><span className={classes.rewardsPrizeMoney}>{props.rewards?.firstPrize}</span></p>
                                </div>
                            </div>}
                            {props.rewards && props.rewards?.isActiveFlag === AppConstants.yText && props.rewards?.secondPrize && <div className={classes.YourPerformanceCard}>
                                <img className={classes.rewardsImage} src={prizeRewardsPath} alt="" />
                                <div className={classes.rewardsSectionTextContainer}>
                                    <p className={classes.rewardsText}>First Runner Up<br /><span className={classes.rewardsPrizeMoney}>{props.rewards?.secondPrize}</span><br /></p>
                                </div>
                            </div>}
                            {props.rewards && props.rewards?.isActiveFlag === AppConstants.yText && props.rewards?.thirdPrize && <div className={classes.YourPerformanceCard}>
                                <img className={classes.rewardsImage} src={prizeRewardsPath} alt="" />
                                <div className={classes.rewardsSectionTextContainer}>
                                    <p className={classes.rewardsText}>Second Runner Up <br /><span className={classes.rewardsPrizeMoney}>{props.rewards?.thirdPrize}</span></p>
                                </div>
                            </div>}
                            {props.rewards && props.rewards?.isActiveFlag === AppConstants.yText && props.rewards?.fourthPrize && <div className={classes.YourPerformanceCard}>
                                <img className={classes.rewardsImage} src={prizeRewardsPath} alt="" />
                                <div className={classes.rewardsSectionTextContainer}>
                                    <p className={classes.rewardsText}>Third Runner Up <br /><span className={classes.rewardsPrizeMoney}>{props.rewards?.fourthPrize}</span></p>
                                </div>
                            </div>}
                        </div>
                    </div>
                </Element>}
                <Element id="organizer">
                    <div className={classes.competitionDetailsSection}>
                        <h1 className={classes.competitionDetailsHeading}>About Organizer</h1>
                        <div className={classes.profilePicMainCnt}>
                            <div className={classes.profilePicPreviewCnt}>
                                {competitionDetailsObj.expertProfilePicPath !== null && <a target={AppConstants.openInNewTab} href={competitionDetailsObj.userRole === AppConstants.userRoleExpert ? `/expert/${competitionDetailsObj.userSlug}` : `/agency/${competitionDetailsObj.userSlug}`}><img alt='Profile Avtar' className={classes.previewProfilePic} src={competitionDetailsObj.expertProfilePicPath}></img></a>}
                                {competitionDetailsObj.expertProfilePicPath === null && <p className={classes.initialProfilePreview}>{competitionDetailsObj.expertInitialName}</p>}
                            </div>
                            {competitionDetailsObj.userRole === AppConstants.userRoleExpert && <div className={classes.profilePicPreviewDetails}>
                                <p><strong>Name</strong> : <a className={classes.nameLink} target={AppConstants.openInNewTab} href={`/expert/${competitionDetailsObj.userSlug}`}>{competitionDetailsObj.expertName}</a></p>
                                <p><strong>Skill Expertise</strong> : {competitionDetailsObj.expertSkills}</p>
                                {competitionDetailsObj.expertOrganizationName !== null && competitionDetailsObj.expertOrganizationName !== "N/A" && <p><strong>Organization</strong> : {competitionDetailsObj.expertOrganizationName}</p>}
                            </div>}
                            {competitionDetailsObj.userRole === AppConstants.userRoleAgency && <div className={classes.profilePicPreviewDetails}>
                                <p><strong>Agency Name</strong> : <a className={classes.nameLink} target={AppConstants.openInNewTab} href={`/agency/${competitionDetailsObj.userSlug}`}>{competitionDetailsObj.agencyName}</a></p>
                            </div>}
                        </div>
                    </div>
                </Element>
                {competitionDetailsObj.competitionPromotionalAdImageOne !== null && <div className={classes.competitionAdsSection}>
                    <div>
                        <img src={competitionDetailsObj.competitionPromotionalAdImageOne} className={classes.competitionAdImage} alt="Advertisment" />
                    </div>
                </div>}
            </div>
            {showConfirmationModal && <CompetitionSolutionConfirmationModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={{ id: competitionDetailsObj.competitionStringId, title: competitionDetailsObj.competitionTitle }} uploadChallenegeSpinner={uploadChallenegeSpinner} uploadedSolutionErrorMessage={uploadedSolutionErrorMessage} uploadedSolutionErrorVarient={uploadedSolutionErrorVarient} />}
            {showQuestionModal && <AskQuestionModal learningActivityDetails={{ id: competitionDetailsObj.id, title: competitionDetailsObj.competitionTitle, learningActivityType: AppConstants.learningActivitiesTypes.competition, organizerEmail: competitionDetailsObj.expertEmail, organizerName: competitionDetailsObj.expertName, agencyName: competitionDetailsObj.agencyName, userRole: competitionDetailsObj.userRole }} closeQuestionModal={closeQuestionModal} />}
            {showLoginModal && <LoginModal onShow={() => setShowLoginModal(AppConstants.trueText)} onClose={hideLoginModal} />}
            <Modal show={showModal} onHide={onCloseModal} backdrop="static" keyboard={false} centered>
                <Modal.Body className={modalClass.modalBody}>
                    {<div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>
                        {modalMessage === ErrorMessages.onlyStudentRaiseCourseQueryError && <p><b>{competitionDetailsObj.competitionStringId} | {competitionDetailsObj.competitionTitle}</b></p>}
                        {modalMessage}
                    </div>
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    <Button className={modalClass.modalCloseButton} onClick={onCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};