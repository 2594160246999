import { useCallback, useEffect, useState } from "react";
import { AppConstants } from "../../constants/AppConstants";
import Auth from "../../utils/Auth";
import { DataService } from "../../services/DataService";
import Carousel from "react-multi-carousel";
import classes from "./CreatorHomePageCarousel.module.css";
import { Button, Modal } from "react-bootstrap";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import modalClass from "../cards/CompetitionsCards.module.css";
import { AiOutlineCheckCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import { BiLoaderAlt } from "react-icons/bi";
import { SuccessMessage } from "../../constants/SuccessMessage";
import { ErrorMessages } from "../../constants/ErrorMessages";

export const CreatorHomePageCarousel = ({ agencyType }) => {
    const [desktopImage, setDesktopImage] = useState([]);
    const [mobileImage, setMobileImage] = useState([]);
    const [ambassadorChallengeRequestSpinner, setAmbassadorChallengeRequestSpinner] = useState(AppConstants.falseText);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(AppConstants.falseText);
    const [showCarousel, setShowCarousel] = useState(AppConstants.falseText);

    const fetchCarouselData = useCallback(async (challengeStatus) => {
        const userRole = Auth.getUserRole();
        const url = `${AppConstants.pageSpecificContentAPI}${AppConstants.creatorHomePageName}`
        const response = await DataService.get(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response) {
            const desktopBannerImages = response.slides?.filter(image => image.imagePath.toLowerCase().includes(AppConstants.desktopText));
            const mobileBannerImages = response.slides?.filter(image => image.imagePath.toLowerCase().includes(AppConstants.mobileText))
            if (challengeStatus === AppConstants.falseText && (userRole === AppConstants.userRoleExpert || (userRole === AppConstants.userRoleAgency && agencyType === AppConstants.agencyTypes.academy))) {
                setDesktopImage(desktopBannerImages);
                setMobileImage(mobileBannerImages);
                if (desktopBannerImages.length > 1) {
                    setShowCarousel(AppConstants.trueText);
                } else {
                    setShowCarousel(AppConstants.falseText);
                }
            } else {
                setDesktopImage(desktopBannerImages.filter(image => !image.imagePath.toLowerCase().includes(AppConstants.ambassadorText.toLowerCase())));
                setMobileImage(mobileBannerImages.filter(image => !image.imagePath.toLowerCase().includes(AppConstants.ambassadorText.toLowerCase())));
                if (desktopBannerImages.filter(image => !image.imagePath.toLowerCase().includes(AppConstants.ambassadorText.toLowerCase())) > 1) {
                    setShowCarousel(AppConstants.trueText);
                } else {
                    setShowCarousel(AppConstants.falseText);
                }
            }
        } else {
            setDesktopImage([]);
            setMobileImage([]);
            setShowCarousel(AppConstants.falseText);
        }
    }, [agencyType]);

    const fetchAmbassadorChallengeStatus = useCallback(async () => {
        const url = AppConstants.hasCreatorAcceptedAmbassadorChallengeAPI;
        const response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyString);

        if (response) {
            fetchCarouselData(response)
        } else {
            fetchCarouselData(AppConstants.falseText)
        }
    }, [fetchCarouselData]);


    useEffect(() => {
        fetchAmbassadorChallengeStatus();
    }, [fetchAmbassadorChallengeStatus]);

    const responsiveSlider = {
        desktop: {
            breakpoint: { max: 3000, min: 991 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 990, min: 481 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 480, min: 0 },
            items: 1
        }
    };

    const sendAmbassadorChallengeRequest = async () => {
        const url = AppConstants.acceptAmbassadorChallengeAPI;
        setAmbassadorChallengeRequestSpinner(AppConstants.trueText);
        const response = await AuthorizedDataService.postRequestWithResponseCode(
            url,
            AppConstants.emptyString,
            AppConstants.emptyString
        );

        if (response.ok) {
            setModalMessage(SuccessMessage.ambassadorChallengeAcceptedMessage);
            setModalTypeError(AppConstants.falseText);
            setAmbassadorChallengeRequestSpinner(AppConstants.falseText);
            setShowModal(AppConstants.trueText)
            fetchAmbassadorChallengeStatus();
        } else if (response.status === AppConstants.httpResponseCodes.responseCode403) {
            setModalMessage("Your session has been expired. Please login and try again");
            setModalTypeError(AppConstants.trueText);
            setAmbassadorChallengeRequestSpinner(AppConstants.falseText);
            setShowModal(AppConstants.trueText);
        } else if (response.status === AppConstants.httpResponseCodes.responseCode409) {
            setModalMessage(ErrorMessages.ambassadorChallengeAlreadyAcceptedError);
            setModalTypeError(AppConstants.trueText);
            setAmbassadorChallengeRequestSpinner(AppConstants.falseText);
            setShowModal(AppConstants.trueText);
        } else {
            setModalMessage(ErrorMessages.ambassadorChallengeAcceptException);
            setModalTypeError(AppConstants.trueText);
            setAmbassadorChallengeRequestSpinner(AppConstants.falseText);
            setShowModal(AppConstants.trueText);
        }
    }

    const closeModal = () => {
        setShowModal(AppConstants.falseText);
    }

    return (
        <>
            <div className={classes.mobileImgContainer}>
                {showCarousel && <Carousel responsive={responsiveSlider} autoPlay={ambassadorChallengeRequestSpinner ? AppConstants.falseText : AppConstants.trueText}
                    autoPlaySpeed={AppConstants.ambassadorChallengeCarouselDuration}
                    customTransition={AppConstants.qrenciaNewsCustomTransition}
                    rewindWithAnimation={AppConstants.trueText}
                    transitionDuration={AppConstants.eoiMobileCarouselDuration}
                    infinite={AppConstants.trueText}
                    showDots={showCarousel ? AppConstants.trueText : AppConstants.falseText}
                    className="eoiBannerCarouselContainerImage">
                    {mobileImage?.filter(data => data.active === AppConstants.trueText).map((slide) => (
                        <div className={classes.imageContainer}>
                            <img key={slide.id} alt="Moblie Banner" className={classes.bannerImage} src={slide.imagePath}></img>
                            {slide.buttonText && <Button onClick={sendAmbassadorChallengeRequest} className={classes.expertMobileButton}>{ambassadorChallengeRequestSpinner ? <BiLoaderAlt className="spinner" /> : slide.buttonText}</Button>}
                        </div>

                    ))}
                </Carousel>}
                {!showCarousel && mobileImage[0]?.active === AppConstants.trueText &&
                    <div className={classes.imageContainer}>
                        <img alt="Moblie Banner" className={classes.bannerImage} src={mobileImage[0].imagePath}></img>
                        {mobileImage[0].buttonText && <Button onClick={sendAmbassadorChallengeRequest} className={classes.expertMobileButton}>{ambassadorChallengeRequestSpinner ? <BiLoaderAlt className="spinner" /> : mobileImage[0].buttonText}</Button>}
                    </div>}
            </div>
            <div className={classes.desktopImgContainer}>
                {showCarousel && <Carousel responsive={responsiveSlider} autoPlay={AppConstants.trueText}
                    autoPlaySpeed={AppConstants.ambassadorChallengeCarouselDuration}
                    customTransition={AppConstants.qrenciaNewsCustomTransition}
                    rewindWithAnimation={AppConstants.trueText}
                    transitionDuration={AppConstants.eoiMobileCarouselDuration}
                    infinite={AppConstants.trueText}
                    showDots={showCarousel ? AppConstants.trueText : AppConstants.falseText}
                    className="eoiBannerCarouselContainerImage">
                    {desktopImage?.filter(data => data.active === AppConstants.trueText).map((slide) => (
                        <div className={classes.imageContainer}>
                            <img key={slide.id} alt="Desktop Banner" className={classes.bannerImage} src={slide.imagePath} />
                            {slide.buttonText && <Button onClick={sendAmbassadorChallengeRequest} className={classes.expertDesktopButton}>{ambassadorChallengeRequestSpinner ? <BiLoaderAlt className="spinner" /> : slide.buttonText}</Button>}
                        </div>
                    ))}
                </Carousel>}
                {!showCarousel && desktopImage[0]?.active === AppConstants.trueText &&
                    <div className={classes.imageContainer}>
                        <img alt="Desktop Banner" className={classes.bannerImage} src={desktopImage[0].imagePath}></img>
                        {desktopImage[0].buttonText && <Button onClick={sendAmbassadorChallengeRequest} className={classes.expertDesktopButton}>{ambassadorChallengeRequestSpinner ? <BiLoaderAlt className="spinner" /> : mobileImage[0].buttonText}</Button>}
                    </div>}
            </div>

            <Modal show={showModal} onHide={closeModal} backdrop="static" keyboard={false} centered>
                <Modal.Body className={modalClass.modalBody}>
                    {(modalTypeError === AppConstants.falseText) && <div className={modalClass.modalSuccessIcon}><AiOutlineCheckCircle /></div>}
                    {(modalTypeError === AppConstants.trueText) && <div className={modalClass.modalErrorIcon}><AiOutlineExclamationCircle /></div>}
                    <div className={modalClass.modalAlertText}>
                        {modalMessage}
                    </div>
                </Modal.Body>
                <Modal.Footer className={modalClass.modalFooter}>
                    <Button className={modalClass.modalCloseButton} onClick={closeModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}