import React, { useState, useEffect } from "react";
import classes from "./AdvancedCompetitionSettings.module.css";
import { AppConstants } from "../../../constants/AppConstants";
import { RiInformationFill } from "react-icons/ri";
import { AiOutlineExclamationCircle, AiFillDelete } from 'react-icons/ai';
import { Tooltip } from "react-tooltip";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";
import { Form, Button } from "react-bootstrap";
import formClass from "../../../common/FormStyling.module.css";

export const AdvancedCompetitionSettings = (props) => {
    const { competitionDetails, cost } = props;
    const [feeWaiver, setFeeWaiver] = useState(AppConstants.falseText);
    const [publicEnrolments, setPublicEnrolments] = useState(AppConstants.falseText);
    const [performanceReport, setPerformanceReport] = useState(AppConstants.falseText);
    const [onlyForClubQrencia, setOnlyForCLubQrencia] = useState(AppConstants.falseText);
    const [enableOfflinePayment, setEnableOfflinePayment] = useState(AppConstants.falseText);
    const [mappedClasses, setMappedClasses] = useState([]);
    const [isOfflinePaymentDisabled, setIsOfflinePaymentDisabled] = useState(AppConstants.falseText);
    const [selectedClasses, setSelectedClasses] = useState([{
        schoolClass: AppConstants.emptyString,
        allSections: []
    }]);

    useEffect(() => {
        const handleChange = () => {
            props.changeAdvancedSettings({
                feeWaiver,
                publicEnrolments,
                performanceReport,
                onlyForClubQrencia,
                enableOfflinePayment,
                selectedClasses
            });
        }

        handleChange();
    }, [feeWaiver, publicEnrolments, performanceReport, onlyForClubQrencia, selectedClasses, enableOfflinePayment]);

    useEffect(() => {
        if (competitionDetails.id) {
            setPerformanceReport(competitionDetails.disablePerformanceResultFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
        } else {
            if (!props.rubricsConfigured) {
                setPerformanceReport(AppConstants.trueText);
            } else {
                setPerformanceReport(AppConstants.falseText);
            }
        }
    }, [props.rubricsConfigured, competitionDetails]);

    useEffect(() => {
        let netAmount = cost ? parseInt(cost) : 0;
        if (netAmount <= 0) {
            setIsOfflinePaymentDisabled(AppConstants.trueText);
            setEnableOfflinePayment(AppConstants.falseText);
        } else {
            setIsOfflinePaymentDisabled(AppConstants.falseText);
        }
    }, [cost]);

    useEffect(() => {
        if (competitionDetails.id) {
            setEnableOfflinePayment(competitionDetails.payOfflineFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
            setFeeWaiver(competitionDetails.competitionFeeWaiverFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText)
            setPublicEnrolments(competitionDetails.disablePublicEnrolmentFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
            setPerformanceReport(competitionDetails.disablePerformanceResultFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
            setOnlyForCLubQrencia(competitionDetails.clubQrenciaFlg === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);

            // set classes if club qrencia flag is "Y"
            if (competitionDetails.clubQrenciaFlg === AppConstants.yText) {
                let resultArray = [];
                competitionDetails.selectedClasses.split(',').forEach(pair => {
                    const [className, section] = pair.trim().split(AppConstants.hyphen);
                    const matchingClass = mappedClasses?.find(c => c.className === className);
                    if (matchingClass) {
                        const allSections = matchingClass.sectionsArray.map(sec => ({
                            section: sec,
                            isActive: sec === section
                        }));
                        resultArray.push({
                            allSections: allSections,
                            schoolClass: className
                        });
                    }
                });
                setSelectedClasses(resultArray);
            }
        } else {
            setFeeWaiver(AppConstants.falseText);
            setPublicEnrolments(AppConstants.falseText);
            setPerformanceReport(AppConstants.falseText);
            setOnlyForCLubQrencia(AppConstants.falseText);
            setEnableOfflinePayment(AppConstants.falseText);
        }
    }, [competitionDetails]);

    useEffect(() => {
        const fetchMappedClassesForSchool = async () => {
            const url = `${AppConstants.getClassAndSectionsForSchoolIdAPI}${props.schoolId}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setMappedClasses(response);
            } else {
                setMappedClasses([]);
            }
        };
        if (props.schoolId) {
            fetchMappedClassesForSchool();
        }
    }, [props.schoolId]);

    const chagneClubQrenciaFlag = () => {
        // toggle the flag value
        setOnlyForCLubQrencia(!onlyForClubQrencia);

        if (onlyForClubQrencia) {
            // if current flag value is true
            setSelectedClasses([{
                schoolClass: AppConstants.emptyString,
                allSections: []
            }]);
        }
    };

    const handleClassChange = (event, idx) => {
        let c = [...selectedClasses];
        if (event.target.value) {
            c[idx].schoolClass = event.target.value;
            c[idx].allSections = mappedClasses.filter(cl => cl.className === event.target.value)[0].sectionsArray.map(section => {
                return {
                    section,
                    isActive: AppConstants.trueText
                }
            });
        } else {
            // remove the class at index
            c[idx].schoolClass = AppConstants.emptyString;
            c[idx].allSections = [];
        }
        setSelectedClasses(c);
    };

    const getNumberOfActiveSections = (sectionsArray) => {
        let count = 0;
        sectionsArray.forEach(section => count = section.isActive ? count + 1 : count);
        return count;
    }

    const handleSectionChange = (event, classIndex, sectionIndex) => {
        let c = [...selectedClasses];
        if (event.target.checked) {
            c[classIndex].allSections[sectionIndex].isActive = AppConstants.trueText;
        } else {
            c[classIndex].allSections[sectionIndex].isActive = AppConstants.falseText;
            if (getNumberOfActiveSections(c[classIndex].allSections) === 0) {
                c[classIndex].schoolClass = AppConstants.emptyString;
                c[classIndex].allSections = [];
            }
        };

        setSelectedClasses(c);
    };

    const addNewClass = () => {
        let newClass = {
            schoolClass: AppConstants.emptyString,
            allSections: []
        }
        setSelectedClasses([...selectedClasses, newClass]);
    };

    const isClassOptionDisabled = (className) => {
        let arr = selectedClasses.filter(cls => cls.schoolClass === className);
        if (arr.length > 0) return true;
        else return false;
    };

    const deleteClass = (index) => {
        setSelectedClasses(prevClasses => {
            const updatedClasses = [...prevClasses];
            updatedClasses.splice(index, 1);
            return updatedClasses;
        });
    };

    return (
        <>
            <div className={classes.mainContainer}>
                {!props.rubricsConfigured && <div className={classes.warningInfoContainer}>
                    <div className={classes.warningMessage}><p><AiOutlineExclamationCircle />Since judging parameters are not configured, the result will not be shared with the participants</p></div>
                </div>
                }
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}>Request Fee Waiver <RiInformationFill className={classes.infoButton} data-tooltip-id="feeWaiverInfo" /> <span> <input type="checkbox" checked={feeWaiver} onChange={() => setFeeWaiver(!feeWaiver)} disabled={onlyForClubQrencia} /></span></div>
                </div>
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}>Disable Public Enrolments <RiInformationFill className={classes.infoButton} data-tooltip-id="publicEnrolmentsInfo" /><span>  <input type="checkbox" checked={publicEnrolments} onChange={() => setPublicEnrolments(!publicEnrolments)} disabled={onlyForClubQrencia} /></span></div>
                </div>
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}>Disable Performance Analysis <RiInformationFill className={classes.infoButton} data-tooltip-id="performanceReportInfo" /><span><input readOnly={props.rubricsConfigured} type="checkbox" checked={props.rubricsConfigured ? performanceReport : true} onChange={() => setPerformanceReport(!performanceReport)} />
                    </span></div>
                </div>
                {!onlyForClubQrencia && !feeWaiver && <div className={classes.switchContainer}>
                    <div className={classes.switchText}>Enable Offline Payment <RiInformationFill className={classes.infoButton} data-tooltip-id="paymentInfoTooltip" /><span><input className={classes.switchInput} disabled={isOfflinePaymentDisabled} type="checkbox" checked={enableOfflinePayment} onChange={() => setEnableOfflinePayment(!enableOfflinePayment)} /></span></div>
                    <Tooltip className={classes.tooltipMessage} id="paymentInfoTooltip"><span className={classes.tooltipMessage}>{AppConstants.offlinePaymentTooltipInfo}</span></Tooltip>
                </div>}
                <Tooltip className={classes.tooltipMessage} id="feeWaiverInfo"><span className={classes.tooltipMessage}>{AppConstants.feeWaiverInfoIcon}</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="publicEnrolmentsInfo"><span className={classes.tooltipMessage}>{AppConstants.disablePublicEnrolmentInfoIcon}</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="performanceReportInfo"><span className={classes.tooltipMessage}>{AppConstants.disablePerformanceAnalysisInfoIcon}</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="clubQrenciaInfo"><span className={classes.tooltipMessage}>By selecting this, the competition will be created exclusively for your school and for the below selected classes.</span></Tooltip>
            </div>

            {props.isExpertAssociatedWithClubQrencia === AppConstants.yText && <div className={classes.switchContainer}>
                <div className={classes.switchText}>Only for Club Qrencia <RiInformationFill className={classes.infoButton} data-tooltip-id="clubQrenciaInfo" /> <span>                    <input readOnly={props.rubricsConfigured} type="checkbox" checked={onlyForClubQrencia} onChange={() => { setPublicEnrolments(AppConstants.falseText); setFeeWaiver(AppConstants.falseText); chagneClubQrenciaFlag(); }} />
                </span></div>
            </div>}
            {onlyForClubQrencia && <div className="row mb-4"> {
                selectedClasses.map((sc, classIndex) => <div key={classIndex}>
                    <div className={classes.mainClassSection}>
                        <div className={classes.clubQrenciaClassDropdown}>
                            <Form.Label className={formClass.formLabel}>Class</Form.Label>
                            <Form.Select className={formClass.selectInput}
                                name={`classDropdown-${classIndex}`}
                                value={sc.schoolClass}
                                onChange={e => handleClassChange(e, classIndex)}
                                required
                            ><option value={AppConstants.emptyString} defaultValue>Select a Class</option>
                                {mappedClasses?.map(option => (
                                    <option disabled={isClassOptionDisabled(option.className)} key={option.className} value={option.className}>
                                        {option.className}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                        <div className={classes.removeIcon}>
                            {classIndex !== 0 && <AiFillDelete onClick={() => deleteClass(classIndex)} />}
                        </div>
                    </div>
                    {sc.schoolClass !== AppConstants.emptyString && <div className={classes.sectionsContainer}>
                        <h5 className={classes.sectionsHeading}>Sections</h5>
                        {
                            sc.allSections.map((section, sectionIndex) => {
                                return <div key={section.section} className={classes.switchContainer}>
                                    <div className={classes.switchText}>{section.section} <span><input type="checkbox" checked={section.isActive} onChange={(e) => handleSectionChange(e, classIndex, sectionIndex)} />
                                    </span></div>
                                </div>

                            })
                        }
                    </div>}
                    {classIndex === selectedClasses.length - 1 && mappedClasses.length != selectedClasses.length && <Button className={classes.newButton} onClick={addNewClass}>+ Add Class</Button>}
                </div>)
            }
            </div>

            }
        </>
    )
}