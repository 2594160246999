import { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import { GoPrimitiveDot } from 'react-icons/go';
import { DataService } from '../../services/DataService';
import './viewCompetitionTabs.css';

export const ViewCompetitionTabs = (props) => {
    //Handler to return selected Skills Value
    function selectCompetitionType(e) {
        props.onSelection(e);
    };

    return (
        <div className="expertViewCompetitionsTabs">
            <Tabs className="expertViewCompetitionsTabs"
                defaultActiveKey={AppConstants.competitionTabHeading[0]}
                id="viewCometitionsTabs"
                fill
                onSelect={(e) => selectCompetitionType(e)}
            >
                <Tab eventKey={AppConstants.competitionTabHeading[0]} title={AppConstants.competitionTabHeading[0]}></Tab>
                <Tab eventKey={AppConstants.competitionTabHeading[1]} title={AppConstants.competitionTabHeading[1]}></Tab>
                <Tab eventKey={AppConstants.competitionTabHeading[2]} title={AppConstants.competitionTabHeading[2]}></Tab>
                <Tab eventKey={AppConstants.competitionTabHeading[3]} title={AppConstants.competitionTabHeading[3]}></Tab>
            </Tabs>
        </div>
    );
}