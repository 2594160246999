import React, { useEffect, useState } from "react";
import { IndividualCompetitionBanner } from "./IndividualCompetitionBanner";
import { MainCompetitionDetailsSection } from "./MainCompetitionDetailsSection";
import { RightCompetitionDetailsSection } from "./RightCompetitionDetailsSection";
import { useParams } from "react-router-dom";
import { BiLoaderAlt } from 'react-icons/bi';
import { AppConstants } from '../../constants/AppConstants';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { DataService } from '../../services/DataService';
import classes from "./IndividualCompetition.module.css";
import Auth from "../../utils/Auth";
import { useNavigate } from "react-router-dom";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { ShareLinkToSocialMediaUtil } from "../../utils/ShareLinkToSocialMediaUtil.js"

export const IndividualCompetition = () => {
    const { categorySlug, skillSlug, competitionSlug } = useParams();
    const [competitionDetails, setCompetitionDetails] = useState([]);
    const [solutionUploadedPath, setSolutionUploadedPath] = useState(AppConstants.emptyString);
    const [cometitionDetailsLoader, setCometitionDetailsLoader] = useState(true);
    const linkToShare = `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.competitions}${AppConstants.forwardSlash}${categorySlug}${AppConstants.forwardSlash}${skillSlug}${AppConstants.forwardSlash}${competitionSlug}`;
    const [rewards, setRewards] = useState(AppConstants.emptyString);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [signupTimerDuration, setSignupTimerDuration] = useState(AppConstants.emptyString);

    const navigate = useNavigate();

    useEffect(() => {
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setSignupTimerDuration(parseInt(response.find(item => item.keyName === AppConstants.signupEnrolmentModalDurationKey).keyValue));
            }
        };
        fetchConfigurableKeys();
        //fetch Competitions details
        if (Auth.isLogin() === true) {
            async function getCompetitionDetails() {
                setCometitionDetailsLoader(true);
                const url = `${AppConstants.getIndividualCompetitionPageAPIWithParameter}${competitionSlug}`;
                const response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setCompetitionDetails(response);
                    setCometitionDetailsLoader(false);
                } else {
                    setCompetitionDetails([]);
                    setCometitionDetailsLoader(false);
                    navigate(AppConstants.asterisk);
                }
            };
            async function getUploadedSolution() {
                setCometitionDetailsLoader(true);
                const uploadedsolutionUrl = `${AppConstants.getSolutionForChallenge}${competitionSlug}`;
                const response = await AuthorizedDataService.getRequest(
                    uploadedsolutionUrl,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response !== undefined) {
                    setSolutionUploadedPath(response.uploadedSolutionPath);
                } else {
                    setSolutionUploadedPath(AppConstants.emptyString);
                }
                setCometitionDetailsLoader(false);
            };
            getCompetitionDetails();
            getUploadedSolution();
        } else {
            async function getCompetitionDetailsUnAurhorized() {
                setCometitionDetailsLoader(true);
                const requestHeaders = {
                    preferredTimezone: timeZone
                }
                const url = `${AppConstants.getIndividualCompetitionPageAPIWithParameter}${competitionSlug}`;
                const response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString,
                    requestHeaders
                );
                if (response !== undefined) {
                    setCompetitionDetails(response);
                    setCometitionDetailsLoader(false);
                } else {
                    setCompetitionDetails([]);
                    setCometitionDetailsLoader(false);
                    navigate(AppConstants.asterisk);
                }
            };
            getCompetitionDetailsUnAurhorized();
        }

    }, [])

    useEffect(() => {
        async function getRewards() {
            const url = `${AppConstants.getIndividualCompetitionPageRewardsAPI}${competitionDetails.competitionId}`;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            setRewards(response);
        }
        getRewards();
    }, [competitionDetails]);

    return (
        <>
            {!cometitionDetailsLoader && competitionDetails.length !== 0 && <IndividualCompetitionBanner competitionData={competitionDetails} />}
            {!cometitionDetailsLoader && competitionDetails.length !== 0 && <div className={classes.shareIconPannelContainer}>
                <ShareLinkToSocialMediaUtil className={classes} linkToShare={linkToShare} />
            </div>}
            {!cometitionDetailsLoader && competitionDetails.length !== 0 && <div className="row flex-column-reverse flex-lg-row">
                <div className="col-md-12 col-lg-8 col-xl-8 p-0">
                    <MainCompetitionDetailsSection competitionData={competitionDetails} rewards={rewards} solutionUploadedPath={solutionUploadedPath} />
                </div>
                <div className="col-md-12 col-lg-4 col-xl-4 p-0">
                    <RightCompetitionDetailsSection signupTimerDuration={signupTimerDuration} competitionData={competitionDetails} />
                </div>
            </div>}
            {cometitionDetailsLoader && <div className={classes.errorContainer}>
                <p className={classes.loaderIcon}><BiLoaderAlt className={classes.spinner} /></p>
            </div>}
        </>
    );

}
