import React, { useState, useEffect } from "react";
import classes from "../createCompetitionComponents/AdvancedCompetitionSettings/AdvancedCompetitionSettings.module.css";
import { AppConstants } from "../../constants/AppConstants";
import { RiInformationFill } from "react-icons/ri";
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { Tooltip } from "react-tooltip";

export const EditAdvancedCompetitionSettings = (props) => {
    const competitionData = props.competitionDetails;
    const enrolledParticipants = competitionData.participantsDetails?.length;
    const [feeWaiver, setFeeWaiver] = useState(competitionData.competitionFeeWaiverFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
    const [publicEnrolments, setPublicEnrolments] = useState(competitionData.disablePublicEnrolmentFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
    const [performanceReport, setPerformanceReport] = useState(competitionData.disablePerformanceResultFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
    const [onlyForClubQrencia, setOnlyForCLubQrencia] = useState(competitionData.clubQrenciaFlg === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
    const [enableOfflinePayment, setEnableOfflinePayment] = useState(competitionData.payOfflineFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
    const [isOfflineFlagUpdatedOnce, setIsOfflineFlagUpdatedOnce] = useState(AppConstants.falseText)

    useEffect(() => {
        const handleChange = () => {
            props.changeAdvancedSettings({ performanceReport, enableOfflinePayment });
        }
        handleChange();
    }, [performanceReport, enableOfflinePayment]);

    useEffect(() => {
        if (!props.rubricsConfigured) {
            setPerformanceReport(AppConstants.trueText);
        }
    }, []);

    useEffect(() => {
        let netAmount = props.cost ? parseInt(props.cost) : 0;
        if (netAmount <= 0 && isOfflineFlagUpdatedOnce) {
            setEnableOfflinePayment(AppConstants.falseText);
        }
        if (!isOfflineFlagUpdatedOnce) {
            setIsOfflineFlagUpdatedOnce(AppConstants.trueText);
        }
    }, [props.cost, isOfflineFlagUpdatedOnce]);

    const isOfflinePayemtnDisabled = () => {
        if (competitionData.competitionStatus === AppConstants.competitionStatus.past) return true;
        let netAmount = props.cost ? parseInt(props.cost) : 0;
        if (netAmount <= 0) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <div className={classes.mainContainer}>
                {!props.rubricsConfigured && <div className={classes.warningInfoContainer}>
                    <div className={classes.warningMessage}><p><AiOutlineExclamationCircle />Since judging parameters are not configured, the result will not be shared with the participants</p></div>
                </div>}
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}>Request Fee Waiver <RiInformationFill className={classes.infoButton} data-tooltip-id="feeWaiverInfo" /> <span>
                        <input type="checkbox" checked={feeWaiver} readOnly={true} disabled={true} />
                    </span></div>
                </div>
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}>Disable Public Enrolments <RiInformationFill className={classes.infoButton} data-tooltip-id="publicEnrolmentsInfo" /><span>
                        <input type="checkbox" checked={publicEnrolments} readOnly={true} disabled={true} />
                    </span></div>
                </div>
                <div className={classes.switchContainer}>
                    <div className={classes.switchText}>Disable Performance Analysis <RiInformationFill className={classes.infoButton} data-tooltip-id="performanceReportInfo" /><span>
                        <input disabled={enrolledParticipants > 0 && competitionData.disablePerformanceResultFlag === AppConstants.nText} readOnly={enrolledParticipants > 0 && competitionData.disablePerformanceResultFlag === AppConstants.nText} type="checkbox" checked={props.rubricsConfigured ? performanceReport : AppConstants.trueText} onChange={() => { setPerformanceReport(!performanceReport); }} />
                    </span></div>
                </div>
                {!onlyForClubQrencia && !feeWaiver && <div className={classes.switchContainer}>
                    <div className={classes.switchText}>Enable Offline Payment <RiInformationFill className={classes.infoButton} data-tooltip-id="paymentInfoTooltip" /><span><input className={classes.switchInput} disabled={isOfflinePayemtnDisabled()} type="checkbox" checked={enableOfflinePayment} onChange={() => setEnableOfflinePayment(!enableOfflinePayment)} /></span></div>
                    <Tooltip className={classes.tooltipMessage} id="paymentInfoTooltip"><span className={classes.tooltipMessage}>{AppConstants.offlinePaymentTooltipInfo}</span></Tooltip>
                </div>}
                <Tooltip className={classes.tooltipMessage} id="feeWaiverInfo"><span className={classes.tooltipMessage}>{AppConstants.feeWaiverInfoIcon}</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="publicEnrolmentsInfo"><span className={classes.tooltipMessage}>{AppConstants.disablePublicEnrolmentInfoIcon}</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="performanceReportInfo"><span className={classes.tooltipMessage}>{AppConstants.disablePerformanceAnalysisInfoIcon}</span></Tooltip>
                <Tooltip className={classes.tooltipMessage} id="clubQrenciaInfo"><span className={classes.tooltipMessage}>By selecting this, the competition will be created exclusively for your school and for the below selected classes.</span></Tooltip>
            </div>

            {props.isExpertAssociatedWithClubQrencia === AppConstants.yText && <div className={classes.switchContainer}>
                <div className={classes.switchText}>Only for Club Qrencia <RiInformationFill className={classes.infoButton} data-tooltip-id="clubQrenciaInfo" /> <span>
                    <input readOnly={true} disabled={true} type="checkbox" checked={onlyForClubQrencia} />
                </span></div>
            </div>}
            {onlyForClubQrencia && <div className="row mb-4"> {
                <div className={classes.selectedClassesSection}>
                    Selected Classes: {competitionData.selectedClasses?.replace(/\-+/g, '').replace(/,+/g, ', ')}
                </div>
            }
            </div>

            }
        </>
    )
}