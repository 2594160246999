import { useState, useEffect } from "react";
import { StudentRecentlyPublishedCompetitionCards } from "../../cards/StudentRecentlyPublishedCompetitionCards";
import { AppConstants } from "../../../constants/AppConstants";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";

export const StudentSchoolRecentlyPublishedCompetitions = (props) => {
    const [competitions, setCompetitions] = useState([]);
    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
        async function fetchRecentlyPublishedCompetitions() {
            const url = AppConstants.getUpcomingCompetitionsForStudentUnderMySchoolAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response) {
                setCompetitions(response);
                setFilteredList(response);
            } else {
                setCompetitions([]);
                setFilteredList([]);
            }

            return null;
        }

        if (props.categoryList?.length > AppConstants.zeroIndex) {
            fetchRecentlyPublishedCompetitions();
        }
    }, [props.categoryList]);

    useEffect(() => {
        if (props.selectedCategory) {
            if (props.selectedCategory === AppConstants.AllText) {
                setFilteredList(competitions);
            } else {
                setFilteredList(competitions.filter(competition => competition.categoryName === props.selectedCategory));
            }
        } else {
            setFilteredList(competitions);
        }
    }, [props.selectedCategory]);

    return (
        <>
            {filteredList?.length > AppConstants.zeroIndex && <StudentRecentlyPublishedCompetitionCards cardDetails={filteredList} category="School" />}
            {(!filteredList || filteredList?.length === AppConstants.zeroIndex) && props.selectedCategory !== AppConstants.emptyString && props.selectedCategory !== AppConstants.AllText && <p className='noDataAvailableError'>{`No upcoming competitions for skill category '${props.selectedCategory}' to display at the moment`}</p>}
            {(!filteredList || filteredList?.length === AppConstants.zeroIndex) && (props.selectedCategory === AppConstants.emptyString || props.selectedCategory === AppConstants.AllText) && <p className="noDataAvailableError">No upcoming competitions to display at the moment</p>}
        </>
    )
}