import { useState, useEffect, useContext } from "react";
import parse from 'html-react-parser';
import classes from './IndividualSkillsActivities.module.css';
import { AppConstants } from "../../../constants/AppConstants";
import { SelectionContext } from '../../../context/SelectionContext';
import { IndividualSkillsPageRaiseNudge } from './IndividualSkillsPageRaiseNudge';
import { IndividualSkillsExpertCards } from './IndividualSkillsExpertCards';
import { IndividualSkillsCourseCards } from './IndividualSkillsCourseCards';
import { IndividualSkillsCompetitionCards } from "./IndividualSkillsCompetitionCards";
import { IndividualSkillsWorkshopCards } from "./IndividualSkillsWorkshopCards";

export const IndividualSkillsActivities = (props) => {
    const [activitiesList, setActivitiesList] = useState([]);
    const [activeTab, setActiveTab] = useState(activitiesList[0]);
    const [filteredList, setFilteredList] = useState(null);
    const { selectedActivity } = useContext(SelectionContext);

    useEffect(() => {
        let activities = [];

        function getMatchingActivity(activityName) {
            let matchingActivity = props.activitiesList?.filter(activity => activity.activityType === activityName)[0];
            if (matchingActivity !== undefined) {
                activities.push(matchingActivity)
            }
        }

        if (props.expertExistsForSkill) {
            setActivitiesList(props.activitiesList);
            filterActivitiesData(props.activitiesList[0]);
            setActiveTab(props.activitiesList[0]);
        } else {
            if (props.allActivitiesForSkill.courseDetails?.length > 0) {
                getMatchingActivity(AppConstants.activitiesType[1])
            }
            if (props.allActivitiesForSkill.competitionDetails?.length > 0) {
                getMatchingActivity(AppConstants.activitiesType[2])
            }

            if (props.allActivitiesForSkill.workshopDetails?.length > 0) {
                getMatchingActivity(AppConstants.activitiesType[3])
            }

            filterActivitiesData(activities[0]);
            setActiveTab(activities[0]);
            setActivitiesList(activities);
        }

    }, [props.activitiesList, props.expertExistsForSkill]);

    useEffect(() => {
        if (props.navigationSelectedActivity != AppConstants.emptyString) {
            filterActivitiesData(props.navigationSelectedActivity);
            setActiveTab(props.navigationSelectedActivity);
        }
    }, [props.navigationSelectedActivity]);

    useEffect(() => {
        if (selectedActivity != AppConstants.emptyString) {
            const matchingActivity = activitiesList.find(activity => activity.activityType === selectedActivity);
            if (matchingActivity != null) {
                filterActivitiesData(matchingActivity);
                setActiveTab(matchingActivity);
            }
        }
    }, [selectedActivity, activitiesList]);

    //set content of selected tab
    const handleTabClick = (tab) => {
        setTimeout(() => {
            filterActivitiesData(tab);
            setActiveTab(tab);
        }, 300);
    };

    const filterActivitiesData = (selectedTab) => {
        if (selectedTab?.activityType === AppConstants.activitiesType[0]) {
            setFilteredList(props.allActivitiesForSkill.creatorDetails);
        } else if (selectedTab?.activityType === AppConstants.activitiesType[1]) {
            setFilteredList(props.allActivitiesForSkill.courseDetails);
        } else if (selectedTab?.activityType === AppConstants.activitiesType[2]) {
            setFilteredList(props.allActivitiesForSkill.competitionDetails);
        } else if (selectedTab?.activityType === AppConstants.activitiesType[3]) {
            setFilteredList(props.allActivitiesForSkill.workshopDetails);
        }
    };

    return (
        <>
            <div className={classes.contentMainContainer}>
                <div className={classes.contentTabContainer}>
                    {activitiesList?.map((tab) => (
                        <div key={tab?.activityId}
                            className={activeTab?.activityId === tab?.activityId ? `${classes.tabItem} ${classes.tabItemActive}` : `${classes.tabItem}`}
                            onClick={() => handleTabClick(tab)}>
                            <img
                                className={classes.tabIconImage}
                                src={tab.activityImage}
                                alt="Passion List Icons"
                            />
                            <p className={classes.tabTitleText}>{tab.activityTitle}</p>
                        </div>
                    ))}
                </div>
                <div className={activeTab?.activityType === AppConstants.activitiesType[3] ? classes.workshopSectionContainer : classes.activitiesSectionContainer}>
                    {activeTab?.activityDescription && <h1 className={classes.contentTitle}>{parse(activeTab.activityDescription)}</h1>}
                    {activeTab?.activityType === AppConstants.activitiesType[0] && props.expertExistsForSkill && <IndividualSkillsExpertCards allActivitiesForSkill={filteredList} skill={props.skill} />}
                    {activeTab?.activityType === AppConstants.activitiesType[1] && <IndividualSkillsCourseCards allActivitiesForSkill={filteredList} skill={props.skill} />}
                    {activeTab?.activityType === AppConstants.activitiesType[2] && <IndividualSkillsCompetitionCards allActivitiesForSkill={filteredList} skill={props.skill} />}
                    {activeTab?.activityType === AppConstants.activitiesType[3] && <IndividualSkillsWorkshopCards allActivitiesForSkill={filteredList} skill={props.skill} />}
                    {activeTab?.activityType === AppConstants.activitiesType[4] && props.expertExistsForSkill && <IndividualSkillsPageRaiseNudge skillId={props.skillId} skill={props.skill} category={AppConstants.learningActivitiesTypes.nudge} />}
                </div>
            </div>
        </>
    );
}