import { useEffect, useState } from "react";
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import { ExpertViewNudgesCards } from "../../component/cards/expertNudgesCards/ExpertViewNudgesCards";
import { FaArrowLeft } from "react-icons/fa";
import { ExpertNudgeDetailsSection } from "../../component/cards/expertNudgesCards/ExpertNudgeDetailsSection";
import Auth from '../../utils/Auth';
import { useNavigate } from "react-router-dom";

export const ExpertNudgesPage = () => {
    const navigate = useNavigate();
    const [activeNudgesArray, setActiveNudgesArray] = useState([]);
    const [closedNudgesArray, setClosedNudgesArray] = useState([]);
    const [viewDetailsSection, setViewDetailsSection] = useState(false);
    const [selectedNudgeData, setSelectedNudgeData] = useState([]);
    const [refreshPage, setRefreshPage] = useState(false);

    useEffect(() => {
        //get active nudges assigned to logged in expert
        async function getActiveNudges() {
            const url = AppConstants.getActiveNudgesAssignedToExpertAPIWithParam;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setActiveNudgesArray(response);
            } else {
                setActiveNudgesArray([]);
            }
        }
        getActiveNudges();

        //get closed nudges assigned to logged in expert
        async function getClosedNudges() {
            const url = AppConstants.getClosedNudgesAssignedToExpertAPIWithParam;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response !== undefined) {
                setClosedNudgesArray(response);
            } else {
                setClosedNudgesArray([]);
            }
        }
        getClosedNudges();
    }, [refreshPage]);

    //view nudge details
    const viewNudgeDetails = (options) => {
        setViewDetailsSection(true);
        setSelectedNudgeData(options);
    }

    //back to raise nudge section
    const backToRaiseNudgeSection = () => {
        setViewDetailsSection(false);
    }

    return (
        <>
            <div className="expertContentSection">
                {!viewDetailsSection && <div>
                    <h1 className="studentRaiseNudgeHeading mt-3">Active Nudges</h1>
                    <ExpertViewNudgesCards nudgesData={activeNudgesArray} closedNudges={false} onViewClick={viewNudgeDetails} />
                    <h1 className="studentRaiseNudgeHeading mt-3">Responded Nudges</h1>
                    <ExpertViewNudgesCards nudgesData={closedNudgesArray} closedNudges={true} onViewClick={viewNudgeDetails} />
                </div>}
                {viewDetailsSection && <div>
                    <div><FaArrowLeft onClick={() => { setRefreshPage(!refreshPage); backToRaiseNudgeSection() }} className="studentRaiseNudgeBackButton" /></div>
                    <ExpertNudgeDetailsSection nudgesData={selectedNudgeData} onBackButtonClick={backToRaiseNudgeSection} />
                </div>}
            </div>
        </>
    )
}