import React, { useEffect, useState } from "react";
import { IoMdAddCircle } from "react-icons/io";
import { Button, Form, Table } from "react-bootstrap";
import { AppConstants } from "../../../constants/AppConstants";
import classes from "./AddJudgementParameters.module.css";
import { AuthorizedDataService } from "../../../services/AuthorizedDataService";

export const AddJudgementParameters = (props) => {

    const { saveCurrentStep, changeRubricsData, skillId, stage, previousSkill, previousRubricsDetails } = props;
    const stages = AppConstants.createCompetitionActions;
    const [rubrics, setRubrics] = useState([]);
    const [allCriteria, setAllCriteria] = useState([]);
    const [isHovered, setIsHovered] = useState({});
    const [judgingParameters, setJudgingParameters] = useState([]);
    const rubricsTableHeaders = AppConstants.rubricsTableHeaders;

    useEffect(() => {
        const changeParentState = () => {
            changeRubricsData({ rubrics, rubricsConfigured: countTotalMarks() > 0 ? AppConstants.trueText : AppConstants.falseText });
        }

        changeParentState();
    }, [rubrics]);

    useEffect(() => {
        if (skillId !== previousSkill) {
            let rbx = judgingParameters?.map((params, i) => {
                let obj = {
                    ...params,
                    uid: i,
                    editable: false,
                    included: true,
                };

                return obj;
            });
            setRubrics(rbx);
        } else {
            let customRubrics = [];
            let rbx = judgingParameters?.map((params, i) => {
                let aspectUniqueId = previousRubricsDetails.filter(rubrics => (rubrics.aspectDescription === params.aspectDescription && rubrics.criteriaId === params.criterionId))[0]?.aspectUniqueId;
                let obj = {
                    ...params,
                    uid: i,
                    editable: false,
                    previousRubrics: aspectUniqueId ? AppConstants.trueText : AppConstants.falseText,
                    aspectUniqueId: aspectUniqueId || AppConstants.nullText,
                    included: aspectUniqueId ? AppConstants.trueText : AppConstants.falseText,
                };

                return obj;
            });

            let indexCounter = AppConstants.zeroIndex;
            previousRubricsDetails?.forEach((params, i) => {
                if (!judgingParameters.some(rubrics => rubrics.aspectDescription === params.aspectDescription && rubrics.criterionId === params.criteriaId)) {
                    customRubrics.push({
                        ...params, criterionUniqueId: params.criteriaId,
                        criterionId: params.criteriaId, criterionName: params.criteriaName,
                        uid: rbx.length + indexCounter
                    });
                    indexCounter++;
                }
            })

            setRubrics([...rbx, ...customRubrics]);
        }
    }, [judgingParameters, previousRubricsDetails]);

    useEffect(() => {
        const fetchJudgingParametersForSkill = async () => {
            try {
                const url = `${AppConstants.getJudgingParametersForSkillApi}${skillId}`
                let response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response) setJudgingParameters(response);
                else setJudgingParameters([]);
            } catch (error) {
                setJudgingParameters([]);
            }
        }

        const fetchJudgingCriteriaForSkill = async () => {
            try {
                const url = `${AppConstants.getJudgingCriteriaForSkillApi}${skillId}`

                let response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );
                if (response) setAllCriteria(response);
                else setAllCriteria(response);
            } catch (error) {
                setAllCriteria([]);
            }
        }

        if (skillId) {
            fetchJudgingParametersForSkill();
            fetchJudgingCriteriaForSkill();
        } else {
            setAllCriteria([]);
            setJudgingParameters([]);
        }
    }, [skillId]);

    const handleSubmit = () => {
        saveCurrentStep({ step: stages.indexOf(stage) });
    }

    const countTotalMarks = () => {
        let marks = 0;
        rubrics?.forEach(rbx => marks = rbx.included ? marks + rbx.marks : marks + 0);

        return marks;
    };

    const addNewRow = (idx) => {
        let newRubrics = rubrics;

        const newObj = {
            criterionName: allCriteria[0].criterionName,
            criterionId: allCriteria[0].criterionId,
            criterionUniqueId: allCriteria[0].criterionUniqueId,
            aspectUniqueId: null,
            aspectDescription: AppConstants.emptyString,
            uid: rubrics.length,
            marks: 5,
            editable: true,
            included: true
        }

        newRubrics.splice(idx + 1, 0, newObj);
        setRubrics([...newRubrics]);
    };

    const selectNewCriteria = ({ event, rbx }) => {
        let selectedCriteria = event.target.value;
        let newCriteriaUniqueId, newCriteriaId;

        allCriteria.forEach(criteria => {
            if (criteria.criterionName === selectedCriteria) {
                newCriteriaId = criteria.criterionId;
                newCriteriaUniqueId = criteria.criterionUniqueId;
            }
        });

        let newRubrics = rubrics.map(r => {
            if (r.uid === rbx.uid) {
                r.criterionName = selectedCriteria;
                r.criterionUniqueId = newCriteriaUniqueId;
                r.criterionId = newCriteriaId;
            }

            return r;
        });

        setRubrics(newRubrics);
    };

    const changeAspect = ({ event, rbx }) => {
        setRubrics(rubrics.map(r => {
            if (r.uid === rbx.uid) {
                r.aspectDescription = event.target.value;
            }

            return r;
        }));
    };

    const toggleCheckboxValue = ({ e, rbx }) => {
        setRubrics(rubrics.map(r => {
            if (r.uid === rbx.uid) {
                r.included = e.target.checked;
            }
            return r;
        }));
    };

    const handleMouseEnter = (idx) => {
        setIsHovered(isHovered => ({ ...isHovered, [idx]: true }));
    }

    const handleMouseLeave = (idx) => {
        setIsHovered(isHovered => ({ ...isHovered, [idx]: false }));
    }

    const toggleRubricsParameters = (e) => {
        setRubrics(rubrics.map(r => {
            r.included = e.target.checked;
            return r;
        }))
    };

    return (<>
        <div>
            <div className={classes.switchContainer}>
                <div className={classes.switchText}>Enable Judgement Parameters <span><input type="checkbox" checked={countTotalMarks() > 0} onChange={toggleRubricsParameters} /></span></div>
            </div>
            <Table className={classes.addRubricsTable} striped bordered hover cellPadding={0} cellSpacing={0} responsive="lg">
                <thead>
                    <tr>
                        <th className={classes.headers}>{rubricsTableHeaders[0]}</th>
                        <th className={classes.headers}>{rubricsTableHeaders[1]}</th>
                        <th className={classes.headers}>{rubricsTableHeaders[2]}</th>
                        <th className={classes.headers}>{rubricsTableHeaders[3]}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rubrics?.map((r, idx) => {
                            return <tr id={r.uid} key={r.uid}
                                className={classes.tableRow}
                                onMouseEnter={() => handleMouseEnter(idx)}
                                onMouseLeave={() => handleMouseLeave(idx)}>
                                {r.editable === true ?
                                    <td className={classes.selectCriteria}><Form.Select name="Criteria" id="Criteria" value={r.criterionName} onChange={(event) => selectNewCriteria({ event, rbx: r })}>
                                        {
                                            allCriteria?.map(criteria => <option
                                                key={criteria.criterionUniqueId}
                                                value={criteria.criterionName}>
                                                {criteria.criterionName}
                                            </option>)
                                        }
                                    </Form.Select></td> :
                                    <td align="left">{r.criterionName}</td>}
                                <td><textarea className={classes.newAspectTextInput} type="text" placeholder="Type an Aspect" value={r.aspectDescription} onChange={(event) => changeAspect({ event, rbx: r })} /></td>
                                <td align="center">{r.marks}</td>
                                <td>
                                    <input type="checkbox"
                                        key={r.uid}
                                        checked={r.included === true}
                                        onChange={(e) => toggleCheckboxValue({ e, rbx: r })}
                                    ></input>
                                </td>
                                {isHovered[idx] && <td className={classes.addRowBtn} onClick={() => addNewRow(idx)}>
                                    <IoMdAddCircle /> {AppConstants.addAspectText}
                                </td>}
                            </tr>
                        })
                    }
                </tbody>
            </Table>
            <div className={classes.addRubricsTotalMarks}>
                Total Marks: {countTotalMarks()}
            </div>
            <div className={classes.generalInfoContainer}>
                <h3 className={classes.generalInfoHeading}>{AppConstants.generalInformationText}</h3>
                {
                    AppConstants.rubricsGeneralInfo.map((info, id) => <p key={id}
                        className={classes.generalInfoText}>
                        {info}
                    </p>)
                }
                <div className={classes.checkboxContainer}>
                    <input type="checkbox" readOnly checked={true} />
                    <p>{AppConstants.includeText}</p>
                </div>
                <div className={classes.checkboxContainer}>
                    <input type="checkbox" readOnly checked={false} />
                    <p>{AppConstants.excludeText}</p>
                </div>
            </div>
        </div>
        <div className={classes.nextBtn}>
            <Button type="submit" onClick={handleSubmit}>{AppConstants.nextText}</Button>
        </div>
    </>
    );
};