import classes from './viewResultJudgeDetails.module.css';
import { AppConstants } from '../../constants/AppConstants';

export const ViewResultJudgeDetails = ({ judges }) => {

    const allJudges = judges == null ? null : judges.split(",");
    const uniqueJudges = new Set(allJudges);
    let newJudges = [...uniqueJudges];

    return (
        <>
            {allJudges &&
                newJudges.map((j, index) => {
                    return <p title={j} className={classes.userInitials}>{j
                        .split(' ')
                        .filter((part, index) => index === 0 || index === j.split(' ').length - 1)
                        .map((part) => part.charAt(0))
                        .join('')}</p>
                })
            }
            {!allJudges && AppConstants.notAvailableText}
        </>
    );
}