import React, { useState } from 'react';
import { AgencyTodaysActivitiesWidget } from '../../component/widgets/AgencyTodaysActivitiesWidget';
import { CreatorHomePageCarousel } from '../../component/creatorHomePageCarousel/CreatorHomePageCarousel';
import { useLoaderData } from 'react-router-dom';
import { AppConstants } from '../../constants/AppConstants';

export const AgencyHomePage = () => {
    const [footerDetails, agencyDetails] = useLoaderData();
    const [showImage, setShowImage] = useState(true);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const adsDefaultImage = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.agencyHomeAdDefaultImagePath}`;

    return (
        <>
            <div className="agencyContentSection">
                <AgencyTodaysActivitiesWidget />
                {agencyDetails.agencyType === AppConstants.agencyTypes.academy && <CreatorHomePageCarousel agencyType={agencyDetails?.agencyType} />}
                {agencyDetails?.agencyType !== AppConstants.agencyTypes.academy && showImage && <div className="templateHomeBannerSection">
                    <img alt="Banner" src={adsDefaultImage} onError={() => { setShowImage(false) }} className="templateHomeBannerImage" />
                </div>}
            </div>
        </>
    );

}