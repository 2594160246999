import { Accordion, Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { FaChartLine, FaHome, FaRegCalendarCheck, FaRegEdit, FaRegUserCircle, FaUsers } from "react-icons/fa";
import { AppConstants } from "../../constants/AppConstants";
import Auth from '../../utils/Auth';
import '../../common/TemplateStyle.css';
import { IoPeopleSharp } from 'react-icons/io5';
import { GrTableAdd } from 'react-icons/gr';

export const PartnerSuccessLeftNav = () => {
    const [expanded, setExpanded] = useState(false);
    return (
        <>
            <Navbar expanded={expanded} expand="lg" className="sideNavigation">
                <Navbar.Toggle className="sidebarToggleButton" onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse className="sideNavigationContent">
                    <Nav className="sideNavigationListSection">
                        <div className="profileSection">
                            {Auth.getLoggedInUserDetails().profileImagePreSignedUrl != null && <div className="profileImageDiv">
                                <img src={Auth.getLoggedInUserDetails().profileImagePreSignedUrl} className="profileImage" alt="User Profile" />
                            </div>}
                            {Auth.getLoggedInUserDetails().profileImagePreSignedUrl == null && <div>
                                <div className="userInitials">{Auth.getLoggedInUserDetails().nameInitials}</div>
                            </div>}
                            <div className="userNameDiv">
                                <p className="userName">{Auth.getLoggedInName()}</p>
                                <p className="qrenciaIdText">QID: {Auth.getLoggedInUserDetails().userId}</p>
                            </div>
                        </div>
                        {/* <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.partnerSuccessRecommendCreatorsPath}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><FaChartLine className="linkIcons" />
                                <p className="navigationLinks">Recommend</p>
                            </NavLink>
                        </Nav.Item> */}
                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.partnerSuccessNumberTracking}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><FaChartLine className="linkIcons" />
                                <p className="navigationLinks">Number Tracking</p>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.partnerSuccessDashboardPath}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><FaHome className="linkIcons" />
                                <p className="navigationLinks">Dashboard</p>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.partnerSuccessBulkEnrolPath}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><FaRegEdit className="linkIcons" />
                                <p className="navigationLinks">Bulk Enrol</p>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.partnerSuccessBulkSignupPath}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><FaUsers className="linkIcons" />
                                <p className="navigationLinks">Bulk Signup</p>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                            <NavLink
                                to={AppConstants.partnerSuccessBulkUploadLeads}
                                className={({ isActive }) =>
                                    isActive ? "active" : undefined
                                }
                            ><GrTableAdd className="linkIcons" />
                                <p className="navigationLinks">Upload Leads</p>
                            </NavLink>
                        </Nav.Item>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><FaRegUserCircle className="linkIcons adminSubMenuIcon" />Manage User</Accordion.Header>
                                <Accordion.Body>
                                    <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.partnerSuccessManageLeadsRoute}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        ><IoPeopleSharp className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Leads</p>
                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item className="sideNavigationItem" onClick={() => setExpanded(false)}>
                                        <NavLink
                                            to={AppConstants.partnerSuccessManageExpertPath}
                                            className={({ isActive }) =>
                                                isActive ? "active" : undefined
                                            }
                                        ><FaRegCalendarCheck className="linkIcons linkIconsSubMenu" />
                                            <p className="navigationLinks">Onboarding</p>
                                        </NavLink>
                                    </Nav.Item>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}