import { useState } from "react";
import { MdClear } from "react-icons/md";
import { AppConstants } from "../../constants/AppConstants";
import { StudentRecentlyPublishedLearningActivitiesCards } from '../cards/StudentRecentlyPublishedLearningActivitiesCards';
import { LearningActivitiesTabs } from "../../component/lists/LearningActivitiesTabs";
import classes from "./StudentHomeRecentlyPublishedLearningActivities.module.css";
import { ErrorMessages } from "../../constants/ErrorMessages";

export const StudentHomeRecentlyPublishedLearningActivities = (props) => {
    const [recentlyPublishedActivitiesData, setRecentlyPublishedActivitiesData] = useState(props.recentlyPublishedActivitiesDetails);
    const [filteredList, setFilteredList] = useState(recentlyPublishedActivitiesData);
    const [selectedLearningActivityType, setSelectedLearningActivityType] = useState(AppConstants.AllText);
    const [filterText, setFilterText] = useState(AppConstants.emptyString);

    // Function to get filtered list based on learning activity selection
    function learningActivitySelectionHandler(selectedLearningActivity) {
        setSelectedLearningActivityType(selectedLearningActivity);
        resetFilterText();
        if (selectedLearningActivity === null || selectedLearningActivity === AppConstants.AllText) {
            setFilteredList(recentlyPublishedActivitiesData);
        }
        else {
            setFilteredList(recentlyPublishedActivitiesData.filter(item => item.learningActivityType === selectedLearningActivity));
        }
    }

    function searchLearningActivity(e) {
        setFilterText(e.target.value)
        let filteredArray = [];
        if (selectedLearningActivityType === AppConstants.AllText) {
            filteredArray = e.target.value.toLowerCase() !== AppConstants.emptyString
                ? recentlyPublishedActivitiesData.filter((item) => ((item.skillSlug?.toLowerCase())?.includes(e.target.value.toLowerCase())) || (item.title?.toLowerCase())?.includes(e.target.value.toLowerCase()) || (item.courseTitle?.toLowerCase())?.includes(e.target.value.toLowerCase()) || (item.workshopTitle?.toLowerCase())?.includes(e.target.value.toLowerCase()) || (item.modeOfDelivery?.toLowerCase().includes(e.target.value.toLowerCase())) || (item.type?.toLowerCase().includes(e.target.value.toLowerCase())) || (item.workshopModeOfDelivery?.toLowerCase().includes(e.target.value.toLowerCase())))
                : recentlyPublishedActivitiesData;
        } else {
            filteredArray = e.target.value.toLowerCase() !== AppConstants.emptyString
                ? recentlyPublishedActivitiesData.filter((item) => (item.learningActivityType === selectedLearningActivityType) && (((item.skillSlug?.toLowerCase())?.includes(e.target.value.toLowerCase()) || (item.title?.toLowerCase())?.includes(e.target.value.toLowerCase()) || (item.courseTitle?.toLowerCase())?.includes(e.target.value.toLowerCase())) || (item.workshopTitle?.toLowerCase())?.includes(e.target.value.toLowerCase()) || (item.type?.toLowerCase().includes(e.target.value.toLowerCase())) || (item.modeOfDelivery?.toLowerCase().includes(e.target.value.toLowerCase())) || (item.workshopModeOfDelivery?.toLowerCase().includes(e.target.value.toLowerCase()))))
                : recentlyPublishedActivitiesData.filter((item) => (item.learningActivityType === selectedLearningActivityType))
        }

        setFilteredList(filteredArray);
    }

    function resetFilterText() {
        setFilterText(AppConstants.emptyString);
        if (selectedLearningActivityType === null || selectedLearningActivityType === AppConstants.AllText) {
            setFilteredList(recentlyPublishedActivitiesData);
        }
        else {
            setFilteredList(recentlyPublishedActivitiesData.filter(item => item.learningActivityType === selectedLearningActivityType));
        }
    }

    return (
        <>
            <div className={classes.header}>
                <h1 className={classes.sectionHeading}>Recently Published Learning Activities</h1>
                <div className={classes.learningActivityTabs}>
                    <LearningActivitiesTabs onSelection={learningActivitySelectionHandler} />
                </div>
            </div>
            <div className={classes.inputBoxContainer}>
                <input type="text" className={classes.searchInputBox} value={filterText} onChange={(e) => { searchLearningActivity(e) }} placeholder="Search learning activity" />
                {filterText.length > 0 && <MdClear className={classes.resetSearchIcon} onClick={() => resetFilterText()} />}
            </div>
            {filteredList.length !== 0 && <StudentRecentlyPublishedLearningActivitiesCards minimumReviewCount={props.minimumReviewCount} learningActivityType={selectedLearningActivityType} cardDetails={filteredList} category="Recently Published" />}
            {filteredList.length === 0 && <div>
                {selectedLearningActivityType === AppConstants.AllText && <p className="noDataAvailableError">{ErrorMessages.noRecentlyPublishedActivitiesText}</p>}
                {selectedLearningActivityType === AppConstants.tabCompetitionValueText && <p className="noDataAvailableError">{ErrorMessages.noRecentlyPublishedCompetitionsText}</p>}
                {selectedLearningActivityType === AppConstants.tabCoursesValueText && <p className="noDataAvailableError">{ErrorMessages.noRecentlyPublishedCoursesText}</p>}
                {selectedLearningActivityType === AppConstants.tabWorkshopValueText && <p className="noDataAvailableError">{ErrorMessages.noRecentlyPublishedWorkshopsText}</p>}
            </div>}
        </>
    );
}