import { Outlet } from 'react-router-dom';
import React from 'react';
import { Row, Col } from "react-bootstrap";
import { PartnerSuccessHeader } from '../../layout/PartnerSuccessPageLayout/PartnerSuccessHeader';
import { PartnerSuccessLeftNav } from '../../layout/PartnerSuccessPageLayout/PartnerSuccessLeftNav';
import { PartnerSuccessFooter } from '../../layout/PartnerSuccessPageLayout/PartnerSuccessFooter';
import { useLoaderData } from 'react-router-dom';

export const PartnerSuccessTemplate = () => {
    const footerDetails = useLoaderData();
    
    return (
        <>
            <PartnerSuccessHeader />
            <Row>
                <Col lg={2}><PartnerSuccessLeftNav /></Col>
                <Col xs={12} sm={12} md={12} lg={10}>
                    <Outlet />
                </Col>
            </Row>
            {footerDetails != null && <PartnerSuccessFooter footerDetails={footerDetails} />}
        </>
    )
}