import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { HiOutlineUserCircle } from 'react-icons/hi';
import { AiOutlineCalendar, AiOutlineException, AiOutlineDownload, AiOutlineEnvironment, AiOutlineExclamationCircle, AiOutlineMail, AiOutlineMobile, AiOutlineUser } from 'react-icons/ai';
import { BiLoaderAlt, BiTimeFive } from 'react-icons/bi';
import { MdOutlineComputer, MdOutlineSend } from 'react-icons/md';
import { FaRegMoneyBillAlt, FaPaperPlane } from 'react-icons/fa';
import { BsBuilding } from 'react-icons/bs';
import { IoMdClose, IoMdStopwatch } from 'react-icons/io';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { Button } from "react-bootstrap";
import { AppConstants } from '../../constants/AppConstants';
import { AuthorizedDataService } from '../../services/AuthorizedDataService';
import { DataService } from '../../services/DataService';
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { ErrorMessages } from "../../constants/ErrorMessages";
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import Auth from "../../utils/Auth";
import individualCourseLayout from "./IndividualCoursePageLayout.module.css";
import { IndividualCoursePageBanner } from "./individualCoursePageBanner/IndividualCoursePageBanner";
import { CourseReviewCards } from "../cards/courseReviewCards/CourseReviewCards";
import { FaqSectionCards } from "../cards/faqSectionCards/FaqSectionCards";
import { EnrolmentModal } from "../modal/EnrolmentModal";
import { ViewSelectedWeekDays } from "../calendar/ViewSelectedWeekDays";
import parse from 'html-react-parser';
import { PaymentsUtil } from "../../utils/PaymentsUtil";
import { LoginModal } from "../modal/LoginModal";
import { DateTime } from "luxon";
import { ShareLinkToSocialMediaUtil } from "../../utils/ShareLinkToSocialMediaUtil";
import { PostStartEnrolmentModal } from "../modal/PostStartEnrolmentModal";
import Moment from "react-moment";
import { AskQuestionModal } from "../modal/AskQuestionModal";
import { FaChalkboardTeacher } from "react-icons/fa";
import { PayOfflineConfirmationModal } from "../modal/PayOfflineConfirmationModal";
import { MathUtil } from "../../utils/MathUtil";
import { useEnrolment } from "../../hooks/useEnrolment";
import { useInAppBrowserStatus } from "../../hooks/useInAppBroswerStatus";
import { IoIosHourglass } from "react-icons/io";
import useWarnOnLeave from "../../hooks/useWarnOnLeave";

export const IndividualCoursePageLayout = () => {
    const navigate = useNavigate();
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [isStudentEnroled, setIsStudentEnroled] = useState(false);
    const { categorySlug, skillSlug, courseSlug } = useParams();
    const [courseDetails, setCourseDetails] = useState({});
    const [coursePageLoadingSpinner, setCoursePageLoadingSpinner] = useState(true);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [showPlayIcon, setShowPlayIcon] = useState(true);
    const [reviewComments, setReviewComments] = useState([]);
    const [reviewSpinner, setReviewSpinner] = useState(false);
    const [comment, setComment] = useState(AppConstants.emptyString);
    const [showQuestionModal, setShowQuestionModal] = useState(false);
    const [showEnrolmentModal, setShowEnrolmentModal] = useState(false);
    const [fetchReviewCommentsFlag, setFetchReviewCommentsFlag] = useState(0);
    const [modalTypeError, setModalTypeError] = useState(false);
    const [modalMessage, setModalMessage] = useState(AppConstants.emptyString);
    const [reviewCommentError, setReviewCommentError] = useState(null);
    const [courseWeekDays, setCourseWeekDays] = useState(null);
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);
    const [searchParams, setSearchParams] = useSearchParams();
    const [addClassFlag, setAddClassFlag] = useState(AppConstants.falseText);
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));
    const [payloadData, setPayloadData] = useState({});
    const [showLoginModal, setShowLoginModal] = useState(AppConstants.falseText);
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [profanityDetails, setProfanityDetails] = useState({});
    const [signupTimerFlag, setSignupTimerFlag] = useState(AppConstants.falseText);
    const [signupTimerDuration, setSignupTimerDuration] = useState(AppConstants.emptyString);
    const [isEnrolButtonClickedOnce, setIsEnrolledButtonClickedOnce] = useState(AppConstants.falseText);
    const [verifyPaymentCalled, setVerifyPaymentCalled] = useState(AppConstants.falseText);
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const { enrolInLearningActivity } = useEnrolment();
    const isInAppBrowser = useInAppBrowserStatus();
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const defaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.courseDefaultThumbnailImage}`;
    const linkToShare = `${process.env.REACT_APP_FRONT_END_URL}${AppConstants.coursesText}${AppConstants.forwardSlash}${categorySlug}${AppConstants.forwardSlash}${skillSlug}${AppConstants.forwardSlash}${courseSlug}`;
    const videoPlayIconUrl = `${mediaContent}${AppConstants.designImagesS3Path}/${AppConstants.videoPlayIconImagePath}`;
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    useWarnOnLeave(shouldWarn);
    const [canStudentPostReview, setCanStudentPostReview] = useState(AppConstants.falseText);

    useEffect(() => {
        const fetchConfigurableKeys = async () => {
            const url = AppConstants.fetchConfigurableKeysAPI;
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setSignupTimerDuration(parseInt(response.find(item => item.keyName === AppConstants.signupEnrolmentModalDurationKey).keyValue));
            }
        };

        fetchConfigurableKeys();

        const fetchCourseDetails = async () => {
            setCoursePageLoadingSpinner(true);
            const url = `${AppConstants.getIndividualCoursePageDetailsAPI}${courseSlug}`;
            const requestHeaders = {
                preferredTimezone: timeZone
            }

            let response;
            if (Auth.isLogin()) {
                response = await AuthorizedDataService.getRequest(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString,
                );
            } else {
                response = await DataService.get(
                    url,
                    AppConstants.emptyString,
                    AppConstants.emptyString,
                    requestHeaders
                );
            }

            if (response !== undefined) {
                setCourseDetails(response);
                var weekDaysCourse = response?.weekDays?.split(',');
                setCourseWeekDays(weekDaysCourse);
                setCoursePageLoadingSpinner(false);
            } else {
                setCourseDetails([]);
                setCoursePageLoadingSpinner(false);
                navigate(AppConstants.asterisk);
            }
        };

        const fetchProfanityApiDetails = async () => {
            const url = AppConstants.getProfanityApiDetailsAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setProfanityDetails(response);
            }
        }
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            fetchProfanityApiDetails();
        }

        fetchCourseDetails();
    }, []);

    useEffect(() => {
        const openSignupModalAfterTimer = (isEnrolButtonClickedOnce) => {
            if (!isEnrolButtonClickedOnce) {
                setShowEnrolmentModal(AppConstants.trueText);
                setSignupTimerFlag(AppConstants.trueText);
            }
        }
        if (!Auth.isLogin() && signupTimerDuration && courseDetails?.uniqueId && courseDetails.disableEnrolmentFlag === AppConstants.nText && (DateTime.fromISO(courseDetails.courseUtcRegistrationClosesOn?.replace(' ', 'T') + 'Z').setZone(timeZone) > DateTime.local().setZone(timeZone) || courseDetails.courseType === AppConstants.flexibleCourseType)) {
            let timer = setTimeout(openSignupModalAfterTimer, signupTimerDuration * 1000, isEnrolButtonClickedOnce);
            return () => { clearTimeout(timer) };
        }

    }, [courseDetails, signupTimerDuration, isEnrolButtonClickedOnce]);

    useEffect(() => {
        const checkStudentEnrollmentStatus = async () => {
            const url = `${AppConstants.checkStudentEnrolmentStatusAPI}${courseDetails.uniqueId}`;
            const response = await AuthorizedDataService.getRequest(url, AppConstants.emptyString, AppConstants.emptyString);
            if (response === 1) {
                setIsStudentEnroled(true);
            } else {
                setIsStudentEnroled(false);
            }
        }

        if (Auth.isLogin() && courseDetails?.uniqueId) {
            checkStudentEnrollmentStatus();
        }
    }, [courseDetails])

    useEffect(() => {
        const fetchCourseReviewComments = async () => {
            const url = `${AppConstants.getCourseReviewCommentsAPI}${courseDetails.uniqueId}`;
            const requestHeaders = {
                preferredTimezone: timeZone
            }
            const response = await DataService.get(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString,
                requestHeaders
            );

            if (response !== undefined) {
                setReviewComments(response);
            } else {
                setReviewComments([]);
            }
        }
        const checkStudentReviewEligiblity = async () => {
            const learningActivityType = AppConstants.tabCoursesValueText;
            const url = `${AppConstants.checkStudentEligibilityToPostCourseReview}${courseDetails.uniqueId}&learningActivityType=${learningActivityType}`;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );

            if (response === 0) {
                setCanStudentPostReview(AppConstants.trueText);
            } else {
                setCanStudentPostReview(AppConstants.falseText);
            }
        }

        if (courseDetails?.uniqueId) {
            fetchCourseReviewComments();
            if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
                checkStudentReviewEligiblity();
            }
        }
    }, [fetchReviewCommentsFlag, courseDetails]);

    const playVideo = () => {
        setIsVideoPlaying(true);
        setShowPlayIcon(false);
    };

    const handleClose = () => {
        setIsVideoPlaying(false);
        setShowPlayIcon(true);
    };

    const checkReviewValidity = async () => {
        try {
            if (comment?.trim() === AppConstants.emptyString) {
                setReviewSpinner(false);
                setReviewCommentError(ErrorMessages.coursePageInvalidReviewComment);
                setTimeout(() => {
                    setReviewCommentError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return AppConstants.falseText;
            };

            setReviewSpinner(AppConstants.trueText);
            const url = `${profanityDetails.endpoint}?text=${comment.trim()}`;
            const options = {
                method: 'GET',
                headers: {
                    'X-RapidAPI-Key': profanityDetails.key,
                    'X-RapidAPI-Host': profanityDetails.host
                }
            };
            const response = await fetch(url, options);
            const data = await response.json();

            if (data.has_profanity) {
                setReviewCommentError(ErrorMessages.reviewProfanityError);
                setTimeout(() => {
                    setReviewCommentError(AppConstants.emptyString);
                }, AppConstants.messageDisappearTime);
                return AppConstants.falseText;
            } else {
                return AppConstants.trueText;
            }
        } catch (error) {
            return AppConstants.falseText;
        }
    }

    const postReviewComments = async () => {
        const isValid = await checkReviewValidity();
        if (isValid) {
            setReviewCommentError(null);
            try {
                const url = AppConstants.postCourseReviewCommentAPI;
                let data = {
                    reviewComment: comment,
                    courseUniqueId: courseDetails.uniqueId
                }
                const response = await AuthorizedDataService.postRequest(
                    url,
                    data,
                    AppConstants.emptyString,
                    AppConstants.emptyString
                );

                if (response !== undefined) {
                    setComment(AppConstants.emptyString);
                    setReviewSpinner(false);
                    setFetchReviewCommentsFlag(fetchReviewCommentsFlag + 1);
                } else {
                    setReviewSpinner(false);
                }
            } catch (error) {
                setReviewSpinner(false);
            }
        } else {
            setReviewSpinner(false);
        }
    };

    const handleAskQuestion = () => {
        if (Auth.isLogin()) {
            if (Auth.getLoggedInUserDetails().userRole !== AppConstants.userRoleStudent) {
                setModalTypeError(true);
                setModalMessage(ErrorMessages.onlyStudentRaiseCourseQueryError)
                setShowEnrolmentModal(true);
            } else {
                setShowQuestionModal(true);
            }
        } else {
            setShowLoginModal(AppConstants.trueText);
        }
    }

    const closeQuestionModal = () => {
        setShowQuestionModal(false);
    };

    const hideLoginModal = () => {
        if (Auth.isLogin()) {
            window.location.reload();
        }
        setShowLoginModal(AppConstants.falseText);
    }

    useEffect(() => {
        const verifyPayment = async () => {
            try {
                if (paymentParam && courseDetails.uniqueId && !verifyPaymentCalled) {
                    setVerifyPaymentCalled(AppConstants.trueText);
                    const data = JSON.parse(window.atob(paymentParam));
                    setPaymentStatus(AppConstants.paymentStatus.pending);
                    if (data.learningActivityType === AppConstants.learningActivitiesTypes.course) {
                        data.learningActivityName = courseDetails.courseTitle;
                        setPayloadData(data);
                        setAddClassFlag(courseDetails.courseType === AppConstants.flexibleCourseType && courseDetails.courseEnrolledOnDate !== AppConstants.nullText ? AppConstants.trueText : AppConstants.falseText);
                        const timer = setTimeout(() => {
                            PaymentsUtil.verifyPayment(data).then((response) => {
                                if (response.verificationStatus === AppConstants.paymentStatus.verified) {
                                    setPaymentStatus(AppConstants.paymentStatus.verified);
                                    setTransactionId(response.transactionId);
                                    window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                                    handleEnrolment(courseDetails.courseId, courseDetails.courseTitle, courseDetails.uniqueId, AppConstants.paymentStatus.verified);
                                } else {
                                    setModalTypeError(true);
                                    setModalMessage(response.message);
                                    setTransactionId(response.transactionId);
                                    setPaymentStatus(AppConstants.emptyString);
                                    window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                                    setShowEnrolmentModal(true);
                                }
                            }).catch(error => {
                                setModalTypeError(true);
                                setModalMessage(ErrorMessages.verifyPaymentFailedError);
                                setPaymentStatus(AppConstants.emptyString);
                                window.history.pushState(AppConstants.nullText, AppConstants.nullText, window.location.href.split(AppConstants.questionMark)[0]);
                                setShowEnrolmentModal(true);
                            });
                        }, AppConstants.paymentVerificationTimeout);

                        return () => clearTimeout(timer);
                    }
                }
            } catch (error) {
                setPaymentStatus(AppConstants.emptyString);
            }
        };
        verifyPayment();
    }, [paymentParam, courseDetails]);

    const confirmBeforeEnrolment = () => {
        setIsEnrolledButtonClickedOnce(AppConstants.trueText);
        setConfirmationPopupActivityDetails({ id: courseDetails.courseId, title: courseDetails.courseTitle, uniqueId: courseDetails.uniqueId, payOfflineFlag: courseDetails.payOfflineFlag, creatorRole: courseDetails.userRole, creatorName: courseDetails.userRole === AppConstants.userRoleExpert ? courseDetails.expertName : courseDetails.agencyName, type: AppConstants.learningActivitiesTypes.course })
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            const startTime = `${courseDetails.utcStartDate} ${courseDetails.windowStartTime}`;
            const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
            const currentDateTime = DateTime.local().setZone(timeZone);
            if (currentDateTime > startDateTime && courseDetails.courseType === AppConstants.structuredCourseType) {
                setShowConfirmationModal(AppConstants.trueText);
            } else {
                if (courseDetails.payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    handleEnrolment(courseDetails.courseId, courseDetails.courseTitle, courseDetails.uniqueId);
                }
            }
        } else {
            handleEnrolment(courseDetails.courseId, courseDetails.courseTitle, courseDetails.uniqueId);
        }

    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                handleEnrolment(courseDetails.courseId, courseDetails.courseTitle, courseDetails.uniqueId);
            }
        }
    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            handleEnrolment(confirmationPopupActivityDetails.courseId, confirmationPopupActivityDetails.courseTitle, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    //enroll to courses
    const handleEnrolment = async (courseId, courseTitle, courseUniqueId, status) => {
        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setModalTypeError(AppConstants.trueText);
            setModalMessage(ErrorMessages.onlyStudentEnrolCourseError);
            setPaymentStatus(AppConstants.emptyString);
            setShowEnrolmentModal(true);
            return;
        }

        const cost = PaymentsUtil.calculateCost({ cost: courseDetails.cost, discount: courseDetails.discount });
        let response = await enrolInLearningActivity({
            uniqueId: courseUniqueId,
            cost,
            learningActivityType: AppConstants.learningActivitiesTypes.course,
            learningActivityId: courseId,
            learningActivityName: courseTitle,
            courseType: courseDetails.courseType,
            subscriptionType: courseDetails.courseType === AppConstants.flexibleCourseType && courseDetails.courseEnrolledOnDate !== null ? courseDetails.trackingType : AppConstants.falseText
        }, status)

        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setModalMessage(response.message);
        setShowEnrolmentModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        handleEnrolment(confirmationPopupActivityDetails.courseId, confirmationPopupActivityDetails.courseTitle, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    };

    const handleEnrolmentModalClose = () => {
        setShowEnrolmentModal(false);
        if (signupTimerFlag) {
            setSignupTimerFlag(AppConstants.falseText);
        } else {
            if (paymentParam) {
                window.location?.replace(window.location.href.split("?")[0]);
            } else if (transactionId) {
                window.location.reload();
            } else {
                if (userLoggedInAndEnroled) {
                    window.location.reload();
                } else {
                    if (modalTypeError) {
                        setModalTypeError(false);
                        setModalMessage(AppConstants.emptyString)
                        setShowEnrolmentModal(false);
                    } else {
                        window.location.reload();
                    }
                }
            }
        }
    };

    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowEnrolmentModal(AppConstants.falseText);
        const rzpay = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShowEnrolmentModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setShouldWarn(AppConstants.trueText);
                setTimeout(async () => {
                    handleEnrolment(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityTitle, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }

        });

        rzpay.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzpay.open();
    };

    async function downloadPreRead() {
        const fileUrl = courseDetails.courseDescriptionDocumentAbsolutePath;
        FileOperationsUtil.downloadDocument(fileUrl, courseDetails.courseTitle, AppConstants.guidelinesFile);
    };

    const verifyReviewDateEligibility = () => {
        return courseDetails.courseType === AppConstants.flexibleCourseType ? AppConstants.trueText : (DateTime.fromISO(`${courseDetails.utcStartDate} ${courseDetails.windowStartTime}`?.replace(' ', 'T') + 'Z').setZone(timeZone) <= DateTime.local().setZone(timeZone));
    };

    const showDisableEnrolmentWarning = () => {
        if (courseDetails.disableEnrolmentFlag === AppConstants.yText) {
            if (isStudentEnroled) {
                return false;
            } else if (courseDetails.courseType === AppConstants.structuredCourseType && DateTime.fromISO(courseDetails.courseUtcRegistrationClosesOn?.replace(' ', 'T') + 'Z').setZone(timeZone) > DateTime.local().setZone(timeZone)) {
                return AppConstants.trueText;
            } else if (courseDetails.courseType === AppConstants.flexibleCourseType) {
                return AppConstants.trueText
            }
        } else {
            return false;
        }
    }

    return (
        <div>
            {!coursePageLoadingSpinner && courseDetails?.id && <IndividualCoursePageBanner courseDetails={courseDetails} />}
            {!coursePageLoadingSpinner && courseDetails?.id && <div className={individualCourseLayout.shareIconPannelContainer}>
                <ShareLinkToSocialMediaUtil className={individualCourseLayout} linkToShare={linkToShare} />
            </div>}
            {!coursePageLoadingSpinner && courseDetails?.id && <div className={individualCourseLayout.courseLayout}>
                <div className={individualCourseLayout.thumbnailContainer}>
                    <div className={individualCourseLayout.leftCourseDetails}>
                        <img className={individualCourseLayout.courseThumbnailImage} src={courseDetails.courseThumbnailImageAbsolutePath ? courseDetails.courseThumbnailImageAbsolutePath : defaultThumbnail} alt="Thumbnail"></img>
                        <div className={individualCourseLayout.leftThumbnailDetails}>
                            <div className={individualCourseLayout.titleContainer}>
                                <p className={individualCourseLayout.courseTitle}>{courseDetails.courseTitle}</p>
                            </div>
                            <div className={individualCourseLayout.courseDuration}>
                                {/* {(courseDetails.courseType === AppConstants.structuredCourseType || courseDetails.trackingType === AppConstants.classBasedTrackingText) && */}
                                <div className={individualCourseLayout.costInfo}>
                                    <BiTimeFive />&nbsp;
                                    <div className={individualCourseLayout.costText}>{courseDetails.duration} {courseDetails.duration !== 0.5 && courseDetails.duration !== 1 ? "Hours" : "Hour"}</div>
                                </div>
                                {/* } */}
                                {/* {(courseDetails.courseType === AppConstants.structuredCourseType || courseDetails.trackingType === AppConstants.classBasedTrackingText) &&  */}
                                <div className={individualCourseLayout.costInfo}>
                                    <MdOutlineComputer />&nbsp;
                                    <div className={individualCourseLayout.costText}>{courseDetails.numberOfClasses} {courseDetails.numberOfClasses !== 1 ? "Classes" : "Class"}</div>
                                </div>
                                {/* } */}
                                {courseDetails.cost !== null && courseDetails.cost !== 0 && courseDetails.cost - courseDetails.discount !== 0 && <div className={individualCourseLayout.costInfo}>
                                    <FaRegMoneyBillAlt />&nbsp;
                                    {courseDetails.discount ?
                                        <div className={individualCourseLayout.costTextContainer}>{PaymentsUtil.calculateCost({ cost: courseDetails.cost, discount: courseDetails.discount })}&nbsp;&nbsp;<div className={individualCourseLayout.costRow}>{MathUtil.formatNumberToLocaleString(courseDetails.cost)}</div>{` (${MathUtil.formatNumberToLocaleString(courseDetails.discount)} OFF)`}</div> :
                                        <div className={individualCourseLayout.costText}>{PaymentsUtil.calculateCost({ cost: courseDetails.cost, discount: courseDetails.discount })}</div>}
                                </div>}
                                {courseDetails.cost !== null && courseDetails.cost !== 0 && courseDetails.cost - courseDetails.discount === 0 && <div className={individualCourseLayout.costInfo}>
                                    <FaRegMoneyBillAlt />&nbsp;
                                    <div className={individualCourseLayout.costText}>{AppConstants.freeText}</div>
                                </div>}
                                {courseDetails.cost === 0 && <div className={individualCourseLayout.costInfo}>
                                    <FaRegMoneyBillAlt />&nbsp;
                                    <div className={individualCourseLayout.costText}>{AppConstants.freeText}</div>
                                </div>}
                            </div>
                            {(courseDetails.trackingType === AppConstants.durationBasedTrackingText) && <div className={`${individualCourseLayout.courseDate} ${individualCourseLayout.thumbnailExpertName}`}>
                                <AiOutlineException />&nbsp;
                                <div className={individualCourseLayout.costText}>Package: {courseDetails.packageDuration} {courseDetails.packageDuration > 1 ? "Days" : "Day"}</div>
                            </div>}
                            {(courseDetails.trackingType === AppConstants.classBasedTrackingText && courseDetails.validity > 0) && <div className={`${individualCourseLayout.courseDate} ${individualCourseLayout.thumbnailExpertName}`}>
                                <AiOutlineException />
                                <div className={individualCourseLayout.costText}>Class Validity: {courseDetails.validity} {courseDetails.validity > 1 ? "Days" : "Day"} after enrolment</div>
                            </div>}

                            {courseDetails.courseType === AppConstants.structuredCourseType && <div className={individualCourseLayout.courseDate}><AiOutlineException />
                                {courseDetails.startTime.split(",")[1]}&nbsp;-&nbsp;{courseDetails.endTime.split(",")[1]} {timeZone === AppConstants.utcTimezone && `(${courseDetails.preferredTimezone})`}</div>}
                            {courseDetails.courseType === AppConstants.flexibleCourseType && courseDetails.trackingType === AppConstants.classBasedTrackingText && courseDetails.subscriptionStatus === AppConstants.subscriptionStatus.active && courseDetails.remainingClasses !== null && <div className={individualCourseLayout.courseDate}><FaChalkboardTeacher />
                                {courseDetails.remainingClasses} {courseDetails.remainingClasses > 0 ? "classes" : "class"} remaining</div>}
                            {courseDetails.courseType === AppConstants.flexibleCourseType && courseDetails.trackingType === AppConstants.classBasedTrackingText && courseDetails.subscriptionStatus === AppConstants.subscriptionStatus.active && courseDetails.subscriptionEndDate !== null && <div className={individualCourseLayout.courseDate}><IoIosHourglass />
                                Valid till:&nbsp;<Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{courseDetails.subscriptionEndDate?.replace(' ', 'T') + 'Z'}</Moment>&nbsp;|&nbsp;<Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{courseDetails.subscriptionEndDate?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${courseDetails.preferredTimezone})`}</div>}
                            {((courseWeekDays && courseWeekDays?.length !== 0) || (courseDetails.startTime != null) || (courseDetails.endTime != null)) && <div className={individualCourseLayout.courseDate}>
                                <AiOutlineCalendar />{(courseWeekDays && courseWeekDays?.length !== 0) && <div>&nbsp;</div>}
                                {(courseWeekDays && courseWeekDays?.length !== 0) && <ViewSelectedWeekDays selectedValues={courseWeekDays} />}
                                {(courseWeekDays && courseWeekDays?.length !== 0) && <div>&nbsp;&nbsp;</div>}
                                <div className={individualCourseLayout.courseTimings}>{courseDetails.startTime?.split(",")[0]} - {courseDetails.endTime?.split(",")[0]} {timeZone === AppConstants.utcTimezone && courseDetails.courseType === AppConstants.flexibleCourseType && `(${courseDetails.preferredTimezone})`}</div>
                            </div>}
                            {courseDetails.courseUtcRegistrationClosesOn && courseDetails.courseType === AppConstants.structuredCourseType && <div className={individualCourseLayout.courseDate}><IoMdStopwatch />Registration Deadline:&nbsp;<Moment format={AppConstants.competitionDatesNewDateFormat} tz={timeZone}>{courseDetails.courseUtcRegistrationClosesOn?.replace(' ', 'T') + 'Z'}</Moment>&nbsp;|&nbsp;<Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{courseDetails.courseUtcRegistrationClosesOn?.replace(' ', 'T') + 'Z'}</Moment> {timeZone === AppConstants.utcTimezone && `(${courseDetails.preferredTimezone})`}</div>}
                            {courseDetails.userRole === AppConstants.userRoleExpert && <p onClick={() => window.open(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.expert}${AppConstants.forwardSlash}${courseDetails.userSlug}`)} className={`${individualCourseLayout.courseDate} ${individualCourseLayout.thumbnailExpertName}`}><AiOutlineUser />Expert: {courseDetails.expertName} <p className={individualCourseLayout.skillName}><FaPaperPlane className={individualCourseLayout.skillIcon} /> {courseDetails.skillName}</p></p>}
                            {courseDetails.userRole === AppConstants.userRoleAgency && <p onClick={() => window.open(`${process.env.REACT_APP_FRONT_END_URL}${AppConstants.agency}${AppConstants.forwardSlash}${courseDetails.userSlug}`)} className={`${individualCourseLayout.courseDate} ${individualCourseLayout.thumbnailExpertName}`}><BsBuilding />Agency: {courseDetails.agencyName} <p className={individualCourseLayout.skillName}><FaPaperPlane className={individualCourseLayout.skillIcon} /> {courseDetails.skillName}</p></p>}
                            {courseDetails.courseDescriptionDocumentAbsolutePath !== null && <div className={individualCourseLayout.displayInline}><AiOutlineDownload />{isInAppBrowser ? <a href={courseDetails.courseDescriptionDocumentAbsolutePath} rel="noreferrer" target="_blank" className={` ${individualCourseLayout.donwloadPreRead} ${individualCourseLayout.thumbnailExpertName}`}>Download Course Brochure</a> : <p onClick={downloadPreRead} className={` ${individualCourseLayout.donwloadPreRead} ${individualCourseLayout.thumbnailExpertName}`}>Download Course Brochure</p>}</div>}
                            {courseDetails.modeOfDelivery === "Offline" && courseDetails.address !== null && <div className={individualCourseLayout.offlineAddress}>
                                <div className={individualCourseLayout.offlineAddressIcon}><AiOutlineEnvironment /> </div><p className={individualCourseLayout.onlineText}><a href={courseDetails.geoLocation} target={AppConstants.openInNewTab}>{`Offline (${courseDetails?.address})`}</a></p></div>}
                            {courseDetails.modeOfDelivery === "Online" && <div className={individualCourseLayout.displayInline}><AiOutlineEnvironment /> <p className={individualCourseLayout.onlineText}>{courseDetails?.modeOfDelivery}</p></div>}
                            {showDisableEnrolmentWarning() && <div className={`${individualCourseLayout.displayInline} ${individualCourseLayout.warningMessage}`}><p><AiOutlineExclamationCircle /> New enrolments are closed for this course</p></div>}
                        </div>
                    </div>
                    <div className={individualCourseLayout.rightSection}>
                        {((DateTime.fromISO(`${courseDetails.utcEndDate} ${courseDetails.windowEndTime}`?.replace(' ', 'T') + 'Z').setZone(timeZone)) > DateTime.local().setZone(timeZone) || courseDetails.courseType === AppConstants.flexibleCourseType) && <button onClick={handleAskQuestion} className={individualCourseLayout.askQuestionButton}>Ask a question</button>}
                        {((DateTime.fromISO(`${courseDetails.utcEndDate} ${courseDetails.windowEndTime}`?.replace(' ', 'T') + 'Z').setZone(timeZone)) > DateTime.local().setZone(timeZone) || courseDetails.courseTYpe === AppConstants.flexibleCourseType) && <Button onClick={handleAskQuestion} className={individualCourseLayout.askButtonBelow}>Ask a question</Button>}
                        {((!isStudentEnroled || (courseDetails.courseType === AppConstants.flexibleCourseType && courseDetails.subscriptionStatus !== AppConstants.subscriptionStatus.active)) && courseDetails.disableEnrolmentFlag === AppConstants.nText && ((DateTime.fromISO(courseDetails.courseUtcRegistrationClosesOn?.replace(' ', 'T') + 'Z').setZone(timeZone) > DateTime.local().setZone(timeZone)) || (courseDetails.courseType === AppConstants.flexibleCourseType && courseDetails.subscriptionStatus !== AppConstants.subscriptionStatus.active)) && (courseDetails.isOfflinePaymentRequested === AppConstants.nText || !courseDetails.isOfflinePaymentRequested))
                            ? <Button onClick={() => confirmBeforeEnrolment({ id: courseDetails.courseId, title: courseDetails.courseTitle, uniqueId: courseDetails.uniqueId, payOfflineFlag: courseDetails.payOfflineFlag, creatorRole: courseDetails.userRole, creatorName: courseDetails.userRole === AppConstants.userRoleExpert ? courseDetails.expertName : courseDetails.agencyName })} className={individualCourseLayout.enrolButton}>{courseDetails.courseEnrolledOnDate !== null ? AppConstants.renewPlanText : "Enrol Now"}</Button>
                            : (courseDetails.modeOfDelivery === "Online" && (courseDetails.isOfflinePaymentRequested === AppConstants.nText || !courseDetails.isOfflinePaymentRequested) && (moment(moment(new Date()).tz(timeZone).format(AppConstants.courseUTCDateFormat)).isSameOrBefore(moment(courseDetails.utcEndDate).tz(timeZone)) || (courseDetails.courseType === AppConstants.flexibleCourseType && courseDetails.subscriptionStatus === AppConstants.subscriptionStatus.active)) && isStudentEnroled
                                ? <Button className={`${individualCourseLayout.enrolButton} ${individualCourseLayout.sessionLinkButton}`} target={AppConstants.openInNewTab} href={courseDetails.sessionLink}>Session Link</Button>
                                : null)}
                        {(courseDetails.isOfflinePaymentRequested === AppConstants.yText) && <Button className={individualCourseLayout.enrolButton} disabled={true}>Requested</Button>}
                    </div>
                </div>
                {(courseDetails?.spocName || courseDetails?.spocEmail || courseDetails?.spocMobileNumber) &&
                    <div className={individualCourseLayout.section}>
                        <div className={`${individualCourseLayout.descriptionHeading} mb-2`}>SPOC Details</div>
                        <div className={individualCourseLayout.spocDetailsContainer}>
                            {courseDetails?.spocName && <div className={individualCourseLayout.displayInline}><HiOutlineUserCircle stroke-width={1.5} size={18} /> <p className={individualCourseLayout.thumbnailExpertName}>{courseDetails?.spocName}</p></div>}
                            {courseDetails?.spocEmail && <div className={individualCourseLayout.displayInline}><AiOutlineMail /> <p className={individualCourseLayout.thumbnailExpertName}>{courseDetails?.spocEmail}</p></div>}
                            {courseDetails?.spocMobileNumber && <div className={individualCourseLayout.displayInline}><AiOutlineMobile /> <p className={individualCourseLayout.thumbnailExpertName}>{courseDetails?.spocMobileNumber}</p></div>}
                        </div>
                    </div>}
                <div className={individualCourseLayout.section}>
                    <div className={individualCourseLayout.descriptionHeading}>Description</div>
                    <div className={`${individualCourseLayout.descriptionBody} quillListIndent ql-editor`}> {parse(courseDetails.courseDescription)}</div>
                </div>
                {(courseDetails.coursePromotionVideoAbsolutePath || reviewComments.length !== 0 || (canStudentPostReview && Auth.isLogin() && Auth.getLoggedInUserDetails()?.userRole === "Student" && isStudentEnroled && verifyReviewDateEligibility())) &&
                    <div className={individualCourseLayout.videoReviewSection}>
                        {courseDetails.coursePromotionVideoAbsolutePath !== null && <div className={individualCourseLayout.promotionVideoSection}>
                            {isVideoPlaying ? (
                                <div>
                                    <video controls autoPlay className={individualCourseLayout.videoContent}>
                                        <source src={courseDetails.coursePromotionVideoAbsolutePath} type="video/mp4" />
                                    </video>
                                    <IoMdClose className={individualCourseLayout.closeButton} onClick={handleClose} />
                                </div>
                            ) : <img src={courseDetails.courseThumbnailImageAbsolutePath || defaultThumbnail} alt="Play button" className={individualCourseLayout.videoContentThumnail} />}
                            {showPlayIcon && <img src={videoPlayIconUrl} onClick={playVideo} alt="Video Play Button" className={individualCourseLayout.videoIcon} />}
                            <div>

                            </div>
                        </div>}
                        <div className={individualCourseLayout.reviewSection}>
                            {(reviewComments.length !== 0 || (canStudentPostReview && Auth.isLogin() && Auth.getLoggedInUserDetails()?.userRole === "Student" && isStudentEnroled && verifyReviewDateEligibility())) && <div className={individualCourseLayout.reviewHeading}>Course Reviews</div>}
                            {reviewComments.length !== 0 && <div className={individualCourseLayout.reviewCards}>
                                {
                                    reviewComments?.map((review, index) => {
                                        return <div>
                                            <CourseReviewCards review={review} />
                                            {index + 1 < reviewComments.length && <hr />}
                                        </div>
                                    })
                                }
                                {
                                    reviewComments.length === 0 && <div className={individualCourseLayout.noReviewText}><p className="noDataAvailableError">Currently there are no reviews for this course</p></div>
                                }
                            </div>}
                            {canStudentPostReview && Auth.isLogin() && Auth.getLoggedInUserDetails()?.userRole === "Student" && isStudentEnroled && verifyReviewDateEligibility() && <div className={individualCourseLayout.reviewInput}>
                                <textarea maxLength={500} className={individualCourseLayout.inputTextBox} value={comment} onChange={(e) => setComment(e.target.value)} placeholder="Enter your comment" />
                                {reviewSpinner ? <BiLoaderAlt className="spinner" /> : <MdOutlineSend onClick={postReviewComments} />}
                            </div>}
                            {Auth.isLogin() && Auth.getLoggedInUserDetails()?.userRole === "Student" && isStudentEnroled && reviewCommentError && <p className={individualCourseLayout.reviewErrorMessage}>{reviewCommentError}</p>}
                        </div>
                    </div>}
                {courseDetails.courseFaqs.length > 0 && ((moment(new Date()).isSameOrBefore(moment(courseDetails.utcEndDate).add(1, 'day').tz(timeZone))) || courseDetails.courseType === AppConstants.flexibleCourseType) && <div className={individualCourseLayout.section}>
                    <div className={individualCourseLayout.faqHeading}>Frequently Asked Questions</div>
                    {
                        courseDetails.courseFaqs?.map(courseFaq => <FaqSectionCards faqDetails={courseFaq} />)
                    }
                </div>}
                {courseDetails.promotionalAdImageOne !== null && <div className={individualCourseLayout.competitionAdsSection}>
                    <div>
                        <img src={courseDetails.promotionalAdImageOne} className={individualCourseLayout.competitionAdImage} alt="Advertisment" />
                    </div>
                </div>}
            </div>}
            {
                coursePageLoadingSpinner && <div className={individualCourseLayout.errorContainer}>
                    <p className={individualCourseLayout.loaderIcon}><BiLoaderAlt className="spinner" /></p>
                </div>
            }
            {showQuestionModal && <AskQuestionModal learningActivityDetails={{ id: courseDetails.id, title: courseDetails.courseTitle, learningActivityType: AppConstants.learningActivitiesTypes.course, organizerEmail: courseDetails.expertEmail, organizerName: courseDetails.expertName, agencyName: courseDetails.agencyName, userRole: courseDetails.userRole }} closeQuestionModal={closeQuestionModal} />}
            {(showEnrolmentModal || payloadData.learningActivityType === AppConstants.learningActivitiesTypes.course) && <EnrolmentModal signupTimerFlag={signupTimerFlag} onShow={() => setShowEnrolmentModal(AppConstants.trueText)} enrolInCourse={handleEnrolment} message={modalMessage} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} modalTypeError={modalTypeError} onClose={handleEnrolmentModalClose} paymentStatus={paymentStatus} transactionId={transactionId} confirmBeforeEnrolment={confirmBeforeEnrolment} creatorName={courseDetails.userRole === AppConstants.userRoleExpert ? courseDetails.expertName : courseDetails.agencyName} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {showLoginModal && <LoginModal onShow={() => setShowLoginModal(AppConstants.trueText)} onClose={hideLoginModal} />}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </div >
    )
}
