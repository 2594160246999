import { Tabs, Tab } from "react-bootstrap";
import { AppConstants } from "../../constants/AppConstants";
import "../expertManageCompetitionComponent/viewCompetitionTabs.css";

export const EventStatusNavTab = (props) => {
    const courseStatus = AppConstants.courseStatus;

    function selectCourseType(e) {
        props.changeStatus(e);
    };

    return (
        <div className="expertViewCompetitionsTabs">
            <Tabs className="expertViewCompetitionsTabs"
                defaultActiveKey={courseStatus.upcoming}
                id="viewCometitionsTabs"
                fill
                onSelect={(e) => selectCourseType(e)}
            >
                <Tab eventKey={courseStatus.upcoming} title={courseStatus.upcoming}></Tab>
                <Tab eventKey={courseStatus.ongoing} title={courseStatus.ongoing}></Tab>
                <Tab eventKey={courseStatus.past} title={courseStatus.past}></Tab>
            </Tabs>
        </div>
    );
}