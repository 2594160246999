import React, { useEffect, useState } from "react";
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { CgPin } from "react-icons/cg";
import { DateTime } from "luxon";
import { Row, Col, Card, Button } from "react-bootstrap";
import classes from './IndividualSkillsCompetitionCards.module.css';
import { BsArrowDownCircle } from 'react-icons/bs';
import { AppConstants } from "../../../constants/AppConstants";
import { DateTimeUtil } from "../../../utils/DateTimeUtil";
import { AiOutlineCalendar } from 'react-icons/ai';
import { IoMedalOutline } from 'react-icons/io5'
import Moment from 'react-moment';
import { BiWallet } from 'react-icons/bi';
import { EnrolmentModal } from "../../modal/EnrolmentModal";
import { PaymentsUtil } from "../../../utils/PaymentsUtil";
import Auth from "../../../utils/Auth";
import { ErrorMessages } from "../../../constants/ErrorMessages";
import { RiGitRepositoryPrivateFill } from "react-icons/ri";
import { LoginModal } from "../../modal/LoginModal";
import { ShareLinkToSocialMediaUtil } from "../../../utils/ShareLinkToSocialMediaUtil";
import { TbShare3 } from "react-icons/tb";
import { PostStartEnrolmentModal } from "../../modal/PostStartEnrolmentModal";
import { PayOfflineConfirmationModal } from "../../modal/PayOfflineConfirmationModal";
import { useEnrolment } from "../../../hooks/useEnrolment";
import useWarnOnLeave from "../../../hooks/useWarnOnLeave";

export const IndividualSkillsCompetitionCards = (props) => {
    const [cardCount, setCardCount] = useState(0);
    const [cardDetails, setCardDetails] = useState(props.allActivitiesForSkill);
    const [searchParams, setSearchParams] = useSearchParams();
    const [viewMoreCardsCount, setViewMoreCardsCount] = useState(0);
    const [paymentParam, setPaymentParam] = useState(searchParams.get(AppConstants.paymentUrlParam));
    const [paramData, setParamData] = useState({});
    const [message, setMessage] = useState(AppConstants.emptyString);
    const [modalTypeError, setModalTypeError] = useState(AppConstants.falseText);
    const [showModal, setShowModal] = useState(false);
    const [userLoggedInAndEnroled, setUserLoggedInAndEnroled] = useState(AppConstants.falseText);
    const [paymentStatus, setPaymentStatus] = useState(AppConstants.emptyString);
    const [transactionId, setTransactionId] = useState(AppConstants.emptyString);
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const [showLoginModal, setShowLoginModal] = useState(AppConstants.falseText);
    const navigate = useNavigate();
    const [showSocialMediaPannel, setShowSocialMediaPannel] = useState(AppConstants.falseText);
    const [linkToShare, setLinkToShare] = useState(AppConstants.emptyString);
    const [editIndex, setEditIndex] = useState(null);
    const mediaContent = process.env.REACT_APP_MEDIA_CONTENT;
    const { enrolInLearningActivity } = useEnrolment();
    const [showOfflinePaymentModal, setShowOfflinePaymentModal] = useState(AppConstants.falseText);
    const [showConfirmationModal, setShowConfirmationModal] = useState(AppConstants.falseText);
    const [confirmationPopupActivityDetails, setConfirmationPopupActivityDetails] = useState({});
    const [displayNetworkError, setDisplayNetworkError] = useState(AppConstants.falseText);
    const [paymentInitiateResponse, setPaymentInitiateResponse] = useState({});
    const [shouldWarn, setShouldWarn] = useState(AppConstants.falseText);
    useWarnOnLeave(shouldWarn);
    const defaultWebBanner = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.individualSkillsCompetitionBannerPath}`;
    const defaultThumbnail = `${mediaContent}${AppConstants.staticContentImagesS3Path}/${AppConstants.bannerImagesS3Path}/${AppConstants.competitionDefaultThumbnailImage}`;

    useEffect(() => {
        setCardDetails(props.allActivitiesForSkill);
    }, [props.allActivitiesForSkill]);


    const handleResize = () => {
        const viewportWidth = window.innerWidth;
        if (viewportWidth >= 992) {
            setCardCount(3);
            setViewMoreCardsCount(3);
        }
        if (viewportWidth >= 576 && viewportWidth <= 991) {
            setCardCount(2);
            setViewMoreCardsCount(2);
        }
        if (viewportWidth <= 575) {
            setCardCount(1);
            setViewMoreCardsCount(1);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    useEffect(() => {
        const verifyPayment = async (data, competition) => {
            try {
                if (competition && competition?.uniqueId) {
                    PaymentsUtil.verifyPayment(data).then((response) => {
                        if (response.verificationStatus === AppConstants.paymentStatus.verified) {
                            setModalTypeError(AppConstants.falseText);
                            setPaymentStatus(AppConstants.paymentStatus.verified);
                            setTransactionId(response.transactionId);
                            window.history.pushState(null, null, window.location.href.split("?")[0]);
                            enrolInCompetition(competition.competitionId, competition.title, competition.uniqueId, AppConstants.paymentStatus.verified);
                        } else {
                            setModalTypeError(AppConstants.trueText);
                            setMessage(response.message);
                            setTransactionId(response.transactionId);
                            setPaymentStatus(AppConstants.emptyString);
                            window.history.pushState(null, null, window.location.href.split("?")[0]);
                            handleModalShow();
                        }
                    }).catch((error) => {
                        setModalTypeError(AppConstants.trueText);
                        setMessage(ErrorMessages.verifyPaymentFailedError);
                        setPaymentStatus(AppConstants.emptyString);
                        window.history.pushState(null, null, window.location.href.split("?")[0]);
                        handleModalShow();
                    });
                }
            } catch (error) {
                setPaymentStatus(AppConstants.emptyString);
            }
        }

        const decodeBase64EncodedString = (encodedString) => {
            try {
                return JSON.parse(window.atob(encodedString));
            } catch (error) {
                return null;
            }
        };

        if (paymentParam && cardDetails.length > 0) {
            const data = decodeBase64EncodedString(paymentParam);
            if (data !== null && data.category === AppConstants.learningActivitiesTypes.competition) {
                const competition = cardDetails.filter(card => card.uniqueId === data.uniqueId)[0];
                if (!competition) {
                    setParamData({});
                    setPaymentStatus(AppConstants.emptyString);
                    return;
                }
                data.learningActivityName = competition.title;
                setParamData(data);
                setPaymentStatus(AppConstants.paymentStatus.pending);

                const timer = setTimeout(() => {
                    verifyPayment(data, competition);
                }, AppConstants.paymentVerificationTimeout);

                return () => clearTimeout(timer);
            }
        };
    }, [paymentParam, cardDetails]);

    const confirmBeforeEnrolment = ({ id, title, uniqueId, startTime, payOfflineFlag, creatorName, creatorRole }) => {
        setConfirmationPopupActivityDetails({ id, title, uniqueId, startTime, type: AppConstants.learningActivitiesTypes.competition, creatorName, creatorRole, payOfflineFlag });
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            const startDateTime = DateTime.fromISO(startTime?.replace(' ', 'T') + 'Z').setZone(timeZone);
            const currentDateTime = DateTime.local().setZone(timeZone);
            // check if the learning activity has started.
            if (currentDateTime > startDateTime) {
                // show confirmation modal to the user.
                setShowConfirmationModal(AppConstants.trueText);
            } else {
                if (payOfflineFlag === AppConstants.yText) {
                    setShowOfflinePaymentModal(AppConstants.trueText);
                } else {
                    enrolInCompetition(id, title, uniqueId);
                }
            }
        } else {
            enrolInCompetition(id, title, uniqueId);
        }
    };

    const updateConfirmationStatus = (status) => {
        setShowConfirmationModal(AppConstants.falseText);
        // if user selects yes -> 
        if (status) {
            if (confirmationPopupActivityDetails.payOfflineFlag === AppConstants.yText) {
                setShowOfflinePaymentModal(AppConstants.trueText);
            } else {
                enrolInCompetition(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
            }
        } else {
            setConfirmationPopupActivityDetails({});
        }

    };

    const getLearnerPaymentMethod = (paymentType) => {
        setShowOfflinePaymentModal(AppConstants.falseText);
        if (paymentType === AppConstants.paymentMethod.online) {
            enrolInCompetition(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId);
        } else {
            setConfirmationPopupActivityDetails({});
        }
    };

    const enrolInCompetition = async (competitionId, competitionTitle, uniqueId, status) => {
        if (Auth.isLogin() && Auth.getUserRole() !== AppConstants.userRoleStudent) {
            setModalTypeError(AppConstants.trueText);
            setMessage(ErrorMessages.enrollmentUserNotStudentError);
            setPaymentStatus(AppConstants.emptyString);
            handleModalShow();
            return;
        }

        const cost = PaymentsUtil.calculateCost({ cost: cardDetails.filter(card => card.uniqueId === uniqueId)[0].cost, discount: 0 });
        const response = await enrolInLearningActivity({ uniqueId, cost, learningActivityName: competitionTitle, learningActivityId: competitionId, learningActivityType: AppConstants.learningActivitiesTypes.competition }, status);
        setModalTypeError(response.modalTypeError);
        setPaymentStatus(response.paymentStatus);
        setMessage(response.message);
        setShowModal(response.showModal);
        setDisplayNetworkError(response.networkError);
        setShouldWarn(response.shouldWarn);
        if (response.showRazorpayPopup) {
            setTimeout(() => {
                showRazorpayPopup(response);
            }, AppConstants.paymentGatewayRedirectTimeout);
        }
    };

    const onClickRetry = () => {
        setPaymentStatus(AppConstants.paymentStatus.pending);
        enrolInCompetition(confirmationPopupActivityDetails.id, confirmationPopupActivityDetails.title, confirmationPopupActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
        PaymentsUtil.verifyRazorpayPaymentStatus(paymentInitiateResponse.response, paymentInitiateResponse.learningActivityDetails);
    };

    const handleModalClose = () => {
        if (paymentParam) {
            window.location.replace(window.location.href.split("?")[0]);
        } else if (transactionId) {
            window.location.reload();
        } else {
            if (userLoggedInAndEnroled) {
                window.location.reload();
            } else {
                setShowModal(AppConstants.falseText);
                setConfirmationPopupActivityDetails({});
            }
        }
    };

    const handleModalShow = () => {
        setShowModal(AppConstants.trueText);
    };

    const handleLoginModalClose = () => {
        setShowLoginModal(AppConstants.falseText);
        if (Auth.isLogin()) {
            if (Auth.getUserRole() === AppConstants.userRoleStudent) {
                navigate(AppConstants.studentCompetitionsPath);
            } else {
                window.location.reload();
            }
        }
    };

    const handleLoginModalShow = () => {
        if (Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) {
            navigate(AppConstants.studentCompetitionsPath);
        } else {
            setShowLoginModal(AppConstants.trueText);
        }
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.openInNewTab);
    };

    // Share on social media
    function shareOnSocialMedia(shareData) {
        setShowSocialMediaPannel(!showSocialMediaPannel);
        setLinkToShare(`${process.env.REACT_APP_FRONT_END_URL}${shareData.competitionLink}`);
    }

    const navigateToGeolocation = (activityCard) => {
        if (activityCard.competitionType?.toLowerCase() === AppConstants.competitionTypeOffline.toLowerCase()) {
            window.open(activityCard.geoLocation, AppConstants.openInNewTab);
        }
    };


    const showRazorpayPopup = ({ options, learningActivityDetails }) => {
        setShowModal(AppConstants.falseText);
        const rzp1 = new window.Razorpay({
            ...options,
            handler: async (response) => {
                setShowModal(AppConstants.trueText);
                setTransactionId(response.razorpay_payment_id);
                setPaymentStatus(AppConstants.paymentStatus.pending);
                setShouldWarn(AppConstants.trueText);
                setPaymentInitiateResponse({ response, learningActivityDetails });
                setTimeout(async () => {
                    enrolInCompetition(learningActivityDetails.learningActivityId, learningActivityDetails.learningActivityName, learningActivityDetails.uniqueId, AppConstants.paymentStatus.verified);
                    PaymentsUtil.verifyRazorpayPaymentStatus(response, learningActivityDetails);
                }, AppConstants.enrolmentDelayTimeout);
            }

        });

        rzp1.on(AppConstants.razorpaypaymentFailed, function (response) {
            PaymentsUtil.handleRazorpayPaymentFailure(response, learningActivityDetails);
        });

        rzp1.open();
    };


    return (
        <>
            <Row xs={1} sm={1} md={2} lg={3} xl={3} className={`${classes.courseActivityCard} mt-3`}>
                {props.allActivitiesForSkill !== null && props.allActivitiesForSkill?.slice(0, cardCount).map((activityCard, index) => (
                    <Col className={classes.courseActivityCardSection}>
                        <Card key={activityCard.uniqueId} className={classes.competitionActivityMainCard} onClick={() => handleCardClick(`${process.env.REACT_APP_FRONT_END_URL}${activityCard.competitionLink}`)}>
                            <Card.Img variant="top" src={defaultWebBanner} className={classes.defaultBannerImage} />
                            <TbShare3 className={classes.shareIconUpcomingCards} onClick={(e) => { stopPropagation(e); setEditIndex(editIndex => editIndex === index ? index : index); shareOnSocialMedia(activityCard) }} />
                            <Card.Body className={classes.activityCardBody}>
                                <Card.Img variant="top" src={activityCard.thumbnailImagePath || defaultThumbnail} className={classes.competitionThumbnailImage} />
                                <div className={classes.competitionDetails}>
                                    <Card.Text className={`${classes.cardTitle} titleFixedHeight`}>
                                        <Card.Title data-tooltip-id={`${activityCard.uniqueId}-title`} className={classes.activityCardTitle}><Link className={classes.activityCardTitleLink}>{activityCard.title}</Link></Card.Title>
                                        <Tooltip className="courseTitleTooltip" id={`${activityCard.uniqueId}-title`}><span>{activityCard.title}</span></Tooltip>
                                    </Card.Text>
                                    {editIndex === index && showSocialMediaPannel && <div onClick={stopPropagation} className={classes.shareIconPannelContainerUpcomingCards}>
                                        <ShareLinkToSocialMediaUtil linkToShare={linkToShare} className={classes} useIcons={AppConstants.trueText} />
                                    </div>}
                                    <div className={classes.dateContainer}>
                                        {activityCard.userRole === "Expert" && <div onClick={stopPropagation} className={classes.competitionActivityOrganizer}>By&nbsp;<Link to={`${AppConstants.forwardSlash}${AppConstants.expert}${AppConstants.forwardSlash}${activityCard.userSlug}`} className={classes.competitionActivityOrganizerLink}>{activityCard.organizer}</Link></div>}
                                        {activityCard.userRole === "Agency" && <div onClick={stopPropagation} className={classes.competitionActivityOrganizer}>By&nbsp;<Link to={`${AppConstants.forwardSlash}${AppConstants.agency}${AppConstants.forwardSlash}${activityCard.userSlug}`} className={classes.competitionActivityOrganizerLink}>{activityCard.organizer}</Link></div>}
                                    </div>
                                    <div className={classes.dateContainer}>
                                        <p className={classes.dateText}>
                                            <AiOutlineCalendar className={classes.cardIcon} />&nbsp;<Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{activityCard.utcStartTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment>, <Moment format="Do MMM YYYY" tz={timeZone}>{activityCard.utcStartTime.replace(' ', 'T') + 'Z'}</Moment> - <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{activityCard.utcEndTime.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment>, <Moment format="Do MMM YYYY" tz={timeZone}>{activityCard.utcEndTime.replace(' ', 'T') + 'Z'}</Moment>{activityCard.preferredTimezone === AppConstants.utcTimezone && `(${activityCard.preferredTimezone})`}</p>
                                    </div>
                                    <div className={classes.modeInfo}>
                                        <div className={classes.modeInfoBox} data-tooltip-id={activityCard.uniqueId} onClick={(e) => { stopPropagation(e); navigateToGeolocation(activityCard) }}>
                                            <CgPin /> {activityCard.competitionType}
                                        </div>
                                        {activityCard.rewardFlag ? <div className={classes.modeInfoBox}>
                                            <IoMedalOutline /> Rewards
                                        </div> : null}
                                        <div className={classes.modeInfoBox}>
                                            <BiWallet /> {PaymentsUtil.calculateCost({ cost: activityCard.cost, discount: 0 })}
                                        </div>
                                    </div>
                                    {activityCard.competitionType?.toLowerCase() === AppConstants.competitionTypeOffline.toLowerCase() && <Tooltip className="tooltipMessage" id={activityCard.uniqueId}><span className="tooltipMessage">{activityCard.venueLocation}</span></Tooltip>}
                                </div>
                                {activityCard.disablePublicEnrolmentFlag === AppConstants.nText ? <Button className={classes.enrolButton} onClick={(e) => { stopPropagation(e); confirmBeforeEnrolment({ id: activityCard.competitionId, title: activityCard.title, uniqueId: activityCard.uniqueId, startTime: activityCard.utcStartTime, payOfflineFlag: activityCard.payOfflineFlag, creatorName: activityCard.organizer, creatorRole: activityCard.userRole }) }}>Enrol</Button> : <Button data-tooltip-id={`${activityCard.uniqueId}-private`} className={classes.privateButton}>
                                    <RiGitRepositoryPrivateFill />
                                    <Tooltip className="courseTooltipBlue" id={`${activityCard.uniqueId}-private`}><span className="courseTooltipBlue">This is a private competition. Public enrolments are disabled for this competition</span></Tooltip>
                                </Button>}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            {props.allActivitiesForSkill !== null && <div className={classes.loadMoreButton} >
                {cardCount < props.allActivitiesForSkill?.length && <BsArrowDownCircle className={classes.loadMoreIcon} onClick={() => setCardCount(cardCount + viewMoreCardsCount)} />}
            </div>}
            {(props.allActivitiesForSkill !== null && props.allActivitiesForSkill?.length >= AppConstants.ISPLearningActivityCardsLimit && ((Auth.isLogin() && Auth.getUserRole() === AppConstants.userRoleStudent) || !Auth.isLogin())) && <div className={classes.exploreMoreButton}>
                {(cardCount >= props.allActivitiesForSkill.length) && <Button onClick={handleLoginModalShow}>{Auth.isLogin() ? `Explore More` : `Login to Explore`}</Button>}
            </div>}
            {(message !== AppConstants.emptyString || paramData.category === AppConstants.learningActivitiesTypes.competition) && (showModal || paramData.category === AppConstants.learningActivitiesTypes.competition) && <EnrolmentModal onShow={handleModalShow} message={message} modalTypeError={modalTypeError} onClose={handleModalClose} enrolInCompetition={enrolInCompetition} setUserLoggedInAndEnroled={setUserLoggedInAndEnroled} userLoggedInAndEnroled={userLoggedInAndEnroled} paymentStatus={paymentStatus} transactionId={transactionId} confirmBeforeEnrolment={confirmBeforeEnrolment} enrolmentDetails={confirmationPopupActivityDetails} displayNetworkError={displayNetworkError} onClickRetry={onClickRetry}></EnrolmentModal>}
            {(props.allActivitiesForSkill == null || props.allActivitiesForSkill?.length === 0) && <p className='noDataAvailableError'>{`No competitions for '${props.skill}' skill to display at the moment`}</p>}
            {showLoginModal && <LoginModal onShow={handleLoginModalShow} onClose={handleLoginModalClose} />}
            {showConfirmationModal && <PostStartEnrolmentModal updateConfirmationStatus={updateConfirmationStatus} learningActivityDetails={confirmationPopupActivityDetails} />}
            {showOfflinePaymentModal && <PayOfflineConfirmationModal getLearnerPaymentMethod={getLearnerPaymentMethod} learningActivityDetails={confirmationPopupActivityDetails} />}
        </>
    );
}