import React, { useState, useEffect } from "react";
import classes from "../../createCourseComponent/addCourseDetails/AddCourseDetails.module.css";
import { AppConstants } from "../../../constants/AppConstants";
import { RiInformationFill } from "react-icons/ri";
import { Tooltip } from "react-tooltip";

export const AdvancedWorkshopSettings = (props) => {
    const { selectedWorkshopData, cost } = props;
    const [isOfflinePaymentDisabled, setIsOfflinePaymentDisabled] = useState(AppConstants.falseText);
    const [enableOfflinePayment, setEnableOfflinePayment] = useState(AppConstants.falseText);

    useEffect(() => {
        if (selectedWorkshopData.id) {
            setEnableOfflinePayment(selectedWorkshopData.payOfflineFlag === AppConstants.yText ? AppConstants.trueText : AppConstants.falseText);
        } else {
            setEnableOfflinePayment(AppConstants.falseText);
        }
    }, [selectedWorkshopData]);

    useEffect(() => {
        const handleChange = () => {
            props.changeAdvancedSettings({
                enableOfflinePayment
            });
        }
        handleChange();
    }, [enableOfflinePayment]);

    useEffect(() => {
        let netAmount = cost ? parseInt(cost) : 0;
        if (netAmount <= 0) {
            setIsOfflinePaymentDisabled(AppConstants.trueText);
            setEnableOfflinePayment(AppConstants.falseText);
        } else {
            setIsOfflinePaymentDisabled(AppConstants.falseText);
        }
    }, [cost]);

    return (
        <div className={classes.mainContainer}>
            <div className={classes.switchContainer}>
                <div className={classes.switchText}>Enable Offline Payment <RiInformationFill className={classes.infoButton} data-tooltip-id="paymentInfoTooltip" /><span><input type="checkbox" disabled={isOfflinePaymentDisabled} checked={enableOfflinePayment} onChange={() => setEnableOfflinePayment(!enableOfflinePayment)} /></span></div>
                <Tooltip className={classes.tooltipMessage} id="paymentInfoTooltip"><span className={classes.tooltipMessage}>{AppConstants.offlinePaymentTooltipInfo}</span></Tooltip>
            </div>
        </div>
    )
};


{/* <div className={classes.switchContainer}>
<div className={classes.switchText}>Enable Offline Payment <RiInformationFill className={classes.infoButton} data-tooltip-id="paymentInfoTooltip" /><span><input type="checkbox" disabled={isOfflinePaymentDisabled()} checked={enableOfflinePayment} onChange={() => setEnableOfflinePayment(!enableOfflinePayment)} /></span></div>
<Tooltip className={classes.tooltipMessage} id="paymentInfoTooltip"><span className={classes.tooltipMessage}>{AppConstants.offlinePaymentTooltipInfo}</span></Tooltip>
</div> */}