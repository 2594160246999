import React from 'react';
import classes from './ExpressionOfInterestPageBanner.module.css';
import { AppConstants } from '../../../constants/AppConstants';
import Carousel from "react-multi-carousel";
export const ExpressionOfInterestPageBanner = ({ eoiCarouselData }) => {
    const desktopBannerImages = eoiCarouselData.slides?.filter(image => image.imagePath.toLowerCase().includes(AppConstants.desktopText));
    const tabletBannerImages = eoiCarouselData.slides?.filter(image => image.imagePath.toLowerCase().includes(AppConstants.tabletText));
    const mobileBannerImages = eoiCarouselData.slides?.filter(image => image.imagePath.toLowerCase().includes(AppConstants.mobileText));

    const responsiveSlider = {
        desktop: {
            breakpoint: { max: 3000, min: 991 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 990, min: 481 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 480, min: 0 },
            items: 1
        }
    };

    return (
        <>
            <div className={classes.mobileImgContainer}>
                <Carousel responsive={responsiveSlider} autoPlay={AppConstants.trueText}
                    autoPlaySpeed={AppConstants.qrenciaNewsAutoPlaySpeed}
                    customTransition={AppConstants.qrenciaNewsCustomTransition}
                    rewindWithAnimation={AppConstants.trueText}
                    transitionDuration={AppConstants.eoiMobileCarouselDuration}
                    infinite={AppConstants.trueText}
                    showDots={AppConstants.trueText}
                    className="eoiBannerCarouselContainerImage">
                    {mobileBannerImages?.filter(data => data.active === AppConstants.trueText).map((slide) => (
                        <img key={slide.id} alt="mobile_banner" className={classes.bannerImage} src={slide.imagePath} />
                    ))}
                </Carousel>
            </div>
            <div className={classes.tabletImgContainer}>
                <Carousel responsive={responsiveSlider} autoPlay={AppConstants.trueText}
                    autoPlaySpeed={AppConstants.qrenciaNewsAutoPlaySpeed}
                    customTransition={AppConstants.qrenciaNewsCustomTransition}
                    rewindWithAnimation={AppConstants.trueText}
                    transitionDuration={AppConstants.eoiMobileCarouselDuration}
                    infinite={AppConstants.trueText}
                    showDots={AppConstants.trueText}
                    className="eoiBannerCarouselContainerImage">
                    {tabletBannerImages?.filter(data => data.active === AppConstants.trueText).map((slide) => (
                        <img key={slide.id} alt="tablet_banner" className={classes.bannerImage} src={slide.imagePath} />
                    ))}
                </Carousel>
            </div>
            <div className={classes.desktopImgContainer}>
                <Carousel responsive={responsiveSlider} autoPlay={AppConstants.trueText}
                    autoPlaySpeed={AppConstants.qrenciaNewsAutoPlaySpeed}
                    customTransition={AppConstants.qrenciaNewsCustomTransition}
                    rewindWithAnimation={AppConstants.trueText}
                    transitionDuration={AppConstants.eoiMobileCarouselDuration}
                    infinite={AppConstants.trueText}
                    showDots={AppConstants.trueText}
                    className="eoiBannerCarouselContainerImage" >
                    {desktopBannerImages?.filter(data => data.active === AppConstants.trueText).map((slide) => (
                        <img key={slide.id} alt="desktop_banner" className={classes.bannerImageWeb} src={slide.imagePath} />
                    ))}
                </Carousel>
            </div>
        </>
    )
}