import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { MdPeopleAlt } from "react-icons/md";
import { MdRocketLaunch } from "react-icons/md";
import { TbCirclesFilled } from "react-icons/tb";
import { StudentTemplatesConstants } from "../../constants/StudentTemplatesConstants";
import { AppConstants } from "../../constants/AppConstants";
import { AuthorizedDataService } from "../../services/AuthorizedDataService";
import classes from "./StudentActivitySnapshot.module.css";
import { MathUtil } from "../../utils/MathUtil";

export const StudentActivitySnapshot = () => {
    const [learningActivities, setLearningActivities] = useState({});

    let cardTitles = StudentTemplatesConstants.activitySnapshotCardTitles;

    useEffect(() => {
        const fetchStudentActivitySnapshot = async () => {
            const url = AppConstants.studentMylearningActivitySnapshotAPI;
            const response = await AuthorizedDataService.getRequest(
                url,
                AppConstants.emptyString,
                AppConstants.emptyString
            );
            if (response) {
                setLearningActivities(response);
            } else {
                setLearningActivities({});
            }
        }

        fetchStudentActivitySnapshot();
    }, []);

    return (
        <>
            <h3 className={classes.activitySnapshotHeading}>Activity Snapshot</h3>
            <div className={classes.activityContainer}>
                <Row xs={1} sm={2} md={2} lg={2} xl={4} >
                    <Col>
                        <div className={`${classes.activityCard} ${classes.activityCardOneBg}`}>
                            <div className={`${classes.iconContainer} ${classes.cardOneIconBg}`} ><TbCirclesFilled /></div>
                            <p className={`${classes.title} ${classes.competitionsTitle}`}>{cardTitles.competitionsParticipated}</p>
                            <h3 className={classes.count}>{MathUtil.formatNumberToLocaleString(learningActivities?.enrolledCompetitions ? learningActivities?.enrolledCompetitions : 0)}</h3>
                            <div className={`${classes.bottomDisplayLine} ${classes.cardOneIconBg}`}></div>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${classes.activityCard} ${classes.activityCardTwoBg}`}>
                            <div className={`${classes.iconContainer} ${classes.cardTwoIconBg}`}><MdRocketLaunch /></div>
                            <p className={`${classes.title} ${classes.skillLabsTitle}`}>{cardTitles.skillLabsEnrolled}</p>
                            <h3 className={classes.count}>{MathUtil.formatNumberToLocaleString(learningActivities?.enrolledCourses ? learningActivities?.enrolledCourses : 0)}</h3>
                            <div className={`${classes.bottomDisplayLine} ${classes.cardTwoIconBg}`}></div>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${classes.activityCard} ${classes.activityCardFourBg}`}>
                            <div className={`${classes.iconContainer} ${classes.cardFourIconBg}`}><MdPeopleAlt /></div>
                            <p className={classes.title}>{cardTitles.workshopsEnrolled}</p>
                            <h3 className={classes.count}>{MathUtil.formatNumberToLocaleString(learningActivities?.enrolledWorkshops ? learningActivities?.enrolledWorkshops : 0)}</h3>
                            <div className={`${classes.bottomDisplayLine} ${classes.cardFourIconBg}`}></div>
                        </div>
                    </Col>
                    <Col>
                        <div className={`${classes.activityCard} ${classes.activityCardThreeBg}`}>
                            <div className={`${classes.iconContainer} ${classes.cardThreeIconBg}`}><TbCirclesFilled /></div>
                            <p className={classes.title}>{cardTitles.nudgeRaisedAndReceived}</p>
                            <h3 className={classes.count}>{MathUtil.formatNumberToLocaleString(learningActivities?.receivedNudges ? learningActivities?.receivedNudges : 0)}</h3>
                            <div className={`${classes.bottomDisplayLine} ${classes.cardThreeIconBg}`}></div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}