import { Link } from "react-router-dom";
import { React, useState } from "react";
import studentCompetitionWidgetCards from './StudentCompetitionWidgetCards.module.css';
import { Row, Col, Card } from "react-bootstrap";
import { FaChalkboardTeacher, FaRegCalendarCheck } from 'react-icons/fa';
import { BiCalendarX } from 'react-icons/bi';
import { AppConstants } from "../../constants/AppConstants";
import Moment from 'react-moment';
import { DateTimeUtil } from "../../utils/DateTimeUtil";
import { FileOperationsUtil } from "../../utils/FileOperationsUtil";
import { TbRefreshAlert } from 'react-icons/tb';
import { BiDownload } from 'react-icons/bi';
import { MdShareLocation } from 'react-icons/md';
import 'moment-timezone';
import { Tooltip } from "react-tooltip";

export const StudentUpcomingCompetitionWidgetCards = (props) => {
    const timeZone = DateTimeUtil.getPreferredTimeZoneForUser();
    const timeZoneAbbr = DateTimeUtil.getZoneNameForPreferredTimeZone(timeZone);
    const [cardCount, setCardCount] = useState(AppConstants.cardCounts.rightWidgetCardCounts);
    const [downloadFailed, setDownloadFailed] = useState(false);

    //download competition pre-read
    async function downloadPreRead(competitionData) {
        setDownloadFailed(false);
        const fileUrl = competitionData.guidelinesDocumentPath;

        try {
            FileOperationsUtil.downloadDocument(fileUrl, competitionData.title, AppConstants.guidelinesFile);
        } catch (error) {
            setDownloadFailed(true);
        }
    }

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const handleCardClick = (url) => {
        window.open(url, AppConstants.newPageTargetSelf);
    };

    return (
        <>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className={studentCompetitionWidgetCards.competitionCardContainer}>
                {props.cardDetails?.slice(0, cardCount).map((competition) => (
                    <Col className={studentCompetitionWidgetCards.competitionCardSection} key={competition.id}>
                        <Card className={studentCompetitionWidgetCards.competitionCard} onClick={() => handleCardClick(`${AppConstants.forwardSlash}${AppConstants.competitions}${AppConstants.forwardSlash}${competition.categorySlug}${AppConstants.forwardSlash}${competition.skillSlug}${AppConstants.forwardSlash}${competition.competionSlug}`)}>
                            <Card.Body className={studentCompetitionWidgetCards.competitionCardBody}>
                                <Card.Title className={studentCompetitionWidgetCards.competitionTitle}><Link>{competition.title}</Link></Card.Title>
                                <div>
                                    {competition.clubQrenciaFlg === AppConstants.yText && <div className={studentCompetitionWidgetCards.dateTimeSection}>
                                        <FaChalkboardTeacher className={studentCompetitionWidgetCards.orangeCalendarIcon} /><div className={studentCompetitionWidgetCards.dateTimeText}>School</div></div>}
                                    <div className={studentCompetitionWidgetCards.dateTimeSection}>
                                        <FaRegCalendarCheck className={studentCompetitionWidgetCards.orangeCalendarIcon} /> <div className={studentCompetitionWidgetCards.dateTimeText}>Start:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{competition.startDateTimeUTC.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competition.startDateTimeUTC.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {competition.preferredTimezone === AppConstants.utcTimezone && `(${competition.preferredTimezone})`}</span></div>
                                    </div>
                                    <div className={studentCompetitionWidgetCards.dateTimeSection}>
                                        <BiCalendarX className={`${studentCompetitionWidgetCards.orangeCalendarIcon} ${studentCompetitionWidgetCards.endDateIcon}`} /> <div className={studentCompetitionWidgetCards.dateTimeText}>End:&nbsp;<span className="competitionDateTimeFormat"><Moment format={AppConstants.competitionCardsDateMonthFormat} tz={timeZone}>{competition.endDateTimeUTC.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> at <Moment format={AppConstants.twelveHoursTimeFormat} tz={timeZone}>{competition.endDateTimeUTC.replace(' ', AppConstants.tText) + AppConstants.zText}</Moment> {competition.preferredTimezone === AppConstants.utcTimezone && `(${competition.preferredTimezone})`}</span></div>
                                    </div>
                                </div>
                                <div onClick={e => { e.stopPropagation(); window.open(`${process.env.REACT_APP_FRONT_END_URL}${competition.userRole === AppConstants.userRoleExpert ? AppConstants.expert : AppConstants.agency}${AppConstants.forwardSlash}${competition.userSlug}`) }} className={studentCompetitionWidgetCards.expertNameText}>By : {competition.organizedBy}</div>
                                <div>
                                    {competition.type === AppConstants.competitionTypeOffline && <div onClick={stopPropagation}>
                                        <a rel="noreferrer" className={studentCompetitionWidgetCards.competitionCardLink} href={competition.geoLocation} target="_blank"> <span data-tooltip-place="top" data-tooltip-id={competition.uniqueId} className={studentCompetitionWidgetCards.widgitLocationSpan}> <MdShareLocation className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Venue Map</span></a> {competition.guidelinesDocumentPath != null && <span className={studentCompetitionWidgetCards.linkSeperator}>|</span>} {competition.guidelinesDocumentPath != null && <a className={studentCompetitionWidgetCards.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(competition); }}><BiDownload className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Guidelines {downloadFailed && <TbRefreshAlert className={studentCompetitionWidgetCards.alert} onClick={(e) => { { stopPropagation(e); downloadPreRead(competition) } }} />}</a>}
                                    </div>}
                                    <Tooltip className={studentCompetitionWidgetCards.tooltipMessage} id={competition.uniqueId}><span className={studentCompetitionWidgetCards.tooltipMessage}>{competition?.venueLocation}</span></Tooltip>
                                    {competition.type === AppConstants.competitionTypeOnline && <div>
                                        <p className={studentCompetitionWidgetCards.competitionOnlineModeText}><MdShareLocation className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Online</p> {competition.guidelinesDocumentPath != null && <span className={studentCompetitionWidgetCards.linkSeperator}>|</span>} {competition.guidelinesDocumentPath != null && <a className={studentCompetitionWidgetCards.competitionCardLink} onClick={(e) => { stopPropagation(e); downloadPreRead(competition) }}><BiDownload className={studentCompetitionWidgetCards.widgetCardsLocationIcon} /> Guidelines {downloadFailed && <TbRefreshAlert className={studentCompetitionWidgetCards.alert} onClick={(e) => { stopPropagation(e); downloadPreRead(competition) }} />}</a>}
                                    </div>}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row xs={1} sm={1} md={1} lg={1} xl={1} className="loadMore">
                <Col className="loadMoreButtonSection">
                    {cardCount < props.cardDetails?.length && <button className="loadMoreButton" onClick={() => setCardCount(cardCount + AppConstants.cardCounts.rightWidgetCardCounts)}>Load more</button>}
                </Col>
            </Row>
        </>
    );
}