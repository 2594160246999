import { Outlet } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Row, Col } from "react-bootstrap";
import { AdminHeader } from '../../layout/AdminPageLayout/AdminHeader';
import { AdminLeftNav } from '../../layout/AdminPageLayout/AdminLeftNav';
import { DataService } from "../../services/DataService";
import { AdminFooter } from '../../layout/AdminPageLayout/AdminFooter';
import { AppConstants } from "../../constants/AppConstants";
import { useLoaderData } from 'react-router-dom';

export const AdminTemplate = () => {
    const footerDetails = useLoaderData();
    
    return (
        <>
            <AdminHeader />
            <Row>
                <Col lg={2}><AdminLeftNav /></Col>
                <Col xs={12} sm={12} md={12} lg={10}>
                    <Outlet />
                </Col>
            </Row>
            {footerDetails != null && <AdminFooter footerDetails={footerDetails} />}
        </>
    )
}